import Dropzone from "react-dropzone";
import {FaPlus} from "react-icons/fa";
import {MdEdit} from "react-icons/md";
import {Row, Spinner} from "react-bootstrap";
import React, {useState} from "react";
import ImageCropperDialogHook from "./ImageCropperDialogHook";
import IconButton from "@material-ui/core/IconButton";

export default function CustomDropzone(props) {

    const [isCropperOpen, setIsCropperOpen] = useState(false)
    const [cropperImageSrc, setCropperImageSrc] = useState("")

    const defaultCrop = {
        aspect: props.aspect,
        unit: '%',
        width: 30,
    }

    const onDrop = (image) => {

        setCropperImageSrc(URL.createObjectURL(image[0]))
        setIsCropperOpen(true)

        console.log(URL.createObjectURL(image[0]))

        // props.onDrop(image)
    }

    const renderContents = () => {
        if (props.imageSrc) {

            return <>
                <img
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit : "contain"
                    }}
                    src={props.imageSrc}/>
                {
                    props.loading ? <Spinner
                            style={{fontSize: "1em", display: true ? "inline" : "none"}}
                            animation="border"/>
                        :
                        <IconButton style={{
                            position: "absolute",
                            zIndex: 10,
                            color: "white"
                        }}>
                            <MdEdit />
                        </IconButton>
                }
            </>
        } else {
            return <>
                {
                    props.loading ? <Spinner
                            style={{fontSize: "1em", display: true ? "inline" : "none"}}
                            animation="border"/>
                        :
                        <FaPlus pload style={{
                            fontSize: "1em",
                            marginBottom: "0.2em",
                        }}/>
                }
                <p style={{
                    fontSize: "0.5em",
                    marginBottom: "0em",
                    textAlign: "center",
                    fontFamily: 'OpenSans-Regular'
                }}>
                    {props.prompt}
                </p>

            </>
        }
    }

    return (
        <>

            <Dropzone
                accept={'image/jpeg, image/png'}
                noDrag={true}
                onDrop={onDrop}>
                {({getRootProps, getInputProps}) => (
                    <div {...getRootProps()} style={{
                        height: props.height,
                        width: props.width,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: "center",
                        borderWidth: 2,
                        borderRadius: "50%",
                        margin: 10,
                        overflow: "hidden",
                        borderColor: '#eeeeee',
                        borderStyle: 'dashed',
                        backgroundColor: '#fafafa',
                        color: '#bdbdbd',
                        outline: 'none',
                        fontSize: "1.5em",
                        transition: 'border .24s ease-in-out'
                    }}>
                        {renderContents()}
                        <input {...getInputProps()} />
                    </div>
                )}
            </Dropzone>


            <ImageCropperDialogHook
                isOpen={isCropperOpen}
                onClose={() => {
                    setIsCropperOpen(false)
                }}
                onNegativeButtonPressed={() => {
                    setIsCropperOpen(false)
                }}

                src={cropperImageSrc}
                crop={defaultCrop}
                onSubmit={(image) => {
                    setIsCropperOpen(false)
                    props.onDrop(image)
                }}
                negativeButtonText={"Back"}

            />

        </>

    )
}
