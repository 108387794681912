import ApiRequest from "../util/ApiRequest";
import GlobalData from "../util/GlobalData";

export default class CourseNotes {
    getMyNotes = async (memberCourseId) => {
        return await ApiRequest.set(`/v1/course_notes/${memberCourseId}`, "GET");
    }

    create = async (body) => {
        return await ApiRequest.set(`/v1/course_notes/create`, "POST", body);
    }

    delete = async (courseNoteId) => {
        return await ApiRequest.set(`/v1/course_notes/${courseNoteId}`, "DELETE");
    }

    update = async (courseNoteId, body) => {
        return await ApiRequest.set(`/v1/course_notes/${courseNoteId}`, "PUT", body);
    }
}
