import Dropzone, {useDropzone} from "react-dropzone";
import {FaPlus} from "react-icons/fa";
import {MdEdit} from "react-icons/md";
import {Row, Spinner} from "react-bootstrap";
import React, {useState, useCallback} from "react";
import ImageCropperDialogHook from "./ImageCropperDialogHook";
import IconButton from "@material-ui/core/IconButton";
import {MdFileUpload} from "react-icons/all";

export default function DocumentDropzone(props) {

    const onDrop = (acceptedFiles) =>{
        if(acceptedFiles.length < 1){
            alert("Format dokumen tidka didukung")
        }
        props.onDrop(acceptedFiles[0])
    }

    const renderContents = () => {

        return <>
            {
                props.loading ? <Spinner
                        style={{fontSize: "1em", display: true ? "inline" : "none"}}
                        animation="border"/>
                    :
                    <MdFileUpload pload style={{
                        fontSize: "2em",
                        marginBottom: "0.5em",
                    }}/>
            }
            <p style={{
                fontSize: "0.5em",
                marginBottom: "0em",
                textAlign: "center",
                fontFamily: 'OpenSans-Regular'
            }}>
                {props.prompt}

                {props.value && <div style={{fontSize : "1.1em"}}>
                    <br/>
                    <br/>
                    {props.value.name}
                </div> }
            </p>

        </>
    }

    return (
        <>

            <Dropzone
                noDrag={false}
                accept={'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation'}
                // noDrag={true}
                onDrop={onDrop}>
                {({getRootProps, getInputProps}) => (
                    <div {...getRootProps()} style={{
                        height: props.height,
                        width: props.width,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: "center",
                        borderWidth: 2,
                        borderRadius: props.isRound ? "50%" : null,
                        overflow: "hidden",
                        borderColor: '#eeeeee',
                        borderStyle: 'dashed',
                        backgroundColor: '#fafafa',
                        color: '#bdbdbd',
                        outline: 'none',
                        fontSize: "1.5em",
                        transition: 'border .24s ease-in-out',
                        cursor : "pointer"
                    }}>
                        <input {...getInputProps()} />
                        {renderContents()}
                    </div>
                )}
            </Dropzone>

        </>

    )
}
