import {Modal} from "react-bootstrap";
import Rating from "react-rating";
import Form from "react-bootstrap/Form";
import PageWrapper from "../page/PageWrapper";
import React, {useState} from "react";
import {AiOutlineClose} from "react-icons/ai";
import {IoMdStar} from "react-icons/io";

export default function RatingModal(props) {
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState(null);
    const [displayedPredicate, setDisplayedPredicate] = useState(null)

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.visible}
            onHide={props.onClose}
        >
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <AiOutlineClose onClick={props.onClose} size={25} style={{marginRight: 15, marginTop: 10, cursor: 'pointer'}}/>
            </div>

            <div style={{
                fontFamily: 'OpenSans-Bold',
                textAlign: 'center',
                marginTop: 15,
                fontSize: '1.25em',
                color: '#3f434f'
            }}>
                Beri penilaian kelas
            </div>

            <div style={{display: 'flex', justifyContent: 'center', marginTop: 30}}>
                <Rating
                    initialRating={rating}
                    onChange={(rating) => {
                        setRating(rating)

                        switch (rating) {
                            case 1:
                                setDisplayedPredicate('Sangat Buruk');
                                break;

                            case 2:
                                setDisplayedPredicate('Buruk');
                                break;

                            case 3:
                                setDisplayedPredicate('Biasa Saja');
                                break;

                            case 4:
                                setDisplayedPredicate('Baik');
                                break;

                            case 5:
                                setDisplayedPredicate('Sangat Baik');
                                break;
                        }
                    }}
                    emptySymbol={<IoMdStar size={50} color={'#C4C4C4'}/>}
                    fullSymbol={<IoMdStar size={50} color={'#FEBF35'}/>}/>
            </div>

            <div style={{
                fontFamily: 'OpenSans-SemiBold',
                fontSize: 17,
                color: '#4e9e99',
                textAlign: 'center',
                marginTop: 15,
                opacity: displayedPredicate ? 1 : 0
            }}>
                {displayedPredicate ? displayedPredicate : 'placeholder'}
            </div>

            <div style={{marginLeft: 20, marginRight: 20, marginTop: 30}}>
                <div style={{fontFamily: 'OpenSans-Bold', color: '#343643', marginBottom: 3}}>
                    Berikan Komentar
                </div>

                <Form.Control as="textarea" rows="3"
                              placeholder={'Berikan komentar kamu di sini'}
                              value={comment}
                              onChange={(e) => {
                                  setComment(e.target.value)
                              }}
                              style={{
                                  paddingLeft: 20,
                                  paddingTop: 15,
                                  fontFamily: 'OpenSans-Regular'
                              }}/>

                <div style={{cursor: 'pointer', userSelect: 'none', backgroundColor: '#2cb3ad', color: 'white', fontFamily: 'OpenSans-Bold', textAlign: 'center', paddingTop: 10, paddingBottom: 10, borderRadius: 5, marginTop: 10, marginBottom: 20}}>KIRIM</div>
            </div>
        </Modal>
    )
}
