import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import ApplicationActivities from "../../models/ApplicationActivity";
import {isMobile} from "react-device-detect";
import ArticleModel from "../../models/Article";
import PageWrapper from "./PageWrapper";
import moment from "moment";
import PaginationArticle from "../reusable/PaginationArticle";
import ReactPaginate from "react-paginate";

export default function ArticlePage(props) {
    let [articleData,setArticleData] = useState([]);
    //begin pagination
    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage] = useState(10);
    //end pagination


    const [isHovered, setHovered] = useState(false);

    const fetchArticle = async function () {
        try {
         let articleModel = await new ArticleModel().getAll();
            setArticleData(articleModel);
        } catch (e) {
            console.log(e)
        }
    }

    const indexOfLastPost = currentPage * postsPerPage;

    const currentPageData = articleData
        .slice(indexOfLastPost, indexOfLastPost + postsPerPage);
    const pageCount = Math.ceil(articleData.length / postsPerPage);


    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
    }
    function strip(html){
        let doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }


    const renderArticle  = (postData) => {

        let element = postData.map((item) =>
            <Col md={12}>
                <Card  style={{marginBottom:10,cursor: 'pointer'}}  onClick={event => { window.location.href = `${item.guid}` }} onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                    <div class="card-horizontal">
                        {/*<Card.Img style={{width:300,height:250}} variant="top" src="http://via.placeholder.com/300x180" />*/}

                        <Card.Body>

                            <Card.Title><h4><a href={item.guid}>{item.post_title}</a></h4></Card.Title>
                            <div style={{marginBottom:10}}>
                                <small className="text-muted author_list">
                                   <span style={{marginRight:10}}>
                                       {moment(item.post_date).format('DD MMMM YYYY')}
                                   </span>
                                    <span>
                                       Oleh {item.author_name}
                                    </span>

                                </small>
                            </div>
                            <Card.Text style={{borderBottom:1,fontWeight: 'normal',color:'#575757'}}>
                                <p style={{color:'#575757',fontSize:15,fontWeight: 'normal'}}  />
                                {strip(item.post_content).substring(0, 250) + '...'}

                            </Card.Text>
                            <div style={{marginBottom:10}}>
                                <small className="text-muted">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-folder" viewBox="0 0 16 16">
                                        <path
                                            d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z"/>
                                    </svg> {' ' + item.tag_name.toUpperCase()}
                                </small>
                            </div>
                        </Card.Body>
                    </div>

                </Card>
            </Col>
        )

        return element;
    }


    useEffect(() => {
        fetchArticle();
    },[]);

    return (
        <PageWrapper headerActive={'article'}>

            <Container style={{marginTop: '60px'}}>
                <Row style={{
                    marginTop: isMobile ? 0 : "6em",
                    fontFamily: 'OpenSans-Bold'
                }}>
                    { renderArticle(currentPageData)}
                    {/*<PaginationArticle*/}
                    {/*    postsPerPage={postsPerPage}*/}
                    {/*    totalPosts={articleData.length}*/}
                    {/*    paginate={paginate}*/}
                    {/*    currentPage = {currentPage}*/}
                    {/*/>*/}

                    <ReactPaginate
                        pageCount={pageCount}
                        nextLabel={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        className="bi bi-chevron-compact-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"/>
                        </svg>}
                        previousLabel={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" className="bi bi-chevron-compact-left"
                                            viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"/>
                        </svg>}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        previousLinkClassName={"pagination__link"}
                        nextLinkClassName={"pagination__link"}
                        disabledClassName={"pagination__link--disabled"}
                        activeClassName={"pagination__link--active"}
                    />

                </Row>

            </Container>
        </PageWrapper>
    );
}
