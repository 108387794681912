import ApiRequest from "../util/ApiRequest";
import GlobalData from "../util/GlobalData";

export default class CourseRating {
    create = async (body) => {
        return await ApiRequest.set("/v1/course_rating", "POST", body);
    }

    getByMemberCourseId = async (body) => {
        return await ApiRequest.set("/v1/course_rating", "POST", body);
    }
}
