import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import PageWrapper from "./PageWrapper";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {isMobile} from "react-device-detect";
import ReactPaginate from "react-paginate";
import LiveStreamingModel from "../../models/LiveStreaming";
import {useHistory, useParams} from "react-router-dom";
import moment from 'moment'

export default function LiveStreamingPage(props) {
    const [streamData, setStreamData] = useState([])
    const event_url = useParams()
    let history = useHistory()

    const getLiveStreamingData = async () => {
        try {
            const streamModel = new LiveStreamingModel();
            let stream = await streamModel.getUrlVideo(event_url.streaming_code);
            console.log('isi stream', stream)
            if (stream.active) {
                setStreamData(stream);
            } else {
                history.push("/notfound");
            }

        } catch (e) {
            console.log(e)
        }

    }

    const embeddedVideo = (url) => {
        let embedlink = '';
        if (url !== undefined) {
            var id = url.split("?v=")[1]; //sGbxmsDFVnE

            embedlink = "https://www.youtube.com/embed/" + id;
        }

        return embedlink;
    }

    const embeddedChat = (url) => {
        let embedlink = '';
        if (url !== undefined) {
            var id = url.split("?v=")[1]; //sGbxmsDFVnE

            embedlink = "https://www.youtube.com/live_chat?v=" + id + "&embed_domain=sinara.narabahasa.id";
        }

        return embedlink;
    }

    let isStreamFinished = true

    if (streamData && moment(streamData.stream_finish).isAfter()) {
        isStreamFinished = false;
    }

    useEffect(() => {
        getLiveStreamingData();

        if (!event_url.streaming_code) {
            history.push("/notfound");
        }

    }, []);
    return (
        <PageWrapper headerActive={'article'}>
            <Container style={{marginTop: '60px'}}>
                <Row style={{
                    marginTop: isMobile ? 0 : "6em",
                    fontFamily: 'OpenSans-Bold'
                }}>
                    <Col md={12}>
                        <div className="live-streaming-video-title">
                            <h2>
                                {streamData.name}
                            </h2>
                        </div>
                    </Col>
                    <Col md={isStreamFinished ? 12 : 9}>
                        <div className="embed-responsive embed-responsive-16by9 custom-video-ratio">
                            <iframe
                                allow="fullscreen" className="embed-responsive-item" type="text/html"
                                // src={embeddedVideo("https://www.youtube.com/watch?v=COih-eCtY_o")}
                                src={embeddedVideo(streamData.url_video)}
                                frameBorder="0"></iframe>
                        </div>
                    </Col>
                    {
                        !isStreamFinished && <Col
                            style={{
                                minHeight :"50vh",
                                marginBottom : "25vh",
                                padding : 0
                            }}
                            md={3}>
                            <div style={{height: "100%", display: 'flex', justifyContent: 'flex-end'}}>
                                <iframe
                                    width={"100%"}
                                    height={isMobile ? "150%" : "100%"}
                                    src={embeddedChat(streamData.url_video)}
                                    frameBorder="0"></iframe>
                            </div>
                        </Col>
                    }

                </Row>
            </Container>
        </PageWrapper>
    );
}
