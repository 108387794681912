import Container from "react-bootstrap/Container";
import React, {useEffect} from "react";
import PageWrapper from "./PageWrapper";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ApplicationActivities from "../../models/ApplicationActivity";
import {isMobile} from "react-device-detect";
import Palette from "../../util/Palette";
import {Button} from "react-bootstrap";

export default function ClaimGiftPage(props) {


    return (
        <PageWrapper>
            <Container style={{marginTop: 80}}>
                <Row>
                    <Col style={{textAlign : "center"}}>
                        <iframe
                            width={640}
                            height={1141}
                            src={'https://docs.google.com/forms/d/e/1FAIpQLSczDVuEtOddLDdVTaD4pxRlv4Y49Mm4CjU4MqiUry2l0sX8Ww/viewform?embedded=true'}
                            frameBorder={0}
                            marginHeight={0}
                            marginWidth={0}
                        >
                            Memuat...
                        </iframe>
                    </Col>

                </Row>

            </Container>
        </PageWrapper>
    )
}
