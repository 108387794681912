import {Button, Col, Container, Modal, Row,} from "react-bootstrap";
import React, {Component, useState} from "react";
import Form from "react-bootstrap/Form";
import User from "../../models/User";
import GlobalData from "../../util/GlobalData";
import Palette from "../../util/Palette";
import {
    isSafari, isMobileSafari
} from "react-device-detect";
import {useCookies} from "react-cookie";
import EditProfileModal from "./EditProfileModal";

import {useHistory} from "react-router-dom";
import Strings from "../../util/Strings";
import {Link} from "react-router-dom";

export default function LoginModal(props) {
    const [cookie, setCookie, removeCookie] = useCookies()
    // const isEN = cookie.lang !== "id";
    const isEN = false;
    const [editProfileShow, setEditProfileShow] = useState(false)

    const history = useHistory()

    const logout = async () => {
        GlobalData.token = null
        GlobalData.user = null

        removeCookie("token", {path: '/'})
        console.log("cookie " + cookie.token)
        setTimeout(function () {
            window.location = "/"
        }, 500);
    }

    const renderMainModal = () => {
        return <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.show}
            onHide={() => {
                props.onHide()
            }}
        >
            <Modal.Header>
                {/*<Modal.Title>*/}
                <Container>
                    <Row>
                        <Col md={12} xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: "center"}}>
                            <img
                                style={{
                                    height: "6em",
                                    objectFit: "contain",
                                }}
                                src={require("../../asset/new-narabahasa-logo.jpeg")}/>
                        </Col>
                    </Row>
                </Container>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{width: "100%", textAlign: "left"}}>
                            {Strings.menu_pop_up.welcome_user} {GlobalData.user?.full_name}
                        </div>
                    </Row>

                    <Row style={{display: 'flex', justifyContent: 'center', marginTop: "0.5em"}}>
                        <Button
                            variant={"outline-primary"}
                            style={{width: '100%', borderColor: Palette.PRIMARY,}}
                            onClick={() => {
                                history.push("/kelas#kelas-saya")
                                props.onHide()
                            }}>
                            Kelas Saya
                        </Button>
                    </Row>

                    <Row style={{display: 'flex', justifyContent: 'center', marginTop: "0.5em"}}>
                        <Link to={"/transaksi-saya"} style={{width : "100%"}}>
                            <Button
                                variant={"outline-primary"}
                                style={{width: '100%', borderColor: Palette.PRIMARY,}}
                                onClick={() => {
                                    props.onHide()
                                }}>
                                Transaksi Saya
                            </Button>
                        </Link>

                    </Row>

                    <Row style={{display: 'flex', justifyContent: 'center', marginTop: "0.5em"}}>
                        <Button
                            variant={"outline-primary"}
                            style={{width: '100%', borderColor: Palette.PRIMARY,}}
                            onClick={() => {
                                history.push("/profil")
                                props.onHide()
                            }}>
                            {Strings.menu_pop_up.edit_profile}
                        </Button>
                    </Row>

                    <Row style={{display: 'flex', justifyContent: 'center', marginTop: "0.5em"}}>
                        <Button
                            variant={"outline-primary"}
                            style={{width: '100%', borderColor: Palette.PRIMARY,}}
                            onClick={() => {
                                logout()
                            }}>
                            {Strings.menu_pop_up.sign_out}
                        </Button>
                    </Row>

                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row style={{display: 'flex', justifyContent: 'center'}}>
                        <Button
                            style={{backgroundColor: Palette.PRIMARY, borderWidth: 0, width: '100%'}}
                            onClick={() => {
                                props.onHide()
                            }}>
                            {Strings.menu_pop_up.back}
                        </Button>
                    </Row>

                </Container>
            </Modal.Footer>
        </Modal>
    }

    return <>
        {renderMainModal()}

        <EditProfileModal
            onHide={() => {
                setEditProfileShow(false)
            }}
            show={editProfileShow}
        />

    </>

}
