import PageWrapper from "./PageWrapper";
import Container from "react-bootstrap/Container";
import React, {useEffect, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import Palette from "../../util/Palette";
import {isMobile} from "react-device-detect";
import Session from "../../models/Session";
import DocumentFormModel from "../../models/DocumentForm";
import Strings from "../../util/Strings";
import {useHistory, useParams, Link} from "react-router-dom";
import moment from "moment";
import Form from "react-bootstrap/Form";
import GlobalData from "../../util/GlobalData";
import MuiAlert from "@material-ui/lab/Alert/Alert";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Select from "react-select";
import DocumentDropzone from "../reusable/DocumentDropzone";
import Spinner from "react-bootstrap/Spinner";

export default function DocumentForm(props) {

    const [upcomingSession, setUpcomingSession] = useState([])

    // console.log('GlobalData.user', GlobalData.user)

    const [isSessionInDatabase, setSessionInDatabase] = useState(true);
    const [classCode, setClassCode] = useState(null);
    const [className, setClassName] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [division, setDivision] = useState('');
    const [rank, setRank] = useState('');
    const [documentType, setDocumentType] = useState('');

    const [queuedFile, setQueuedFile] = useState(null)

    const [isUploading, setIsUploading] = useState(false)

    let {class_code} = useParams();

    const history = useHistory()

    useEffect(() => {
        fetchUpcomingSessions()
    }, [])

    const fetchUpcomingSessions = async () => {
        try {

            console.log("code", class_code)

            if (class_code) {

                try{
                    const session = await new Session().getByCode(class_code);

                    console.log("found ", session)

                    const isSameDay = new moment(session.start_time).format("DD MMMM YYYY") === new moment(session.end_time).format("DD MMMM YYYY");

                    setClassCode(class_code)
                    setClassName(session.name + ` (${new moment(session.start_time).format("DD MMMM YYYY")}${!isSameDay ? ' -- ' + new moment(session.end_time).format("DD MMMM YYYY") : ''})`)

                    return
                }catch(e){
                    console.log("class not found")
                }
            }

            const result = await new Session().getUpcomingForDropdown()
            let formattedSession = result.map((obj, key) => {
                console.log(obj)

                const isSameDay = new moment(obj.start_time).format("DD MMMM YYYY") === new moment(obj.end_time).format("DD MMMM YYYY");

                return {
                    value: obj.code,
                    label: obj.name + ` (${new moment(obj.start_time).format("DD MMMM YYYY")}${!isSameDay ? ' -- ' + new moment(obj.end_time).format("DD MMMM YYYY") : ''})`,
                }
            })

            console.log(formattedSession)

            setUpcomingSession(formattedSession)


        } catch (e) {
            console.log(e)
        }

    }

    const reset = () => {
        setEmail("")
        setName("")
        setClassCode(null)
        setClassName("")
        setDivision("")
        setRank("")
        setDocumentType("")
        setQueuedFile(null)
    }

    const handleSubmit = async () => {
        if (!email) {
            showSnackBar('Harap isi alamat surel Anda', 'warning')
        } else if (!validateEmail(email)) {
            showSnackBar('Harap masukan alamat surel yang valid', 'warning')
        } else if (!name) {
            showSnackBar('Harap isi nama Anda', 'warning')
        } else if (!classCode && isSessionInDatabase) {
            showSnackBar('Harap pilih kelas', 'warning')
        } else if (!className) {
            showSnackBar('Harap isi nama kelas', 'warning')
        } else if (!division) {
            showSnackBar('Harap isi divisi', 'warning')
        } else if (!rank) {
            showSnackBar('Harap isi jabatan', 'warning')
        } else if (!documentType) {
            showSnackBar('Harap isi jenis dokumen', 'warning')
        } else if (!queuedFile) {
            showSnackBar('Harap unggah dokumen', 'warning')
        } else {

            setIsUploading(true)

            try {
                let documentFormModel = new DocumentFormModel()

                const fileUploadResult = await documentFormModel.upload(queuedFile)
                console.log(fileUploadResult)

                const result = await documentFormModel.create({
                    full_name: name,
                    email,
                    class_code: classCode,
                    class_name: className,
                    division: division,
                    document_type: documentType,
                    rank: rank,
                    document_url: fileUploadResult.location
                });

                if (result.id) {
                    showSnackBar('Dokumen anda berhasil kami simpan.', 'success')
                    reset()
                }
                setIsUploading(false)

            } catch (e) {
                console.log(e)

                showSnackBar('Kesalahan Terjadi!', 'warning')
                setIsUploading(false)

            }
        }
        console.log('user', GlobalData.user)
        // console.log('test')
    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const [snackBarProp, setSnackBarProp] = useState({});

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const showSnackBar = (message, severity) => {
        setSnackBarProp({
            open: true,
            message,
            severity
        })
    }

    return <PageWrapper
        loginListener={(user) => {
            setName(user.full_name)
            setEmail(user.email)
        }}
        headerShy={false}
        headerActive={'book-store'}>

        <Snackbar
            anchorOrigin={{vertical: "top", horizontal: "center"}}
            open={snackBarProp.open} autoHideDuration={6000}
            onClose={() => {
                setSnackBarProp({
                    open: false
                })
            }}>
            <Alert severity={snackBarProp.severity}>
                {snackBarProp.message}
            </Alert>
        </Snackbar>

        <Container style={{marginBottom: '60px'}} fluid>

            <Container>
                <Row style={{
                    marginTop: isMobile ? 0 : "4em",
                    fontFamily: 'OpenSans-Bold'
                }}>

                </Row>

                <Row style={{
                    marginTop: "2em",
                }}>
                    <Container fluid style={{
                        maxWidth: 800,
                    }}>
                        <Row>

                            <Col
                                style={{
                                    color: Palette.PRIMARY,
                                    fontSize: "2em",
                                    fontFamily: 'OpenSans-Bold',
                                    textAlign: "center"
                                }}
                                md={12}>
                                Formulir Unggahan Dokumen
                                <br/>
                            </Col>

                            <Col md={12}>
                                <br/>
                                Harap gunakan formulir ini untuk mengunggah dokumen yang akan ditinjau. Harap unggah
                                dokumen dalam bentuk yang bisa diedit (DOC, XLS, atau PPT), bukan PDF. Terima kasih.
                                <br/>
                                <br/>
                            </Col>

                            <Col md={12}>
                                <Form>
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <Form.Label>Nama</Form.Label>
                                        <Form.Control
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            style={{fontSize: "1em"}} type="text" placeholder=""/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>{Strings.login_pop_up.email}</Form.Label>
                                        <Form.Control
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            style={{fontSize: "1em"}} type="email" placeholder=""/>
                                    </Form.Group>
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <Form.Label
                                            style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                            <div style={{flex: 1}}>
                                                Kelas
                                            </div>

                                            {/*<div>*/}
                                            {/*    <Checkbox*/}
                                            {/*        checked={!isSessionInDatabase}*/}
                                            {/*        onChange={() => setSessionInDatabase(!isSessionInDatabase)}*/}
                                            {/*        inputProps={{ 'aria-label': 'primary checkbox' }}*/}
                                            {/*    />*/}

                                            {/*    <small>Kelas tidak ada pada daftar</small>*/}
                                            {/*</div>*/}
                                        </Form.Label>
                                        <div style={{width: "100%"}}>
                                            {isSessionInDatabase ?
                                                <Select
                                                    isDisabled={class_code && classCode && className}
                                                    value={{value: classCode, label: className}}
                                                    onChange={(session) => {
                                                        setClassCode(session.value)
                                                        setClassName(session.label)
                                                    }}
                                                    placeholder={"Pilih Kelas"}
                                                    options={upcomingSession}
                                                    style={{
                                                        display: "flex", width: "100%"
                                                    }}
                                                /> :
                                                <Form.Control
                                                    onChange={(e) => setClassName(e.target.value)}
                                                    style={{fontSize: "1em"}} type="text" placeholder=""/>
                                            }
                                        </div>
                                    </Form.Group>

                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <Form.Label>Divisi</Form.Label>
                                        <Form.Control
                                            value={division}
                                            onChange={(e) => setDivision(e.target.value)}
                                            style={{fontSize: "1em"}} type="text" placeholder=""/>
                                    </Form.Group>

                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <Form.Label>Jabatan</Form.Label>
                                        <Form.Control
                                            value={rank}
                                            onChange={(e) => setRank(e.target.value)}
                                            style={{fontSize: "1em"}} type="text" placeholder=""/>
                                    </Form.Group>

                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <Form.Label>Jenis Dokumen</Form.Label>
                                        <Form.Control
                                            value={documentType}
                                            onChange={(e) => setDocumentType(e.target.value)}
                                            style={{fontSize: "1em"}} type="text" placeholder=""/>
                                    </Form.Group>

                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <Form.Label>Unggahan</Form.Label>
                                        <DocumentDropzone
                                            width={"100%"}
                                            height={"200px"}
                                            // id={"reference-component"}
                                            aspect={1}
                                            value={queuedFile}
                                            loading={false}
                                            prompt={<div style={{paddingLeft: 10, paddingRight: 10}}>Harap unggah
                                                dokumen dalam bentuk yang bisa diedit (DOC, XLS, atau PPT), bukan
                                                PDF.</div>}
                                            onDrop={acceptedFile => {
                                                console.log(acceptedFile)
                                                setQueuedFile(acceptedFile)
                                            }}
                                        />
                                    </Form.Group>

                                </Form>
                            </Col>
                            <Col md={12} style={{
                                display: "flex",
                                justifyContent: "center"
                            }}>
                                <Button
                                    style={{
                                        paddingLeft: 36,
                                        paddingRight: 36
                                    }}
                                    disabled={isUploading}
                                    onClick={handleSubmit}>
                                    Kirim
                                </Button>
                            </Col>
                            {
                                isUploading && <Col md={12} style={{textAlign: "center"}}>
                                    <br/>
                                    Sedang Menunggah Dokumen <Spinner animation="border" size={"sm"}/>
                                </Col>
                            }


                        </Row>
                    </Container>
                </Row>

            </Container>

        </Container>

    </PageWrapper>

}
