import ApiRequest from "../util/ApiRequest";

export default class ApplicationActivities{

    static queue = [];

    static sendActivity = async (activity) =>{

        this.queue.push(activity)

        console.log(this.queue)

        if(this.queue.length >= 5 ){

            try{
                const response = await ApiRequest.set('/activities', 'POST', this.queue);
                this.queue = [];

                return response;
            }catch(e){
                console.log(e)
            }


        }

    }

}
