import PageWrapper from "./PageWrapper";
import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Quiz from "../../models/Quiz";
import Form from "react-bootstrap/Form";
import Checkbox from "@material-ui/core/Checkbox";
import {withStyles} from '@material-ui/core/styles';
import Palette from "../../util/Palette";
import Radio from "@material-ui/core/Radio";
import Select from "react-select";
import Cities from "../../asset/constants/Cities";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import QuizTextInput from "../reusable/QuizTextInput";
import QuizRadioButton from "../reusable/QuizRadioButton";
import QuizCheckBox from "../reusable/QuizCheckBox";
import QuizCityPicker from "../reusable/QuizCityPicker";
import Button from "react-bootstrap/Button";
import GlobalData from "../../util/GlobalData";
import {useCookies} from "react-cookie";
import {useHistory} from "react-router-dom";
import Session from "../../models/Session";
import moment from "moment";
import QuizForm from "../../models/QuizForm";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Alert from "@material-ui/lab/Alert";

var momentTz = require('moment-timezone');

function shuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

export default function NoLoginQuizPage(props) {
    const [isBtnFocused, setBtnFocused] = useState(false);
    const [quizzes, setQuizzes] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [quizId, setQuizId] = useState(-1);
    const [cookie, setCookie, removeCookie] = useCookies();

    const [isAnswered, setAnswered] = useState(false);

    const [session, setSession] = useState({})

    const [score, setScore] = useState(0)

    const history = useHistory()

    const [username, setUsername] = useState(null)
    const [email, setEmail] = useState(null)

    const [snackBarProp, setSnackBarProp] = useState({});

    const [user, setUser] = useState({...GlobalData.user})

    const [multipleChoicesTotalQuestions, setMultipleChoicesTotalQuestions] = useState(0);
    // console.log("USER GLOB DT", GlobalData.user)

    useEffect(() => {
        getQuizData()
    }, [])

    const getSessionByCode = async () => {


        try {
            let sessionModel = new Session()
            const sessionObj = await sessionModel.getByCode(props.match.params.code);

            setSession(sessionObj);

            setTimeout(function () {
                if (moment().isAfter(sessionObj.question_end_time)) {
                    history.push(`/kelas/detail/${props.match.params.code}`)
                    alert('Waktu tes akhir sudah berakhir. Silakan hubungi admin untuk informasi lebih lengkap.')
                }
            }, 100);


        } catch (e) {
            console.log(e)
        }
    }

    const getQuizData = () => {
        Quiz.getByClassCode(props.match.params.code)
            .then(res => {
                getSessionByCode()

                let formattedQuiz = []

                for (let qj of res.question_json) {

                    console.log(qj)
                    formattedQuiz.push({
                        ...qj,
                        options: qj.options ? shuffle(qj.options) : null
                    })

                }
                setQuizzes(formattedQuiz)


                setQuizId(res.id);
            })
            .catch(err => {
                console.log("err", err)
                if (err.code) {
                    console.log(err.code)

                    if (err.code === 'NO_TOKEN_PROVIDED') {
                        alert('Harap login untuk mengakses halaman ini')
                    } else if (err.code === 'PARTICIPANT_ALREADY_ANSWER') {
                        alert('Anda sudah pernah menjawab kuis ini')
                    } else if (err.code === 'MEMBER_NOT_PARTICIPATED') {
                        alert('Anda belum terdaftar pada kelas ini')
                    }
                    window.open(`/kelas/detail/${props.match.params.code}`, "_self")
                }
            })

    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const handleSubmit = async() => {
        let isAllAnswered = true;

        answers.map(answer => {
            if (!answer) {
                isAllAnswered = false;
            }
        })

        console.log(answers)

        if (answers.length !== quizzes.length || !isAllAnswered) {
            showSnackBar('Mohon menjawab semua pertanyaan yang tersedia', 'warning');
        }
        else if(!username || username === ""){
            showSnackBar('Harap isi nama Anda', 'warning');
        }
        else if(!email || email === ""){
            showSnackBar('Harap isi alamat surel Anda', 'warning');
        }
        else if(!validateEmail(email)) {
            showSnackBar('Harap masukan alamat surel yang valid', 'warning')
        }
        else {
            setAnswered(true)

            let score = 0;
            let multipleChoicesTotalQuestions = 0;

            for (let i in answers) {

                let answer = answers[i]
                let question = quizzes[i]

                if (question.type === 'option') {
                    multipleChoicesTotalQuestions++;
                }

                if (answer === question.correctAnswer) {
                    // console.log("CORRECTO")
                    score++;
                }

            }

            setMultipleChoicesTotalQuestions(multipleChoicesTotalQuestions);
            setScore(score)

            try{
                let result = await QuizForm.answer(
                    props.match.params.code,
                    session.name,
                    username,
                    email,
                    quizId,
                    answers
                )
                showSnackBar("Jawaban tes akhir anda telah berhasil kami simpan.", 'success')
                console.log(result)
            }catch(e){
                console.log(e)
            }

        }

        console.log(isAllAnswered)
    }

    const showSnackBar = (message, severity) => {
        setSnackBarProp({
            open: true,
            message,
            severity
        })
    }

    return (
        <PageWrapper
            headerShy={false}
            history={props.history}
            form
            // hideFooter
            loginListener={(user) => {
                setUser(user)
                getQuizData()
            }}
        >

            <Snackbar
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                open={snackBarProp.open} autoHideDuration={6000}
                onClose={() => {
                    setSnackBarProp({
                        open: false
                    })
                }}>
                <Alert severity={snackBarProp.severity}>
                    {snackBarProp.message}
                </Alert>
            </Snackbar>

            <Container style={{
                marginTop: 150,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: "center",
                maxWidth: 900,
            }}>
                <Row style={{
                    width: "100%"
                }}>
                    <Col md={12} style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start"
                    }}>
                        {
                            props.match.params.code ?
                                <div>
                                    <a href={"/kelas/detail/" + props.match.params.code}>{"<"} Kembali ke kelas</a>
                                </div>
                                :
                                null
                        }

                        <b style={{
                            fontSize: "1.3em",
                            marginTop: 10
                        }}>Tes Akhir
                        </b>

                        <div style={{
                            marginTop: 10
                        }}>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{width: 100}}>
                                    Kelas
                                </div>
                                <div>
                                    : {session.name}
                                </div>
                            </div>

                            <div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{width: 100}}>
                                    Tanggal Sesi
                                </div>
                                <div>
                                    : {new moment(session.start_time).format("D MMMM YYYY")}
                                </div>
                            </div>


                            <div style={{display: "flex", flexDirection: "row"}}>
                                <div>
                                    Harap dikumpulkan
                                    sebelum {momentTz(session.question_end_time).tz('Asia/Jakarta').format('D MMMM YYYY, HH:mm')} WIB
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row style={{
                    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                    borderRadius: 10,
                    marginLeft: 5,
                    marginRight: 5,
                    backgroundColor: 'white',
                    maxWidth: 900,
                    width: '100%',
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingTop: 35,
                    paddingBottom: 25,
                    marginTop: 20
                }}
                >
                    <Col md={12} style={{fontFamily: 'OpenSans-SemiBold', fontSize: '18px', color: '#202124'}}>
                        Nama
                    </Col>

                    {/*<Col md={12}*/}
                    {/*     style={{fontFamily: 'OpenSans-Regular', fontSize: '15px', marginTop: '4px', color: '#878787'}}>*/}
                    {/*    Masukan nama lengkap anda (Untuk Sertifikat)*/}
                    {/*</Col>*/}

                    <Col xs={12}>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Control
                                value={username}
                                disabled={isAnswered}
                                onChange={(event) => setUsername(event.target.value)}
                                style={{
                                    color: 'black',
                                    boxShadow: 'none',
                                    fontSize: '18px',
                                    fontFamily: 'OpenSans-Regular',
                                    borderTopWidth: 0,
                                    borderLeftWidth: 0,
                                    borderRightWidth: 0,
                                    borderRadius: 0,
                                    borderBottomWidth: 2,
                                    marginTop: 15,
                                }} placeholder="Nama"/>
                        </Form.Group>
                    </Col>
                    <Col md={12} style={{fontFamily: 'OpenSans-SemiBold', fontSize: '18px', color: '#202124'}}>
                        Surel <i>(Email)</i>
                    </Col>
                    <Col xs={12}>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Control
                                value={email}
                                disabled={isAnswered}
                                onChange={(event) => setEmail(event.target.value)}
                                style={{
                                    color: 'black',
                                    boxShadow: 'none',
                                    fontSize: '18px',
                                    fontFamily: 'OpenSans-Regular',
                                    borderTopWidth: 0,
                                    borderLeftWidth: 0,
                                    borderRightWidth: 0,
                                    borderRadius: 0,
                                    borderBottomWidth: 2,
                                    marginTop: 15,
                                }} placeholder="Surel"/>
                        </Form.Group>
                    </Col>
                </Row>

                {
                    quizzes.map(
                        (quiz, idx) => {
                            switch (quiz.type) {
                                case "isian":
                                    return <QuizTextInput
                                        idx={idx}
                                        question={quiz.fieldName}
                                        description={quiz.description}
                                        value={answers[idx]}
                                        disabled={isAnswered}
                                        onChange={(value) => {
                                            const answersTemp = [...answers];

                                            answersTemp[idx] = value;

                                            setAnswers(answersTemp)
                                        }}
                                    />

                                case "option":
                                    return <QuizRadioButton
                                        idx={idx}
                                        question={quiz.fieldName}
                                        description={quiz.description}
                                        options={quiz.options}
                                        correctAnswer={quiz.correctAnswer}
                                        value={answers[idx]}
                                        disabled={isAnswered}
                                        onChange={(value) => {
                                            const answersTemp = [...answers];

                                            answersTemp[idx] = value;

                                            setAnswers(answersTemp)
                                        }}/>
                            }
                        }
                    )
                }
                {/*<QuizTextInput/>*/}

                {/*<QuizCheckBox/>*/}

                {/*<QuizRadioButton/>*/}

                {/*<QuizCityPicker/>*/}

                {quizzes.length !== 0 && !isAnswered ?
                    <Row style={{
                        width: "100%",
                        maxWidth: 900, paddingLeft: 20, display: "flex", justifyContent: "flex-end"
                    }}>
                        <Button
                            onClick={handleSubmit}
                            onMouseEnter={() => setBtnFocused(true)}
                            onMouseLeave={() => setBtnFocused(false)}
                            style={{
                                fontFamily: 'OpenSans-SemiBold',
                                backgroundColor: '#3882e0',
                                borderWidth: 0,
                                paddingLeft: 35,
                                paddingRight: 35,
                                paddingTop: 10,
                                paddingBottom: 10,
                                marginTop: 20,
                                fontSize: '18px',
                                opacity: isBtnFocused ? .85 : 1
                            }}>
                            Kirim
                        </Button>
                    </Row> : <Row style={{
                        width: "100%",
                        maxWidth: 900, paddingLeft: 20, display: "flex", justifyContent: "flex-end"
                    }}>
                        <Button
                            disabled={true}
                            variant={"success"}
                            style={{
                                fontFamily: 'OpenSans-SemiBold',
                                borderWidth: 0,
                                paddingLeft: 35,
                                paddingRight: 35,
                                paddingTop: 10,
                                paddingBottom: 10,
                                marginTop: 20,
                                fontSize: '18px',
                            }}>
                            Nilai Anda : {score}/{multipleChoicesTotalQuestions}
                        </Button>
                    </Row>
                }
                <div style={{height : 60}}>

                </div>
            </Container>
        </PageWrapper>
    )
}
