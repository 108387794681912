import {Card, Modal} from "react-bootstrap";
import React from "react";
import GlobalData from "../../util/GlobalData";

export default function ExternalUrlModal(props) {

    let urls = props.urlList ? props.urlList : []

    return <>
        <Modal
            {...props}
            size="md"
            centered
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Header closeButton>
                Pilih Pranala Luar
            </Modal.Header>
            <Modal.Body>

                {
                    urls.map((obj,key) => {
                        return <a href={obj.url}>
                            <Card style={{
                                display : "flex",
                                flexDirection : "row",
                                alignItems : "center",
                                color : "black",
                                marginBottom : "0.2em"
                            }}>
                                <img
                                    style={{
                                        height : "3em",
                                        width : "5em",
                                        objectFit : "contain"
                                    }}
                                    src={obj.url.includes("tokopedia") ? require("../../asset/logo-tokped.png") : obj.url.includes("bukuindie") ? require("../../asset/tokobudi.jpg") : require("../../asset/logo-shopee.jpg")}/>
                                {obj.name}
                            </Card>
                        </a>
                    })
                }

            </Modal.Body>
        </Modal>
    </>

}
