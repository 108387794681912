import ApiRequest from "../util/ApiRequest";

export default class Merchandise {

     getAll = async () => {
        let news = await ApiRequest.set("/v1/merchandises", "GET");

        return news
    }

    create = async (body) => {
         return await ApiRequest.set('/v1/merchandise/create', 'POST', body)
    }

    getById = async (id) => {
        return await ApiRequest.set(`/v1/merchandise/` + id, "GET");
    }

    deleteById = async (id) => {
        return await ApiRequest.set(`/v1/merchandise/` + id, "DELETE");
    }

    restoreById = async (id) => {
        return await ApiRequest.set(`/v1/merchandise/` + id, "PATCH");
    }

    updateById = async (id, body) => {
         return await ApiRequest.set(`/v1/merchandise/` + id, "PUT", body)
    }
}
