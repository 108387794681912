import Container from "react-bootstrap/Container";
import React, {useEffect, useState} from "react";
import PageWrapper from "./PageWrapper";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ApplicationActivities from "../../models/ApplicationActivity";
import {isMobile} from "react-device-detect";
import Palette from "../../util/Palette";
import moment from "moment";
import {Button} from "react-bootstrap";
import User from "../../models/User";
import Form from "react-bootstrap/Form";
import {
    isSafari, isMobileSafari
} from "react-device-detect";
import Strings from "../../util/Strings";
import Select from "react-select";
// import Cities from "../../asset/constants/Cities";
import CustomDropzone from "../reusable/CustomDropzone";
import ProvincesWithCities from "../../models/ProvincesWithCities";
import GlobalData from "../../util/GlobalData";
export default function Profile(props) {
    const editable_strings = GlobalData.strings.profile_page;
    let [user, setUser] = useState({})

    let [editedUser, setEditedUser] = useState({})
    let [editMode, setEditMode] = useState(false)

    let [iconLoading, setIconLoading] = useState(false)

    const [provinces, setProvinces] = useState([]);
    const [cities, setCities] = useState([]);
    const isDeviceSafari = isSafari || isMobileSafari

    useEffect(() => {
        getUserData()

        getProvinces()
    }, [])


    const getUserData = async () => {
        try {
            let user = new User()
            let result = await user.tokenLogin()
            setUser(result)
            console.log(result)
        } catch (e) {
            console.log(e)
        }
    }

    const getProvinces = async () => {
        const provincesWithCities = new ProvincesWithCities();

        const provinces = await provincesWithCities.getProvinces();

        const provincesTemp = provinces.map(province => {
            return {value: province, label: province}
        });

        setProvinces(provincesTemp)
    }

    const getCitiesByProvince = async (province) => {
        const provincesWithCities = new ProvincesWithCities();

        const cities = await provincesWithCities.getCitiesByProvince(province);

        const citiesTemp = cities.map(city => {
            return {value: city, label: city}
        });

        setCities(citiesTemp)
    }

    const saveUser = async () => {
        if (!editedUser.full_name) {
            alert('Nama lengkap tidak boleh kosong!')
        } else if (!editedUser.email) {
            alert('Alamat surel tidak boleh kosong!')
        } else {
            try {
                let user = new User()
                let result = await user.updateUser({...editedUser})

                getUserData()
                setEditMode(false)

                console.log(result)
            } catch (e) {
                console.log(e)
            }
        }
    }

    const onImagePicked = async function (image) {
        console.log(image)
        setIconLoading(true)
        try {
            let result = await new User().updateProfilePicture(image)

            console.log(result)

            setIconLoading(false)
            setEditedUser({...editedUser, image_url: result.location})

        } catch (e) {
            let tempMessage = "Kesalahan Terjadi"
            if (e.msg) {
                if (e.msg.message) {
                    tempMessage = e.msg.message
                }
            }
            setIconLoading(false)
            console.log(e)
        }
    }


    return (
        <PageWrapper headerActive={"profile"}>
            <Container style={{marginTop: isMobile ? "4em" : "5em"}}>
                <Row style={{display: 'flex', justifyContent: 'center'}}>
                    <span style={{fontSize: 24, fontWeight: 'bold'}}>{editable_strings.profile}</span>
                </Row>

                <Row style={{
                    marginTop: isMobile ? 30 : 40,
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <Col
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                        md={3}
                        sm={12}
                    >
                        {
                            editMode ?
                                <CustomDropzone
                                    width={isMobile ? "10em" : "200px"}
                                    height={isMobile ? "10em" : "200px"}
                                    // id={"reference-component"}
                                    aspect={1}
                                    imageSrc={editedUser?.image_url ? editedUser?.image_url : require("../../asset/public_image_default_user.png")}
                                    loading={iconLoading}
                                    prompt={<></>}
                                    onDrop={acceptedFiles => onImagePicked(acceptedFiles)}
                                />
                                :
                                <img
                                    style={{
                                        padding: 10,
                                        width: isMobile ? "10em" : "80%",
                                        objectFit: "contain",
                                        borderRadius: "50%",
                                        height: isMobile ? "10em" : null,
                                        backgroundColor: Palette.SECONDARY,
                                        marginBottom: isMobile ? "1em" : null
                                    }}
                                    src={user?.image_url ? user?.image_url : require("../../asset/public_image_default_user.png")}/>

                        }


                    </Col>

                    <Col md={4} style={{
                        display: !isMobile ? "none" : null,
                    }}>

                    </Col>
                    <Col md={5}>

                        <div
                            style={{fontFamily: 'OpenSans-SemiBold', marginTop: isMobile ? '1.5em': null}}>{editable_strings.name}
                        </div>
                        <div style={{
                            fontSize: "1.2em",
                            color: 'grey',
                            display: editMode ? "none" : null
                        }}>
                            {user.full_name}
                        </div>
                        <div style={{
                            display: !editMode ? "none" : null
                        }}>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Control
                                    onChange={evt => {
                                        setEditedUser({...editedUser, full_name: evt.target.value})
                                    }}
                                    value={editedUser.full_name}
                                    style={{fontSize: "1em"}} placeholder=""/>
                            </Form.Group>
                        </div>

                        <div
                            style={{marginTop: 20, fontFamily: 'OpenSans-SemiBold'}}>{editable_strings.email}
                        </div>
                        <div style={{
                            fontSize: "1.2em",
                            color: 'grey',
                            display: editMode ? "none" : null
                        }}>
                            {user.email}
                        </div>
                        <div style={{
                            display: !editMode ? "none" : null
                        }}>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Control
                                    onChange={evt => {
                                        console.log(evt.target.value + "")
                                        setEditedUser({...editedUser, email: evt.target.value})
                                    }}
                                    value={editedUser.email}
                                    style={{fontSize: "1em"}} type="email" placeholder=""/>
                            </Form.Group>
                        </div>

                        <Row style={{
                            marginTop: 20,
                            display: isMobile ? "none" : null
                        }}>
                            <Col md={6} style={{
                                marginTop: 10,
                            }}>
                                <Button
                                    variant={editMode ? "outline-primary" : "primary"}

                                    onClick={() => {
                                        setEditMode(!editMode)
                                        setEditedUser({...user})

                                        if (user.province) {
                                            getCitiesByProvince(user.province)
                                        }
                                    }}
                                    block>{editMode ? Strings.profile_page.cancel : editable_strings.edit_profile}</Button>
                            </Col>
                            {/*<Col md={6} style={{*/}
                            {/*    marginTop : 10,*/}
                            {/*    display : editMode ? "none" : null*/}
                            {/*}}>*/}
                            {/*    <Button block>Ubah Password</Button>*/}
                            {/*</Col>*/}

                            <Col md={6} style={{
                                marginTop: 10,
                                display: !editMode ? "none" : null
                            }}>
                                <Button
                                    onClick={() => {
                                        saveUser()
                                    }}
                                    variant={"outline-primary"}
                                    block>
                                    {Strings.profile_page.save}
                                </Button>
                            </Col>
                        </Row>

                    </Col>
                </Row>

                <Row style={{
                    marginTop: isMobile ? 0 : 30,
                    justifyContent: "center"
                }}>
                    <Col md={{span: 4}} xs={12}>
                        <div
                            style={{
                                fontFamily: 'OpenSans-SemiBold',
                                marginTop: isMobile && editMode ? 5 : 20
                            }}>{editable_strings.birth_date}
                        </div>
                        <div style={{
                            fontSize: "1.2em",
                            color: 'grey',
                            display: editMode ? "none" : null
                        }}>
                            {user.date_of_birth ? new moment(user.date_of_birth).format("DD MMMM YYYY") : "-"}
                        </div>
                        <div style={{
                            display: !editMode ? "none" : null
                        }}>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Control
                                    onChange={evt => {
                                        console.log('dob:', evt.target.value ? new Date(evt.target.value) : null)
                                        setEditedUser({
                                            ...editedUser,
                                            date_of_birth: evt.target.value ? new Date(evt.target.value) : null
                                        })
                                    }}
                                    value={new moment(editedUser.date_of_birth).format("YYYY-MM-DD")}
                                    style={{fontSize: "1em"}} type="date" placeholder=""/>
                            </Form.Group>
                        </div>
                    </Col>
                    <Col md={{span: 4}} xs={12}>
                        <div
                            style={{
                                fontFamily: 'OpenSans-SemiBold',
                                marginTop: isMobile && editMode ? 5 : 20
                            }}>{editable_strings.gender}
                        </div>
                        <div style={{
                            fontSize: "1.2em",
                            color: 'grey',
                            display: editMode ? "none" : null
                        }}>
                            {user.gender ? user.gender === "M" ? "Pria" : "Wanita" : "-"}
                        </div>
                        <div style={{
                            display: !editMode ? "none" : null
                        }}>
                            <Form.Check
                                inline
                                checked={editedUser.gender === "M"}
                                onChange={evt => setEditedUser({...editedUser, gender: evt.target.value})}
                                name={"gender"}
                                type={"radio"}
                                label={`Pria`}
                                value={"M"}
                            />

                            <Form.Check
                                inline
                                checked={editedUser.gender === "F"}
                                onChange={evt => setEditedUser({...editedUser, gender: evt.target.value})}
                                name={"gender"}
                                type={"radio"}
                                label={`Wanita`}
                                value={"F"}
                            />
                        </div>
                    </Col>

                </Row>

                <Row style={{
                    marginTop: isMobile ? 0 : 30,
                    justifyContent: "center"
                }}>
                    <Col md={{span: 4}} xs={12}>
                        <div
                            style={{fontFamily: 'OpenSans-SemiBold', marginTop: 20}}>{editable_strings.province}
                        </div>
                        <div style={{
                            fontSize: "1.2em",
                            color: 'grey',
                            display: editMode ? "none" : null
                        }}>
                            {user.province ? user.province : "-"}
                        </div>
                        <div style={{
                            display: !editMode ? "none" : null
                        }}>
                            <Select
                                options={provinces}
                                style={{
                                    display: "flex", width: "100%"
                                }}
                                value={{value: editedUser.province, label: editedUser.province}}
                                onChange={(option) => {
                                    getCitiesByProvince(option.value)
                                    setEditedUser({...editedUser, province: option.value, city: ''})
                                }}
                                placeholder={"Pilih"}
                                fullWidth
                            />
                            {/*<Form.Group controlId="exampleForm.ControlSelect1">*/}
                            {/*    <Form.Control*/}
                            {/*        onChange={evt => {*/}
                            {/*            setEditedUser({...editedUser, city: evt.target.value})*/}
                            {/*        }}*/}
                            {/*        value={editedUser.city}*/}
                            {/*        style={{fontSize: "1em"}} placeholder=""/>*/}
                            {/*</Form.Group>*/}
                        </div>
                    </Col>

                    <Col md={{span: 4}} xs={12}>
                        <div
                            style={{fontFamily: 'OpenSans-SemiBold', marginTop: 20}}>{editable_strings.city}
                        </div>
                        <div style={{
                            fontSize: "1.2em",
                            color: 'grey',
                            display: editMode ? "none" : null
                        }}>
                            {user.city ? user.city : "-"}
                        </div>
                        <div style={{
                            display: !editMode ? "none" : null
                        }}>
                            <Select
                                options={cities}
                                style={{
                                    display: "flex", width: "100%"
                                }}
                                value={{value: editedUser.city, label: editedUser.city}}
                                onChange={(option) => {
                                    setEditedUser({...editedUser, city: option.value})
                                }}
                                placeholder={"Pilih"}
                                fullWidth
                            />
                            {/*<Form.Group controlId="exampleForm.ControlSelect1">*/}
                            {/*    <Form.Control*/}
                            {/*        onChange={evt => {*/}
                            {/*            setEditedUser({...editedUser, city: evt.target.value})*/}
                            {/*        }}*/}
                            {/*        value={editedUser.city}*/}
                            {/*        style={{fontSize: "1em"}} placeholder=""/>*/}
                            {/*</Form.Group>*/}
                        </div>
                    </Col>

                </Row>

                <Row style={{
                    marginTop: isMobile ? 0 : 30,
                    justifyContent: "center"
                }}>

                    <Col md={{span: 4}} xs={12}>
                        <div
                            style={{fontFamily: 'OpenSans-SemiBold', marginTop: 20}}>{editable_strings.organization}
                        </div>
                        <div style={{
                            fontSize: "1.2em",
                            color: 'grey',
                            display: editMode ? "none" : null
                        }}>
                            {user.organization ? user.organization : "-"}
                        </div>
                        <div style={{
                            display: !editMode ? "none" : null
                        }}>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Control
                                    onChange={evt => {
                                        setEditedUser({...editedUser, organization: evt.target.value})
                                    }}
                                    value={editedUser.organization}
                                    style={{fontSize: "1em"}} placeholder=""/>
                            </Form.Group>
                        </div>
                    </Col>

                    <Col md={{span: 4}} xs={12}>
                        <div
                            style={{
                                fontFamily: 'OpenSans-SemiBold',
                                marginTop: isMobile && editMode ? 5 : 20
                            }}>{editable_strings.phone_num}
                        </div>
                        <div style={{
                            fontSize: "1.2em",
                            color: 'grey',
                            display: editMode ? "none" : null
                        }}>
                            {user.phone_num ? user.phone_num : "-"}
                        </div>
                        <div style={{
                            display: !editMode ? "none" : null
                        }}>
                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Control
                                    onChange={evt => {
                                        setEditedUser({...editedUser, phone_num: evt.target.value})
                                    }}
                                    value={editedUser.phone_num}
                                    style={{fontSize: "1em"}} placeholder=""/>
                            </Form.Group>
                        </div>
                    </Col>
                </Row>

                <Row style={{
                    marginTop: isMobile ? 0 : 30,
                    justifyContent: "center"
                }}>
                    <Col md={{span: 4}} xs={12}>
                        <div
                            style={{
                                fontFamily: 'OpenSans-SemiBold',
                                marginTop: isMobile && editMode ? 5 : 20
                            }}>{editable_strings.subscribe_newsletter}
                        </div>
                        <div style={{
                            fontSize: "1.2em",
                            color: 'grey',
                            display: editMode ? "none" : null
                        }}>
                            {user.subscribe_newsletter ? "Iya" : 'Tidak'}
                        </div>
                        <div style={{
                            display: !editMode ? "none" : null
                        }}>
                            <Form.Check
                                inline
                                checked={editedUser.subscribe_newsletter === "1" || editedUser.subscribe_newsletter === 1}
                                onChange={evt => {
                                    console.log(evt.target.value)
                                    setEditedUser({...editedUser, subscribe_newsletter: evt.target.value})
                                }}
                                name={"subscribe-newsletter"}
                                type={"radio"}
                                label={`Iya`}
                                value={1}
                            />

                            <Form.Check
                                inline
                                checked={editedUser.subscribe_newsletter === "0" || editedUser.subscribe_newsletter === 0}
                                onChange={evt => {
                                    console.log(evt.target.value)
                                    setEditedUser({...editedUser, subscribe_newsletter: evt.target.value})
                                }}
                                name={"subscribe-newsletter"}
                                type={"radio"}
                                label={`Tidak`}
                                value={0}
                            />
                        </div>
                    </Col>
                    <Col md={{span: 4}} xs={12}>

                    </Col>

                </Row>

                <Row style={{
                    display: !isMobile ? "none" : null,
                    marginTop: 30,
                    marginBottom: 40
                }}
                >
                    <Col md={12} style={{
                        paddingTop: "1em"
                    }}>
                        <Button
                            variant={editMode ? "outline-primary" : "primary"}
                            onClick={() => {
                                setEditMode(!editMode)
                                setEditedUser({...user})

                                if (user.province) {
                                    getCitiesByProvince(user.province)
                                }
                            }}
                            block>{editMode ? Strings.profile_page.cancel : editable_strings.edit_profile}</Button>
                    </Col>

                    <Col md={12} style={{
                        marginTop: 10,
                        display: !editMode ? "none" : null
                    }}>
                        <Button
                            onClick={() => {
                                saveUser()
                            }}
                            variant={"outline-primary"}
                            block>
                            {Strings.profile_page.save}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </PageWrapper>
    )
}
