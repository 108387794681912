import Container from "react-bootstrap/Container";
import React, { useEffect } from "react";
import PageWrapper from "./PageWrapper";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ApplicationActivities from "../../models/ApplicationActivity";
import { isMobile } from "react-device-detect";
import Palette from "../../util/Palette";
import { Button } from "react-bootstrap";

export default function KoordinasiGoogleFormPage(props) {

    return (
        <PageWrapper>
            <Container style={{ marginTop: 80 }}>
                <Row>
                    <Col style={{ textAlign: "center" }}>
                        <div className="responsive-survey">
                            <iframe
                                width={640}
                                height={1300}
                                src={'https://docs.google.com/forms/d/e/1FAIpQLSeh_bk-nxYwnBXqbXQVOIUxhasVAbN3YoVEIc21CYtOU93G8A/viewform?embedded=true'}
                                frameBorder={0}
                                marginHeight={0}
                                marginWidth={0}
                            >
                                Memuat...
                            </iframe>
                        </div>
                    </Col>

                </Row>

            </Container>
        </PageWrapper>
    )
}
