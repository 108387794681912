import React, {Component, useEffect, useState} from 'react';
import {BrowserRouter, HashRouter, Route, useHistory, Switch} from "react-router-dom"
import './App.css';
import Home from "./component/page/Home";
import {ParallaxProvider} from "react-scroll-parallax";
import Sessions from "./component/page/Sessions";
import {withCookies, CookiesProvider, useCookies} from "react-cookie";
import GlobalData from "./util/GlobalData";
import BookStore from "./component/page/BookStore";
import Info from "./component/page/Info";
import PostPaymentPage from "./component/page/PostPaymentPage";
import News from "./component/page/News";
import ReadNews from "./component/page/ReadNews";
import SessionDetail from "./component/page/SessionDetail";
import ArticlePage from "./component/page/ArticlePage";
import RegisteredSessions from "./component/page/RegisteredSessions";
import ProfilePage from "./component/page/ProfilePage";
import NotFound from "./component/page/NotFound";
import QuizPage from "./component/page/QuizPage";
import OnlineClassPreview from "./component/page/OnlineClassPreview";
import OnlineClass from "./component/page/OnlineClass";
import Transaction from "./component/page/Transaction";
import NewIndex from "./component/page/NewIndex";
import ContactUsForm from "./component/page/ContacUsForm";
import PresentForm from "./component/page/PresentForm";
import Merchandise from "./component/page/MerchandiseV4";
import ComplaintForm from "./component/page/ComplaintForm";
import DocumentForm from "./component/page/DocumentForm";
import Strings from "./models/Strings";
import LiveStreamingPage from "./component/page/LiveStreamingPage";
import WatchRecordingPage from "./component/page/WatchRecordingPage";
import TTSPage from "./component/page/TTSPage";
import NoLoginQuizPage from "./component/page/NoLoginQuizPage";
import LiveStreamingChatPage from "./component/page/LiveStreamingChatPage";
import PDFPage from "./component/page/PDFPage";
import PDFPageV2 from "./component/page/PDFPageV2";
import ClaimGiftPage from "./component/page/ClaimGiftPage";
import RespondentIdentityPage from "./component/page/RespondentIdentityPage";
import KoordinasiGoogleFormPage from "./component/page/KoordinasiGoogleFormPage";
import FeedBackPage from './component/page/FeedbackPage';

// import {Connector, subscribe} from 'mqtt-react';

function App() {

    const [cookie, setCookie, removeCookie] = useCookies()

    const [stringsLoaded, setStringsLoaded] = useState(false)

    useEffect(() => {
        getStrings()
    }, [])

    // async startMQTT(){
    //     let mqttManager = new MQTTManager();
    //     await mqttManager.initialize()
    // }

    const getStrings = async () => {
        try {
            const stringModel = new Strings();

            const result = await stringModel.get();

            GlobalData.strings = JSON.parse(result.json);

            setStringsLoaded(true)

        } catch (e) {
            console.log(e)
        }
    }

    if (!stringsLoaded) {
        return <></>
    }

    return (
        <>
            <CookiesProvider>
                <ParallaxProvider>
                    <BrowserRouter basename="/">
                        {/*<BrowserRouter>*/}
                        <Switch>

                            <Route exact path="/" component={NewIndex}/>
                            <Route exact path="/kelas" component={Sessions}/>
                            <Route exact path="/account-activated" component={Sessions}/>
                            <Route path="/post-payment" component={PostPaymentPage}/>
                            {/*<Route path="/festival-literasi" component={Sessions}/>*/}
                            {/*<Route path="/seminar/:id" component={Sessions}/>*/}
                            <Route exact path="/seminar" component={Sessions}/>
                            <Route path="/seminar/detail/:code" component={SessionDetail}/>
                            <Route path="/kelas/detail/:code" component={SessionDetail}/>
                            <Route path="/masuk/:transactionCode" component={SessionDetail}/>

                            <Route path="/tes/masuk/:token" component={QuizPage}/>

                            <Route path="/tes/:code" component={QuizPage}/>

                            <Route path="/kuis/:code" component={NoLoginQuizPage}/>
                            <Route path="/tes-akhir/:code" component={NoLoginQuizPage}/>


                            <Route path="/support" component={Info}/>
                            {/*<Route path="/baca-mading/:id" component={ReadNews}/>*/}
                            {/*<Route path="/kelas-terdaftar" component={RegisteredSessions}/>*/}
                            <Route exact path= "/siaran-langsung/:streaming_code" component={LiveStreamingPage}/>
                            <Route exact path= "/chat/:id" component={LiveStreamingChatPage}/>

                            <Route path="/profil" component={ProfilePage}/>
                            <Route path="/kdm/detail/:code" component={OnlineClassPreview}/>

                            <Route path="/kdm/:id" component={OnlineClassPreview}/>
                            <Route path="/kelas-daring-mandiri/:id" component={OnlineClass}/>

                            <Route path="/:code/kelas-daring-mandiri/pratinjau" component={OnlineClassPreview}/>

                            <Route path="/:code/kelas-daring-mandiri" component={OnlineClass}/>

                            <Route path="/transaksi-saya" component={Transaction}/>
                            <Route path="/kreasi" component={Merchandise}/>
                            <Route exact path="/transaksi-saya" component={Transaction}/>
                            <Route path="/transaksi-saya/:order_id" component={Transaction}/>

                            <Route exact path="/form-pertanyaan" component={ContactUsForm}/>
                            <Route exact path="/pertanyaan" component={ContactUsForm}/>
                            <Route exact path="/artikel" component={ArticlePage}/>
                            <Route exact path="/presensi" component={PresentForm}/>

                            <Route exact path= "/aduan" component={ComplaintForm}/>

                            <Route exact path= "/formulir-dokumen" component={DocumentForm}/>

                            <Route exact path= "/pertanyaan/:class_code" component={ContactUsForm}/>
                            <Route exact path= "/presensi/:class_code" component={PresentForm}/>
                            <Route exact path= "/formulir-dokumen/:class_code" component={DocumentForm}/>
                            <Route exact path= "/rekaman" component={WatchRecordingPage}/>

                            <Route exact path= "/tts-narabahasa" component={TTSPage}/>
                            <Route exact path= "/tts" component={TTSPage}/>

                            <Route exact path= "/pdf-page/:pdfURL" component={PDFPage}/>
                            <Route exact path= "/pdf-page-v2/view" component={PDFPageV2}/>
                            <Route exact path= "/klaim" component={ClaimGiftPage}/>
                            <Route exact path= "/survei" component={RespondentIdentityPage}/>
                            <Route exact path= "/koordinasi" component={KoordinasiGoogleFormPage}/>
                            <Route exact path= "/umpanbalik" component={FeedBackPage}/>

                            <Route path="*" component={NotFound}/>

                        </Switch>
                    </BrowserRouter>
                </ParallaxProvider>
            </CookiesProvider>
        </>

    );


}


export default withCookies(App);
