import ApiRequest from "../util/ApiRequest";
import GlobalData from "../util/GlobalData";

export default class DocumentForm {
    create = async (body) => {
        return await ApiRequest.set(`/v1/document_forms`, "POST", body);
    }
    upload = async (image) => {

        let formData = new FormData();

        formData.append('upload', image, image.name);

        return await ApiRequest.setMultipart(`/v1/document_forms/attachment`, "POST", formData);

    }
}
