import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import React, {Component, useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import User from "../../models/User";
import GlobalData from "../../util/GlobalData";
import Palette from "../../util/Palette";
import {useCookies} from "react-cookie";
import moment from "moment";
import Select from "react-select";
import Cities from "../../asset/constants/Cities";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Strings from "../../util/Strings";
import ProvincesWithCities from "../../models/ProvincesWithCities";

export default function EditProfileModal(props) {
    const [cookie, setCookie, removeCookie] = useCookies()
    const isEN = false;

    const defaultRegisterState = {
        passwordMismatch: false,
        emailIsRegistered: false,
    }

    const [user, setUser] = useState({...GlobalData.user});
    const [error, setError] = useState(null);
    const [registerState, setRegisterState] = useState({...defaultRegisterState});
    const [provinces, setProvinces] = useState([]);
    const [cities, setCities] = useState([]);

    const getProvinces = async () => {
        const provincesWithCities = new ProvincesWithCities();

        const provinces = await provincesWithCities.getProvinces();

        const provincesTemp = provinces.map(province => {
            return {value: province, label: province}
        });

        setProvinces(provincesTemp)
    }

    const getCitiesByProvince = async (province) => {
        const provincesWithCities = new ProvincesWithCities();

        const cities = await provincesWithCities.getCitiesByProvince(province);

        const citiesTemp = cities.map(city => {
            return {value: city, label: city}
        });

        setCities(citiesTemp)
    }

    useEffect(() => {
        if (props.show) {
            getProvinces()

            if(GlobalData.user.province) {
                getCitiesByProvince(GlobalData.user.province)
            }
            // setError('Harap lengkapi data Anda sebelum mendaftar pada sesi ini')
            setUser({...GlobalData.user});
        }

    }, [props.show])

    const handleSubmit = async () => {
        setError(null);

        let userModel = new User()

        let param = {
            ...GlobalData.user,
            ...user
        }

        // console.log(param)

        if(!param.email) {
            setError('Alamat surel tidak boleh kosong!')
        } else if(!param.full_name) {
            setError('Nama lengkap tidak boleh kosong!')
        } else if(!param.date_of_birth) {
            setError('Tanggal lahir tidak boleh kosong!')
        } else if(!param.gender) {
            setError('Jenis Kelamin tidak boleh kosong!')
        } else if(!param.organization) {
            setError('Organisasi tidak boleh kosong!')
        } else if(!param.province) {
            setError('Provinsi tempat tinggal tidak boleh kosong!')
        } else if(!param.city) {
            setError('Kota tempat tinggal tidak boleh kosong!')
        } else if(!param.phone_num) {
            setError('Nomor Ponsel tidak boleh kosong!')
        } else {
            try {
                let result = await userModel.updateUser(param)
                console.log(result)
                if (props.onHide) props.onHide();
                if (props.onProfileCompleted) props.onProfileCompleted(result)

                // alert(isEN ? 'Profile Successfully Updated!' : "Profil Berhasil Diubah!")
                // window.location.reload();
            } catch (e) {
                let msg = isEN ? 'An error occured' : "Terjadi Kesalahan"

                if (e.msg) {
                    if (e.code === "DUPLICATE_ACCOUNT") {
                        msg = isEN ? 'Email has been registered' : "Email Telah Terdaftar"
                    }

                    setError(msg);

                    return;
                }

                setError(msg);

                console.log(e)
            }
        }
    }

    const renderEditProfileBody = () => {

        // console.log(user)

        return <> <p style={{
            fontSize: "0.8em"
        }}>
            {isEN ? 'Complete Profile' : 'Lengkapi Profil'}<br/>
        </p>
            <Form
                style={{
                    fontSize: "0.7em"
                }}>
                <Form.Group>
                    <Form.Label>Alamat Surel <a
                        style={{
                            color: "red",
                            display: registerState.emailIsRegistered ? "inline" : "none"
                        }}>{isEN ? '(Email has been registered)' : '(Alamat surel telah terdaftar)'}</a></Form.Label>
                    <Form.Control
                        value={user.email}
                        onChange={evt => setUser({...user, email: evt.target.value})}
                        style={{fontSize: "1em"}} type="email" placeholder=""/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{isEN ? 'Full Name' : 'Nama Lengkap'}</Form.Label>
                    <Form.Control
                        value={user.full_name}
                        onChange={evt => setUser({...user, full_name: evt.target.value})}
                        style={{fontSize: "1em"}} type="text" placeholder=""/>
                </Form.Group>

                <Form.Group>
                    <Form.Label>{isEN ? 'Birth Date' : 'Tanggal Lahir'}</Form.Label>

                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Control
                            onChange={evt => {
                                console.log('dob:', evt.target.value ? new Date(evt.target.value) : null)
                                setUser({...user, date_of_birth: evt.target.value ? new Date(evt.target.value) : null})
                            }}
                            value={new moment(user.date_of_birth).format("YYYY-MM-DD")}
                            style={{fontSize: "1em"}} type="date" placeholder=""/>
                    </Form.Group>
                </Form.Group>

                <Form.Group>
                    <Form.Label>{isEN ? 'Gender' : 'Jenis Kelamin'}</Form.Label>

                    <div>
                        <Form.Check
                            inline
                            checked={user.gender === "M"}
                            onChange={evt => setUser({...user, gender: evt.target.value})}
                            name={"gender"}
                            type={"radio"}
                            label={`Pria`}
                            value={"M"}
                        />

                        <Form.Check
                            inline
                            checked={user.gender === "F"}
                            onChange={evt => setUser({...user, gender: evt.target.value})}
                            name={"gender"}
                            type={"radio"}
                            label={`Wanita`}
                            value={"F"}
                        />
                    </div>
                </Form.Group>

                <Form.Group>
                    <Form.Label>{isEN ? 'Province' : 'Provinsi Tempat Tinggal'}</Form.Label>
                    <Select
                        options={provinces}
                        style={{
                            display: "flex", width: "100%"
                        }}
                        value={{value: user.province, label: user.province}}
                        onChange={(option) => {
                            setUser({...user, province: option.value, city: ''})
                            getCitiesByProvince(option.value)
                        }}
                        placeholder={"Pilih"}
                        fullWidth
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Label>{isEN ? 'City' : 'Kabupaten/Kota'}</Form.Label>
                    <Select
                        options={cities}
                        style={{
                            display: "flex", width: "100%"
                        }}
                        value={{value: user.city, label: user.city}}
                        onChange={(option) => {
                            setUser({...user, city: option.value})
                        }}
                        placeholder={"Pilih"}
                        fullWidth
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Label>{isEN ? 'Organization' : 'Organisasi'}</Form.Label>
                    <Form.Control
                        value={user.organization}
                        onChange={evt => setUser({...user, organization: evt.target.value})}
                        style={{fontSize: "1em"}} type="text" placeholder=""/>
                </Form.Group>

                <Form.Group controlId="exampleForm.ControlSelect1" style={{marginBottom: 0}}>
                    <Form.Label>{isEN ? 'Phone Number' : 'Nomor Ponsel'}</Form.Label>
                    <Form.Control
                        value={user.phone_num}
                        onChange={evt => setUser({...user, phone_num: evt.target.value})}
                        style={{fontSize: "1em"}} type="text" placeholder=""/>
                </Form.Group>

                <FormControlLabel
                    control={
                        <Checkbox
                            color={"success"}
                            onChange={(asdf) => {
                                setUser({...user, subscribe_newsletter: !user.subscribe_newsletter})
                            }}
                            checked={user.subscribe_newsletter || false}
                        />
                    }
                    style={{
                        color : user.subscribe_newsletter ? null : "grey",
                    }}
                    label={<p style={{ fontSize: '.75em', paddingTop: 20}}>{Strings.register_pop_up.register_newsletter}</p>}
                />
            </Form>
        </>
    }

    return <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.show}
        onHide={() => {
            setError(null);

            setRegisterState({...registerState, passwordMismatch: false, emailIsRegistered: false})

            props.onHide();
        }}
    >
        <Modal.Header>
            {/*<Modal.Title>*/}
            <Container>
                <Row>
                    <Col md={6} xs={6} style={{display: 'flex', alignItems: 'center'}}>
                        <img
                            style={{
                                height: "4vh",
                                objectFit: "contain",
                            }}
                            src={require("../../asset/mainlogo.png")}/>
                    </Col>
                    <Col md={6} xs={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        {props.onHide ? <p style={{fontWeight: 'bold', cursor: 'pointer', color: 'grey'}}
                                           onClick={props.onHide}>x</p> : null}
                    </Col>
                </Row>
            </Container>


            {/*</Modal.Title>*/}
        </Modal.Header>
        <Modal.Body>
            {renderEditProfileBody()}
            <span style={{
                color: "red",
                fontSize: "0.8em"
            }}>
                    {error ? error : props.message}
                </span>

        </Modal.Body>
        <Modal.Footer>
            <Container style={{padding: 0}}>
                <Row>
                    <Col md={6} style={{marginTop: '10px'}}>

                    </Col>
                    <Col md={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button
                            style={{backgroundColor: Palette.PRIMARY, borderWidth: 0}}
                            onClick={handleSubmit}>{isEN ? 'Save' : 'Simpan'}</Button>
                    </Col>
                </Row>
            </Container>
        </Modal.Footer>
    </Modal>
}
