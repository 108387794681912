import ApiRequest from "../util/ApiRequest";
import GlobalData from "../util/GlobalData";

export default class Quiz {
    static getByClassCode = async (code) => {
        return await ApiRequest.set(`/v1/class/${code}/quiz/question`, "GET");
    }

    static getByParticipationToken = async (token) => {
        return await ApiRequest.set(`/v1/quiz/token/${token}`, "GET");
    }

    static answer = async (id, body) => {
        return await ApiRequest.set(`/v1/quiz/question/${id}/answer`, "POST", {answer_json: body});
    }

    static answerWithToken = async (token, body) => {
        return await ApiRequest.set(`/v1/quiz/answer/${token}/token`, "PUT", {answer_json: body});
    }
}
