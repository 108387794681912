import {useHistory, useParams, useLocation, Link} from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";


import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
    FaPlus,
    FaMinus,
    FaBars,
    FaCartPlus, MdArrowBack

} from "react-icons/all";
import Divider from "@material-ui/core/Divider";
import Col from "react-bootstrap/Col";

import Palette from "../../util/Palette";
import {ButtonGroup, Row, Spinner} from "react-bootstrap";
import Dropzone from "react-dropzone";
import IconButton from "@material-ui/core/IconButton";

import {useCookies} from "react-cookie";
import textFormatter from "../../util/textFormatter";
import GlobalData from "../../util/GlobalData";
import {
    isMobile
} from "react-device-detect";
import SimpleLineIcon from "react-simple-line-icons";
import {AiOutlineClose} from "react-icons/ai";
// import CartManager from "../../util/CartManager";
// import Product from "../../models/Product";
import MuiAlert from "@material-ui/lab/Alert/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import ProgressBar from "./ProgressBar";
import Container from "react-bootstrap/Container";
import moment from "moment";
import Form from "react-bootstrap/Form";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Order from '../../models/Order'
import PresenceForm from "../../models/PresenceForm";
import Session from "../../models/Session";
import Strings from "../../util/Strings";
import CompleteProfileModal from "./CompleteProfileModal";

const drawerWidth = 550

export default function Cart(props) {
    const DISCOUNT_TYPE = {
        PERCENT: 'PERCENT',
        AMOUNT: 'AMOUNT'
    }

    const orderModel = new Order();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const sessionModel = new Session();
    let history = useHistory()
    let location = useLocation();

    const [user, setUser] = useState({...props.user});
    const anchor = "right"
    const [isCheckoutModalShown, setCheckoutModalShown] = useState(false);
    const [cookie, setCookie, removeCookie] = useCookies(["token"])
    const [events, setEvents] = useState([])
    const [activeEvent, setActiveEvent] = useState({})

    const [products, setProducts] = useState([])
    const [participantNote, setParticipantNote] = useState(null);

    const [cartData, setCartData] = useState([])

    const {event_url} = useParams()

    const [isLoading, setLoading] = useState(false);
    const [voucherCode, setVoucherCode] = useState("")
    const [voucherMessage, setVoucherMessage] = useState("")
    const [discountPercentage, setDiscountPercentage] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [discountType, setDiscountType] = useState(null);
    const [completeProfileModalMessage, setCompleteProfileModalMessage] = useState("")
    const [isCompleteProfileModalShown, setCompleteProfileModalShown] = useState(false)
    // console.log("active tab " + props.activeTab)

    // const fetchProducts = async () => {
    //     try {
    //         const allProducts = await new Product().getAll();
    //         console.log(allProducts)
    //         setProducts(allProducts)
    //
    //         try {
    //         } catch (e) {
    //             e.API = 'fetchSessions'
    //             console.log(e)
    //         }
    //     } catch (e) {
    //         console.log(e)
    //     }
    // }

    const {cart, setCart, cartEmptyAction} = props;

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
        // fetchProducts()
    }, [])

    useEffect(() => {
        setUser(props.user)
        // setCartData(CartManager.getCartContent())
    }, [props.user])

    const getProductData = (id) => {

        let selectedProduct = products.find((obj, key) => {
            return obj.id == id
        })

        return selectedProduct ? selectedProduct : {}
    }

    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    // const product =
    //     [
    //         {
    //          id: 1,
    //          name: "Baju Raka",
    //          size: "XL",
    //          price: 30000,
    //             quantity: 4,
    //          image:  require("./baju1.jpg")
    //         },
    //         {
    //             id: 2,
    //             name: "Exclusive clothes",
    //             size: "M",
    //             price: 130000,
    //             quantity: 1,
    //             image:  require("./baju2.jpg")
    //
    //         },
    //         {
    //             id: 2,
    //             name: "Exclusive clothes",
    //             size: "M",
    //             price: 130000,
    //             quantity: 1,
    //             image:  require("./baju2.jpg")
    //
    //         },{
    //         id: 2,
    //         name: "Exclusive clothes",
    //         size: "M",
    //         price: 130000,
    //         quantity: 1,
    //         image:  require("./baju2.jpg")
    //
    //     },{
    //         id: 2,
    //         name: "Exclusive clothes",
    //         size: "M",
    //         price: 130000,
    //         quantity: 1,
    //         image:  require("./baju2.jpg")
    //
    //     },{
    //         id: 2,
    //         name: "Exclusive clothes",
    //         size: "M",
    //         price: 130000,
    //         quantity: 1,
    //         image:  require("./baju2.jpg")
    //
    //     },{
    //         id: 2,
    //         name: "Exclusive clothes",
    //         size: "M",
    //         price: 130000,
    //         quantity: 1,
    //         image:  require("./baju2.jpg")
    //
    //     },{
    //         id: 2,
    //         name: "Exclusive clothes",
    //         size: "M",
    //         price: 130000,
    //         quantity: 1,
    //         image:  require("./baju2.jpg")
    //
    //     },{
    //         id: 2,
    //         name: "Exclusive clothes",
    //         size: "M",
    //         price: 130000,
    //         quantity: 1,
    //         image:  require("./baju2.jpg")
    //
    //     },{
    //         id: 2,
    //         name: "Exclusive clothes",
    //         size: "M",
    //         price: 130000,
    //         quantity: 1,
    //         image:  require("./baju2.jpg")
    //
    //     },{
    //         id: 2,
    //         name: "Exclusive clothes",
    //         size: "M",
    //         price: 130000,
    //         quantity: 1,
    //         image:  require("./baju2.jpg")
    //
    //     },{
    //         id: 2,
    //         name: "Exclusive clothes",
    //         size: "M",
    //         price: 130000,
    //         quantity: 1,
    //         image:  require("./baju2.jpg")
    //
    //     },
    //
    //     ];

    const checkout = () => {
        console.log('checkout', user)
        if (!user.date_of_birth || !user.gender || !user.phone_num || !user.organization || !user.city) {
            setCompleteProfileModalShown(true)
            setCompleteProfileModalMessage('Harap lengkapi data Anda sebelum melakukan pembayaran')
        } else {
            setCheckoutModalShown(true)
        }


        // setLoading(true)
        //
        // setTimeout(() => {
        //     window.location.href = 'https://midtrans.com/?utm_source=google&utm_medium=paidsearch&utm_campaign=ID20_Midtrans_Leads_MerchantAcquisition_Brand&utm_term=midtrans&utm_content=midtrans&gclid=Cj0KCQiA-aGCBhCwARIsAHDl5x-hYsTY6a0wprftwv1KeDdpDcIPhl2MvNAUkywmFZuA6ksIYs4dUYYaAjgvEALw_wcB'
        // }, 3000)
    }

    const getVoucherInfo = async () => {
        let sessionModel = new Session();
        try {
            let result = await sessionModel.getVoucherInfo(voucherCode)

            // console.log(result)

            if (result.used) {
                alert('Voucer ini telah digunakan')
                setVoucherCode("")
                setDiscountPercentage(0)
                return
            }

            let price = cart.reduce((total, obj) => {
                return total + obj.price
            }, 0)

            if(result.minimum_usage){
                if(result.minimum_usage > price){
                    setDiscountPercentage(0)
                    setDiscountAmount(0)
                    return setVoucherMessage("Minimum pembelian untuk memakai voucer ini adalah Rp" + thousandSeparator(result.minimum_usage))
                }
            }

            if (result.percentage) {
                setDiscountType(DISCOUNT_TYPE.PERCENT)
                setVoucherMessage(Strings.class_detail.you_will_get_discount + result.percentage + "%")
                setDiscountPercentage(result.percentage)
            } else {
                setDiscountType(DISCOUNT_TYPE.AMOUNT)
                setVoucherMessage(Strings.class_detail.you_will_get_discount + "Rp" + thousandSeparator(result.amount))
                setDiscountAmount(result.amount)
            }


        } catch (e) {
            console.log(e.code)
            if (e.code === "VOUCHER_NOT_FOUND") {
                setVoucherMessage(Strings.class_detail.code_not_found)
                setDiscountPercentage(0)
            }
        }
    }

    const thousandSeparator = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    const totalPrice =
        cart.reduce((total, obj) => {
            return total + obj.price
        }, 0)

    return (
        <>
            <CompleteProfileModal
                message={completeProfileModalMessage}
                show={isCompleteProfileModalShown}
                onProfileCompleted={(user) => {
                    setCheckoutModalShown(true)

                    setUser(user)
                }}
                onHide={() => {
                    setCompleteProfileModalShown(false)
                }}
            />

            <Dialog
                open={isCheckoutModalShown}
                fullWidth>
                <Container>
                    <Row style={{marginTop: 25, marginBottom: 15}}>
                        <Col style={{fontFamily: 'OpenSans-SemiBold', fontSize: 20, marginLeft: 15, color: '#302a28'}}>
                            Lanjutkan Pembayaran
                        </Col>
                    </Row>

                    <Row>
                        <Col style={{
                            width: '100%',
                            height: 1,
                            backgroundColor: '#e9eaec',
                            marginTop: 5,
                            marginBottom: 30
                        }}/>
                    </Row>

                    <Row>
                        {
                            cart.map((item, idx) => {
                                console.log('DEBUG', item)

                                return (
                                    <>
                                        <Col xs={12} style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                                            <img
                                                src={item.code ? item.class_image_url : item.course_image_url}
                                                onClick={(e) => e.stopPropagation()}
                                                style={{
                                                    objectFit: 'cover',
                                                    width: 70,
                                                    height: 70,
                                                    marginLeft: 20,
                                                    borderRadius: 10
                                                }}/>

                                            <div style={{flex: 1}}>

                                                <Row>
                                                    <Col xs={12} md={6} style={{
                                                        paddingLeft: 40,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        flexDirection: 'column'
                                                    }}>
                                                        <div
                                                            style={{fontFamily: 'OpenSans-SemiBold', fontSize: 15}}>
                                                            {item.code ? item.name : item.title}
                                                            {/*{session.name}*/}

                                                        </div>
                                                        <div style={{
                                                            fontSize: 14,
                                                            color: 'grey'
                                                        }}>
                                                            {item.code ? item.topic?.name : item.category?.join(', ')}
                                                        </div>
                                                    </Col>

                                                    <Col style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'flex-end',
                                                        fontFamily: 'OpenSans-SemiBold',
                                                        fontSize: 15,
                                                        paddingRight: 40,
                                                    }}>
                                                        Rp{thousandSeparator(item.price)}
                                                        {/*Rp{session.price ? thousandSeparator(session.price) : '0'}*/}
                                                    </Col>
                                                </Row>
                                            </div>

                                        </Col>

                                        <Col xs={12} style={{marginTop: 10, marginBottom: 10}}/>
                                    </>
                                )
                            })
                        }
                    </Row>

                    <Row>
                        <Col style={{
                            width: '100%',
                            height: 1,
                            backgroundColor: '#e9eaec',
                            marginTop: 20,
                            marginBottom: 20,
                        }}/>
                    </Row>

                    <Row>
                        <Col xs={12} md={9} style={{paddingRight: windowWidth < 768 ? 30 : 0, paddingLeft: 30}}>
                            <Form.Control
                                value={voucherCode}
                                onChange={(e) => {
                                    setVoucherCode(e.target.value)
                                }}
                                style={{
                                    fontSize: "1em",
                                    fontFamily: 'OpenSans-Regular',
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                }} type="text"
                                placeholder="Kode Voucer"/>
                            <div style={{
                                fontFamily: 'OpenSans-SemiBold',
                                // color: discountPercentage != 0 ? Palette.SECONDARY : Palette.PRIMARY,
                                color: Palette.SECONDARY,
                                fontSize: 13,
                                marginTop: 5,
                            }}>
                                {voucherMessage}
                            </div>
                        </Col>

                        <Col style={{
                            marginTop: windowWidth < 768 ? 10 : 0,
                            paddingRight: 30,
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <Button
                                onClick={() => {
                                    getVoucherInfo()
                                }}
                                style={{
                                    fontFamily: 'OpenSans-Bold',
                                    borderWidth: 0,
                                    backgroundColor: '#5fcaf9',
                                    height: 43,
                                }}>
                                Gunakan
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col style={{
                            width: '100%',
                            height: 1,
                            backgroundColor: '#e9eaec',
                            marginTop: 25,
                            marginBottom: 25,
                        }}/>
                    </Row>

                    <Row>
                        <Col xs={12} style={{paddingLeft: 30, paddingRight: 30}}>
                            <Form.Control
                                as={'textarea'}
                                onChange={(e) => {
                                    setParticipantNote(e.target.value)
                                }}
                                style={{
                                    fontSize: "1em",
                                    fontFamily: 'OpenSans-Regular',
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                }} type="text"
                                placeholder="Tulis Catatan (Opsional)"/>
                        </Col>
                    </Row>

                    <Row>
                        <Col style={{
                            width: '100%',
                            height: 1,
                            backgroundColor: '#e9eaec',
                            marginTop: 25,
                            marginBottom: 25,
                        }}/>
                    </Row>

                    <Row style={{marginLeft: 7}}>
                        <Col style={{fontFamily: 'OpenSans-Regular', fontSize: 15}}>
                            Subtotal
                        </Col>

                        <Col style={{
                            fontFamily: 'OpenSans-SemiBold',
                            fontSize: 15,
                            paddingRight: 37,
                            textAlign: 'right'
                        }}>
                            Rp {textFormatter.moneyFormatter(cart.reduce((total, obj) => {
                            return total + obj.price
                        }, 0))}
                            {/*Rp{session.price ? thousandSeparator(session.price) : '0'}*/}
                        </Col>
                    </Row>

                    <Row style={{marginLeft: 7, marginTop: 10}}>
                        <Col style={{fontFamily: 'OpenSans-Regular', fontSize: 15}}>
                            Diskon
                        </Col>

                        <Col style={{
                            fontFamily: 'OpenSans-SemiBold',
                            fontSize: 15,
                            paddingRight: 37,
                            textAlign: 'right',
                            color: Palette.PRIMARY
                        }}>
                            {/*- Rp0*/}
                            - {discountType === DISCOUNT_TYPE.PERCENT ? `Rp${thousandSeparator(totalPrice * discountPercentage / 100)}` : discountType === DISCOUNT_TYPE.AMOUNT ? `Rp${thousandSeparator(discountAmount)}` : 'Rp0'}
                        </Col>
                    </Row>

                    <Row>
                        <Col style={{
                            width: '100%',
                            height: 1,
                            backgroundColor: '#e9eaec',
                            marginTop: 15,
                            marginBottom: 10,
                        }}/>
                    </Row>

                    <Row style={{marginLeft: 7, marginTop: 10}}>
                        <Col style={{fontFamily: 'OpenSans-SemiBold', fontSize: 16}}>
                            Total
                        </Col>

                        <Col style={{
                            fontFamily: 'OpenSans-SemiBold',
                            fontSize: 19,
                            paddingRight: 37,
                            textAlign: 'right',

                        }}>
                            Rp{totalPrice && discountType === DISCOUNT_TYPE.PERCENT ?
                            thousandSeparator(totalPrice - totalPrice * discountPercentage / 100) :
                            totalPrice && discountType === DISCOUNT_TYPE.AMOUNT && totalPrice - discountAmount > 0 ?
                                thousandSeparator(totalPrice - discountAmount) : discountType || !totalPrice ? '0' : thousandSeparator(totalPrice)}
                        </Col>
                    </Row>

                    <Row>
                        <Col style={{
                            display: 'flex',
                            flexDirection: windowWidth < 469 ? 'column' : 'row',
                            justifyContent: 'flex-end',
                            marginRight: 16,
                            marginTop: 50,
                            marginBottom: 22,
                            fontFamily: 'OpenSans-Bold'
                        }}>
                            <Button variant={'link'} style={{
                                marginRight: windowWidth < 469 ? 0 : 20,
                                order: windowWidth < 469 ? 2 : 0,
                                marginTop: windowWidth < 469 ? 8 : 0
                            }}
                                    onClick={() => setCheckoutModalShown(false)}
                            >
                                Batal
                            </Button>
                            <Button
                                onClick={async () => {
                                    let arr_cart_class = [];
                                    //    console.log('cuaks',JSON.parse(localStorage.cart));
                                    if (cart.length > 0 && cart !== undefined) {
                                        for (let value of cart) {
                                            if (value.code !== undefined) {
                                                let available_class = await sessionModel.getAvailableClass(value.code);
                                                if (available_class.length !== undefined && available_class.length > 0) {
                                                    if (available_class[0].usage_class >= available_class[0].quota) {
                                                        alert('Kelas ' + value.name + ' Sudah memenuhi kuota')
                                                        let new_cart = JSON.parse(localStorage.cart);
                                                        for (let i = 0; i < new_cart.length; i++) {
                                                            if (new_cart[i].code !== undefined) {
                                                                if (new_cart[i].code === value.code)
                                                                    new_cart.splice(i, 1);
                                                                break;
                                                            }
                                                        }
                                                        localStorage.setItem("cart", JSON.stringify(new_cart));
                                                        window.location.reload()
                                                    }
                                                }
                                            }

                                        }
                                    }
                                    try {


                                        let formattedCart = cart.map((obj)=>{

                                            delete obj.courseChapters
                                            delete obj.courseSubChapters

                                            return {
                                                ...obj,
                                            }
                                        })

                                        const result = await orderModel.checkout({
                                            orders: formattedCart,
                                            participant_note: participantNote,
                                            voucher_code: voucherCode
                                        })

                                        localStorage.setItem('cart', '[]')

                                        if (result.snap_payment_url) {
                                            window.location.replace(result.snap_payment_url)
                                        } else if (result.success) {
                                            alert('Anda telah berhasil terdaftar!')

                                            window.location.reload()
                                        }
                                        console.log(result)
                                    } catch (e) {
                                        if(e.code === 'VOUCHER_NOT_FOUND') {
                                            alert(e.msg)
                                        }

                                        if(e.code === "VOUCHER_MINIMUM_USAGE_NOT_REACHED"){
                                            if(e.msg){
                                                alert(e.msg)
                                            }else{
                                                alert("Voucer tidak bisa dipakai")
                                            }
                                            return
                                        }

                                        if(e.code === 'USER_HAS_REGISTERED') {
                                            alert('Anda sudah melakukan pendaftaran pada satu atau lebih kelas di keranjang Anda!');
                                            window.location.reload()
                                        } else if (e.code === 'QUOTA_IS_FULL') {
                                            alert('Satu atau lebih kelas di keranjang Anda sudah memenuhi kuota!');
                                        }
                                        console.log(cart)
                                    }
                                }}
                                    disabled={isLoading}
                                    style={{
                                    width: windowWidth < 469 ? '100%' : 100,
                                    height: 40,
                                    order: windowWidth < 469 ? 0 : 2
                                }}>
                                    Bayar
                                    </Button>
                                    </Col>
                                    </Row>
                                    </Container>

                                    </Dialog>


                                    <Drawer
                                    variant={isMobile ? "temporary" : "persistent"}
                                    anchor={anchor}
                                    open={props.open}
                                    >

                                    <div
                                    style={{
                                    width: windowWidth > drawerWidth ? drawerWidth : windowWidth,
                                    display: "flex",
                                    flexDirection: "column",
                                    marginTop: isMobile ? "0" : "2em",
                                    overflowY: "hidden",
                                    padding: 10
                                }}
                                    trackYProps={{
                                    renderer: props => {
                                    const {elementRef, ...restProps} = props;
                                    return <span {...restProps} style={{...restProps.style, backgroundColor: "white"}}
                                    ref={elementRef} className="trackY"/>;
                                }
                                }}
                                    smoothScrolling={true}
                                    >

                                    <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginBottom: 30
                                }}>
                                    <span style={{
                                    fontSize: 20,
                                    flex: 1,
                                    marginLeft: 15,
                                    fontWeight: '700',
                                    color: 'rgb(79, 79, 79)'
                                }}>Keranjang Belanja</span>

                                    <IconButton
                                    onClick={() => {
                                    props.onClose();
                                }}
                                    onMouseDown={e => e.preventDefault()}
                                    >
                                    <AiOutlineClose color={Palette.PRIMARY}/>
                                    </IconButton>
                                    </div>

                                    <List style={{
                                    overflow: "auto",
                                    marginBottom: "5em"
                                }}>
                                {
                                    cart.map((item, idx) => {
                                    console.log('item', item)

                                    return (
                                    <>
                                    <ListItem>
                                    <ListItemIcon>
                                    <Link
                                    to={item.code ? `/kelas/detail/${item.code}` : item.course_code ? `/kdm/detail/${item.course_code}` : `/kdm/${item.id}`}>
                                    <img
                                    style={{width: 100, objectFit: 'cover', borderRadius: 10}}
                                    src={item.code ? item.class_image_url : item.course_image_url}/>
                                    </Link>
                                    </ListItemIcon>

                                    <div style={{paddingLeft: 30, flex: 1}}>
                                    <Link
                                    to={item.code ? `/kelas/detail/${item.code}` : item.course_code ? `/kdm/detail/${item.course_code}` : `/kdm/${item.id}`}>
                                    <div style={{fontSize: 20, color: 'rgb(79, 79, 79)'}}>
                                {item.code ? item.name : item.title}
                                    </div>

                                {(item.topic || item.category) &&
                                    <div style={{
                                    fontSize: 15,
                                    marginTop: 5,
                                    color: 'rgb(79, 79, 79)',
                                    fontWeight: '300'
                                }}>
                                {item.code ? item.topic?.name : item.category?.join(', ')}
                                    </div>
                                }
                                    </Link>

                                    <div style={{
                                    fontSize: 15,
                                    marginTop: 5,
                                    fontWeight: '700',
                                    color: Palette.PRIMARY
                                }}>
                                    Rp{thousandSeparator(item.price)}
                                    </div>
                                    </div>

                                    <IconButton
                                    onClick={() => {
                                    const cartTemp = [...cart];

                                    cartTemp.splice(idx, 1);

                                    setCart(cartTemp)

                                    localStorage.setItem('cart', JSON.stringify(cartTemp));

                                    if (cartTemp.length === 0) cartEmptyAction();
                                    // setCartData(CartManager.removeItem(key))
                                }}
                                    onMouseDown={e => e.preventDefault()}
                                    style={{color: Palette.PRIMARY}}
                                    >
                                    <SimpleLineIcon name="trash"/>
                                    </IconButton>
                                    </ListItem>

                                {idx !== cart.length - 1 && <div style={{
                                    height: 1,
                                    backgroundColor: '#f0f0f0',
                                    marginTop: 10,
                                    marginBottom: 10
                                }}/>}
                                    </>
                                    )
                                })
                                }

                                {/*{*/}
                                {/*    cartData.map((obj, key) => {*/}

                                {/*        let product = getProductData(obj.product_id)*/}

                                {/*        return (*/}
                                {/*            <ListItem>*/}
                                {/*                <ListItemIcon>*/}
                                {/*                    <img src={product?.image_url} style={{width: 125}}/>*/}
                                {/*                </ListItemIcon>*/}
                                {/*                <div>*/}
                                {/*                    <span style={{*/}
                                {/*                        // fontSize: 18,*/}
                                {/*                        color: '#575757',*/}
                                {/*                        fontFamily: 'OpenSans-Regular',*/}
                                {/*                        marginLeft: 10*/}
                                {/*                    }}>{product?.name}*/}
                                {/*                        <div style={{*/}
                                {/*                            color: '#ff85a1', fontFamily: 'OpenSans-Regular',*/}
                                {/*                            marginLeft: 10*/}
                                {/*                        }}><b>{obj.customization && obj.customization.zodiac}</b></div>*/}
                                {/*                    </span>*/}
                                {/*                    <div style={{*/}
                                {/*                        color: '#575757',*/}
                                {/*                        fontWeight: "bold",*/}
                                {/*                        fontFamily: 'OpenSans-Regular',*/}
                                {/*                        margin: 10,*/}
                                {/*                        marginTop: 20*/}
                                {/*                    }}>*/}
                                {/*                        <span>Kuantitas:</span>*/}

                                {/*                        <ButtonGroup*/}
                                {/*                            style={{*/}
                                {/*                                marginLeft: 20*/}
                                {/*                            }}*/}
                                {/*                            size="sm">*/}
                                {/*                            <Button*/}
                                {/*                                disabled={obj.quantity === 1}*/}
                                {/*                                onClick={() => {*/}
                                {/*                                    // setCartData(CartManager.decreaseItemQuantity(key))*/}
                                {/*                                }}*/}
                                {/*                                variant={"outline-primary"}>-</Button>*/}
                                {/*                            <Button variant={"outline-primary"} disabled>{obj.quantity}</Button>*/}
                                {/*                            <Button*/}
                                {/*                                onClick={() => {*/}
                                {/*                                    // setCartData(CartManager.addItemQuantity(key))*/}
                                {/*                                }}*/}
                                {/*                                variant={"outline-primary"}>+</Button>*/}
                                {/*                        </ButtonGroup>*/}

                                {/*                        <IconButton*/}
                                {/*                            onClick={() => {*/}
                                {/*                                // setCartData(CartManager.removeItem(key))*/}
                                {/*                            }}*/}
                                {/*                            onMouseDown={e => e.preventDefault()}*/}
                                {/*                            style={{color: Palette.PRIMARY}}*/}
                                {/*                        >*/}
                                {/*                            <SimpleLineIcon name="trash"/>*/}
                                {/*                        </IconButton>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}


                                {/*            </ListItem>*/}
                                {/*        );*/}
                                {/*    })*/}
                                {/*}*/}
                                    </List>
                                    <div style={{position: "fixed", bottom: 0, right: 0}}>
                                    <Button
                                    disabled={isLoading}
                                    style={{
                                    background: Palette.PRIMARY,
                                    // paddingInlineStart: "4em",
                                    // paddingInlineEnd: "4em",
                                    color: "white",
                                    width: windowWidth > drawerWidth ? drawerWidth : windowWidth,
                                    opacity: isLoading ? .3 : 1
                                }}
                                    onClick={() => {
                                    if (!props.isLoggedIn) {
                                    props.loginPopup()
                                } else {
                                    checkout()
                                }
                                }}
                                    size={"sm"}>

                                {isLoading ?
                                    <Spinner
                                    style={{marginTop: 18, marginBottom: 18}}
                                    animation="border"/> :
                                    <>
                                    <SimpleLineIcon name="check"/>
                                    Menuju Pembayaran
                                    <br/>
                                    <span style={{
                                    color: "white",
                                    fontSize: 18,
                                    fontWeight: "bold",
                                    fontFamily: 'OpenSans-Regular',
                                }}>Total: Rp
                                {textFormatter.moneyFormatter(totalPrice)}

                                    </span>
                                    </>
                                }

                                    </Button>

                                    </div>

                                    </div>
                                    </Drawer>

                                    </>
                                    )
                                    }
