import Dialog from "@material-ui/core/Dialog/Dialog";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import MuiAlert from "@material-ui/lab/Alert/Alert";
import { Parser } from 'html-to-react';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useCookies } from "react-cookie";
import { Scrollbars } from 'react-custom-scrollbars';
import { AiOutlineClockCircle } from "react-icons/ai";
import { FaAward, FaMinus, FaPlus } from "react-icons/fa";
import { IoIosPaper, IoMdArrowBack, IoMdCheckmark, IoMdStar } from "react-icons/io";
import { MdDateRange, MdVideoLibrary } from "react-icons/md";
import Rating from "react-rating";
import { useHistory } from "react-router-dom";
import Course from "../../models/Course";
import Order from "../../models/Order";
import Session from "../../models/Session";
import GlobalData from "../../util/GlobalData";
import Palette from "../../util/Palette";
import Strings from "../../util/Strings";
import CompleteProfileModal from "../reusable/CompleteProfileModal";
import IndependentClassCard from "../reusable/IndependentClassCard";
import ProgressBar from "../reusable/ProgressBar";
import PageWrapper from "./PageWrapper";

export default function OnlineClassPreview(props) {
    const DISCOUNT_TYPE = {
        PERCENT: 'PERCENT',
        AMOUNT: 'AMOUNT'
    }
    const editable_strings = GlobalData.strings.private_class_detail;
    const [cookie, setCookie, removeCookie] = useCookies()
    const history = useHistory()
    const [user, setUser] = useState({...GlobalData.user});
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isDescriptionCollapsed, setDescriptionCollapsed] = useState(true);
    const [instructorExpandedIdx, setInstructorExpandedIdx] = useState(0);
    const [topicExpandedIdx, setTopicExpandedIdx] = useState(0);
    const [course, setCourse] = useState({});
    const [totalSyllabusContent, setTotalSyllabusContent] = useState(0)
    const [totalSyllabusDuration, setTotalSyllabusDuration] = useState(0)
    const [cart, setCart] = useState([]);
    const [isCartOpen, setCartOpen] = useState(false);
    const [snackBarProp, setSnackBarProp] = useState({});
    const [discountPercentage, setDiscountPercentage] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [discountType, setDiscountType] = useState(null);
    const [isCheckoutModalShown, setCheckoutModalShown] = useState(false);
    const [voucherCode, setVoucherCode] = useState("")
    const [voucherMessage, setVoucherMessage] = useState("")
    const [participantNote, setParticipantNote] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(GlobalData.token || cookie.token)
    const [loginShow, setLoginShow] = useState(false);
    const [loginMessage, setLoginMessage] = useState("");
    const [avgRating, setAvgRating] = useState('0.0');
    const [completeProfileModalMessage, setCompleteProfileModalMessage] = useState("")
    const [isCompleteProfileModalShown, setCompleteProfileModalShown] = useState(false)
    const [editableString, setEditableString] = useState({})
    const [isAllCommentsShown, setAllCommentsShown] = useState(false);
    const [shownCommentIdx, setShownCommentIdx] = useState([]);
    const [upcomingSession, setUpcomingSession] = useState([])
    const [courses, setCourses] = useState([]);

    const orderModel = new Order();

    function shuffle(array) {
        let currentIndex = array.length,  randomIndex;

        // While there remain elements to shuffle...
        while (currentIndex != 0) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    const fetchCourses = async () => {
        try {
            const result = await new Course().getAll()
            const coursesTemp = result.filter((obj, key) => (props.match.params.code && props.match.params.code !== obj.course_code) || (!props.match.params.code && props.match.params.id !== obj.id))
            setCourses(shuffle(coursesTemp))
        } catch (e) {
            console.log(e)
        }
    }

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const getVoucherInfo = async () => {
        let sessionModel = new Session();
        try {
            let result = await sessionModel.getVoucherInfo(voucherCode)

            // console.log(result)

            if (result.used) {
                setVoucherMessage(Strings.class_detail.voucher_used)
                setDiscountPercentage(0)
                return
            }

            if (result.minimum_usage) {
                if (result.minimum_usage > course.price) {
                    setDiscountPercentage(0)
                    setDiscountAmount(0)
                    return setVoucherMessage("Minimum pembelian untuk memakai voucer ini adalah Rp" + thousandSeparator(result.minimum_usage))
                }
            }

            if (result.percentage) {
                setDiscountType(DISCOUNT_TYPE.PERCENT)
                setVoucherMessage(Strings.class_detail.you_will_get_discount + result.percentage + "%")
                setDiscountPercentage(result.percentage)
            } else {
                setDiscountType(DISCOUNT_TYPE.AMOUNT)
                setVoucherMessage(Strings.class_detail.you_will_get_discount + "Rp" + thousandSeparator(result.amount))
                setDiscountAmount(result.amount)
            }


        } catch (e) {
            console.log(e.code)
            if (e.code === "VOUCHER_NOT_FOUND") {
                setVoucherMessage(Strings.class_detail.code_not_found)
                setDiscountPercentage(0)
            }
        }
    }

    const participate = async () => {
        try {
            const result = await orderModel.checkout({
                orders: [course],
                participant_note: participantNote,
                voucher_code: voucherCode
            })

            localStorage.setItem('cart', '[]')

            if (result.snap_payment_url) {
                window.location.replace(result.snap_payment_url)
            } else if (result.success) {
                alert('Anda telah berhasil terdaftar!')

                window.location.reload()
            }
            console.log(result)
        } catch (e) {
            if (e.code === 'VOUCHER_NOT_FOUND') {
                alert(e.msg)
            }

            if (e.code === "VOUCHER_MINIMUM_USAGE_NOT_REACHED") {
                if (e.msg) {
                    alert(e.msg)
                } else {
                    alert("Voucer tidak bisa dipakai")
                }
                return
            }

            if (e.code === 'USER_HAS_REGISTERED') {
                alert('Anda sudah pernah melakukan pendaftaran pada kelas ini!');
                window.location.reload();
            } else if (e.code === 'QUOTA_IS_FULL') {
                alert(e.msg);
                window.location.reload();
            }

            console.log(cart)
        }
        // try {
        //     setIsLoading(true)
        //     const result = await sessionModel.participate(session.code, voucherCode, participantNote)
        //
        //     console.log('bayar', result);
        //
        //     if (result.status === 'ACCEPTED') {
        //         alert('Anda berhasil terdaftar pada acara ini!')
        //         window.location.reload()
        //         setCheckoutModalShown(false)
        //         setIsLoading(false)
        //     } else if (result.status === 'PENDING') {
        //         alert('Kuota kelas sudah penuh. Kami akan mengabarkan Anda jika ada kuota baru.')
        //         window.location.reload()
        //         setCheckoutModalShown(false)
        //         setIsLoading(false)
        //     } else if (session.price > 0) {
        //         window.open(result?.transaction.snap_payment_url, '_self')
        //         setCheckoutModalShown(false)
        //         setIsLoading(false)
        //     }
        //
        // } catch (e) {
        //     console.log(e)
        // }
    }

    const getApproximateHours = (secondsParam) => {
        const hours = Math.floor(secondsParam / 3600);
        const minutes = Math.floor((secondsParam % 3600) / 60);

        return hours === 0 ? 1 : minutes > 30 ? hours + 1 : hours
    }

    const [benefits, setBenefits] = useState([
        
        // {
        //     icon: <MdSignalCellular1Bar color={Palette.SECONDARY} size={20}/>,
        //     title: 'Pemula',
        // },
    ])

    //sorted from 5 stars to 1 star
    const [ratings, setRatings] = useState([0, 0, 0, 0, 0]);

    const topReviews = [
        {
            rating: 5,
            reviewer: 'MS',
            date: 'May 21, 2020',
            review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        },
        {
            rating: 3,
            reviewer: 'AA',
            date: 'Sep 27, 2020',
            review: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.'
        },
    ]

    const printTimeFromSeconds = (secondsParam) => {
        var hours = Math.floor(secondsParam / 3600) < 10 ? ("00" + Math.floor(secondsParam / 3600)).slice(-2) : Math.floor(secondsParam / 3600);
        var minutes = ("00" + Math.floor((secondsParam % 3600) / 60)).slice(-2);
        var seconds = ("00" + (secondsParam % 3600) % 60).slice(-2);
        return `${hours !== "00" ? " " + parseInt(hours) + " Jam " : ""}${minutes !== "00" ? " " + parseInt(minutes) + " Menit" : ""}${seconds !== "00" ? " " + parseInt(seconds) + " Detik" : ""}`
    }


    useEffect(() => {
        const cart = localStorage.getItem('cart');

        if (cart) {
            setCart(JSON.parse(cart))
        }

        window.addEventListener('resize', handleResize)
        getData()

        fetchCourses()

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const changePaymentMethod = async (orderId) => {
        try {
            const result = await orderModel.changePaymentMethod(orderId);

            window.open(result?.snap_payment_url, '_self')
            console.log(result)
        } catch (e) {
            console.log(e)
        }
    }

    const getData = async () => {


        try {
            let result;
            if (props.match.params.code) {
                const course = await new Course().getByCode(props.match.params.code)

                result = await new Course().getById(parseInt(course.id))
            } else {
                result = await new Course().getById(parseInt(props.match.params.id))
            }


            console.log('result', result)


            let totalSyllabusContentTemp = 0;
            let totalSyllabusDurationTemp = 0;

            Object.keys(result.courseSubChapters).forEach(key => {
                totalSyllabusContentTemp += result.courseSubChapters[key].length;

                result.courseSubChapters[key].forEach(courseSubChapter => {
                    totalSyllabusDurationTemp += courseSubChapter.duration;
                })
            })

            setTotalSyllabusContent(totalSyllabusContentTemp)

            setTotalSyllabusDuration(totalSyllabusDurationTemp)

            const benefitsTemp = [...benefits];

            if (result.title.includes('KNO')) {
                // No action needed, or you can add a different logic here if necessary
            } else {
                benefitsTemp.push({
                    icon: <MdVideoLibrary color={Palette.SECONDARY} size={20} />,
                    title: 'Video dan Modul Pembelajaran',
                });
            
                benefitsTemp.push({
                    icon: <IoIosPaper color={Palette.SECONDARY} size={20} />,
                    title: 'Soal Latihan',
                });
            }

            if (result.additional_information) {
                JSON.parse(result.additional_information).map(info => {
                    if (info === 'Sertifikat') {
                        benefitsTemp.push({
                            icon: <FaAward color={Palette.SECONDARY} size={20}/>,
                            title: 'Sertifikat Elektronik',
                        })
                    }
                })
            }

            benefitsTemp.push({
                icon: <AiOutlineClockCircle color={Palette.SECONDARY} size={20}/>,
                title: `Waktu Pembelajaran Fleksibel`,
            })

            benefitsTemp.push({
                icon: <MdDateRange color={Palette.SECONDARY} size={20}/>,
                title: `Dapat Diakses Selama 1 Tahun`,
            })


            setBenefits(benefitsTemp)

            let avgRating = 0;

            let noOfRatings = [0, 0, 0, 0, 0];

            result.allRating.map(rating => {
                noOfRatings[5 - rating.rating]++;

                avgRating += rating.rating;
            })

            noOfRatings = noOfRatings.map((noOfRating) => {
                return noOfRating / result.allRating.length * 100
            })

            setRatings(noOfRatings);

            console.log('ratings', ratings)

            avgRating /= result.allRating.length;

            if (!isNaN(avgRating)) {
                setAvgRating(avgRating.toFixed(1))
            }

            result.courseChapters.forEach(item => {
                if (item.custom_sub_chapter_sequence) {
                    const customSubChapterSequence = JSON.parse(item.custom_sub_chapter_sequence);

                    const courseSubChapters = []

                    customSubChapterSequence.forEach(item2 => {
                        result.courseSubChapters[item.id].forEach(subChapter => {
                            if (subChapter.id === item2) {
                                courseSubChapters.push(subChapter);
                            }
                        })
                    })

                    result.courseSubChapters[item.id] = courseSubChapters
                }
            })


            if (result.custom_chapter_sequence) {
                const customChapterSequence = JSON.parse(result.custom_chapter_sequence);

                const courseChapters = [];
                customChapterSequence.forEach(item => {
                    result.courseChapters.forEach(chapter => {
                        if (chapter.id === item) {
                            courseChapters.push(chapter);
                        }
                    })
                })


                setCourse({
                    ...result,
                    courseChapters,
                    what_to_learn: JSON.parse(result.what_to_learn),
                    category: JSON.parse(result.category)
                });
            } else {
                setCourse({
                    ...result,
                    what_to_learn: JSON.parse(result.what_to_learn),
                    category: JSON.parse(result.category)
                });
            }


        } catch (e) {
            console.log(e)

            if (e.http_status) {
                if (e.http_status === 404) {
                    history.push('/')
                }
            }
        }
    }

    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    const showSnackBar = (message, severity) => {
        setSnackBarProp({
            open: true,
            message,
            severity
        })
    }

    const addToCart = () => {
        console.log('course', course)

        showSnackBar('Kelas daring berhasil ditambahkan ke keranjang!')
        setCartOpen(true)

        let cartTemp = [...cart];

        if (cartTemp) {
            let inCart = false;

            cartTemp.forEach(item => {
                if (!item.code && item.id === course.id) inCart = true;
            })

            if (!inCart) {
                cartTemp.push(course)

                setCart(cartTemp)
                localStorage.setItem('cart', JSON.stringify(cartTemp))
            }
        } else {
            setCart([course])
            localStorage.setItem('cart', JSON.stringify([course]))
        }
    }

    const thousandSeparator = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    useEffect(() => {
        if (!loginShow) setLoginMessage('')
    }, [loginShow])

    const embeddedVideo = (url) => {
        let embedlink = '';
        if (url !== undefined) {
            var id = url.split("?v=")[1]; //sGbxmsDFVnE

            embedlink = "https://www.youtube.com/embed/" + id;
        }

        return embedlink;
    }

    return (
        <PageWrapper
            cart={cart}
            setCart={setCart}
            isCartOpen={isCartOpen}
            setCartOpen={setCartOpen}
            headerShy={false}
            history={props.history}
            loginShow={loginShow}
            setLoginShow={setLoginShow}
            message={loginMessage}
            headerActive={'my-session'}
            loginListener={(user) => {
                console.log("LOGIN LISTENER CALLED", user)
                setUser(user)
            }}
        >
            <CompleteProfileModal
                message={completeProfileModalMessage}
                show={isCompleteProfileModalShown}
                onProfileCompleted={(user) => {
                    setCheckoutModalShown(true)

                    setUser(user)
                }}
                onHide={() => {
                    setCompleteProfileModalShown(false)
                }}
            />

            <Dialog open={isCheckoutModalShown} fullWidth>
                <Container>
                    <Row style={{marginTop: 25, marginBottom: 15}}>
                        <Col style={{fontFamily: 'OpenSans-SemiBold', fontSize: 20, marginLeft: 15, color: '#302a28'}}>
                            Lanjutkan Pembayaran
                        </Col>
                    </Row>

                    <Row>
                        <Col style={{
                            width: '100%',
                            height: 1,
                            backgroundColor: '#e9eaec',
                            marginTop: 5,
                            marginBottom: 30
                        }}/>
                    </Row>

                    <Row>
                        <Col style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                            <img
                                src={course.course_image_url ? course.course_image_url : '/image/no-img-placeholder.png'}
                                onClick={(e) => e.stopPropagation()}
                                style={{
                                    objectFit: 'cover',
                                    width: 70,
                                    height: 70,
                                    marginLeft: 20,
                                }}/>

                            <div style={{flex: 1}}>

                                <Row>
                                    <Col xs={12} md={6} style={{
                                        paddingLeft: 40,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column'
                                    }}>
                                        <div
                                            style={{fontFamily: 'OpenSans-SemiBold', fontSize: 15}}>{course.title}</div>
                                        {course.category?.length > 0 &&
                                        <div style={{
                                            fontSize: 14,
                                            color: 'grey'
                                        }}>{course.category.join(', ')}</div>
                                        }
                                    </Col>

                                    <Col style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                        fontFamily: 'OpenSans-SemiBold',
                                        fontSize: 15,
                                        paddingRight: 40,
                                    }}>
                                        Rp{course.price ? thousandSeparator(course.price) : '0'}
                                    </Col>
                                </Row>
                            </div>

                        </Col>
                    </Row>

                    <Row>
                        <Col style={{
                            width: '100%',
                            height: 1,
                            backgroundColor: '#e9eaec',
                            marginTop: 20,
                            marginBottom: 20,
                        }}/>
                    </Row>

                    <Row>
                        <Col xs={12} md={9} style={{paddingRight: windowWidth < 768 ? 30 : 0, paddingLeft: 30}}>
                            <Form.Control
                                value={voucherCode}
                                onChange={(e) => {
                                    setVoucherCode(e.target.value)
                                }}
                                style={{
                                    fontSize: "1em",
                                    fontFamily: 'OpenSans-Regular',
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                }} type="text"
                                placeholder="Kode Voucer"/>
                            <div style={{
                                fontFamily: 'OpenSans-SemiBold',
                                color: (discountPercentage != 0) ? Palette.SECONDARY : Palette.PRIMARY,
                                fontSize: 13,
                                marginTop: 5,
                            }}>
                                {voucherMessage}
                            </div>
                        </Col>

                        <Col style={{
                            marginTop: windowWidth < 768 ? 10 : 0,
                            paddingRight: 30,
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <Button
                                onClick={() => {
                                    getVoucherInfo()
                                }}
                                style={{
                                    fontFamily: 'OpenSans-Bold',
                                    borderWidth: 0,
                                    backgroundColor: '#5fcaf9',
                                    height: 43,
                                }}>
                                Gunakan
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col style={{
                            width: '100%',
                            height: 1,
                            backgroundColor: '#e9eaec',
                            marginTop: 25,
                            marginBottom: 25,
                        }}/>
                    </Row>

                    <Row>
                        <Col xs={12} style={{paddingLeft: 30, paddingRight: 30}}>
                            <Form.Control
                                as={'textarea'}
                                onChange={(e) => {
                                    setParticipantNote(e.target.value)
                                }}
                                style={{
                                    fontSize: "1em",
                                    fontFamily: 'OpenSans-Regular',
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                }} type="text"
                                placeholder="Tulis Catatan (Opsional)"/>
                        </Col>
                    </Row>

                    <Row>
                        <Col style={{
                            width: '100%',
                            height: 1,
                            backgroundColor: '#e9eaec',
                            marginTop: 25,
                            marginBottom: 25,
                        }}/>
                    </Row>

                    <Row style={{marginLeft: 7}}>
                        <Col style={{fontFamily: 'OpenSans-Regular', fontSize: 15}}>
                            Subtotal
                        </Col>

                        <Col style={{
                            fontFamily: 'OpenSans-SemiBold',
                            fontSize: 15,
                            paddingRight: 37,
                            textAlign: 'right'
                        }}>
                            Rp{course.price ? thousandSeparator(course.price) : '0'}
                        </Col>
                    </Row>

                    <Row style={{marginLeft: 7, marginTop: 10}}>
                        <Col style={{fontFamily: 'OpenSans-Regular', fontSize: 15}}>
                            Diskon
                        </Col>

                        <Col style={{
                            fontFamily: 'OpenSans-SemiBold',
                            fontSize: 15,
                            paddingRight: 37,
                            textAlign: 'right',
                            color: Palette.PRIMARY
                        }}>
                            - {discountType === DISCOUNT_TYPE.PERCENT ? `Rp${thousandSeparator(course.price * discountPercentage / 100)}` : discountType === DISCOUNT_TYPE.AMOUNT ? `Rp${thousandSeparator(discountAmount)}` : 'Rp0'}
                        </Col>
                    </Row>

                    <Row>
                        <Col style={{
                            width: '100%',
                            height: 1,
                            backgroundColor: '#e9eaec',
                            marginTop: 15,
                            marginBottom: 10,
                        }}/>
                    </Row>

                    <Row style={{marginLeft: 7, marginTop: 10}}>
                        <Col style={{fontFamily: 'OpenSans-SemiBold', fontSize: 16}}>
                            Total
                        </Col>

                        <Col style={{
                            fontFamily: 'OpenSans-SemiBold',
                            fontSize: 19,
                            paddingRight: 37,
                            textAlign: 'right',

                        }}>
                            Rp{course.price && discountType === DISCOUNT_TYPE.PERCENT ?
                            thousandSeparator(course.price - course.price * discountPercentage / 100) :
                            course.price && discountType === DISCOUNT_TYPE.AMOUNT && course.price - discountAmount > 0 ?
                                thousandSeparator(course.price - discountAmount) : discountType || !course.price ? '0' : thousandSeparator(course.price)}
                        </Col>
                    </Row>

                    <Row>
                        <Col style={{
                            display: 'flex',
                            flexDirection: windowWidth < 550 ? 'column' : 'row',
                            justifyContent: 'flex-end',
                            marginRight: 16,
                            marginTop: 50,
                            marginBottom: 22,
                            fontFamily: 'OpenSans-Bold'
                        }}>
                            <Button variant={'link'} style={{
                                marginRight: windowWidth < 550 ? 0 : 20,
                                order: windowWidth < 550 ? 2 : 0,
                                marginTop: windowWidth < 550 ? 8 : 0
                            }}
                                    onClick={() => setCheckoutModalShown(false)}>
                                Batal
                            </Button>

                            {/*<Button*/}
                            {/*    disabled={isLoading}*/}
                            {/*    variant={'info'}*/}
                            {/*    style={{*/}
                            {/*        width: windowWidth < 550 ? '100%' : 240,*/}
                            {/*        height: 40,*/}
                            {/*        marginRight: windowWidth < 550 ? 0 : 20,*/}
                            {/*        order: 1,*/}
                            {/*        marginTop: windowWidth < 550 ? 10 : 0*/}
                            {/*    }}*/}
                            {/*    onClick={addToCart}>*/}
                            {/*    Tambahkan ke Keranjang*/}
                            {/*</Button>*/}

                            <Button
                                style={{
                                    width: windowWidth < 550 ? '100%' : 100,
                                    height: 40,
                                    order: windowWidth < 550 ? 0 : 2
                                }} onClick={participate}>
                                {/**todo: calculate price - discount**/}
                                {course.price === 0 ? 'Daftar' : 'Bayar'}
                            </Button>
                        </Col>
                    </Row>
                </Container>

            </Dialog>

            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={snackBarProp.open} autoHideDuration={6000}
                onClose={() => {
                    setSnackBarProp({
                        open: false
                    })
                }}>
                <Alert severity={snackBarProp.severity}>
                    {snackBarProp.message}
                </Alert>
            </Snackbar>

            <div style={{marginTop: 60, backgroundColor: Palette.SECONDARY, paddingBottom: 20}}>
                <Container>
                    <Row>
                        <Col style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: 20}}>
                            <IoMdArrowBack
                                onClick={() => history.push('/kelas#kelas-daring-mandiri')}
                                size={25} color={'white'} style={{cursor: 'pointer'}}/>

                            <div style={{color: 'white', fontFamily: 'OpenSans-SemiBold', marginLeft: 20}}>
                                Kembali
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6} lg={6} style={{
                            paddingTop: 10,
                            // marginLeft: 45,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start'
                        }}
                        >
                            {/*<div style={{*/}
                            {/*    color: 'white',*/}
                            {/*    fontFamily: 'OpenSans-SemiBold',*/}
                            {/*    backgroundColor: Palette.PRIMARY,*/}
                            {/*    paddingLeft: 10,*/}
                            {/*    paddingRight: 10,*/}
                            {/*    fontSize: '.9em'*/}
                            {/*}}>*/}
                            {/*    PALING LAKU*/}
                            {/*</div>*/}

                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                paddingTop: 20,
                                color: 'white',
                                fontSize: 30,
                                fontFamily: 'OpenSans-Bold'
                            }}>
                                {course.title}
                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                paddingTop: 5,
                                color: 'white',
                                fontFamily: 'OpenSans-SemiBold'
                            }}>
                                {editable_strings.investation} :
                                {
                                    course.non_discounted_price &&
                                    <div style={{
                                        fontSize: "0.8em",
                                        display: "flex",
                                        flexDirection: "row"
                                    }}>
                                        <div style={{
                                            textDecoration: "line-through",
                                        }}>
                                            Rp{course.non_discounted_price ? thousandSeparator(course?.non_discounted_price) : '-'}
                                        </div>
                                        <div style={{
                                            borderRadius: 10,
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                            marginLeft: 10,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            color: "white",
                                            fontSize: "0.9em",
                                            backgroundColor: Palette.PRIMARY
                                        }}>
                                            -{Math.floor((course.non_discounted_price - course.price) / course.non_discounted_price * 100)}%
                                        </div>
                                    </div>
                                }
                                <b style={{marginTop: 5}}>Rp{course.price ? thousandSeparator(course.price) : '-'}</b>
                            </div>

                            <div style={{display: 'flex', flexDirection: 'row', marginTop: 30}}>
                                <div style={{
                                    fontFamily: 'OpenSans-Regular',
                                    color: 'white',
                                    fontSize: '.9em',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>
                                    {editable_strings.rating}

                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        marginTop: 10
                                    }}>
                                        <Rating
                                            readonly
                                            initialRating={parseFloat(avgRating)}
                                            emptySymbol={<IoMdStar size={20} color={'#C4C4C4'}/>}
                                            fullSymbol={<IoMdStar size={20} color={'white'}/>}/>

                                        <div style={{fontFamily: 'OpenSans-SemiBold', marginLeft: 10, marginTop: 2}}>
                                            {avgRating}
                                        </div>

                                        {/*<div style={{marginLeft: 10}}>*/}
                                        {/*    (> 50000 penilaian)*/}
                                        {/*</div>*/}
                                    </div>
                                </div>

                                {course.category?.length > 0 &&
                                <>
                                    <div style={{
                                        width: 1,
                                        height: 40,
                                        backgroundColor: 'white',
                                        marginLeft: 30,
                                        marginRight: 30,
                                        marginTop: 10
                                    }}/>

                                    <div style={{
                                        fontFamily: 'OpenSans-Regular',
                                        color: 'white',
                                        fontSize: '.9em',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        {editable_strings.category}

                                        <div style={{marginTop: 10}}>
                                            {course.category.join(', ')}
                                        </div>
                                    </div>
                                </>
                                }


                            </div>
                        </Col>

                        {course.preview_video_url &&
                        <Col
                            lg={6}
                            style={{
                                // marginLeft: window.innerWidth < 992 ? 45 : 0,
                                marginTop: window.innerWidth < 992 ? 30 : 0
                            }}
                        >
                            {
                                course.preview_video_url && (course.preview_video_url.includes("youtube") || course.preview_video_url.includes("youtu.be")) ?
                                    <>
                                        <iframe
                                            allow="fullscreen" className="embed-responsive-item" type="text/html"
                                            style={{width: '100%', height: 300}}
                                            src={embeddedVideo(course.preview_video_url)}
                                            frameBorder="0"></iframe>
                                    </>
                                    :
                                    <video
                                        onContextMenu={(e) => {
                                            e.preventDefault()
                                        }}
                                        controlsList="nodownload" disablePictureInPicture
                                        controls width={"100%"} height={"100%"}>
                                        <source
                                            src={course.preview_video_url}
                                            type='video/mp4'/>
                                    </video>
                            }

                            {/*<iframe*/}
                            {/*      src={"https://drive.google.com/file/d/196pimHgogmCHMaNu8wgRo2T0pDdhAfHj/preview"}*/}
                            {/*      height={250} width={"100%"}*/}
                            {/*  />*/}
                            {/*  <YouTube videoId="fRVr8e_5yFs" opts={{width: '100%', height: 250}}/>*/}
                        </Col>
                        }
                    </Row>

                    <Row style={{marginTop: 15}}>
                        <Col style={{display: 'flex', alignItems: 'center'}}>
                            {course.title &&
                            <div
                                onClick={() => {
                                    if (course.memberCourse?.status === 'ACCEPTED') {
                                        // console.log(moment().isAfter(moment(course.memberCourse?.accepted_at).add(1, 'year')))
                                        if (props.match.params.code) {
                                            history.push(`/${props.match.params.code}/kelas-daring-mandiri`)
                                        } else {
                                            history.push(`/kelas-daring-mandiri/${course.id}`)
                                        }

                                    } else if (course.memberCourse?.status === 'REJECTED' || course.memberCourse?.status === 'EXPIRED' || course.order?.paid_status === 'expire') {
                                        participate()
                                    } else if (course.order?.snap_payment_url) {
                                        window.open(course?.order.snap_payment_url, '_self')
                                    } else {
                                        if (!isLoggedIn) {
                                            setLoginMessage(Strings.class_detail.login_before_purchasing)
                                            setLoginShow(true)
                                        } else if (!user.date_of_birth || !user.gender || !user.phone_num || !user.organization || !user.city) {
                                            setCompleteProfileModalShown(true)
                                            setCompleteProfileModalMessage('Harap lengkapi data Anda sebelum mendaftar pada sesi ini')
                                        } else {
                                            setCheckoutModalShown(true)
                                        }
                                    }

                                    // history.push(`/kelas-daring-mandiri/${course.id}`)
                                }}
                                style={{
                                    backgroundColor: Palette.PRIMARY,
                                    padding: 25,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingLeft: 30,
                                    paddingRight: 30,
                                    color: 'white',
                                    fontFamily: 'OpenSans-SemiBold',
                                    cursor: 'pointer',
                                    marginRight: 15,
                                }}>
                                {course.memberCourse?.status === 'ACCEPTED' ? 'Masuk Kelas' : course.memberCourse?.status === 'WAITING' ? 'BAYAR' : 'DAFTAR'}
                            </div>
                            }

                            {course.title && (!course.memberCourse?.status || (course.memberCourse?.status === 'EXPIRED' || course.memberCourse?.status === 'REJECTED')) &&
                            <a href={'#'}
                               onClick={(e) => {
                                   e.preventDefault()
                                   addToCart()
                                   // history.push(`/kelas-daring-mandiri/${course.id}`)
                               }}
                               style={{
                                   paddingLeft: 0,
                                   paddingRight: 0,
                                   paddingTop: 15,
                                   paddingBottom: 15,
                                   color: 'white',
                                   fontFamily: 'OpenSans-SemiBold',
                                   cursor: 'pointer'
                               }}>
                                Tambahkan ke Keranjang
                            </a>
                            }

                            {
                                (course.memberCourse?.status === 'ACCEPTED' || course.memberCourse?.status === 'WAITING') &&
                                <div style={{
                                    fontFamily: 'OpenSans-SemiBold',
                                    fontSize: '.9em',
                                    // marginLeft: 20,
                                    color: 'white'
                                }}>
                                    {course.memberCourse?.status === 'ACCEPTED' ? 'Telah terdaftar' : 'Menunggu Pembayaran'}
                                </div>
                            }

                        </Col>
                    </Row>
                </Container>
            </div>

            <Container style={{paddingLeft: 20, marginTop: 40}}>
                <Row>
                    <Col md={12} lg={7}>
                        {
                            course.description &&
                            <>
                                <div style={{fontFamily: 'OpenSans-SemiBold', fontSize: '1.2em', color: '#393b37'}}>
                                    {editable_strings.about_class}
                                </div>


                                <div style={{
                                    fontFamily: 'OpenSans-Regular',
                                    color: '#82847f',
                                    marginTop: 30,
                                    whiteSpace: 'pre-wrap'
                                }}>
                                    {new Parser().parse(`<p>${course.description}</p>`)}
                                </div>
                            </>
                        }

                        {/*<div style={{*/}
                        {/*    fontFamily: 'OpenSans-Regular',*/}
                        {/*    color: '#82847f',*/}
                        {/*    marginTop: 20,*/}
                        {/*    display: isDescriptionCollapsed ? 'none' : 'block'*/}
                        {/*}}>*/}
                        {/*    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque*/}
                        {/*    laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi*/}
                        {/*    architecto*/}
                        {/*    beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur*/}
                        {/*    aut*/}
                        {/*    odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi*/}
                        {/*    nesciunt.*/}
                        {/*    Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci*/}
                        {/*    velit,*/}
                        {/*    sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat*/}
                        {/*    voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit*/}
                        {/*    laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit*/}
                        {/*    qui*/}
                        {/*    in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum*/}
                        {/*    fugiat*/}
                        {/*    quo voluptas nulla pariatur?*/}
                        {/*</div>*/}

                        {/*<div style={{marginTop: 30}}>*/}
                        {/*    <a href={'#'}*/}
                        {/*       onClick={(e) => {*/}
                        {/*           e.preventDefault()*/}

                        {/*           setDescriptionCollapsed(!isDescriptionCollapsed)*/}
                        {/*       }}*/}
                        {/*       style={{fontFamily: 'OpenSans-SemiBold', color: '#55759d'}}>*/}
                        {/*        {isDescriptionCollapsed ? 'Tampilkan Semua' : 'Sembunyikan'}*/}
                        {/*    </a>*/}
                        {/*</div>*/}

                        {course.what_to_learn?.length > 0 &&
                        <div style={{border: '1px solid #efefef', padding: 15, borderRadius: 5, marginTop: 30}}>
                            <div style={{fontFamily: 'OpenSans-SemiBold', fontSize: '.95em', color: '#858687'}}>
                                {editable_strings.lesson_list}
                            </div>

                            <Container fluid>
                                <Row>
                                    {
                                        course.what_to_learn?.map(whatToLearn => {
                                            return (
                                                <Col xs={12} md={6} style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'flex-start',
                                                    marginTop: 20
                                                }}>
                                                    <div style={{width: 30}}>
                                                        <IoMdCheckmark color={'#4a7e6b'} size={20}/>
                                                    </div>

                                                    <div style={{
                                                        marginLeft: 15,
                                                        color: '#858185',
                                                        fontFamily: 'OpenSans-Regular',
                                                        fontSize: '.9em'
                                                    }}>
                                                        {whatToLearn}
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }


                                    {/*<Col style={{*/}
                                    {/*    display: 'flex',*/}
                                    {/*    flexDirection: 'row',*/}
                                    {/*    alignItems: 'flex-start',*/}
                                    {/*    marginTop: 20*/}
                                    {/*}}>*/}
                                    {/*    <div style={{width: 30}}>*/}
                                    {/*        <IoMdCheckmark color={'#4a7e6b'} size={20}/>*/}
                                    {/*    </div>*/}

                                    {/*    <div style={{*/}
                                    {/*        marginLeft: 15,*/}
                                    {/*        color: '#858185',*/}
                                    {/*        fontFamily: 'OpenSans-Regular',*/}
                                    {/*        fontSize: '.9em'*/}
                                    {/*    }}>*/}
                                    {/*        sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam*/}
                                    {/*        aliquam quaerat*/}
                                    {/*        voluptatem.*/}
                                    {/*    </div>*/}
                                    {/*</Col>*/}
                                </Row>
                                {/*<Row>*/}
                                {/*    <Col style={{*/}
                                {/*        display: 'flex',*/}
                                {/*        flexDirection: 'row',*/}
                                {/*        alignItems: 'flex-start',*/}
                                {/*        marginTop: 20*/}
                                {/*    }}>*/}
                                {/*        <div style={{width: 30}}>*/}
                                {/*            <IoMdCheckmark color={'#4a7e6b'} size={20}/>*/}
                                {/*        </div>*/}

                                {/*        <div style={{*/}
                                {/*            marginLeft: 15,*/}
                                {/*            color: '#858185',*/}
                                {/*            fontFamily: 'OpenSans-Regular',*/}
                                {/*            fontSize: '.9em'*/}
                                {/*        }}>*/}
                                {/*            Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis*/}
                                {/*            suscipit*/}
                                {/*            laboriosam*/}
                                {/*        </div>*/}
                                {/*    </Col>*/}
                                {/*    <Col style={{*/}
                                {/*        display: 'flex',*/}
                                {/*        flexDirection: 'row',*/}
                                {/*        alignItems: 'flex-start',*/}
                                {/*        marginTop: 20*/}
                                {/*    }}>*/}
                                {/*        <div style={{width: 30}}>*/}
                                {/*            <IoMdCheckmark color={'#4a7e6b'} size={20}/>*/}
                                {/*        </div>*/}

                                {/*        <div style={{*/}
                                {/*            marginLeft: 15,*/}
                                {/*            color: '#858185',*/}
                                {/*            fontFamily: 'OpenSans-Regular',*/}
                                {/*            fontSize: '.9em'*/}
                                {/*        }}>*/}
                                {/*            Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,*/}
                                {/*            consectetur, adipisci*/}
                                {/*            velit*/}
                                {/*        </div>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                            </Container>
                        </div>
                        }
                    </Col>

                    <Col>
                        {
                            benefits.map(benefit => {
                                return (
                                    <div style={{display: 'flex', alignItems: 'center', marginTop: 30}}>
                                        <div style={{
                                            width: 40,
                                            height: 40,
                                            border: '2px solid #e9e9e9',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: 20
                                        }}>
                                            {benefit.icon}
                                        </div>

                                        <div style={{marginLeft: 15, fontFamily: 'OpenSans-Bold', color: '#3d414a'}}>
                                            {benefit.title}

                                            <div style={{
                                                color: '#9aa2a2',
                                                fontFamily: 'OpenSans-Regular',
                                                fontSize: '.9em'
                                            }}>
                                                {benefit.description}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Col>
                </Row>

                {course.instructors?.length > 0 &&
                <Row style={{marginTop: 40}}>
                    <Col>
                        <div style={{
                            fontFamily: 'OpenSans-SemiBold',
                            fontSize: '1.2em',
                            color: '#393b37',
                            marginBottom: 15
                        }}>
                            {editable_strings.about_widyaiswara}
                        </div>

                        <Accordion defaultActiveKey={0} onSelect={(selected) => {
                            console.log('selecteed', selected)
                            setInstructorExpandedIdx(selected)
                        }}
                        >
                            {
                                course.instructors?.map((instructor, key) => {
                                    return (
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey={key}
                                                              style={{backgroundColor: Palette.SECONDARY}}>
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    <img style={{
                                                        width: 50,
                                                        height: 50,
                                                        borderRadius: 45,
                                                        objectFit: 'cover'
                                                    }}
                                                         src={instructor.instructor_image_url ? instructor.instructor_image_url : require('../../asset/public_image_default_user.png')}/>

                                                    <div style={{
                                                        marginLeft: 15,
                                                        color: 'white',
                                                        fontFamily: 'OpenSans-SemiBold',
                                                        flex: 1
                                                    }}>
                                                        {instructor.name}

                                                        <div style={{fontFamily: 'OpenSans-Regular'}}>
                                                            {instructor.position}
                                                        </div>
                                                    </div>

                                                    {
                                                        instructor.description &&
                                                        <>
                                                            {instructorExpandedIdx === key ?
                                                                <FaMinus color={'#e6e6e6'}/> :
                                                                <FaPlus color={'#e6e6e6'}/>}
                                                        </>
                                                    }

                                                </div>
                                            </Accordion.Toggle>
                                            {instructor.description &&
                                            <Accordion.Collapse eventKey={key}>
                                                <Card.Body style={{fontFamily: 'OpenSans-Regular'}}>
                                                    {new Parser().parse(`<p>${instructor.description}</p>`)}
                                                </Card.Body>
                                            </Accordion.Collapse>
                                            }
                                        </Card>
                                    )
                                })
                            }
                        </Accordion>
                    </Col>
                </Row>
                }

                {
                    course.courseChapters && course.courseChapters?.length > 0 &&
                    <Row style={{marginTop: 40}}>
                        <Col>
                            <div style={{
                                fontFamily: 'OpenSans-SemiBold',
                                fontSize: '1.2em',
                                color: '#393b37',
                                marginBottom: 15
                            }}>
                                {editable_strings.discuss_topic}
                            </div>

                            <Row style={{marginBottom: 10}}>
                                <Col style={{fontFamily: 'OpenSans-SemiBold', display: 'flex', alignItems: 'center'}}>
                                    Topik (Total: {course.courseChapters?.length})
                                </Col>

                                <Col style={{fontFamily: 'OpenSans-SemiBold', display: 'flex', alignItems: 'center'}}>
                                    Materi (Total: {totalSyllabusContent})
                                </Col>

                                <Col style={{fontFamily: 'OpenSans-SemiBold', display: 'flex', alignItems: 'center'}}>
                                    Durasi (Total: {printTimeFromSeconds(totalSyllabusDuration)})
                                </Col>

                                <Col>
                                </Col>
                            </Row>


                            <Accordion defaultActiveKey={0} onSelect={(selected) => {
                                console.log('selecteed', selected)
                                setTopicExpandedIdx(selected)
                            }}>
                                <Card>
                                    {
                                        course.courseChapters?.map((courseChapter, idx) => {
                                            let duration = 0;

                                            course.courseSubChapters[courseChapter.id].forEach(courseSubChapter => {
                                                duration += courseSubChapter.duration;
                                            })

                                            return (
                                                <>
                                                    <Accordion.Toggle as={Card.Header} eventKey={idx}
                                                                      style={{backgroundColor: Palette.SECONDARY}}>
                                                        <Row>
                                                            <Col style={{
                                                                fontFamily: 'OpenSans-Regular',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                color: 'white'
                                                            }}>
                                                                {courseChapter.name}
                                                            </Col>

                                                            <Col style={{
                                                                fontFamily: 'OpenSans-Regular',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                color: 'white'
                                                            }}>
                                                                {course.courseSubChapters[courseChapter.id].length} Materi
                                                            </Col>

                                                            <Col style={{
                                                                fontFamily: 'OpenSans-Regular',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                color: 'white'
                                                            }}>
                                                                {printTimeFromSeconds(duration)}
                                                            </Col>

                                                            <Col
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'flex-end'
                                                                }}>
                                                                {topicExpandedIdx === idx ?
                                                                    <FaMinus color={'#e6e6e6'}/> :
                                                                    <FaPlus color={'#e6e6e6'}/>}
                                                            </Col>
                                                        </Row>

                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey={idx}>
                                                        <Card.Body style={{fontFamily: 'OpenSans-Regular'}}>
                                                            {
                                                                course.courseSubChapters[courseChapter.id].map((courseSubChapter, idx) => {
                                                                    console.log("csc", courseSubChapter)

                                                                    let type = courseSubChapter.type

                                                                    if (type === "Video") {
                                                                        try {
                                                                            let content = JSON.parse(courseSubChapter.content)
                                                                            if (content.document) {
                                                                                type = "Video dan Modul"
                                                                            }
                                                                        } catch (e) {
                                                                            console.log(e)
                                                                        }
                                                                    }

                                                                    return (
                                                                        <Row style={{marginBottom: 30}}>
                                                                            <Col style={{
                                                                                fontFamily: 'OpenSans-Regular',
                                                                                display: 'flex',
                                                                                alignItems: 'center'
                                                                            }}>
                                                                                {new Parser().parse(`<p>${courseSubChapter.name}</p>`)}
                                                                            </Col>

                                                                            <Col style={{
                                                                                fontFamily: 'OpenSans-Regular',
                                                                                display: 'flex',
                                                                                alignItems: 'center'
                                                                            }}>
                                                                                {new Parser().parse(`<p>${type}</p>`)}
                                                                            </Col>

                                                                            <Col style={{
                                                                                fontFamily: 'OpenSans-Regular',
                                                                                display: 'flex',
                                                                                alignItems: 'center'
                                                                            }}>
                                                                                {new Parser().parse(`<p>${printTimeFromSeconds(courseSubChapter.duration)}</p>`)}
                                                                            </Col>

                                                                            <Col style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'flex-end'
                                                                            }}>

                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                })
                                                            }
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </>
                                            )
                                        })
                                    }
                                </Card>
                            </Accordion>
                        </Col>
                    </Row>
                }

                {course.allRating?.length > 0 &&
                <Row
                    style={{marginTop: 40}}>
                    <Col xs={12}>
                        <div style={{
                            fontFamily: 'OpenSans-SemiBold',
                            fontSize: '1.2em',
                            color: '#393b37',
                            marginBottom: 15
                        }}>
                            {editable_strings.review}
                        </div>
                    </Col>

                    <Col xs={12} md={4}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 25}}>
                            <div style={{fontSize: 45, fontFamily: 'OpenSans-SemiBold'}}>
                                {avgRating}
                            </div>

                            <div style={{marginLeft: 15}}>
                                <Rating
                                    readonly
                                    initialRating={parseFloat(avgRating)}
                                    emptySymbol={<IoMdStar size={15} color={'#C4C4C4'}/>}
                                    fullSymbol={<IoMdStar size={15} color={'#FEBF35'}/>}/>

                                <div style={{fontFamily: 'OpenSans-Regular', fontSize: 14}}>
                                    {course.allRating?.length} ulasan
                                </div>
                            </div>
                        </div>

                        {
                            ratings.map((rating, idx) => {
                                return (
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: 10,
                                        paddingRight: 15
                                    }}>
                                        <div style={{marginRight: 15, fontFamily: 'OpenSans-Regular', fontSize: 14}}>
                                            {5 - idx} {editable_strings.stars}
                                        </div>
                                        <ProgressBar bgcolor={'#FEBF35'} completed={rating}/>
                                        <div style={{
                                            marginLeft: 15,
                                            fontFamily: 'OpenSans-Regular',
                                            fontSize: 14,
                                            width: 30
                                        }}>
                                            {Math.round(rating)}%
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Col>

                    <Col xs={0} md={1}/>

                    <Col>
                        <div style={{
                            fontFamily: 'OpenSans-SemiBold',
                            fontSize: 14,
                            marginBottom: 30,
                            marginTop: windowWidth < 768 ? 30 : 0
                        }}>
                            {editable_strings.review_from_class} {course.title}
                        </div>

                        <Scrollbars style={{width: '100%', height: 300, marginBottom: 30}}>
                            {
                                course.allRating.map((rating, idx) => {
                                    if (!isAllCommentsShown && idx > 2) {
                                        return null
                                    }

                                    return (
                                        <div style={{marginBottom: 30}}>
                                            <Rating
                                                readonly
                                                initialRating={rating.rating}
                                                emptySymbol={<IoMdStar size={15} color={'#C4C4C4'}/>}
                                                fullSymbol={<IoMdStar size={15} color={'#FEBF35'}/>}/>

                                            <div style={{
                                                fontFamily: 'OpenSans-Regular',
                                                marginTop: 5,
                                                fontSize: 14
                                            }}>oleh {rating.full_name} - {moment(rating.created_at).format('dddd, DD MMMM YYYY')}
                                                {/*{topReview.date}*/}
                                            </div>

                                            <div style={{
                                                fontFamily: 'OpenSans-Regular',
                                                marginTop: 15,
                                                fontSize: 14
                                            }}>{rating.comment && rating.comment.length > 300 && !shownCommentIdx.includes(idx) ? rating.comment.substring(0, 301) + '...' : rating.comment} {rating.comment && rating.comment.length > 300 ?
                                                <a
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        const shownCommentIdxTemp = [...shownCommentIdx];

                                                        if (!shownCommentIdx.includes(idx)) {
                                                            shownCommentIdxTemp.push(idx)
                                                        } else {
                                                            let deleteIdx = -1;

                                                            shownCommentIdxTemp.forEach((commentIdx, key) => {
                                                                if (commentIdx === idx) {
                                                                    deleteIdx = key;
                                                                }
                                                            })

                                                            shownCommentIdxTemp.splice(deleteIdx, 1)
                                                        }

                                                        setShownCommentIdx(shownCommentIdxTemp)
                                                    }}
                                                    href={'#'}>{!shownCommentIdx.includes(idx) ? 'Selengkapnya' : 'Sembunyikan'}</a> : null}</div>
                                        </div>
                                    )
                                })
                            }
                        </Scrollbars>

                        {course.allRating.length > 3 ? <a
                            onClick={(e) => {
                                e.preventDefault()
                                setAllCommentsShown(!isAllCommentsShown)
                            }
                            }
                            href={'#'}>{isAllCommentsShown ? 'Tampilkan Beberapa' : 'Tampilkan Semua Ulasan'}</a> : null}

                        {/*{*/}
                        {/*    topReviews.map(topReview => {*/}
                        {/*        return (*/}
                        {/*            <div style={{marginBottom: 30}}>*/}
                        {/*                <Rating*/}
                        {/*                    readonly*/}
                        {/*                    initialRating={topReview.rating}*/}
                        {/*                    emptySymbol={<IoMdStar size={15} color={'#C4C4C4'}/>}*/}
                        {/*                    fullSymbol={<IoMdStar size={15} color={'#FEBF35'}/>}/>*/}

                        {/*                <div style={{*/}
                        {/*                    fontFamily: 'OpenSans-Regular',*/}
                        {/*                    marginTop: 5,*/}
                        {/*                    fontSize: 14*/}
                        {/*                }}>oleh {topReview.reviewer} {topReview.date}</div>*/}

                        {/*                <div style={{*/}
                        {/*                    fontFamily: 'OpenSans-Regular',*/}
                        {/*                    marginTop: 15,*/}
                        {/*                    fontSize: 14*/}
                        {/*                }}>{topReview.review}</div>*/}
                        {/*            </div>*/}
                        {/*        )*/}
                        {/*    })*/}
                        {/*}*/}
                    </Col>
                </Row>
                }

                {courses.length > 0 ?
                    <>
                        <Row style={{
                            fontFamily: 'OpenSans-Bold',
                            color: '#393b37',
                            fontSize: '1.2em',
                            marginTop: 50,
                            marginBottom: -20,
                            marginLeft: 0
                        }}>
                            Rekomendasi Kelas Lainnya
                        </Row>

                        <Row>
                            {
                                courses.map((course, key) => {
                                    if (key > 2) return null;

                                    return (
                                        <IndependentClassCard
                                            image={course.course_image_url}
                                            title={course.title}
                                            index={key + 1}
                                            onClick={() => {
                                                if (course.course_code) {
                                                    history.push(`/kdm/detail/${course.course_code}`)
                                                } else {
                                                    history.push(`/kdm/${course.id}`)
                                                }
                                            }}
                                        />
                                    )
                                })
                            }
                        </Row>
                    </> : null
                }
            </Container>
        </PageWrapper>
    )
}
