import React, {Component, useEffect} from "react";
import {Button, ButtonGroup, Modal, Nav, Navbar, NavDropdown} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {FaCalendar, FaList} from "react-icons/fa";
import Col from "react-bootstrap/Col";
import PageWrapper from "./PageWrapper";
import moment from "moment";
import ScheduleNode from "../reusable/ScheduleNode";
import Session from "../../models/Session"
import _ from "lodash"
import SessionModal from "../reusable/SessionModal";
import GlobalData from "../../util/GlobalData";
import 'moment/locale/id'
import cookie from 'react-cookies'

import {
    isMobile
} from "react-device-detect";
import ApplicationActivities from "../../models/ApplicationActivity";
import Palette from "../../util/Palette";
import SessionCard from "../reusable/SessionCard";

import FullCalendar, {formatDate} from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

export default class Sessions extends Component {

    firstLoad = true

    constructor(props) {
        super(props)

        let winWidth = window.innerWidth

        this.state = {
            qr: "",
            error: false,
            errorMessage: "",
            windowIsLarge: winWidth > 1199,
            isSessionModalShown: false,
            loginShow: false,
            loginMessage: null,
            isLanguageInfoOpen: true,
            categories: {},
            isHovered: false,
            selectedCategory: 'Semua',
        }

        this.fetchSessions = this.fetchSessions.bind(this);

        this.fetchSessions();
    }

    async fetchSessions() {
        try {
            const categories = await new Session().getAllCategory();
            const categoriesTemp = {
                Semua: {isHovered: false, data: []}
            }

            categories.map(category => {
                categoriesTemp[category.code] = {isHovered: false, data: [], name: category.name}
            })

            try {
                const sessions = await new Session().getRegisteredClass()

                sessions.map(session => {
                    categoriesTemp['Semua'].data.push(session.class);
                    session.class.topic = categoriesTemp[session.class.topic_code].name;
                    session.class.status = session.status;
                    categoriesTemp[session.class.topic_code].data.push(session.class);
                })

                console.log(categoriesTemp)

                this.setState({
                    categories: categoriesTemp,
                });
            } catch (e) {
                e.API = 'fetchSessions'
                console.log(e)
            }
        } catch (e) {
            console.log(e)
        }
    }

    showLogin = () => {
        this.setState({
            loginMessage: 'Harap login untuk melanjutkan pendaftaran',
            isSessionModalShown: false,
            loginShow: true,
            customOnHide: () => {
                this.fetchSessions();
                this.setState({loginShow: false, loginMessage: null, customOnHide: null})
            }
        })
    }

    addApplicationActivity = (session) => {

    }

    renderSessionOfDay(sessionOfDay) {
        return <>
            {sessionOfDay.map((s, key) => {
                return <ScheduleNode
                    session={s}
                    key={key}
                    id={s.id}
                    image={s.image_url}
                    speaker={s.session_speaker}
                    title={s.session_name}
                    timeStart={moment(s.start_time)}
                    timeEnd={moment(s.end_time)}
                    onClick={(session) => {


                        this.addApplicationActivity(session)
                        this.setState({
                            ...this.props,
                            selectedSession: session,
                            isSessionModalShown: true
                        })
                        this.props.history.push("/akademi-Seminar Edukasi Musik/" + s.id)

                    }}
                />
            })}
        </>
    }

    renderCategoryNode(category) {
        return (
            <div onClick={() => this.setState({selectedCategory: category})} style={{
                cursor: 'pointer',
                fontSize: 14,
                letterSpacing: 2,
                fontWeight: '400',
                color: this.state.categories[category].isHovered || this.state.selectedCategory === category ? Palette.PRIMARY : '#868887',
                marginLeft: 30,
                marginBottom: 15
            }}
                 onMouseOver={() => {
                     const categoriesTemp = {...this.state.categories};

                     categoriesTemp[category] = {
                         ...categoriesTemp[category],
                         isHovered: true
                     }

                     this.setState({categories: categoriesTemp})
                 }}
                 onMouseLeave={() => {
                     const categoriesTemp = {...this.state.categories};

                     categoriesTemp[category] = {
                         ...categoriesTemp[category],
                         isHovered: false
                     }

                     this.setState({categories: categoriesTemp})
                 }}>
                {category !== 'Semua' ? this.state.categories[category].name : 'Semua'}
                <div style={{
                    width: this.state.categories[category].isHovered || this.state.selectedCategory === category ? '100%' : 0,
                    height: 1,
                    backgroundColor: Palette.PRIMARY,
                    marginTop: 3,
                    transition: 'width .1s'
                }}/>
            </div>
        )
    }

    renderSessionCategory() {
        return (
            <Row style={{marginTop: 20, marginLeft: 0, marginRight: 0}}>

                <Col md={12} style={{fontFamily: 'OpenSans-Bold', marginBottom: 40, fontSize: '1.5em'}}>
                    Kelas Terdaftar
                </Col>

                <Col style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Row>

                        {
                            Object.keys(this.state.categories).map(category => {
                                console.log(category)

                                return this.renderCategoryNode(category)
                            })
                        }
                    </Row>

                    {/*{this.categoryNode()}*/}
                    {/*<div onClick={() => this.setState({selectedCategory: null})} style={{cursor: 'pointer', textDecorationLine: !this.state.selectedCategory ? 'underline' : 'none', letterSpacing: 2, fontWeight: !this.state.selectedCategory ? '400' : '300', color: '#4f4f4f'}}>*/}
                    {/*    All*/}
                    {/*</div>*/}

                    {/*{*/}
                    {/*    this.categories.map(category => {*/}
                    {/*        return (*/}
                    {/*            <div onClick={() => this.setState({selectedCategory: category})} style={{cursor: 'pointer', letterSpacing: 2, fontWeight: category === this.state.selectedCategory ? '400' : '300', textDecorationLine: category === this.state.selectedCategory ? 'underline' : 'none', color: '#4f4f4f', marginLeft: 20}}>*/}
                    {/*                {category}*/}
                    {/*            </div>*/}
                    {/*        )*/}
                    {/*    })*/}
                    {/*}*/}
                </Col>
            </Row>
        )
    }

    renderSessionListView() {

        if (this.state.categories[this.state.selectedCategory]) {

            return (
                <>
                    {
                        this.renderSessionCategory()
                    }
                    <Row>
                        {
                            this.state.categories[this.state.selectedCategory].data.map(session => {
                                return (
                                    <SessionCard
                                        image={session.class_image_url ? session.class_image_url : '/image/no-img-placeholder.png'}
                                        name={session.name}
                                        topic={session.topic}
                                        status={session.status}
                                        onClick={() => this.props.history.push(`/kelas/detail/${session.code}`)}/>
                                )
                            })
                        }
                    </Row>
                </>
            )
        }
    }

    renderSession() {

        return <Row style={{marginTop: "2em", transition: 'height 5s'}}>


            <Col md={12}>
                {this.renderSessionListView()}
            </Col>

        </Row>
    }

    render() {

        let lang = cookie.load('lang')
        // console.log(lang)

        let isEN = lang !== "id"

        return (
            <PageWrapper
                headerShy={false}
                history={this.props.history}
                loginShow={this.state.loginShow}
                message={this.state.loginMessage}
                customOnHide={this.state.customOnHide}
                loginListener={(user) => {
                    this.fetchSessions()
                }}
                headerActive={'my-session'}
            >

                <Modal
                    size="sm"
                    centered
                    onHide={() => {
                        this.setState({paymentModalVisible: false})
                        window.location.reload();
                    }}
                    show={this.state.paymentModalVisible}
                >
                    <Modal.Header closeButton>
                        Informasi
                    </Modal.Header>
                    <Modal.Body>
                        <div>Untuk melanjutkan
                            pembayaran, silahkan menekan <a href={this.state.paymentUrl}>tautan ini</a>.
                        </div>
                    </Modal.Body>

                </Modal>

                <SessionModal
                    show={this.state.isSessionModalShown}
                    session={this.state.selectedSession}
                    setPending={() => {
                        this.fetchSessions();
                        this.setState({isSessionModalShown: false})
                    }}
                    onHide={() => {
                        this.setState({
                            isSessionModalShown: false,
                            selectedSession: null
                        })
                        window.history.pushState({}, '', "/akademi-Seminar Edukasi Musik");
                        // this.props.history.push("/akademi-Seminar Edukasi Musik")
                    }}
                    showLogin={this.showLogin}
                    fetchSessions={this.fetchSessions}
                    showPaymentModal={(paymentUrl) => {
                        console.log("hoihoi")
                        this.setState({paymentUrl, paymentModalVisible: true, isSessionModalShown: false})
                    }}
                />

                <Container style={{marginTop: "1em"}}>
                    <Row style={{
                        marginTop: isMobile ? 0 : "2em"
                    }}>
                    </Row>

                    {
                        this.renderSession()
                    }


                    {/*{this.state.sessions.map((session, key) => {*/}
                    {/*    return <ScheduleNode*/}
                    {/*        key={key}*/}
                    {/*        image={session.image_url}*/}
                    {/*        speaker={session.session_speaker}*/}
                    {/*        title={session.session_name}*/}
                    {/*        timeStart={moment(session.start_time)}*/}
                    {/*        timeEnd={moment(session.end_time)}*/}
                    {/*    />*/}
                    {/*})}*/}

                </Container>
            </PageWrapper>
        );
    }
}
