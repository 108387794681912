import ApiRequest from "../util/ApiRequest";
import GlobalData from "../util/GlobalData";

export default class MemberCourseProgress {
    create = async (body) => {
        return await ApiRequest.set("/v1/member_course_progress", "POST", body);
    }

    update = async (id, body) => {
        return await ApiRequest.set(`/v1/member_course_progress/${id}`, "PUT", body);
    }


    getAllByCourseChapterId = async(id) => {
        return await ApiRequest.set(`/v1/member_course_progress/${id}/all`, "GET");
    }
}
