import Container from "react-bootstrap/Container";
import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import {useCookies} from "react-cookie";
import Button from "react-bootstrap/Button";
import Palette from "../../util/Palette";
import PageWrapper from "./PageWrapper";

export default function PostPaymentPage(props) {
    const [statusImg, setStatusImg] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [cookie, setCookie, removeCookie] = useCookies()
    const isEN = false
    const [isBtnHovered, setBtnHovered] = useState(false)

    useEffect(() => {
        const paymentStatus = new URLSearchParams(props.location.search).get('transaction_status');

        if (paymentStatus === "settlement" || paymentStatus === "capture") {
            setStatusImg('/image/check-mark.png');
            setPaymentStatus(isEN ? 'Payment Successful!' : 'Pembayaran Berhasil!')
        } else if (paymentStatus === "deny") {
            setStatusImg('/image/error.png');
            setPaymentStatus(isEN ? 'Payment Failed!' : 'Pembayaran Gagal!')
        } else if (paymentStatus === "pending") {
            setStatusImg('/image/exclamation.png');
            setPaymentStatus(isEN ? 'Waiting for Payment!' : 'Menunggu Pembayaran!')
        }
    })

    return (
        <PageWrapper
            headerActive={"post-payment"}
            backgroundColor={"#f7f9fa"}
        >
            <Container fluid style={{backgroundColor: '#f7f9fa', paddingTop: '5em', paddingBottom: '15px', minHeight : "60vh"}}>
                <Row style={{display: 'flex', justifyContent: 'center'}}>
                    <img src={statusImg} id="status_img" style={{width: '80px', height: '80px', marginTop: '15px'}}/>
                </Row>

                <Row style={{display: 'flex', justifyContent: 'center'}}>
                    <h4 style={{marginTop: '10px'}}><b>
                        {paymentStatus}
                    </b></h4>
                </Row>

                <Row style={{display: 'flex', justifyContent: 'center'}}>
                    <p style={{marginTop: '10px', fontWeight: '300', width : "100%", textAlign : "center"}}>{isEN ? 'Please close this page to continue your activities or' : 'Silakan tutup halaman ini untuk melanjutkan aktivitas Anda atau'}</p>
                </Row>

                <Row style={{display: 'flex', justifyContent: 'center'}}>
                    <Button style={{backgroundColor: Palette.PRIMARY, borderWidth: 0, fontWeight: '600', opacity: isBtnHovered ? .8 : 1}} onMouseEnter={() => setBtnHovered(true)} onMouseLeave={() => setBtnHovered(false)} onClick={() => window.open('/', "_self")}>{isEN ? 'Back to Homepage' : 'Kembali ke Halaman Utama'}</Button>
                </Row>
            </Container>
        </PageWrapper>

    )
}
