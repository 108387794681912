import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import PageWrapper from "./PageWrapper";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {isMobile} from "react-device-detect";
import ReactPaginate from "react-paginate";
import LiveStreamingModel from "../../models/LiveStreaming";
import {useHistory, useParams} from "react-router-dom";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

export default function TTSPage(props) {

    const {pdfURL} = useParams()

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    return (
        <PageWrapper headerActive={'article'}>
            <Container style={{marginTop: '60px'}}>
                <Row style={{
                    marginTop: isMobile ? 0 : "6em",
                    fontFamily: 'OpenSans-Bold'
                }}>
                    {/*{decodeURIComponent(pdfURL)}*/}
                    <Col
                        style={{overflowX : "scroll"}}
                        // onContextMenu={(e) => {
                        //     e.preventDefault()
                        // }}
                        md={12}>
                        <Document
                            onLoadSuccess={ ({ numPages }) =>{
                                console.log("numpages", numPages)
                                setNumPages(numPages);
                            }}
                            file={decodeURIComponent(pdfURL)}
                        >
                            {
                                numPages && [...Array(numPages)].map((obj,key)=>{
                                    return <Page
                                        className={"custom-pdf-page"}
                                        pageNumber={key+1} />
                                })
                            }
                        </Document>
                        {/*<embed*/}
                        {/*    src={decodeURIComponent(pdfURL)}*/}
                        {/*    width='100%'*/}
                        {/*    height='1000' frameBorder='0'></embed>*/}
                    </Col>
                </Row>
            </Container>
        </PageWrapper>
    )

    // return <PageWrapper headerActive={'article'}>
    //     <Container style={{marginTop: '60px'}}>
    //         <Row style={{
    //             marginTop: isMobile ? 0 : "6em",
    //             fontFamily: 'OpenSans-Bold'
    //         }}>
    //
    //             <Col md={12}>
    //                 <iframe
    //                     width="100%"
    //                     height={"1000"}
    //                     // style="background-color:white; padding:5px 0px 0 5px; border:3px solid black; margin:auto; display:block"
    //                     frameBorder="0" src="https://crosswordlabs.com/embed/tts-sinara"></iframe>
    //             </Col>
    //         </Row>
    //     </Container>
    // </PageWrapper>


}
