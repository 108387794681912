import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import React, {useState} from "react";
import {FaEye} from "react-icons/fa";
import moment from "moment"

export default function SessionCard(props) {
    const [isHovered, setHovered] = useState(false);

    const {customSM, customMD} = props

    // console.log(props.name)
    // console.log(props.topic)

    // console.log("session class status", props.name)
    // console.log("session class status", props.topic)

    return (
        <Col xs={12} sm={customSM ? customSM : 6} md={customMD ? customMD : 4} style={{marginTop: 50, cursor: 'pointer'}} onClick={props.onClick}
             onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            <div style={{position: 'relative', transition: 'height 5s'}}>
                {props.registrationStatus === 'ACCEPTED' || props.registrationStatus === 'WAITING' ?
                    <div style={{
                        fontFamily: 'OpenSans-SemiBold',
                        fontSize: 15,
                        color: props.registrationStatus === 'ACCEPTED' ? 'white' : '#4f4f4f',
                        borderBottomLeftRadius: 4,
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        backgroundColor: props.registrationStatus === 'ACCEPTED' ? '#5fcaf9' : 'yellow',
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 2,
                        paddingBottom: 2
                    }}>
                        {props.registrationStatus === 'ACCEPTED' ?
                            props.classStatus === "ENDED" ? 'Arsip' : 'Terdaftar' : 'Menunggu Pembayaran'}
                    </div> : null
                }
                <img src={props.image}
                     style={{width: '100%', height: 320, objectFit: 'contain'}}/>

                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    height: '100%',
                    width: '100%',
                    backgroundColor: '#133c57E6',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    opacity: isHovered ? 1 : 0,
                    transition: 'opacity 0.8s'
                }}>
                    <FaEye style={{
                        fontSize: 30,
                        color: "white"
                    }}/>
                    {/*<img src={'gif/eye.gif'} style={{width: 50}}/>*/}
                </div>
            </div>

            <div style={{fontSize: 20, marginTop: 15, color: '#4f4f4f'}}>
                {props.name}
            </div>

            <div style={{fontSize: 15, marginTop: 10, fontWeight: '300', color: '#4f4f4f'}}>
                {typeof props.topic === "string" && typeof props.name === "string" ? props.topic : null}
            </div>

            {
                !props.permanent && <div style={{fontSize: 15, marginTop: 10, fontWeight: '300', color: '#4f4f4f'}}>
                    {(new moment(props.classDate)).format("D MMM YYYY")}
                </div>
            }

        </Col>
    )


}
