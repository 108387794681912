import React, {Component, useEffect, useState} from "react";
import {Slide} from "react-reveal";
import {Helmet} from "react-helmet";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageWrapper from "../page/PageWrapper";
import Palette from "../../util/Palette";
import {Button, Navbar, Dropdown} from "react-bootstrap";
import GlobalData from "../../util/GlobalData";
import {Link, useHistory} from "react-router-dom";
import {
    isMobile
} from "react-device-detect";
import {useCookies} from "react-cookie";
import {withCookies, Cookies} from 'react-cookie';
import Container from "react-bootstrap/Container";
import EditProfileModal from "./EditProfileModal";

import UserMenuModal from "./UserMenuModal";
import Strings from "../../util/Strings";
import {FaShoppingCart} from "react-icons/fa";

import {IconButton} from "@material-ui/core";
import Badge from '@material-ui/core/Badge';
import Cart from "./Cart";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import MuiAlert from "@material-ui/lab/Alert/Alert";
import {FiChevronDown} from "react-icons/fi";
import SideBar from "./SideBar";
import {IoMdMenu} from "react-icons/io";
import {GiHamburgerMenu} from "react-icons/gi";
import RecordingUrlModal from "./RecordingUrlModal";
import AnnouncementModal from "./AnnouncementModal";
import Session from "../../models/Session";

export default function Header(props) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    let [scrollPosition, setScrollPosition] = useState(0)
    const [editProfileShow, setEditProfileShow] = useState(false)
    let history = useHistory()
    let user = GlobalData.user
    const [cookie, setCookie, removeCookie] = useCookies()

    const [userMenuModalShown, setUserMenuModalShown] = useState(false)

    const [isSideBarOpen, setIsSideBarOpen] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const [cart, setCart] = useState([]);

    let [otherSessions, setOtherSessions] = useState([])
    let [gsmsSessions, setGsmsSessions] = useState([])

    const [announcementOpen, setAnnouncementOpen] = useState(false)
    const editable_strings = GlobalData.strings.header_menu;

    useEffect(() => {
        const cart = localStorage.getItem('cart');

        if (cart) {
            setCart(JSON.parse(cart))
        }


        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
            window.removeEventListener('scroll', listenToScroll)
        }
    }, []);

    useEffect(() => {
        // const isViewed = localStorage.getItem('is11JunMaintenanceAnnouncementViewed');
        // if (!isViewed) {
        //     setAnnouncementOpen(true)
        // }
        fetchUpcomingSessions()
    }, [])

    const fetchUpcomingSessions = async () => {
        try {

            const result = await new Session().getAll()
            const sessionTemp = result.filter((obj, key) => new Date(obj.start_time) > new Date())

            let otherSessions = []
            let gsmsSessions = []

            for (let s of sessionTemp) {
                if (s.topic_code === "4ZLBL2UED0") {
                    otherSessions.push(s)
                } else if (s.topic_code === "4KCSLSAUV5") {
                    gsmsSessions.push(s)
                }
            }

            setOtherSessions(otherSessions)
            setGsmsSessions(gsmsSessions)

        } catch (e) {
            console.log(e)
        }
    }

    const cartEmptyAction = () => {
        setIsDrawerOpen(false)

        showSnackBar('Keranjang Belanja Anda Kosong', 'warning')
    }

    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    useEffect(() => window.addEventListener('scroll', listenToScroll))

    function listenToScroll() {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height

        setScrollPosition(scrolled)
    }

    let header;

    if (isMobile) {
        header = <Slide top when={
            !props.shy || scrollPosition > 0.1
        }
        >
            <div style={{
                width: "100%",
                height: "5em",
                position: "fixed",
                top: 0,
                zIndex: 1000,
                marginLeft: 0,
                marginRight: 0,
                display: "flex",
                alignItems: "center",
                background: "white",
                boxShadow: "0px 4px 8px 1px #888888"
            }}>

                <Row style={{
                    width: "100%",
                    padding: 0,
                    margin: 0
                }}>
                    <Col xs={{span: 12}}>
                        <div style={{
                            position: "absolute",
                            height: "100%",
                            display: "flex",
                            alignItems: "center"
                        }}>
                            <IconButton
                                // disabled={CartManager.getCartContent().length === 0}
                                style={{
                                    color: Palette.DARK_GREY,
                                    // color: CartManager.getCartContent().length === 0 ? "lightGrey" : Palette.PRIMARY,
                                }}
                                onClick={() => {
                                    setIsSideBarOpen(true)
                                }}
                                onMouseDown={e => e.preventDefault()}
                            >
                                <GiHamburgerMenu/>
                            </IconButton>
                        </div>

                        <img
                            onClick={() => history.push("/")}
                            style={{
                                width: "100%",
                                marginTop: "0.3em",
                                marginBottom: "0.3em",
                                maxHeight: "5em",
                                objectFit: "contain",
                                cursor: 'pointer'
                            }}
                            // src={require("../../asset/mainlogo.png")}
                            src={require("../../asset/new-narabahasa-logo.jpeg")}
                        />
                    </Col>

                    {/*<div style={{*/}
                    {/*    margin: "absolute",*/}
                    {/*    position: "absolute",*/}
                    {/*    right: "8%",*/}
                    {/*    display: props.isLoggedIn ? "flex" : "none",*/}
                    {/*    flexDirection: "row-reverse"*/}
                    {/*}}*/}
                    {/*>*/}

                    {/*    <Dropdown*/}
                    {/*        onSelect={(eventKey, event) => {*/}
                    {/*            if (eventKey === "3") {*/}
                    {/*                console.log("this is chosen")*/}
                    {/*                GlobalData.token = null*/}
                    {/*                GlobalData.user = null*/}

                    {/*                removeCookie("token", {path: '/'})*/}
                    {/*                console.log("cookie " + cookie.token)*/}
                    {/*                setTimeout(function () {*/}
                    {/*                    window.location.reload();*/}
                    {/*                }, 500);*/}
                    {/*            }*/}

                    {/*            if (eventKey === "1") {*/}
                    {/*                console.log("numero uno")*/}
                    {/*                setEditProfileShow(true)*/}
                    {/*            }*/}

                    {/*            // if (eventKey === "2") {*/}
                    {/*            //     setIsLanguageModalShow(true)*/}
                    {/*            // }*/}

                    {/*        }}*/}
                    {/*        alignRight>*/}
                    {/*        <Dropdown.Toggle*/}
                    {/*            variant={"light"}*/}
                    {/*            id="dropdown-custom-1">*/}
                    {/*            <span style={{*/}
                    {/*                marginLeft: '10px',*/}
                    {/*                color: Palette.PRIMARY,*/}
                    {/*                fontWeight: 'bold',*/}
                    {/*                fontSize: '0.8em'*/}
                    {/*            }}>*/}
                    {/*                {user ? user.full_name : null}*/}
                    {/*            </span>*/}


                    {/*        </Dropdown.Toggle>*/}
                    {/*        <Dropdown.Menu className="super-colors">*/}
                    {/*            <Dropdown.Item eventKey="1">Ubah Profil</Dropdown.Item>*/}
                    {/*            <Dropdown.Item eventKey="3">Keluar</Dropdown.Item>*/}
                    {/*        </Dropdown.Menu>*/}
                    {/*    </Dropdown>{' '}*/}

                    {/*</div>*/}
                </Row>

            </div>
        </Slide>
    } else {

        if (scrollPosition < 0.05 && props.shy) {
            header = null;
        } else {
            header = <>
                <div style={{
                    background: Palette.PRIMARY
                }}>

                </div>
                <Slide top when={
                    !props.shy || scrollPosition > 0.1
                }>
                    <div>
                        <div style={{
                            width: "100%",
                            position: "fixed",
                            top: 0,
                            zIndex: 1000,
                            display: "flex",
                            background: "white",
                            boxShadow: "0px -2px 3px 1px #888888",
                            padding: '10px',
                            flexDirection: "column",
                            alignItems: "center"
                        }}
                        >

                            <Container>

                                <Row>
                                    <Col md={12}
                                         style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        {
                                            windowWidth <= 800 &&
                                            <div style={{
                                                position: "absolute",
                                                left: 0,
                                                bottom: 0,
                                                display: props.isLoggedIn ? 'flex' : 'block',
                                                flexDirection: 'row'
                                            }}>
                                                <IconButton
                                                    // disabled={CartManager.getCartContent().length === 0}
                                                    style={{
                                                        color: Palette.DARK_GREY
                                                        // color: CartManager.getCartContent().length === 0 ? "lightGrey" : Palette.PRIMARY,
                                                    }}
                                                    onClick={() => {
                                                        setIsSideBarOpen(true)
                                                    }}
                                                    onMouseDown={e => e.preventDefault()}
                                                >
                                                    <GiHamburgerMenu/>
                                                </IconButton>
                                            </div>
                                        }

                                        <img
                                            onClick={() => history.push("/")}
                                            style={{
                                                maxHeight: "5em",
                                                objectFit: "contain",
                                                marginTop: '6px',
                                                cursor: "pointer"
                                            }}
                                            src={require("../../asset/new-narabahasa-logo.jpeg")}
                                        />

                                        <div style={{
                                            position: "absolute",
                                            right: 0,
                                            bottom: 0,
                                            display: props.isLoggedIn ? 'flex' : 'block',
                                            flexDirection: 'row'
                                        }}>
                                            {
                                                windowWidth > 800 &&
                                                <>
                                                    {props.isLoggedIn ?
                                                        <div style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center'
                                                        }}
                                                        >
                                                            <Dropdown
                                                                onSelect={(eventKey, event) => {
                                                                    if (eventKey === "3") {
                                                                        console.log("this is chosen")
                                                                        GlobalData.token = null
                                                                        GlobalData.user = null

                                                                        removeCookie("token", {path: '/'})
                                                                        console.log("cookie " + cookie.token)
                                                                        setTimeout(function () {
                                                                            window.location.reload();
                                                                        }, 500);

                                                                    }

                                                                    if (eventKey === "1") {
                                                                        console.log("numero uno")
                                                                        setEditProfileShow(true)
                                                                    }
                                                                }}
                                                                alignRight>
                                                                <Dropdown.Toggle
                                                                    onClick={() => setUserMenuModalShown(true)}
                                                                    variant={"light"}
                                                                    id="dropdown-custom-1">

                                                <span style={{
                                                    marginLeft: '10px',
                                                    color: Palette.PRIMARY,
                                                    fontWeight: 'bold',
                                                    fontSize: '0.8em'
                                                }}>
                                                    {user ? user.full_name : null}
                                                </span>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="super-colors">
                                                                    <Dropdown.Item
                                                                        eventKey="1">Ubah Profil</Dropdown.Item>
                                                                    <Dropdown.Item eventKey="3">Keluar</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div> :
                                                        <Button
                                                            style={{
                                                                background: Palette.PRIMARY,
                                                                paddingInlineStart: "4em",
                                                                paddingInlineEnd: "4em"
                                                            }}
                                                            onClick={() => {
                                                                props.onLoginPress()
                                                                console.log("caserole")
                                                            }}
                                                            size={"sm"}>
                                                            {Strings.header.login_button}
                                                        </Button>}

                                                    <IconButton
                                                        // disabled={CartManager.getCartContent().length === 0}
                                                        style={{
                                                            marginLeft: "1em",
                                                            color: Palette.DARK_GREY
                                                            // color: CartManager.getCartContent().length === 0 ? "lightGrey" : Palette.PRIMARY,
                                                        }}
                                                        onClick={() => {
                                                            if ((props.cart && props.cart.length > 0) || cart.length > 0) setIsDrawerOpen(true)
                                                            else cartEmptyAction()
                                                        }}
                                                        onMouseDown={e => e.preventDefault()}
                                                    >
                                                        {(props.cart && props.cart.length > 0) || cart.length > 0 ?
                                                            <Badge
                                                                badgeContent={props.cart ? props.cart.length : cart.length}
                                                                color="secondary">
                                                                <FaShoppingCart/>
                                                            </Badge> :
                                                            <FaShoppingCart/>
                                                        }
                                                    </IconButton>
                                                </>
                                            }
                                        </div>
                                    </Col>

                                    {
                                        windowWidth > 800 &&
                                        <Col md={12} style={{
                                            display: "flex",
                                            marginTop: 15,
                                            marginBottom: 5
                                        }}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                // paddingBottom: windowWidth > 800 ? 15 : 10,
                                                flex: 1,
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>

                                                {/*<div className="dropdown" style={{marginLeft: 30}}>*/}

                                                {/*<span*/}
                                                {/*    style={{fontWeight: '600', color: Palette.PRIMARY, cursor: 'pointer'}}>Daftar Kelas <FiChevronDown/></span>*/}
                                                {/*<div className="dropdown-content" style={{paddingBottom: 10}}>*/}

                                                {/*{*/}
                                                {/*    props.isLoggedIn &&*/}
                                                {/*    <>*/}
                                                {/*        <Link*/}
                                                {/*            to={'/kelas#kelas-saya'}*/}
                                                {/*            style={{fontWeight: '600'}}>Kelas Saya</Link>*/}

                                                {/*        <div style={{*/}
                                                {/*            backgroundColor: '#dbdbdb',*/}
                                                {/*            height: 1,*/}
                                                {/*            marginTop: 10,*/}
                                                {/*            marginBottom: 10*/}
                                                {/*        }}/>*/}
                                                {/*    </>*/}
                                                {/*}*/}


                                                {/*    <Link to={'/kelas#kelas-publik-mendatang'}*/}
                                                {/*          style={{fontWeight: '600'}}>Kelas*/}
                                                {/*        Publik Mendatang</Link>*/}

                                                {/*    <div style={{*/}
                                                {/*        backgroundColor: '#dbdbdb',*/}
                                                {/*        height: 1,*/}
                                                {/*        marginTop: 10,*/}
                                                {/*        marginBottom: 10*/}
                                                {/*    }}/>*/}

                                                {/*<Link to={'/kelas#kelas-daring-mandiri'}*/}
                                                {/*      style={{fontWeight: '600'}}>Kelas*/}
                                                {/*    Daring Mandiri</Link>*/}

                                                {/*</div>*/}
                                                {/*</div>*/}

                                                <Link
                                                    to={'/kelas#kelas-publik-mendatang'}
                                                    style={{fontWeight: '600'}}>
                                                    {editable_strings.public_class_soon}
                                                </Link>

                                                <Link to={'/kelas#kelas-daring-mandiri'}
                                                      style={{fontWeight: '600', marginLeft: 30}}>
                                                    {editable_strings.private_class ? editable_strings.private_class :"Kelas Mandiri"}
                                                </Link>

                                                {
                                                    otherSessions.length > 0 &&
                                                    <Link to={'/kelas#acara-lain'}
                                                          style={{fontWeight: '600', marginLeft: 30}}>
                                                        Acara Lain
                                                    </Link>
                                                }

                                                {
                                                    gsmsSessions.length > 0 &&
                                                    <Link to={'/kelas#gsms'}
                                                          style={{fontWeight: '600', marginLeft: 30}}>
                                                        GSMS
                                                    </Link>
                                                }


                                                <Link
                                                    to={'/kreasi'}
                                                    style={{fontWeight: '600', marginLeft: 30}}>
                                                    {editable_strings.creation}
                                                </Link>

                                                <Link
                                                    to={'/artikel'}
                                                    style={{fontWeight: '600', marginLeft: 30}}>
                                                    {editable_strings.article}
                                                </Link>

                                                {
                                                    props.isLoggedIn &&
                                                    <>
                                                        <div style={{width : 5, marginLeft : 15, height : "100%", backgroundColor : Palette.PRIMARY}}>

                                                        </div>
                                                        <Link
                                                            to={'/kelas#kelas-saya'}
                                                            style={{fontWeight: '600', marginLeft: 15}}>
                                                            {editable_strings.my_class}</Link>
                                                        <Link
                                                            to={'/transaksi-saya'}
                                                            style={{fontWeight: '600', marginLeft: 30}}>
                                                            {editable_strings.my_transaction}
                                                        </Link>
                                                    </>

                                                }


                                            </div>
                                        </Col>
                                    }

                                </Row>

                            </Container>
                        </div>
                    </div>
                </Slide>
            </>
        }

    }

    const [snackBarProp, setSnackBarProp] = useState({});

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const showSnackBar = (message, severity) => {
        setSnackBarProp({
            open: true,
            message,
            severity
        })
    }

    return <>
        {header}

        <Snackbar
            anchorOrigin={{vertical: "top", horizontal: "center"}}
            open={snackBarProp.open} autoHideDuration={6000}
            onClose={() => {
                setSnackBarProp({
                    open: false
                })
            }}>
            <Alert severity={snackBarProp.severity}>
                {snackBarProp.message}
            </Alert>
        </Snackbar>

        <UserMenuModal
            show={userMenuModalShown}
            onHide={() => {
                setUserMenuModalShown(false)
            }}
        />


        <EditProfileModal
            onHide={() => {
                setEditProfileShow(false)
            }}
            show={editProfileShow}
        />

        <SideBar
            gsmsSessions={gsmsSessions}
            openCart={() => {
                // showSnackBar('Keranjang belanja kosong!', 'warning')

                setIsSideBarOpen(false)
                setIsDrawerOpen(true)
            }}
            onClose={() => setIsSideBarOpen(false)}
            open={isSideBarOpen}
            isLoggedIn={props.isLoggedIn}
            accountPopup={() => {
                setIsSideBarOpen(false)

                setUserMenuModalShown(true)
            }}
            loginPopup={() => {
                setIsSideBarOpen(false)
                //tampilin pake snackbar aja
                // props.setLoginMessage("Harap login terdahulu sebelum checkout")
                props.onLoginPress()
            }}/>

        <Cart
            user={props.user}
            cartEmptyAction={cartEmptyAction}
            cart={props.cart ? props.cart : cart}
            setCart={props.setCart ? props.setCart : setCart}
            onClose={() => {
                if (props.setCartOpen) props.setCartOpen(false)

                setIsDrawerOpen(false)
            }}
            open={isDrawerOpen || props.isCartOpen}
            isLoggedIn={props.isLoggedIn}
            loginPopup={() => {
                setIsDrawerOpen(false)
                showSnackBar('Harap login terlebih dahulu sebelum melakukan pembayaran', 'warning')
                //tampilin pake snackbar aja
                // props.setLoginMessage("Harap login terdahulu sebelum checkout")
                props.onLoginPress()
            }}
        />

        <AnnouncementModal
            isOpen={announcementOpen}
            onClose={() => {
                setAnnouncementOpen(false)
            }}
        />

    </>

}
