import {useHistory, useParams, useLocation, Link} from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
    FaPlus,
    FaMinus,
    FaBars,
    FaCartPlus, MdArrowBack

} from "react-icons/all";
import Divider from "@material-ui/core/Divider";
import Col from "react-bootstrap/Col";

import Palette from "../../util/Palette";
import {ButtonGroup, Row, Spinner} from "react-bootstrap";
import Dropzone from "react-dropzone";
import IconButton from "@material-ui/core/IconButton";

import {useCookies} from "react-cookie";
import textFormatter from "../../util/textFormatter";
import GlobalData from "../../util/GlobalData";
import {
    isMobile
} from "react-device-detect";
import SimpleLineIcon from "react-simple-line-icons";
import {AiOutlineClose} from "react-icons/ai";
// import CartManager from "../../util/CartManager";
// import Product from "../../models/Product";
import MuiAlert from "@material-ui/lab/Alert/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import ProgressBar from "./ProgressBar";
import {FaShoppingCart} from "react-icons/fa";

const drawerWidth = 550

export default function Cart(props) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    let user = GlobalData.user
    let history = useHistory()
    let location = useLocation();

    const anchor = "left"

    const [cookie, setCookie, removeCookie] = useCookies(["token"])
    const [events, setEvents] = useState([])
    const [activeEvent, setActiveEvent] = useState({})

    const [products, setProducts] = useState([])

    const [cartData, setCartData] = useState([])

    const {event_url} = useParams()

    const [isLoading, setLoading] = useState(false);

    // console.log("active tab " + props.activeTab)

    // const fetchProducts = async () => {
    //     try {
    //         const allProducts = await new Product().getAll();
    //         console.log(allProducts)
    //         setProducts(allProducts)
    //
    //         try {
    //         } catch (e) {
    //             e.API = 'fetchSessions'
    //             console.log(e)
    //         }
    //     } catch (e) {
    //         console.log(e)
    //     }
    // }


    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
        // fetchProducts()
    }, [])

    useEffect(() => {
        // setCartData(CartManager.getCartContent())
    }, [props.open])

    const getProductData = (id) => {

        let selectedProduct = products.find((obj, key) => {
            return obj.id == id
        })

        return selectedProduct ? selectedProduct : {}
    }

    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    // const product =
    //     [
    //         {
    //          id: 1,
    //          name: "Baju Raka",
    //          size: "XL",
    //          price: 30000,
    //             quantity: 4,
    //          image:  require("./baju1.jpg")
    //         },
    //         {
    //             id: 2,
    //             name: "Exclusive clothes",
    //             size: "M",
    //             price: 130000,
    //             quantity: 1,
    //             image:  require("./baju2.jpg")
    //
    //         },
    //         {
    //             id: 2,
    //             name: "Exclusive clothes",
    //             size: "M",
    //             price: 130000,
    //             quantity: 1,
    //             image:  require("./baju2.jpg")
    //
    //         },{
    //         id: 2,
    //         name: "Exclusive clothes",
    //         size: "M",
    //         price: 130000,
    //         quantity: 1,
    //         image:  require("./baju2.jpg")
    //
    //     },{
    //         id: 2,
    //         name: "Exclusive clothes",
    //         size: "M",
    //         price: 130000,
    //         quantity: 1,
    //         image:  require("./baju2.jpg")
    //
    //     },{
    //         id: 2,
    //         name: "Exclusive clothes",
    //         size: "M",
    //         price: 130000,
    //         quantity: 1,
    //         image:  require("./baju2.jpg")
    //
    //     },{
    //         id: 2,
    //         name: "Exclusive clothes",
    //         size: "M",
    //         price: 130000,
    //         quantity: 1,
    //         image:  require("./baju2.jpg")
    //
    //     },{
    //         id: 2,
    //         name: "Exclusive clothes",
    //         size: "M",
    //         price: 130000,
    //         quantity: 1,
    //         image:  require("./baju2.jpg")
    //
    //     },{
    //         id: 2,
    //         name: "Exclusive clothes",
    //         size: "M",
    //         price: 130000,
    //         quantity: 1,
    //         image:  require("./baju2.jpg")
    //
    //     },{
    //         id: 2,
    //         name: "Exclusive clothes",
    //         size: "M",
    //         price: 130000,
    //         quantity: 1,
    //         image:  require("./baju2.jpg")
    //
    //     },{
    //         id: 2,
    //         name: "Exclusive clothes",
    //         size: "M",
    //         price: 130000,
    //         quantity: 1,
    //         image:  require("./baju2.jpg")
    //
    //     },{
    //         id: 2,
    //         name: "Exclusive clothes",
    //         size: "M",
    //         price: 130000,
    //         quantity: 1,
    //         image:  require("./baju2.jpg")
    //
    //     },
    //
    //     ];

    const checkout = () => {
        console.log('checkout')

        setLoading(true)

        setTimeout(() => {
            window.location.href = 'https://midtrans.com/?utm_source=google&utm_medium=paidsearch&utm_campaign=ID20_Midtrans_Leads_MerchantAcquisition_Brand&utm_term=midtrans&utm_content=midtrans&gclid=Cj0KCQiA-aGCBhCwARIsAHDl5x-hYsTY6a0wprftwv1KeDdpDcIPhl2MvNAUkywmFZuA6ksIYs4dUYYaAjgvEALw_wcB'
        }, 3000)
    }

    return (
        <>

            <Drawer
                variant={isMobile ? "temporary" : "persistent"}
                anchor={anchor}
                // open={true}
                open={props.open}
            >

                <div
                    style={{
                        width: windowWidth > drawerWidth ? drawerWidth : windowWidth,
                        display: "flex",
                        flexDirection: "column",
                        overflowY: "hidden",
                        padding: 10
                    }}
                    trackYProps={{
                        renderer: props => {
                            const {elementRef, ...restProps} = props;
                            return <span {...restProps} style={{...restProps.style, backgroundColor: "white"}}
                                         ref={elementRef} className="trackY"/>;
                        }
                    }}
                    smoothScrolling={true}
                >

                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: 30
                    }}>
                        <span style={{
                            fontSize: 20,
                            flex: 1,
                            marginLeft: 15,
                            fontWeight: '700',
                            color: 'rgb(79, 79, 79)'
                        }}></span>

                        <IconButton
                            onClick={() => {
                                props.onClose();
                            }}
                            onMouseDown={e => e.preventDefault()}
                        >
                            <AiOutlineClose color={Palette.PRIMARY}/>
                        </IconButton>
                    </div>

                    <List style={{
                        overflow: "auto",
                        marginBottom: "8em"
                    }}>

                        <ListItem>
                            <div style={{fontSize: 15, color: 'rgb(79, 79, 79)', fontWeight: '300'}}>
                                Daftar Kelas
                            </div>
                        </ListItem>

                        <ListItem>
                            <div style={{paddingLeft: 30, flex: 1}}>
                                <div style={{fontSize: 20, color: 'rgb(79, 79, 79)'}}>
                                    <Link to={'/kelas#kelas-publik-mendatang'}
                                          onClick={props.onClose}
                                          style={{fontWeight: '600'}}>
                                        Kelas Publik
                                    </Link>
                                </div>
                            </div>
                        </ListItem>

                        <div style={{height: 1, backgroundColor: '#f0f0f0', marginTop: 10, marginBottom: 10}}/>

                        <ListItem>
                            <div style={{paddingLeft: 30, flex: 1}}>
                                <div style={{fontSize: 20, color: 'rgb(79, 79, 79)'}}>
                                    <Link to={'/kelas#kelas-daring-mandiri'}
                                          onClick={props.onClose}
                                          style={{fontWeight: '600'}}>
                                        Kelas Mandiri
                                    </Link>
                                </div>
                            </div>
                        </ListItem>

                        <div style={{height: 1, backgroundColor: '#f0f0f0', marginTop: 10, marginBottom: 10}}/>

                        <ListItem>
                            <div style={{paddingLeft: 30, flex: 1}}>
                                <div style={{fontSize: 20, color: 'rgb(79, 79, 79)'}}>
                                    <Link to={'/kelas#acara-lain'}
                                          onClick={props.onClose}
                                          style={{fontWeight: '600'}}>
                                        Acara Lain
                                    </Link>
                                </div>
                            </div>
                        </ListItem>

                        <div style={{height: 1, backgroundColor: '#f0f0f0', marginTop: 10, marginBottom: 10}}/>

                        {props.gsmsSessions.length > 0 ?
                            <>
                                <ListItem>
                                    <div style={{paddingLeft: 30, flex: 1}}>
                                        <div style={{fontSize: 20, color: 'rgb(79, 79, 79)'}}>
                                            <Link to={'/kelas#gsms'}
                                                  onClick={props.onClose}
                                                  style={{fontWeight: '600'}}>
                                                GSMS
                                            </Link>
                                        </div>
                                    </div>
                                </ListItem>

                                <div style={{height: 1, backgroundColor: '#f0f0f0', marginTop: 10, marginBottom: 10}}/>
                            </> : null
                        }

                        {
                            props.isLoggedIn &&
                            <>
                                <ListItem>
                                    <div style={{paddingLeft: 30, flex: 1}}>
                                        <div style={{fontSize: 20, color: 'rgb(79, 79, 79)'}}>
                                            <Link to={'/kelas#kelas-saya'}
                                                  onClick={props.onClose}
                                                  style={{fontWeight: '600'}}>
                                                Kelas Saya
                                            </Link>
                                        </div>
                                    </div>
                                </ListItem>
                                <div style={{height: 1, backgroundColor: '#f0f0f0', marginTop: 10, marginBottom: 10}}/>
                            </>
                        }

                        {/*{*/}
                        {/*    cartData.map((obj, key) => {*/}

                        {/*        let product = getProductData(obj.product_id)*/}

                        {/*        return (*/}
                        {/*            <ListItem>*/}
                        {/*                <ListItemIcon>*/}
                        {/*                    <img src={product?.image_url} style={{width: 125}}/>*/}
                        {/*                </ListItemIcon>*/}
                        {/*                <div>*/}
                        {/*                    <span style={{*/}
                        {/*                        // fontSize: 18,*/}
                        {/*                        color: '#575757',*/}
                        {/*                        fontFamily: 'OpenSans-Regular',*/}
                        {/*                        marginLeft: 10*/}
                        {/*                    }}>{product?.name}*/}
                        {/*                        <div style={{*/}
                        {/*                            color: '#ff85a1', fontFamily: 'OpenSans-Regular',*/}
                        {/*                            marginLeft: 10*/}
                        {/*                        }}><b>{obj.customization && obj.customization.zodiac}</b></div>*/}
                        {/*                    </span>*/}
                        {/*                    <div style={{*/}
                        {/*                        color: '#575757',*/}
                        {/*                        fontWeight: "bold",*/}
                        {/*                        fontFamily: 'OpenSans-Regular',*/}
                        {/*                        margin: 10,*/}
                        {/*                        marginTop: 20*/}
                        {/*                    }}>*/}
                        {/*                        <span>Kuantitas:</span>*/}

                        {/*                        <ButtonGroup*/}
                        {/*                            style={{*/}
                        {/*                                marginLeft: 20*/}
                        {/*                            }}*/}
                        {/*                            size="sm">*/}
                        {/*                            <Button*/}
                        {/*                                disabled={obj.quantity === 1}*/}
                        {/*                                onClick={() => {*/}
                        {/*                                    // setCartData(CartManager.decreaseItemQuantity(key))*/}
                        {/*                                }}*/}
                        {/*                                variant={"outline-primary"}>-</Button>*/}
                        {/*                            <Button variant={"outline-primary"} disabled>{obj.quantity}</Button>*/}
                        {/*                            <Button*/}
                        {/*                                onClick={() => {*/}
                        {/*                                    // setCartData(CartManager.addItemQuantity(key))*/}
                        {/*                                }}*/}
                        {/*                                variant={"outline-primary"}>+</Button>*/}
                        {/*                        </ButtonGroup>*/}

                        {/*                        <IconButton*/}
                        {/*                            onClick={() => {*/}
                        {/*                                // setCartData(CartManager.removeItem(key))*/}
                        {/*                            }}*/}
                        {/*                            onMouseDown={e => e.preventDefault()}*/}
                        {/*                            style={{color: Palette.PRIMARY}}*/}
                        {/*                        >*/}
                        {/*                            <SimpleLineIcon name="trash"/>*/}
                        {/*                        </IconButton>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}


                        {/*            </ListItem>*/}
                        {/*        );*/}
                        {/*    })*/}
                        {/*}*/}

                        {
                            props.isLoggedIn && <>
                                <ListItem>
                                    <div style={{fontSize: 15, color: 'rgb(79, 79, 79)', fontWeight: '300'}}>
                                        Transaksi
                                    </div>
                                </ListItem>

                                <ListItem>
                                    <div style={{paddingLeft: 30, flex: 1}}>
                                        <div style={{fontSize: 20, color: 'rgb(79, 79, 79)'}}>
                                            <Link to={'/transaksi-saya'}
                                                  onClick={props.onClose}
                                                  style={{fontWeight: '600'}}>
                                                Transaksi Saya
                                            </Link>
                                        </div>
                                    </div>
                                </ListItem>
                            </>
                        }

                        <div style={{height: 1, backgroundColor: '#f0f0f0', marginTop: 10, marginBottom: 10}}/>


                        <ListItem>
                            <div style={{fontSize: 15, color: 'rgb(79, 79, 79)', fontWeight: '300'}}>
                                Kreasi
                            </div>
                        </ListItem>

                        <ListItem>
                            <div style={{paddingLeft: 30, flex: 1}}>
                                <div style={{fontSize: 20, color: '#a52631'}}>
                                    <Link to={'/kreasi'}
                                          onClick={props.onClose}
                                          style={{fontWeight: '600'}}>
                                        Kreasi
                                    </Link>
                                </div>
                            </div>
                        </ListItem>

                        <div style={{height: 1, backgroundColor: '#f0f0f0', marginTop: 10, marginBottom: 10}}/>


                        <ListItem>
                            <div style={{fontSize: 15, color: 'rgb(79, 79, 79)', fontWeight: '300'}}>
                                Artikel
                            </div>
                        </ListItem>

                        <ListItem>
                            <div style={{paddingLeft: 30, flex: 1}}>
                                <div style={{fontSize: 20, color: '#a52631'}}>
                                    <Link to={'/artikel'}
                                          onClick={props.onClose}
                                          style={{fontWeight: '600'}}>
                                        Artikel
                                    </Link>
                                </div>
                            </div>
                        </ListItem>
                    </List>


                    <div style={{position: "fixed", bottom: 0, left: 0}}>
                        <div
                            style={{display: 'flex', marginBottom: 30, alignItems: 'center', justifyContent: 'center'}}>
                            <FaShoppingCart color={Palette.PRIMARY}/>

                            <Link to={'#'}
                                  onClick={(e) => {
                                      e.preventDefault()

                                      props.openCart()
                                  }}
                                  style={{fontWeight: '600', marginLeft: 15}}>
                                Keranjang Belanja
                            </Link>
                        </div>

                        <Button
                            disabled={isLoading}
                            style={{
                                background: Palette.PRIMARY,
                                // paddingInlineStart: "4em",
                                // paddingInlineEnd: "4em",
                                color: "white",
                                width: windowWidth > drawerWidth ? drawerWidth : windowWidth,
                                opacity: isLoading ? .3 : 1,
                                paddingTop: 18,
                                paddingBottom: 18
                            }}
                            onClick={() => {
                                if (!props.isLoggedIn) {
                                    props.loginPopup()
                                } else {
                                    props.accountPopup()
                                }
                            }}
                            size={"sm"}>

                            {isLoading ?
                                <Spinner
                                    style={{marginTop: 18, marginBottom: 18}}
                                    animation="border"/> :
                                <>
                                    <span style={{
                                        color: "white",
                                        fontSize: 18,
                                        fontWeight: "bold",
                                        fontFamily: 'OpenSans-Regular',
                                        paddingTop: 18, paddingBottom: 18
                                    }}>
                                        {props.isLoggedIn && user ? user.full_name : 'Masuk'}
                                        {/*    {textFormatter.moneyFormatter(CartManager.getCartContent().reduce((total, obj) => {*/}
                                        {/*    return total + obj.price * obj.quantity*/}
                                        {/*}, 0))}*/}

                                </span>
                                </>
                            }

                        </Button>

                    </div>

                </div>
            </Drawer>

        </>
    )
}
