import ApiRequest from "../util/ApiRequest";
import apiConfig from "../util/apiConfig";
import GlobalData from "../util/GlobalData";
export default class Article {
    getAll = async () => {
        let response = await fetch('https://narabahasa.id/articles.php', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then(function(response) {
            return response.json();
        }).then(function(data) {
            return data;
        });
    return response;
    }
}