import Dialog from "@material-ui/core/Dialog";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Rating from "react-rating";
import {IoMdStar} from "react-icons/io";
import FeedbackCard from "./FeedbackCard";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import PageWrapper from "../page/PageWrapper";
import React, {useState} from "react";
import Session from "../../models/Session";
import Strings from "../../util/Strings";
import moment from "moment";
import {FaExternalLinkAlt} from "react-icons/all";
import {useHistory} from "react-router-dom";

export default function RecordingUrlModal(props) {
    const {isOpen, onClose, recordings, session} = props

    const history = useHistory()

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    console.log(session)

    return <Dialog open={isOpen} fullWidth={true} onClose={() => onClose()}>
        <Container>
            <Row style={{marginTop: 25, marginBottom: 15}}>
                <Col md={12} style={{fontFamily: 'OpenSans-SemiBold', fontSize: 18, marginLeft: 5, color: '#302a28'}}>
                    Tonton Rekaman
                </Col>
            </Row>

            <Row>
                <Col style={{
                    width: '100%',
                    height: 1,
                    backgroundColor: '#e9eaec',
                    marginTop: 5,
                    marginBottom: 40
                }}/>
            </Row>

            <Row style={{
                borderRadius: 8,
                // padding: 25,
                paddingBottom: 15,
                maxWidth: '700px',
                marginLeft: 0,
                marginRight: 0
            }}>
                <Container>
                    <Row style={{
                        marginBottom: 30
                    }}>
                        {
                            recordings.map((obj, key) => {
                                return <Col md={12}>
                                    <a href={`#`}
                                       onClick={(e) => {
                                           e.preventDefault();
                                           if(moment(session.start_time).isBefore("2021-06-25")){
                                               window.location = obj.url
                                           }else{
                                               history.push("/rekaman", {url: obj.url})
                                           }
                                       }}
                                       style={{display : "flex", flexDirection : "row", alignItems:"center"}}>
                                        <div style={{flex : 1}}>
                                            {obj.title}
                                        </div>
                                        <div>
                                            <FaExternalLinkAlt/>
                                        </div>
                                    </a>
                                    <hr/>
                                </Col>
                            })
                        }
                    </Row>

                </Container>

            </Row>
        </Container>
    </Dialog>


}
