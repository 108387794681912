import ApiRequest from "../util/ApiRequest";
import GlobalData from "../util/GlobalData";

export default class User {

    login = async (user) => {

        let userData = await ApiRequest.set("/v1/member/login", "POST", {
            email: user.email,
            password: user.password
        });

        GlobalData.user = userData

        if (userData.token) {
            GlobalData.token = userData.token
        }

        return userData

    }

    register = async (user) => {
        let response = await ApiRequest.set("/v1/member/register", "POST", {
            email: user.email,
            full_name: user.full_name,
            password: user.password,
            gender: user.gender,
            date_of_birth: user.date_of_birth,
            phone_num: user.phone_num,
            subscribe_newsletter: user.subscribe_newsletter,
        });

        return response;
    }

    updateUser = async (user) => {
        delete user.token
        let response = await ApiRequest.set(`/v1/member`, "PUT", user)
        return response
    }

    tokenLogin = async () => {
        let userData = await ApiRequest.set(`/v1/member/login/token`, "POST");
        GlobalData.user = userData
        console.log(GlobalData.user)
        return userData
    }

    forgotPassword = async (email) => {
        return await ApiRequest.set('/v1/member/recovery/request', 'POST', {email})
    }

    changePassword = async (password, validationCode) => {
        console.log('/recover/confirm/' + validationCode)
        console.log({password})

        return await ApiRequest.set('/v1/member/recovery/reset', 'POST', {password, token: validationCode})
    }

    updateProfilePicture = async (image) => {

        let formData = new FormData();

        formData.append('upload', image, image.name);

        return await ApiRequest.setMultipart(`/v1/member/upload`, "POST", formData);
    }

}
