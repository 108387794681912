
import PageWrapper from "./PageWrapper";
import Container from "react-bootstrap/Container";
import React, {useEffect, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import Palette from "../../util/Palette";
import {isMobile} from "react-device-detect";
import Session from "../../models/Session";
import ComplaintForm from "../../models/ComplaintForm";
import Strings from "../../util/Strings";
import SessionCard from "../reusable/SessionCard";
import {useHistory, Link} from "react-router-dom";
import moment from "moment";
import Form from "react-bootstrap/Form";
import GlobalData from "../../util/GlobalData";
import MuiAlert from "@material-ui/lab/Alert/Alert";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Select from "react-select";
import Checkbox from "@material-ui/core/Checkbox";

export default function ContactUsForm(props) {
    const [upcomingSession, setUpcomingSession] = useState([])

    // console.log('GlobalData.user', GlobalData.user)

    const [isSessionInDatabase, setSessionInDatabase] = useState(true);
    const [classCode, setClassCode] = useState(null);
    const [className, setClassName] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [complaint, setComplaint] = useState('');
    const complaintFormModel = new ComplaintForm();

    const history = useHistory()

    useEffect(() => {
        fetchUpcomingSessions()
    }, [])

    const fetchUpcomingSessions = async () => {
        try {

            const result = await new Session().getUpcomingForDropdown()

            let formattedSession = result.map((obj, key) => {
                console.log(obj)

                const isSameDay = new moment(obj.start_time).format("DD MMMM YYYY") === new moment(obj.end_time).format("DD MMMM YYYY");

                return {
                    value: obj.code,
                    label: obj.name + ` (${new moment(obj.start_time).format("DD MMMM YYYY")}${!isSameDay ? ' -- ' + new moment(obj.end_time).format("DD MMMM YYYY") : ''})`,
                }
            })

            console.log(formattedSession)

            setUpcomingSession(formattedSession)

        } catch (e) {
            console.log(e)
        }

    }

    const reset = () => {
        setEmail("")
        setComplaint("")
        setName("")
        setClassCode(null)
        setClassName("")
    }

    const handleSubmit = async () => {
        if(!email) {
            showSnackBar('Harap isi alamat surel Anda', 'warning')
        } else if(!validateEmail(email)) {
            showSnackBar('Harap masukan alamat surel yang valid', 'warning')
        } else if(!name) {
            showSnackBar('Harap isi nama Anda', 'warning')
        } else if(!complaint) {
            showSnackBar('Harap isi aduan Anda', 'warning')
        } else {
            try {
                const result = await complaintFormModel.create({
                    name,
                    email,
                    complaint,
                    class_code: classCode,
                    class_name: className
                });

                if(result.id) {
                    showSnackBar('Aduan Anda Telah Kami Rekam', 'success')
                    reset()
                }
            } catch (e) {
                console.log(e)

                showSnackBar('Kesalahan Terjadi!', 'warning')
            }
        }
        console.log('user', GlobalData.user)
        // console.log('test')
    }

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const [snackBarProp, setSnackBarProp] = useState({});

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const showSnackBar = (message, severity) => {
        setSnackBarProp({
            open: true,
            message,
            severity
        })
    }

    return <PageWrapper
        loginListener={(user) => {
            setName(user.full_name)
            setEmail(user.email)
        }}
        headerShy={false}
        headerActive={'book-store'}>

        <Snackbar
            anchorOrigin={{vertical: "top", horizontal: "center"}}
            open={snackBarProp.open} autoHideDuration={6000}
            onClose={() => {
                setSnackBarProp({
                    open: false
                })
            }}>
            <Alert severity={snackBarProp.severity}>
                {snackBarProp.message}
            </Alert>
        </Snackbar>

        <Container style={{marginBottom: '60px'}} fluid>

            <Container>
                <Row style={{
                    marginTop: isMobile ? 0 : "4em",
                    fontFamily: 'OpenSans-Bold'
                }}>

                </Row>

                <Row style={{
                    marginTop: "2em",
                }}>
                    <Container fluid style={{
                        maxWidth : 900,
                    }}>
                        <Row>

                            <Col
                                style={{
                                    color: Palette.PRIMARY,
                                    fontSize: "2em",
                                    fontFamily: 'OpenSans-Bold',
                                    textAlign : "center"
                                }}
                                md={12}>
                                Formulir Bantuan Sinara
                                <br/>
                            </Col>
                            <Col md={12}>
                                <Form>
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <Form.Label>Nama</Form.Label>
                                        <Form.Control
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            style={{fontSize: "1em"}} type="text" placeholder=""/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>{Strings.login_pop_up.email}</Form.Label>
                                        <Form.Control
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            style={{fontSize: "1em"}} type="email" placeholder=""/>
                                    </Form.Group>

                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <Form.Label>Aduan</Form.Label>
                                        <Form.Control
                                            value={complaint}
                                            onChange={(e) => setComplaint(e.target.value)}
                                            rows={7}
                                            as={'textarea'}
                                            style={{fontSize: "1em"}} type="text" placeholder=""/>
                                    </Form.Group>

                                </Form>
                            </Col>
                            <Col md={12} style={{
                                display : "flex",
                                justifyContent : "center"
                            }}>
                                <Button
                                    style={{
                                        paddingLeft : 36,
                                        paddingRight : 36
                                    }}
                                    onClick={handleSubmit}>
                                    Kirim
                                </Button>
                            </Col>

                        </Row>
                    </Container>
                </Row>

            </Container>

        </Container>

    </PageWrapper>

}
