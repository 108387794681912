import React, {useEffect, useState} from "react";
import PageWrapper from "./PageWrapper";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Palette from "../../util/Palette";
import {Link} from "react-router-dom";
import {FiChevronRight} from "react-icons/fi";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Dialog from "@material-ui/core/Dialog/Dialog";
import {IconButton} from "@material-ui/core";
import {AiOutlineClose} from "react-icons/ai";
import Order from "../../models/Order";
import moment from "moment";
import textFormatter from "../../util/textFormatter";
import GlobalData from "../../util/GlobalData";
import {useCookies} from "react-cookie";
import Strings from "../../util/Strings";
import 'moment/locale/id'


export default function Transaction(props) {
  const [trxStatus, setTrxStatus] = useState('WAITING');
  const [isDetailModalVisible, setDetailModalVisible] = useState(false);
  const orderModel = new Order();
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [loginShow, setLoginShow] = useState(false);
  const [loginMessage, setLoginMessage] = useState("");

  const [cookie, setCookie, removeCookie] = useCookies()
  const [isLoggedIn, setIsLoggedIn] = useState(GlobalData.token || cookie.token)

  const [noOfTrx, setNoOfTrx] = useState(0);
  const editable_strings_common = GlobalData.strings.transaction_page;
  const editable_strings_card = GlobalData.strings.transaction_card;
  const editable_strings_detail = GlobalData.strings.transaction_detail;
  useEffect(() => {
    if (!isLoggedIn) {
      setLoginMessage('Harap masuk sebelum melihat transaksi Anda')
      return setLoginShow(true)
    } else {
      getMyOrder();
    }
  }, [])

  const getMyOrder = async () => {
    try {
      const result = await orderModel.getMyOrder()

      console.log("r", result)

      if (result.orders) {
        result.orders = result.orders.map((obj) => {

          if (obj.latest_midtrans_notification) {
            try {
              let latestMidtransNotification = JSON.parse(obj.latest_midtrans_notification)
              return {
                ...obj,
                latestMidtransNotification: latestMidtransNotification
              }
            } catch (e) {

            }
          }

          return {
            ...obj
          }

        })
      }

      if (props.match?.params?.order_id) {
        let found = false;

        result.orders.forEach((order) => {
          if (order.order_id === props.match?.params?.order_id) {
            found = true
            setSelectedOrder(order)
            setDetailModalVisible(true)
          }
        })

        if (!found) {
          alert('Transaksi tidak ditemukan!')
        }
      }

      setOrders(result.orders.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.created_at) - new Date(a.created_at);
      }));
    } catch (e) {
      console.log(e)
    }
  }

  const thousandSeparator = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  const PaymentStates = {
    WAITING_NOTIFICATION: "WAIT_SNAP_NOTIFICATION",
    PENDING: "pending",
    CHALLENGE: "challenge",
    DENY: "deny",
    CANCEL: "cancel",
    CAPTURE: "capture",
    SETTLEMENT: "settlement",
    EXPIRE: "expire"
  }

  const changePaymentMethod = async (orderId) => {
    try {
      const result = await orderModel.changePaymentMethod(orderId);

      window.open(result?.snap_payment_url, '_self')
    } catch (e) {
      console.log(e)
    }
  }

  const paymentMethodMapper = (payment_type) => {

    const capitalize = (parameter) => {
      return parameter.charAt(0).toUpperCase() + parameter.slice(1);
    }

    const removeUnderScoreAndCapitalize = (payment_type) => {
      let splited = payment_type.split("_")
      let total = ""
      for (let s of splited) {
        total += (capitalize(s) + " ")
      }
      return total
    }

    if (!payment_type) {
      return ""
    }

    switch (payment_type) {
      case "credit_card" :
        return "Kartu Kredit"
      case "bank_transfer" :
        return "Transfer"
      case "gopay" :
        return "Gopay"
      case "shopeepay" :
        return "Shopee Pay"
    }

    return removeUnderScoreAndCapitalize(payment_type)
  }

  useEffect(() => {
    let item = 0;

    orders.forEach(order => {
      if (trxStatus === 'WAITING' && (order.paid_status === PaymentStates.CAPTURE || order.paid_status === PaymentStates.SETTLEMENT || order.paid_status === PaymentStates.EXPIRE || order.paid_status === PaymentStates.CANCEL)) return null
      if (trxStatus === 'PAID' && order.paid_status !== PaymentStates.CAPTURE && order.paid_status !== PaymentStates.SETTLEMENT) return null

      item++;
    })

    setNoOfTrx(item)
  }, [trxStatus, orders])

  return (
    <PageWrapper
      loginShow={loginShow}
      message={loginMessage}
    >
      {selectedOrder.details &&
        <Dialog
          open={isDetailModalVisible}
          fullWidth>
          <Container>
            <Row style={{marginTop: 25, marginBottom: 15}}>
              <Col style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <div style={{
                  fontFamily: 'OpenSans-SemiBold',
                  fontSize: 20,
                  marginLeft: 15,
                  color: '#302a28',
                  flex: 1
                }}>
                  {editable_strings_detail.transaction_detail_title}
                </div>

                <div>
                  <IconButton
                    // disabled={CartManager.getCartContent().length === 0}
                    style={{
                      color: Palette.DARK_GREY
                      // color: CartManager.getCartContent().length === 0 ? "lightGrey" : Palette.PRIMARY,
                    }}
                    onClick={() => {
                      setDetailModalVisible(false)
                    }}
                    onMouseDown={e => e.preventDefault()}
                  >
                    <AiOutlineClose/>
                  </IconButton>
                </div>
              </Col>
            </Row>

            <Row>
              <Col style={{
                width: '100%',
                height: 1,
                backgroundColor: '#e9eaec',
                marginTop: 5,
                marginBottom: 20
              }}/>
            </Row>

            <Row>
              <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                marginBottom: 20
              }}>
                <img
                  style={{
                    width: "100%",
                    height: 40,
                    objectFit: "contain"
                  }}
                  src={require("../../asset/new-narabahasa-logo.png")}/>
              </div>
            </Row>

            <Row>
              <Col md={12}>
                <div style={{
                  fontFamily: 'OpenSans-SemiBold',
                  fontSize: 15,
                  marginLeft: 15,
                  marginBottom: 30,
                  color: '#302a28',
                  flex: 1
                }}>
                  Data Peserta :
                  <div style={{
                    fontFamily: 'OpenSans-Regular',
                  }}>
                    {GlobalData.user?.full_name}<br/>
                    {GlobalData.user?.email}<br/><br/>
                  </div>
                  {editable_strings_card.transaction_id}: {selectedOrder?.order_id}
                  {
                    selectedOrder?.paid_at && moment(selectedOrder?.paid_at).isAfter("2020-01-01") ?
                      <>
                        <br/>
                        Status Transaksi : Dibayar
                        pada {moment(selectedOrder?.paid_at).format("DD MMM YYYY HH:mm")}
                        {
                          selectedOrder?.latestMidtransNotification && selectedOrder?.latestMidtransNotification?.payment_type &&
                          <>
                            <br/>
                            Metode Pembayaran
                            : {paymentMethodMapper(selectedOrder?.latestMidtransNotification?.payment_type)}
                          </>
                        }
                      </>
                      :
                      <>
                        <br/>
                        Status Transaksi : Menunggu Pembayaran
                      </>
                  }
                </div>
              </Col>
            </Row>

            <Row>
              {
                selectedOrder.details.map((item, idx) => {
                  return (
                    <>
                      <Col xs={12} style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                        <Link
                          to={item.class_code ? `/kelas/detail/${item.class_code}` : item.course?.course_code ? `/kdm/detail/${item.course?.course_code}` : `/kdm/${item.course?.id}`}>
                          <img
                            src={item.class_code ? item.class?.class_image_url : item.course?.course_image_url}
                            onClick={(e) => e.stopPropagation()}
                            style={{
                              objectFit: 'cover',
                              width: 70,
                              height: 70,
                              marginLeft: 20,
                              borderRadius: 10
                            }}/>
                        </Link>

                        <div style={{flex: 1}}>

                          <Row>
                            <Col xs={12} md={6} style={{
                              paddingLeft: 40,
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column'
                            }}>
                              <Link
                                to={item.class_code ? `/kelas/detail/${item.class_code}` : item.course?.course_code ? `/kdm/detail/${item.course?.course_code}` : `/kdm/${item.course?.id}`}>
                                <div
                                  style={{fontFamily: 'OpenSans-SemiBold', fontSize: 15}}>
                                  {item.class_code ? item.class?.name : item.course?.title}
                                  {/*{session.name}*/}

                                </div>
                                <div style={{
                                  fontSize: 14,
                                  color: 'grey'
                                }}>
                                  {item.class_code ? item.class?.topic?.name : JSON.parse(item.course?.category)?.join(', ')}
                                </div>
                              </Link>
                            </Col>

                            <Col style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-end',
                              fontFamily: 'OpenSans-SemiBold',
                              fontSize: 15,
                              paddingRight: 40,
                            }}>
                              Rp{thousandSeparator(item.price)}
                              {/*Rp{session.price ? thousandSeparator(session.price) : '0'}*/}
                            </Col>
                          </Row>
                        </div>

                      </Col>

                      {idx !== selectedOrder.details.length - 1 &&
                        <Col xs={12} style={{marginTop: 10, marginBottom: 10}}/>}
                    </>
                  )
                })
              }
            </Row>

            <Row>
              <Col style={{
                width: '100%',
                height: 1,
                backgroundColor: '#e9eaec',
                marginTop: 25,
                marginBottom: 25,
              }}/>
            </Row>

            <Row style={{marginLeft: 7}}>
              <Col style={{fontFamily: 'OpenSans-Regular', fontSize: 15}}>
                {editable_strings_detail.subtotal}
              </Col>

              <Col style={{
                fontFamily: 'OpenSans-SemiBold',
                fontSize: 15,
                paddingRight: 37,
                textAlign: 'right'
              }}>
                Rp{textFormatter.moneyFormatter(selectedOrder.details.reduce((total, obj) => {
                return total + obj.price
              }, 0))}
                {/*Rp{session.price ? thousandSeparator(session.price) : '0'}*/}
              </Col>
            </Row>

            <Row style={{marginLeft: 7, marginTop: 10}}>
              <Col style={{fontFamily: 'OpenSans-Regular', fontSize: 15}}>
                {editable_strings_detail.discount}
              </Col>

              <Col style={{
                fontFamily: 'OpenSans-SemiBold',
                fontSize: 15,
                paddingRight: 37,
                textAlign: 'right',
                color: Palette.PRIMARY
              }}>
                - Rp{
                selectedOrder.voucher && selectedOrder.voucher?.percentage ?
                  textFormatter.moneyFormatter(selectedOrder.amount * selectedOrder.voucher?.percentage / (100 - selectedOrder.voucher?.percentage)) :
                  selectedOrder.voucher && selectedOrder.voucher?.amount ? textFormatter.moneyFormatter(selectedOrder.voucher?.amount) : '0'
              }
                {/*- {discountType === DISCOUNT_TYPE.PERCENT ? `Rp${thousandSeparator(session.price * discountPercentage / 100)}` : discountType === DISCOUNT_TYPE.AMOUNT ? `Rp${thousandSeparator(discountAmount)}` : 'Rp0'}*/}
              </Col>
            </Row>

            <Row>
              <Col style={{
                width: '100%',
                height: 1,
                backgroundColor: '#e9eaec',
                marginTop: 15,
                marginBottom: 10,
              }}/>
            </Row>

            <Row style={{marginLeft: 7, marginTop: 10, marginBottom: 40}}>
              <Col style={{fontFamily: 'OpenSans-SemiBold', fontSize: 16}}>
                {editable_strings_detail.total}
              </Col>

              <Col style={{
                fontFamily: 'OpenSans-SemiBold',
                fontSize: 19,
                paddingRight: 37,
                textAlign: 'right',

              }}>
                Rp{textFormatter.moneyFormatter(selectedOrder.amount)}
                {/*Rp{session.price && discountType === DISCOUNT_TYPE.PERCENT ?*/}
                {/*thousandSeparator(session.price - session.price * discountPercentage / 100) :*/}
                {/*session.price && discountType === DISCOUNT_TYPE.AMOUNT && session.price - discountAmount > 0 ?*/}
                {/*    thousandSeparator(session.price - discountAmount) : discountType || !session.price ? '0' : thousandSeparator(session.price)}*/}
              </Col>
            </Row>
          </Container>

        </Dialog>
      }

      <Container style={{marginTop: 100}}>
        <Row>
          <Col style={{display: 'flex', flexDirection: 'row', marginBottom: 20}}>
            <div
              onClick={() => setTrxStatus('WAITING')}
              style={{
                userSelect: 'none',
                cursor: 'pointer',
                backgroundColor: trxStatus === 'WAITING' ? Palette.PRIMARY : 'white',
                color: trxStatus === 'WAITING' ? 'white' : Palette.PRIMARY,
                border: `1px solid ${Palette.PRIMARY}`,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 3,
                paddingBottom: 3,
                borderRadius: 5,
                fontFamily: 'OpenSans-SemiBold',
                marginRight: 25
              }}>
              {editable_strings_common.waiting_payment}
            </div>

            <div
              onClick={() => setTrxStatus('PAID')}
              style={{
                userSelect: 'none',
                cursor: 'pointer',
                backgroundColor: trxStatus === 'PAID' ? Palette.PRIMARY : 'white',
                color: trxStatus === 'PAID' ? 'white' : Palette.PRIMARY,
                border: `1px solid ${Palette.PRIMARY}`,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 3,
                paddingBottom: 3,
                borderRadius: 5,
                fontFamily: 'OpenSans-SemiBold',
                marginRight: 15
              }}>
              {editable_strings_common.paid}
            </div>
          </Col>

          {
            noOfTrx === 0 &&
            <Col xs={12}
                 style={{
                   fontFamily: 'OpenSans-Bold',
                   fontSize: 20,
                   textAlign: 'center',
                   marginTop: 50,
                   color: 'grey'
                 }}>
              {editable_strings_common.transaction_not_found}
            </Col>
          }

          {
            orders.map(order => {
              if (trxStatus === 'WAITING' && (order.paid_status === PaymentStates.CAPTURE || order.paid_status === PaymentStates.SETTLEMENT || order.paid_status === PaymentStates.EXPIRE || order.paid_status === PaymentStates.CANCEL)) return null
              if (trxStatus === 'PAID' && order.paid_status !== PaymentStates.CAPTURE && order.paid_status !== PaymentStates.SETTLEMENT) return null

              return (
                <Col xs={12} style={{
                  boxShadow: 'rgba(0, 0, 0, 0.09) 0px 2px 8px',
                  marginTop: 30,
                  borderRadius: 5,
                  padding: 15
                }}>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div style={{fontFamily: 'OpenSans-Regular', color: 'grey', flex: 1}}>
                      {editable_strings_card.transaction_id}: {order.order_id}
                    </div>

                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <Link
                        style={{fontWeight: '700', fontFamily: 'OpenSans-Regular'}}
                        onClick={(e) => {
                          e.preventDefault()

                          setSelectedOrder(order)

                          console.log("o", order)

                          setDetailModalVisible(true)
                        }}
                        to={'#'}>
                        {editable_strings_card.view_detail_transaction}
                      </Link>

                      <FiChevronRight/>
                    </div>
                  </div>

                  <div style={{fontFamily: 'OpenSans-Regular', color: 'grey'}}>
                    {editable_strings_card.transaction_date}: {moment(order.created_at).format('DD-MMM-YYYY, HH:mm')}
                  </div>

                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div style={{
                      fontFamily: 'OpenSans-Regular',
                      color: 'grey',
                      marginRight: 10
                    }}> {editable_strings_card.transaction_total}:
                    </div>

                    <div style={{
                      fontFamily: 'OpenSans-SemiBold',
                      color: Palette.PRIMARY,
                      fontSize: 20,
                      flex: 1
                    }}>

                      Rp{thousandSeparator(order.amount)}
                    </div>

                    {trxStatus === 'WAITING' &&
                      <>
                        <Button
                          variant={'info'}
                          onClick={() => changePaymentMethod(order.id)}
                          style={{
                            fontFamily: 'OpenSans-SemiBold',
                            marginRight: 10
                          }}>
                          Ganti Metode Pembayaran
                        </Button>

                        <Button
                          onClick={() => {
                            if (order.paid_status === PaymentStates.EXPIRE) changePaymentMethod(order.id)
                            else window.open(order.snap_payment_url, '_self')
                          }}
                          style={{
                            fontFamily: 'OpenSans-SemiBold'
                          }}>
                          Bayar
                        </Button>
                      </>
                    }
                  </div>

                  {/*<div style={{fontF}}/>*/}
                </Col>
              )
            })
          }
        </Row>
      </Container>
    </PageWrapper>
  )
}
