import PageWrapper from "./PageWrapper";
import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Quiz from "../../models/Quiz";
import Form from "react-bootstrap/Form";
import Checkbox from "@material-ui/core/Checkbox";
import {withStyles} from '@material-ui/core/styles';
import Palette from "../../util/Palette";
import Radio from "@material-ui/core/Radio";
import Select from "react-select";
import Cities from "../../asset/constants/Cities";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import QuizTextInput from "../reusable/QuizTextInput";
import QuizRadioButton from "../reusable/QuizRadioButton";
import QuizCheckBox from "../reusable/QuizCheckBox";
import QuizCityPicker from "../reusable/QuizCityPicker";
import Button from "react-bootstrap/Button";
import GlobalData from "../../util/GlobalData";
import {useCookies} from "react-cookie";
import {useHistory} from "react-router-dom";
import Session from "../../models/Session";
import moment from "moment";

var momentTz = require('moment-timezone');

function shuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

export default function QuizPage(props) {
    const [isBtnFocused, setBtnFocused] = useState(false);
    const [quizzes, setQuizzes] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [quizId, setQuizId] = useState(-1);
    const [cookie, setCookie, removeCookie] = useCookies();

    const [isAnswered, setAnswered] = useState(false);

    const [session, setSession] = useState({})

    const [score, setScore] = useState(0)

    const history = useHistory()

    const [username, setUsername] = useState(null)
    const [user, setUser] = useState({...GlobalData.user})

    const [multipleChoicesTotalQuestions, setMultipleChoicesTotalQuestions] = useState(0);
    // console.log("USER GLOB DT", GlobalData.user)

    useEffect(() => {
        getQuizData()
    }, [])

    const getSessionByCode = async (classCode, quizAnswer) => {


        try {
            let sessionModel = new Session()
            const sessionObj = await sessionModel.getByCode(classCode ? classCode : props.match.params.code);

            console.log(
                moment(sessionObj.question_end_time).tz('Asia/Jakarta').format('DD MMMM YYYY, HH:mm')
            )

            setSession(sessionObj);

            setTimeout(function () {
                if (!quizAnswer && !classCode && moment().isAfter(sessionObj.question_end_time)) {
                    history.push(`/kelas/detail/${props.match.params.code}`)
                    alert('Waktu tes akhir sudah berakhir. Silakan hubungi admin untuk informasi lebih lengkap.')
                }
            }, 100);




        } catch (e) {
            console.log(e)
        }
    }

    const getQuizData = () => {
        if (props.match.params.code) {
            Quiz.getByClassCode(props.match.params.code)
                .then(res => {

                    let loggedIn = GlobalData.token || cookie.token
                    if (!loggedIn) {
                        alert('Harap login untuk mengakses halaman ini')
                        window.open(`/kelas/detail/${props.match.params.code}`, "_self")
                    } else {
                        getSessionByCode(null, res.quiz_answer)



                        if (res.quiz_answer) {
                            setAnswers(res.quiz_answer.answer_json)
                            setAnswered(true)

                            let questions = res.question_json
                            let answers = res?.quiz_answer.answer_json

                            let score = 0;
                            let multipleChoicesTotalQuestions = 0;

                            for (let i in answers) {

                                let answer = answers[i]
                                let question = questions[i]

                                if(question.type === 'option') {
                                    multipleChoicesTotalQuestions++;
                                }

                                if (answer === question.correctAnswer) {
                                    // console.log("CORRECTO")
                                    score++;
                                }

                            }

                            setMultipleChoicesTotalQuestions(multipleChoicesTotalQuestions);
                            setScore(score)
                            setQuizzes(res.question_json)
                        } else {
                            let formattedQuiz = []

                            for (let qj of res.question_json) {

                                console.log(qj)
                                formattedQuiz.push({
                                    ...qj,
                                    options: qj.options ? shuffle(qj.options) : null
                                })

                            }
                            setQuizzes(formattedQuiz)

                        }


                        setQuizId(res.id);

                    }


                })
                .catch(err => {
                    if (err.code) {
                        console.log(err.code)

                        if (err.code === 'NO_TOKEN_PROVIDED') {
                            alert('Harap login untuk mengakses halaman ini')
                        } else if (err.code === 'PARTICIPANT_ALREADY_ANSWER') {
                            alert('Anda sudah pernah menjawab kuis ini')
                        } else if (err.code === 'MEMBER_NOT_PARTICIPATED') {
                            alert('Anda belum terdaftar pada kelas ini')
                        }
                        window.open(`/kelas/detail/${props.match.params.code}`, "_self")
                    }
                })
        } else {
            Quiz.getByParticipationToken(props.match.params.token)
                .then(res => {

                    console.log(res)
                    getSessionByCode(res.class_code, res.quiz_answer)

                    let username = res.member?.full_name
                    if (username) {
                        setUsername(username)
                    }

                    if (res.quiz_answer) {
                        setAnswers(res.quiz_answer.answer_json)
                        setAnswered(true)

                        let questions = res.question_json
                        let answers = res?.quiz_answer.answer_json

                        let score = 0;
                        console.log(answers)

                        for (let i in answers) {

                            let answer = answers[i]
                            let question = questions[i]

                            console.log(question.correctAnswer, answer)

                            if (answer === question.correctAnswer) {
                                console.log("CORRECTO")
                                score++;
                            }

                        }
                        setScore(score)
                        setQuizzes(res.question_json)


                    } else {
                        let formattedQuiz = []

                        for (let qj of res.question_json) {

                            console.log(qj)
                            formattedQuiz.push({
                                ...qj,
                                options: qj.options ? shuffle(qj.options) : null
                            })

                        }
                        setQuizzes(formattedQuiz)
                    }

                })
                .catch(err => {
                    if (err.code) {
                        console.log(err.code)

                        if (err.code === 'NO_TOKEN_PROVIDED') {
                            alert('Harap login untuk mengakses halaman ini')
                        } else if (err.code === 'PARTICIPANT_ALREADY_ANSWER') {
                            alert('Anda sudah pernah menjawab kuis ini')
                        } else if (err.code === 'MEMBER_NOT_PARTICIPATED') {
                            alert('Anda belum terdaftar pada kelas ini')
                        } else {
                            alert("Terjadi Kesalahan")
                        }
                        // window.open(`/kelas/detail/${props.match.params.code}`, "_self")
                    }
                })
        }

    }

    const handleSubmit = () => {
        let isAllAnswered = true;

        answers.map(answer => {
            if (!answer) {
                isAllAnswered = false;
            }
        })

        console.log(answers)

        if (answers.length !== quizzes.length || !isAllAnswered) {
            alert('Mohon menjawab semua pertanyaan yang tersedia');
        } else {

            if (props.match.params.code) {
                Quiz.answer(quizId, answers).then(res => {
                    alert("Terima kasih, jawaban tes Anda sudah diterima")
                    window.location.reload();
                }).catch(err => {
                    if (err.code === 'PARTICIPANT_ALREADY_ANSWER') {
                        alert('Anda sudah pernah menjawab test akhir ini')
                        window.location.reload()
                    }
                    console.log(err)
                })
            } else {
                Quiz.answerWithToken(props.match.params.token, answers).then(res => {
                    alert("Terima kasih, jawaban tes Anda sudah diterima")
                    window.location.reload();
                }).catch(err => {
                    if (err.code === 'PARTICIPANT_ALREADY_ANSWER') {
                        alert('Anda sudah pernah menjawab test akhir ini')
                        window.location.reload()
                    }
                    console.log(err)
                })
            }

        }

        console.log(isAllAnswered)
    }

    return (
        <PageWrapper
            headerShy={false}
            history={props.history}
            form
            // hideFooter
            loginListener={(user) => {
                setUser(user)
                getQuizData()
            }}
        >
            <Container style={{
                marginTop: 150,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: "center",
                maxWidth: 900,
            }}>
                <Row style={{
                    width: "100%"
                }}>
                    <Col md={12} style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start"
                    }}>
                        {
                            props.match.params.code ?
                                <div>
                                    <a href={"/kelas/detail/" + props.match.params.code}>{"<"} Kembali ke kelas</a>
                                </div>
                                :
                                null
                        }

                        <b style={{
                            fontSize: "1.3em",
                            marginTop: 10
                        }}>Tes Akhir
                        </b>

                        <div style={{
                            marginTop: 10
                        }}>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{width: 100}}>
                                    Nama Peserta
                                </div>
                                <div>
                                    : {username ? username : user?.full_name}
                                </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{width: 100}}>
                                    Kelas
                                </div>
                                <div>
                                    : {session.name}
                                </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{width: 100}}>
                                    Tanggal Sesi
                                </div>
                                <div>
                                    : {new moment(session.start_time).format("DD MMMM YYYY")}
                                </div>
                            </div>

                            {props.match.params.code ?
                                <div style={{display: "flex", flexDirection: "row"}}>
                                    <div>
                                        Harap dikumpulkan
                                        sebelum {momentTz(session.question_end_time).tz('Asia/Jakarta').format('DD MMMM YYYY, HH:mm')} WIB
                                    </div>
                                </div> : null
                            }
                        </div>
                    </Col>
                </Row>
                {
                    quizzes.map(
                        (quiz, idx) => {
                            switch (quiz.type) {
                                case "isian":
                                    return <QuizTextInput
                                        idx={idx}
                                        question={quiz.fieldName}
                                        description={quiz.description}
                                        value={answers[idx]}
                                        disabled={isAnswered}
                                        onChange={(value) => {
                                            const answersTemp = [...answers];

                                            answersTemp[idx] = value;

                                            setAnswers(answersTemp)
                                        }}
                                    />

                                case "option":
                                    return <QuizRadioButton
                                        idx={idx}
                                        question={quiz.fieldName}
                                        description={quiz.description}
                                        options={quiz.options}
                                        correctAnswer={quiz.correctAnswer}
                                        value={answers[idx]}
                                        disabled={isAnswered}
                                        onChange={(value) => {
                                            const answersTemp = [...answers];

                                            answersTemp[idx] = value;

                                            setAnswers(answersTemp)
                                        }}/>
                            }
                        }
                    )
                }
                {/*<QuizTextInput/>*/}

                {/*<QuizCheckBox/>*/}

                {/*<QuizRadioButton/>*/}

                {/*<QuizCityPicker/>*/}

                {quizzes.length !== 0 && !isAnswered ?
                    <Row style={{
                        width: "100%",
                        maxWidth: 900, paddingLeft: 20, display: "flex", justifyContent: "flex-end"
                    }}>
                        <Button
                            onClick={handleSubmit}
                            onMouseEnter={() => setBtnFocused(true)}
                            onMouseLeave={() => setBtnFocused(false)}
                            style={{
                                fontFamily: 'OpenSans-SemiBold',
                                backgroundColor: '#3882e0',
                                borderWidth: 0,
                                paddingLeft: 35,
                                paddingRight: 35,
                                paddingTop: 10,
                                paddingBottom: 10,
                                marginTop: 20,
                                fontSize: '18px',
                                opacity: isBtnFocused ? .85 : 1
                            }}>
                            Kirim
                        </Button>
                    </Row> : <Row style={{
                        width: "100%",
                        maxWidth: 900, paddingLeft: 20, display: "flex", justifyContent: "flex-end"
                    }}>
                        <Button
                            disabled={true}
                            variant={"success"}
                            style={{
                                fontFamily: 'OpenSans-SemiBold',
                                borderWidth: 0,
                                paddingLeft: 35,
                                paddingRight: 35,
                                paddingTop: 10,
                                paddingBottom: 10,
                                marginTop: 20,
                                marginBottom : 50,
                                fontSize: '18px',
                            }}>
                            Nilai Anda : {score}/{multipleChoicesTotalQuestions}
                        </Button>
                    </Row>
                }
                <div style={{height : 60}}>

                </div>
            </Container>
        </PageWrapper>
    )
}
