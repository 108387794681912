import PageWrapper from "./PageWrapper";
import Container from "react-bootstrap/Container";
import React, {useEffect, useRef, useState} from "react";
import {Col, Row} from "react-bootstrap";
import Palette from "../../util/Palette";
import {isMobile} from "react-device-detect";
import Session from "../../models/Session";
import Course from "../../models/Course";
import Strings from "../../util/Strings";
import SessionCard from "../reusable/SessionCard";
import {useHistory, Link} from "react-router-dom";
import moment from "moment";
import IndependentClassCard from "../reusable/IndependentClassCard";
import ArticleModel from "../../models/Article";
import Card from "react-bootstrap/Card";
import Merchandise from "../../models/Merchandise";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import textFormatter from "../../util/textFormatter";
import "../../asset/styles/carousel.css"
import Dialog from "@material-ui/core/Dialog";
import Button from "react-bootstrap/Button";

export default function NewIndex(props) {

    const [upcomingSession, setUpcomingSession] = useState([])
    const [upcomingSessionRows, setUpcomingSessionRows] = useState([]);
    const [courses, setCourses] = useState([]);
    const [coursesRows, setCoursesRows] = useState([]);
    let [articleData, setArticleData] = useState([]);
    const [merchandise, setMerchandise] = useState([]);
    const [merchandiseRows, setMerchandiseRows] = useState([]);

    let [otherSessions, setOtherSessions] = useState([])
    let [otherSessionsRows, setOtherSessionsRows] = useState([])

    let [gsmsSessions, setGsmsSessions] = useState([])
    let [gsmsSessionsRows, setGsmsSessionsRows] = useState([])

    const [isBookModalShown, setBookModalShown] = useState(false);
    const productImgRef = useRef(null);
    const [productImageTransformOrigin, setImageTransformOrigin] = useState(null);
    const [isImageLoaded, setImageLoaded] = useState(false);
    const [selectedBook, setSelectedBook] = useState({additional_information: {}});

    const [productImages, setProductImages] = useState([]);
    const [productImgHoveredIdx, setProductImgHoveredIdx] = useState(-1);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [selectedProductImgIdx, setSelectedProductImgIdx] = useState(0);

    const history = useHistory()

    const fetchArticle = async function () {
        try {
            let articleModel = await new ArticleModel().getAll();
            setArticleData(articleModel);
            console.log(articleModel);
        } catch (e) {
            console.log(e)
        }
    }

    async function getMerchandise() {
        try {
            let result = await new Merchandise().getAll();
            console.log("Merchandise:", result)


            setMerchandise(result);

            const merchandiseRowsTemp = [];

            for (let a = 0; a < Math.ceil(result.length / 3); a++) {
                merchandiseRowsTemp.push(null)
            }

            setMerchandiseRows(merchandiseRowsTemp)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect((obj, key) => {
        fetchUpcomingSessions()
        fetchCourses()
        fetchArticle()
        getMerchandise()
    }, [])

    const fetchCourses = async () => {
        try {

            const result = await new Course().getAll()

            setCourses(result)

            const coursesRowsTemp = [];

            for (let a = 0; a < Math.ceil(result.length / 3); a++) {
                coursesRowsTemp.push(null)
            }

            setCoursesRows(coursesRowsTemp)
        } catch (e) {
            console.log(e)
        }
    }

    const fetchUpcomingSessions = async () => {
        try {

            const result = await new Session().getAll()
            const sessionTemp = result.filter((obj, key) => new Date(obj.start_time) > new Date())

            console.log(sessionTemp)
            let normalSessions = []
            let otherSessions = []
            let gsmsSessions = []

            for (let s of sessionTemp) {
                if (s.topic_code === "4ZLBL2UED0") {
                    otherSessions.push(s)
                } else if (s.topic_code === "4KCSLSAUV5") {
                    gsmsSessions.push(s)
                } else {
                    normalSessions.push(s)
                }
            }

            setUpcomingSession(normalSessions)
            setOtherSessions(otherSessions)
            setGsmsSessions(gsmsSessions)


            const upcomingSessionRowsTemp = [];

            for (let a = 0; a < Math.ceil(normalSessions.length / 3); a++) {
                upcomingSessionRowsTemp.push(null)
            }

            const otherSessionRowsTemp = []

            for (let a = 0; a < Math.ceil(otherSessions.length / 3); a++) {
                otherSessionRowsTemp.push(null)
            }

            const gsmsSessionRowsTemp = []

            for (let a = 0; a < Math.ceil(gsmsSessions.length / 3); a++) {
                gsmsSessionRowsTemp.push(null)
            }

            setUpcomingSessionRows(upcomingSessionRowsTemp)
            setOtherSessionsRows(otherSessionRowsTemp)
            setGsmsSessionsRows(gsmsSessionRowsTemp)
        } catch (e) {
            console.log(e)
        }
    }

    function strip(html) {
        let doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }

    const renderArticle = () => {

        let element = articleData.map((item, key) => {
                if (key > 2) return null

                return (
                    <Col md={12}>
                        <Card style={{marginBottom: 10, cursor: 'pointer'}} onClick={event => {
                            window.location.href = `${item.guid}`
                        }}>
                            <div class="card-horizontal">
                                {/*<Card.Img style={{width:300,height:250}} variant="top" src="http://via.placeholder.com/300x180" />*/}

                                <Card.Body>

                                    <Card.Title><h4><a href={item.guid}>{item.post_title}</a></h4></Card.Title>
                                    <div style={{marginBottom: 10}}>
                                        <small className="text-muted author_list">
                                   <span style={{marginRight: 10}}>
                                       {moment(item.post_date).format('D MMMM YYYY')}
                                   </span>
                                            <span>
                                       Oleh : {item.author_name}
                                    </span>

                                        </small>
                                    </div>
                                    <Card.Text style={{borderBottom: 1, fontWeight: 'normal', color: '#575757'}}>
                                        <p style={{color: '#575757', fontSize: 15, fontWeight: 'normal'}}/>
                                        {strip(item.post_content).substring(0, 250) + '...'}

                                    </Card.Text>
                                    <div style={{marginBottom: 10}}>
                                        <small className="text-muted">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor"
                                                 className="bi bi-folder" viewBox="0 0 16 16">
                                                <path
                                                    d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z"/>
                                            </svg>
                                            {' ' + item.tag_name.toUpperCase()}
                                        </small>
                                    </div>
                                </Card.Body>
                            </div>
                        </Card>
                    </Col>
                )
            }
        )

        return element;
    }

    return <PageWrapper
        headerShy={false}
        headerActive={'book-store'}>

        <Container style={{marginBottom: '60px'}} fluid>

            <Container>
                <Row style={{
                    marginTop: isMobile ? 0 : "2em",
                    fontFamily: 'OpenSans-Bold'
                }}>

                </Row>

                <Row style={{
                    marginTop: "4em"
                }}>
                    {
                        upcomingSession.length > 0 &&
                        <Col
                            style={{
                                color: Palette.PRIMARY,
                                fontSize: "1.5em",
                                fontFamily: 'OpenSans-Bold'
                            }}
                            md={12}>
                            Kelas Publik Mendatang<br/>
                            <Link to={"/kelas#kelas-publik-mendatang"}
                                  style={{fontFamily: "OpenSans-Regular", fontSize: "0.6em"}}>Lihat Semua Kelas Publik
                                Mendatang</Link>
                        </Col>
                    }


                    <Col md={12}>


                        <Row>
                            {isMobile && upcomingSession.map((session, key) => {
                                if (key <= 2) {
                                    return <SessionCard
                                        key={key}
                                        classStatus={session.classStatus}
                                        registrationStatus={session.status}
                                        permanent={session.permanent}
                                        classDate={session.class_date}
                                        image={session.class_image_url ? session.class_image_url : '/image/no-img-placeholder.png'}
                                        name={session.name}
                                        topic={session.topic}
                                        onClick={() => {
                                            history.push(`/kelas/detail/${session.code}`)
                                        }}/>
                                }

                                return null;
                            })}


                            {upcomingSessionRows.length > 0 && !isMobile &&
                            <Carousel showThumbs={false} showStatus={false} autoPlay={true} infiniteLoop={true}
                                      showIndicators={false}>
                                {
                                    upcomingSessionRows.map((row, idx) => {
                                        return (
                                            <Row>
                                                {upcomingSession.map((session, key) => {
                                                    if (key <= ((idx + 1) * 3) - 1 && key >= ((idx + 1) * 3) - 3) {
                                                        return <SessionCard
                                                            key={key}
                                                            classStatus={session.classStatus}
                                                            registrationStatus={session.status}
                                                            permanent={session.permanent}
                                                            classDate={session.class_date}
                                                            image={session.class_image_url ? session.class_image_url : '/image/no-img-placeholder.png'}
                                                            name={session.name}
                                                            topic={session.topic}
                                                            onClick={() => {
                                                                history.push(`/kelas/detail/${session.code}`)
                                                            }}/>
                                                    }

                                                    return null;
                                                })}
                                            </Row>
                                        )
                                    })
                                }
                            </Carousel>
                            }
                        </Row>
                    </Col>
                </Row>

                <Row>
                    {
                        coursesRows.length > 0 && <>
                            <Col
                                style={{
                                    color: Palette.PRIMARY,
                                    fontSize: "1.5em",
                                    fontFamily: 'OpenSans-Bold',
                                    marginTop: "1em"
                                }}
                                md={12}
                            >
                                Kelas Mandiri <br/>
                                <Link to={"/kelas#kelas-daring-mandiri"}
                                      style={{fontFamily: "OpenSans-Regular", fontSize: "0.6em"}}>Lihat Semua Kelas Daring
                                    Mandiri</Link>
                            </Col>
                            <Col md={12}>
                                <Row>
                                    {isMobile && coursesRows.map((row, idx) => {
                                        return (
                                            <Row>
                                                {
                                                    courses
                                                        .map((course, key) => {
                                                            if (key <= ((idx + 1) * 3) - 1 && key >= ((idx + 1) * 3) - 3) {
                                                                console.log("key " + key)
                                                                return <IndependentClassCard
                                                                    image={course.course_image_url}
                                                                    index={key + 1}
                                                                    title={course.title}
                                                                    onClick={() => {
                                                                        console.log('course', course)
                                                                        if (course.course_code) {
                                                                            history.push(`/kdm/detail/${course.course_code}`)
                                                                        } else {
                                                                            history.push(`/kdm/${course.id}`)
                                                                        }
                                                                        // history.push(`/kelas-daring-mandiri/${course.id}/pratinjau`)
                                                                    }}
                                                                />
                                                            }

                                                            return null
                                                        })
                                                }
                                            </Row>
                                        )
                                    })}

                                    {coursesRows.length > 0 && !isMobile &&
                                    <Carousel showThumbs={false} showStatus={false} autoPlay={true} infiniteLoop={true}
                                              showIndicators={false}>
                                        {
                                            coursesRows.map((row, idx) => {
                                                return (
                                                    <Row>
                                                        {
                                                            courses
                                                                .map((course, key) => {
                                                                    if (key <= ((idx + 1) * 3) - 1 && key >= ((idx + 1) * 3) - 3) {
                                                                        console.log("key " + key)
                                                                        return <IndependentClassCard
                                                                            image={course.course_image_url}
                                                                            index={key + 1}
                                                                            title={course.title}
                                                                            onClick={() => {
                                                                                console.log('course', course)
                                                                                if (course.course_code) {
                                                                                    history.push(`/kdm/detail/${course.course_code}`)
                                                                                } else {
                                                                                    history.push(`/kdm/${course.id}`)
                                                                                }
                                                                                // history.push(`/kelas-daring-mandiri/${course.id}/pratinjau`)
                                                                            }}
                                                                        />
                                                                    }

                                                                    return null
                                                                })
                                                        }
                                                    </Row>
                                                )
                                            })
                                        }
                                    </Carousel>
                                    }
                                </Row>
                            </Col>
                        </>
                    }

                    {
                        gsmsSessions.length > 0 && <>
                            <Col
                                style={{
                                    color: Palette.PRIMARY,
                                    fontSize: "1.5em",
                                    fontFamily: 'OpenSans-Bold',
                                    marginTop: "1em"
                                }}
                                md={12}
                            >
                                GSMS <br/>
                                <Link to={"/kelas#gsms"}
                                      style={{fontFamily: "OpenSans-Regular", fontSize: "0.6em"}}>Lihat Semua GSMS</Link>
                            </Col>
                            <Col
                                style={{
                                }}
                                md={12}>
                                <Row  style={{
                                    width : "100%",
                                    padding : 0
                                }}>
                                    {isMobile && gsmsSessions.map((session, key) => {
                                        if (key <= 2) {
                                            return <SessionCard
                                                key={key}
                                                classStatus={session.classStatus}
                                                registrationStatus={session.status}
                                                permanent={session.permanent}
                                                classDate={session.class_date}
                                                image={session.class_image_url ? session.class_image_url : '/image/no-img-placeholder.png'}
                                                name={session.name}
                                                topic={session.topic}
                                                onClick={() => {
                                                    history.push(`/kelas/detail/${session.code}`)
                                                }}/>
                                        }

                                        return null;
                                    })}

                                    {gsmsSessions.length === 1 && !isMobile &&
                                    <SessionCard
                                        classStatus={gsmsSessions[0].classStatus}
                                        registrationStatus={gsmsSessions[0].status}
                                        permanent={gsmsSessions[0].permanent}
                                        classDate={gsmsSessions[0].class_date}
                                        image={gsmsSessions[0].class_image_url ? gsmsSessions[0].class_image_url : '/image/no-img-placeholder.png'}
                                        name={gsmsSessions[0].name}
                                        topic={gsmsSessions[0].topic}
                                        onClick={() => {
                                            history.push(`/kelas/detail/${gsmsSessions[0].code}`)
                                        }}/>
                                    }

                                    {gsmsSessions.length > 1 && !isMobile &&
                                    <Carousel
                                        width={"100%"}
                                        style={{
                                            width : "100%",
                                            padding : 0

                                        }}
                                        showThumbs={false} showStatus={false} autoPlay={true} infiniteLoop={true}
                                              showIndicators={false}>
                                        {
                                            gsmsSessionsRows.map((row, idx) => {
                                                return (
                                                    <Row style={{
                                                        width : "100%"
                                                    }}>
                                                        {gsmsSessions.map((session, key) => {
                                                            if (key <= ((idx + 1) * 3) - 1 && key >= ((idx + 1) * 3) - 3) {
                                                                return <SessionCard
                                                                    key={key}
                                                                    classStatus={session.classStatus}
                                                                    registrationStatus={session.status}
                                                                    permanent={session.permanent}
                                                                    classDate={session.class_date}
                                                                    image={session.class_image_url ? session.class_image_url : '/image/no-img-placeholder.png'}
                                                                    name={session.name}
                                                                    topic={session.topic}
                                                                    onClick={() => {
                                                                        history.push(`/kelas/detail/${session.code}`)
                                                                    }}/>
                                                            }

                                                            return <Col xs={12} sm={6} md={4}/>
                                                        })}
                                                    </Row>
                                                )
                                            })
                                        }
                                    </Carousel>
                                    }
                                </Row>
                            </Col>
                        </>
                    }


                    {
                        otherSessions.length > 0 && <>
                            <Col
                                style={{
                                    color: Palette.PRIMARY,
                                    fontSize: "1.5em",
                                    fontFamily: 'OpenSans-Bold',
                                    marginTop: "1em"
                                }}
                                md={12}
                            >
                                Acara Lain <br/>
                                <Link to={"/kelas#acara-lain"}
                                      style={{fontFamily: "OpenSans-Regular", fontSize: "0.6em"}}>Lihat Semua Acara Lain</Link>
                            </Col>
                            <Col md={12}>
                                <Row>
                                    {isMobile && otherSessions.map((session, key) => {
                                        if (key <= 2) {
                                            return <SessionCard
                                                key={key}
                                                classStatus={session.classStatus}
                                                registrationStatus={session.status}
                                                permanent={session.permanent}
                                                classDate={session.class_date}
                                                image={session.class_image_url ? session.class_image_url : '/image/no-img-placeholder.png'}
                                                name={session.name}
                                                topic={session.topic}
                                                onClick={() => {
                                                    history.push(`/kelas/detail/${session.code}`)
                                                }}/>
                                        }

                                        return null;
                                    })}


                                    {otherSessions.length === 1 && !isMobile &&
                                    <SessionCard
                                        classStatus={otherSessions[0].classStatus}
                                        registrationStatus={otherSessions[0].status}
                                        permanent={otherSessions[0].permanent}
                                        classDate={otherSessions[0].class_date}
                                        image={otherSessions[0].class_image_url ? otherSessions[0].class_image_url : '/image/no-img-placeholder.png'}
                                        name={otherSessions[0].name}
                                        topic={otherSessions[0].topic}
                                        onClick={() => {
                                            history.push(`/kelas/detail/${otherSessions[0].code}`)
                                        }}/>
                                    }

                                    {otherSessions.length > 1 && !isMobile &&
                                    <Carousel showThumbs={false} showStatus={false} autoPlay={true} infiniteLoop={true}
                                              showIndicators={false}>
                                        {
                                            otherSessionsRows.map((row, idx) => {
                                                return (
                                                    <Row>
                                                        {otherSessions.map((session, key) => {
                                                            if (key <= ((idx + 1) * 3) - 1 && key >= ((idx + 1) * 3) - 3) {
                                                                return <SessionCard
                                                                    key={key}
                                                                    classStatus={session.classStatus}
                                                                    registrationStatus={session.status}
                                                                    permanent={session.permanent}
                                                                    classDate={session.class_date}
                                                                    image={session.class_image_url ? session.class_image_url : '/image/no-img-placeholder.png'}
                                                                    name={session.name}
                                                                    topic={session.topic}
                                                                    onClick={() => {
                                                                        history.push(`/kelas/detail/${session.code}`)
                                                                    }}/>
                                                            }

                                                            return null;
                                                        })}
                                                    </Row>
                                                )
                                            })
                                        }
                                    </Carousel>
                                    }
                                </Row>
                            </Col>
                        </>
                    }

                    <Col
                        style={{
                            color: Palette.PRIMARY,
                            fontSize: "1.5em",
                            fontFamily: 'OpenSans-Bold',
                            marginTop: "1em"
                        }}
                        md={12}>Publikasi <br/>
                        <Link to={"/kreasi"}
                              style={{fontFamily: "OpenSans-Regular", fontSize: "0.6em"}}>Lihat Semua Publikasi</Link>
                    </Col>
                    <Col md={12}>
                        <Row>
                            {
                                isMobile && merchandise
                                    .map((merch, key) => {
                                        if (key <= 2) {
                                            console.log("key " + key)
                                            return (
                                                <IndependentClassCard
                                                    image={merch.image_url}
                                                    index={key + 1}
                                                    title={merch.name}
                                                    topic={`Rp${textFormatter.moneyFormatter(merch.price)}`}
                                                    onClick={() => {
                                                        setSelectedBook(merch)
                                                        let tempArr = [];

                                                        tempArr.push({url: merch.image_url})

                                                        for (let i in merch.other_image_url) {
                                                            tempArr.push({url: merch.other_image_url[i]})
                                                        }

                                                        console.log('image array', tempArr)
                                                        setProductImages(tempArr)
                                                        setBookModalShown(true);
                                                    }}
                                                />
                                            )
                                        }

                                        return null
                                    })
                            }

                            {merchandiseRows.length > 0 && !isMobile &&
                            <Carousel showThumbs={false} showStatus={false} autoPlay={true} infiniteLoop={true}
                                      showIndicators={false}>
                                {
                                    merchandiseRows.map((row, idx) => {
                                        return (
                                            <Row>
                                                {
                                                    merchandise
                                                        .map((merch, key) => {
                                                            if (key <= ((idx + 1) * 3) - 1 && key >= ((idx + 1) * 3) - 3) {
                                                                console.log("key " + key)
                                                                return (
                                                                    <IndependentClassCard
                                                                        image={merch.image_url}
                                                                        index={key + 1}
                                                                        title={merch.name}
                                                                        topic={`Rp${textFormatter.moneyFormatter(merch.price)}`}
                                                                        onClick={() => {
                                                                            setSelectedBook(merch)
                                                                            let tempArr = [];

                                                                            tempArr.push({url: merch.image_url})

                                                                            for (let i in merch.other_image_url) {
                                                                                tempArr.push({url: merch.other_image_url[i]})
                                                                            }

                                                                            console.log('image array', tempArr)
                                                                            setProductImages(tempArr)
                                                                            setBookModalShown(true);
                                                                        }}
                                                                    />
                                                                )
                                                            }

                                                            return null
                                                        })
                                                }
                                            </Row>
                                        )
                                    })
                                }
                            </Carousel>
                            }
                        </Row>

                    </Col>

                    <Col
                        style={{
                            color: Palette.PRIMARY,
                            fontFamily: 'OpenSans-Bold',
                            marginBottom: 10,
                            marginTop: 30
                        }}
                        md={12}>
                        <div style={{fontSize: '1.5em', marginBottom: 40}}>Artikel Terbaru<br/>
                            <Link to={"/artikel"}
                                  style={{fontFamily: "OpenSans-Regular", fontSize: "0.6em"}}>Lihat Semua Artikel</Link>
                        </div>

                        {renderArticle()}
                    </Col>
                </Row>
            </Container>

        </Container>

        <Dialog open={isBookModalShown} maxWidth={'md'} fullWidth={true} onClose={() => {
            setBookModalShown(false)
        }}
        >
            <Container style={{minHeight: '60vh'}}>
                <Row style={{margin: 'auto'}}>
                    <Col style={{paddingBottom: 30, marginLeft: 30}}>
                        <div
                            style={{
                                width: '100%',
                                height: undefined,
                                overflow: 'hidden',
                                marginTop: 40,
                            }}
                            ref={productImgRef}
                            onMouseLeave={() => setImageTransformOrigin(null)}
                            onMouseMove={(e) => {
                                if (productImages[selectedProductImgIdx]?.loaded) {
                                    setImageTransformOrigin({
                                        x: (e.clientX - productImgRef.current.getBoundingClientRect().left) / productImgRef.current.clientWidth * 100,
                                        y: (e.clientY - productImgRef.current.getBoundingClientRect().top) / productImgRef.current.clientHeight * 100
                                    })
                                }
                            }}
                        >
                            <img
                                onLoad={() => setImageLoaded(true)}
                                // onClick={() => {
                                //     if (isImageLoaded && session.class_image_url) setImagePreview(true)
                                // }}
                                src={productImages[selectedProductImgIdx]?.url ? productImages[selectedProductImgIdx]?.url : '/image/no-img-placeholder.png'}
                                style={{
                                    cursor: productImages[selectedProductImgIdx]?.loaded ? 'crosshair' : 'auto',
                                    objectFit: 'contain',
                                    width: 300,
                                    height: 300,
                                    maxHeight: 300,
                                    backgroundColor: 'white',
                                    transform: productImageTransformOrigin ? 'scale(1.5)' : 'none',
                                    transformOrigin: productImageTransformOrigin ? `${productImageTransformOrigin.x}% ${productImageTransformOrigin.y}%` : '50% 50%'
                                }}/>
                        </div>
                        <Row style={{
                            marginLeft: 0,
                            marginRight: 0,
                            borderTop: '1px solid #e5e7e9',
                            marginTop: 20,
                            paddingTop: 15
                        }}>
                            {
                                productImages.map(
                                    (productImage, idx) => {
                                        console.log('images', productImage)
                                        return (
                                            <Col
                                                onMouseOver={() => {
                                                    setProductImgHoveredIdx(idx)
                                                }}
                                                onMouseLeave={() => {
                                                    setProductImgHoveredIdx(-1)
                                                }}
                                                onLoad={() => {
                                                    const productImagesTemp = [...productImages];

                                                    productImagesTemp[idx].loaded = true;

                                                    setProductImages(productImagesTemp);
                                                }}
                                                onClick={() => {
                                                    setSelectedProductImgIdx(idx)
                                                }}
                                                style={{
                                                    marginLeft: 0,
                                                    marginRight: 15,
                                                    backgroundColor: productImage.loaded ? 'white' : '#f0f0f0',
                                                    maxWidth: 70,
                                                    maxHeight: 70,
                                                    borderRadius: 5,
                                                    boxShadow: productImgHoveredIdx === idx || selectedProductImgIdx === idx ? '0 3px 6px 0 rgba(49,53,59,0.5)' : 'none',
                                                    paddingLeft: 0,
                                                    cursor: 'pointer',
                                                }}>
                                                <img
                                                    src={productImage.url}
                                                    style={{
                                                        objectFit: 'contain',
                                                        width: 70,
                                                        height: 70,
                                                        // backgroundColor: 'white',
                                                        borderRadius: 5,
                                                    }}/>
                                            </Col>
                                        )
                                    }
                                )
                            }
                        </Row>
                    </Col>
                    <Col
                        sm={12}
                        md={6}
                        style={{
                            backgroundColor: '',
                            padding: 0,
                            marginRight: 50,
                            paddingTop: windowWidth < 768 ? 30 : 50,
                            display: 'flex',
                            flexDirection: 'column',
                            // justifyContent: 'center',
                            order: windowWidth < 768 ? 1 : 0
                        }}>
                        <div style={{fontFamily: 'OpenSans-Bold', fontWeight: '400', fontSize: '1rem', color: 'rgba(49, 53, 59, 0.96)'}}>
                            {selectedBook.name}
                        </div>

                        <div style={{fontFamily: 'OpenSans-Bold', fontWeight: '300', fontSize: '1.7rem', color: 'rgba(49, 53, 59, 0.96)'}}>
                            Rp{selectedBook.price ? textFormatter.moneyFormatter(selectedBook.price) : selectedBook.price}
                        </div>

                        <div style={{fontFamily: 'OpenSans-Bold', marginTop: 50, fontSize: '.8rem', color: Palette.PRIMARY}}>
                            DESKRIPSI
                        </div>

                        <div style={{whiteSpace : "pre-line", fontFamily: 'OpenSans-Regular',  marginTop: 10, maxHeight: '34vh', width: '110%', overflow: 'auto'}}>
                            {selectedBook.description ? selectedBook.description : '-'}
                        </div>
                    </Col>

                </Row>


                <Row>
                    <Col style={{
                        width: '100%',
                        height: 1,
                        backgroundColor: '#e9eaec',
                        marginTop: 25,
                        marginBottom: 25,
                    }}/>
                </Row>

                <Row>
                    <Col style={{
                        display: 'flex',
                        flexDirection: windowWidth < 550 ? 'column' : 'row',
                        justifyContent: 'flex-end',
                        marginRight: 16,
                        marginBottom: 22,
                        fontFamily: 'OpenSans-Bold'
                    }}>
                        <Button variant={'link'} style={{
                            marginRight: windowWidth < 550 ? 0 : 20,
                            order: windowWidth < 550 ? 2 : 0,
                            marginTop: windowWidth < 550 ? 8 : 0
                        }}
                                onClick={() => setBookModalShown(false)}>
                            Batal
                        </Button>

                        <a href={selectedBook.marketplace_link} target={'_blank'} style={{textDecoration: 'none'}}
                           onClick={() => {
                               console.log(selectedBook.marketplace_link)
                           }}>
                            <Button
                                style={{
                                    width: windowWidth < 550 ? '100%' : 100,
                                    height: 40,
                                    order: windowWidth < 550 ? 0 : 2
                                }}>
                                Beli
                            </Button>
                        </a>
                    </Col>
                </Row>
            </Container>

        </Dialog>

    </PageWrapper>

}
