import ApiRequest from "../util/ApiRequest";
import GlobalData from "../util/GlobalData";

export default class Order {
    checkout = async (body) => {
        return await ApiRequest.set("/v1/order/checkout", "POST", body);
    }

    getMyOrder = async () => {
        return await ApiRequest.set("/v1/order", "GET");
    }

    changePaymentMethod = async (orderId) => {
        return await ApiRequest.set(`/v1/order/change_payment/${orderId}`, "POST");
    }
}
