import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import React, {Component, useState} from "react";
import Form from "react-bootstrap/Form";
import User from "../../models/User";
import GlobalData from "../../util/GlobalData";
import moment from "moment";
import 'moment/locale/id'
import Session from "../../models/Session";
import ExternalUrlModal from "./ExternalUrlModal";
import textFormatter from "../../util/textFormatter";

export default function PaymentGuideModal(props) {

    const [isURLModalOpen, setURLModalOpen] = useState(false);
    const [isOpen, setOpen] = useState(true);

    const additionalInfo = props.session ? JSON.parse(props.session.additional_information) : {};
    console.log(additionalInfo.type);

    const closeExternalModal = () =>{
        setURLModalOpen(false);
        setOpen(true)
    }

    const renderGuide = () => {

        if (additionalInfo.type === 'donation') {
            return <p>1. Berikan donasi minimal sebesar Rp{textFormatter.moneyFormatter(props.session.price)} melalui <a href={additionalInfo.url}>tautan
                ini</a>.</p>
        } else {
            return <p>1. Beli buku di <a href={"#"} onClick={(e) => {

                if (additionalInfo.books) {

                    setURLModalOpen(true)
                    setOpen(false)

                } else {
                    window.open(additionalInfo.url)
                }

            }}>tautan ini</a></p>
        }

    }

    return <>
        <ExternalUrlModal
            onHide={() => closeExternalModal()}
            show={isURLModalOpen} urlList={additionalInfo.books}/>
        <Modal
            size="xl"
            centered
            show={props.show && isOpen}
            onHide={() => props.onClose()}
        >
            <Modal.Header closeButton>
                Petunjuk Pendaftaran
            </Modal.Header>

            <Container>
                <Row>
                    <Col md={4} style={{
                        marginTop: "1em",
                        marginBottom: "1em"
                    }}>
                        <img
                            style={{
                                width: "100%"
                            }}
                            src={additionalInfo.type === 'donation' ? GlobalData.subfolder + "image/step1.PNG" : GlobalData.subfolder + "image/step1b.png"}/>
                        {renderGuide()}
                    </Col>
                    <Col md={4} style={{
                        marginTop: "1em",
                        marginBottom: "1em"
                    }}>
                        <img
                            style={{
                                width: "100%"
                            }}
                            src={GlobalData.subfolder + "image/step2.PNG"}/>
                        2. Kirimkan tangkapan layar
                        bukti {additionalInfo.type === 'donation' ? 'donasimu' : 'pembayaranmu'} melalui aplikasi atau
                        website Seminar Edukasi Musik
                    </Col>
                    <Col md={4} style={{
                        marginTop: "1em",
                        marginBottom: "1em"
                    }}>
                        <img
                            style={{
                                width: "100%"
                            }}
                            src={GlobalData.subfolder + "image/step3.png"}/>
                        3. Kamu akan mendapatkan konfirmasi dan petunjuk untuk masuk ke ruang virtual melalui aplikasi
                        dan
                        surel 2 jam sebelum acara dimulai!</Col>
                </Row>
            </Container>
        </Modal>
    </>

}
