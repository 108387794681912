import React, {Component, useEffect, useState} from "react";
import {Slide} from "react-reveal";
import {Helmet} from "react-helmet";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageWrapper from "../page/PageWrapper";
import Palette from "../../util/Palette";
import {Button, Navbar, Dropdown} from "react-bootstrap";
import GlobalData from "../../util/GlobalData";
import {Link, useHistory} from "react-router-dom";
import {
    isMobile
} from "react-device-detect";
import {useCookies} from "react-cookie";
import {withCookies, Cookies} from 'react-cookie';
import Container from "react-bootstrap/Container";

import {GiPisces} from "react-icons/gi";
import {
    FaHome,
    FaUserCircle,
    FaRegNewspaper,
    FaEnvelope,
    FaMapMarkerAlt,
    FaPhone,
    FaWhatsapp,
    FaInstagram, FaYoutube
} from "react-icons/fa";
import {FiClock} from "react-icons/fi";
import {AiFillStar} from "react-icons/ai";
import {GiLighthouse} from "react-icons/gi";

import {
    withRouter
} from 'react-router-dom'
import Strings from "../../util/Strings";
import UserMenuModal from "./UserMenuModal";
import {AiOutlineForm} from "react-icons/all";

function Header(props) {
    const strings = GlobalData.strings.footer;

    let [scrollPosition, setScrollPosition] = useState(0)

    const isLoggedIn = props.isLoggedIn
    const [userMenuModalShown, setUserMenuModalShown] = useState(false)


    function listenToScroll() {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height

        setScrollPosition(scrolled)
    }

    useEffect(() => window.addEventListener('scroll', listenToScroll))
    useEffect(() => {
        console.log('strings', GlobalData.strings)

        return () => {
            window.removeEventListener('scroll', listenToScroll)
        }
    }, []);

    if (isMobile) {
        return <>
            <div style={{
                width: "100%",
                height: "3.5em",
                position: "fixed",
                bottom: 0,
                zIndex: 1000,
                backgroundColor: "white",
                boxShadow: "5px 5px 5px 5px #888888",
                display: "flex",
                flexDirection: "row",
                color: "grey"
            }}>

                {/*<div style={{*/}
                {/*    flex: 1,*/}
                {/*    display: "flex",*/}
                {/*    flexDirection: "column",*/}
                {/*    alignItems: "center",*/}
                {/*    justifyContent: "center",*/}
                {/*    color: props.activeTab === "home" ? Palette.PRIMARY : null*/}
                {/*}} onClick={() => {*/}
                {/*    props.history.push("/")*/}
                {/*}}*/}
                {/*>*/}
                {/*    <FaHome style={{*/}
                {/*        fontSize: "1.35em",*/}
                {/*        marginBottom: '0.2em'*/}
                {/*    }}/>*/}
                {/*    <div style={{*/}
                {/*        fontSize: "0.6em"*/}
                {/*    }}>*/}
                {/*        Beranda*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    color: props.activeTab === "my-session" ? Palette.PRIMARY : null
                }} onClick={() => props.history.push("/")}>
                    <FaHome style={{
                        fontSize: "1.35em",
                        marginBottom: '0.2em'
                    }}/>
                    <div style={{
                        fontSize: "0.6em"
                    }}>
                        Kelas
                    </div>
                </div>


                {/*<div style={{*/}
                {/*    flex: 1,*/}
                {/*    display: "flex",*/}
                {/*    flexDirection: "column",*/}
                {/*    alignItems: "center",*/}
                {/*    justifyContent: "center",*/}
                {/*    color: props.activeTab === "news" ? Palette.PRIMARY : null*/}
                {/*}} onClick={() => props.history.push("/info")}>*/}
                {/*    <FaInfoCircle style={{*/}
                {/*        fontSize: "1.35em",*/}
                {/*        marginBottom: '0.2em'*/}
                {/*    }}/>*/}
                {/*    <div style={{*/}
                {/*        fontSize: "0.6em"*/}
                {/*    }}>*/}
                {/*        Informasi*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        color: props.activeTab === "profile" ? Palette.PRIMARY : null
                    }}
                    onClick={() => {
                        console.log("hoi")

                        if (isLoggedIn) {
                            setUserMenuModalShown(true)
                        } else {
                            props.onLoginPress()
                        }

                    }}
                >
                    <FaUserCircle style={{
                        fontSize: "1.35em",
                        marginBottom: '0.2em'
                    }}/>
                    <div style={{
                        fontSize: "0.6em"
                    }}>
                        Akun
                    </div>
                </div>

            </div>


            <UserMenuModal
                show={userMenuModalShown}
                onHide={() => {
                    setUserMenuModalShown(false)
                }}
            />

        </>
    }


    // return <Slide bottom when={scrollPosition > 0.9}>
    //     <div style={{
    //         width: "100%",
    //         height: "2em",
    //         position: "fixed",
    //         bottom: 0,
    //         zIndex: 1000,
    //         display: "flex",
    //         justifyContent: "center",
    //         background: Palette.PRIMARY,
    //         boxShadow: "0.5px 0.5px 0.5px #888888"
    //     }}>
    //         <a style={{
    //             textAlign: "center",
    //             width: "100%",
    //             height: "100%",
    //             color: "white",
    //             fontSize: "0.8em",
    //             display: "flex",
    //             justifyContent: "center",
    //             alignItems: "center"
    //         }}>
    //             Powered By Intellivent
    //         </a>
    //     </div>
    // </Slide>


    return <>

        <div style={{winWidth: '100vh', backgroundColor: '#dedede', height: 1}}/>
        <div style={{
            // height: "15em",
            width: "100%",
            // boxShadow: "5px 5px 5px 5px #888888",
        }}>
            <Container style={{
                width: "100%",
                zIndex: 1000,
                display: "flex",
                background: "white",
                color: "black",
                fontSize: "0.9em",
                paddingTop: "1em"
            }}>
                <Row style={{marginTop: "0.5em", marginBottom: "1em", width: "100%"}}>
                    <Col md={3} style={{display: "flex", flexDirection: "column"}}>
                        <b style={{marginTop: "0.5em"}}>
                            {strings.about_title}
                        </b>
                        <ul style={{paddingLeft: "0.1em", marginTop: "1em"}}>
                            <p>
                                {strings.about_content}
                            </p>
                        </ul>
                    </Col>
                    <Col md={6} style={{display: "flex", flexDirection: "column"}}>

                        <b style={{marginTop: "0.5em"}}>
                            {strings.contact_title}
                        </b>
                        <ul style={{paddingLeft: "0.1em", marginTop: "1em"}}>
                            <FaMapMarkerAlt style={{color: Palette.PRIMARY, marginRight: "0.5em"}}/>
                            {strings.contact_address}
                        </ul>
                        <a href={"https://wa.me/" + strings.contact_telephone.replace(/\s/g, '')} target="_blank">
                            <ul
                                style={{
                                    paddingLeft: "0.1em", marginTop: ".1em",
                                    cursor: "pointer"
                                }}
                            >
                                <FaWhatsapp style={{color: Palette.PRIMARY, marginRight: "0.5em", fontSize: "1.2em"}}/>
                                {strings.contact_telephone}
                            </ul>
                        </a>

                        <ul style={{paddingLeft: "0.1em", marginTop: ".1em"}}>
                            <FaEnvelope style={{color: Palette.PRIMARY, marginRight: "0.5em"}}/>
                            {strings.contact_email}
                        </ul>

                        <a href={"https://www.instagram.com/narabahasa/"} target="_blank">
                            <ul style={{paddingLeft: "0.1em", marginTop: ".1em"}}>
                                <FaInstagram style={{color: Palette.PRIMARY, marginRight: "0.5em"}}/>
                                Instagram
                            </ul>
                        </a>

                        <a href={"https://www.youtube.com/channel/UC1j321n3lNCz1Smu-aBF9hw"} target="_blank">
                            <ul style={{paddingLeft: "0.1em", marginTop: ".1em"}}>
                                <FaYoutube style={{color: Palette.PRIMARY, marginRight: "0.5em"}}/>
                                YouTube
                            </ul>
                        </a>

                    </Col>
                    <Col md={3} style={{display: "flex", flexDirection: "column"}}>

                        <b style={{marginTop: "0.5em"}}>
                            {strings.other_title}
                        </b>


                        <a style={{marginTop: "1em"}} href={"https://narabahasa.id"} target={"_blank"}>
                            {strings.other_main_website}
                        </a>

                        <a href={"https://narabahasa.id/kebijakan-privasi"} onClick={() => {
                        }}>
                            {strings.other_privacy_policy}
                        </a>

                        <a href={"/aduan"} onClick={() => {
                        }}>
                            {strings.complaint}
                        </a>

                        <a href={"https://play.google.com/store/apps/details?id=id.narabahasa.sinara"}
                           style={{marginTop: 15}}>
                            <img
                                src={require('../../asset/find-us-on-play-store.png')}
                                style={{width: '100%', maxWidth: 130}}
                            />
                        </a>

                        {/*<b style={{marginTop: "1em"}}>*/}
                        {/*    {isEN ? 'Contact Us' : 'Hubungi Kami'}*/}
                        {/*</b>*/}
                        {/*<ul style={{paddingLeft: "0.1em"}}>*/}
                        {/*    Instagram :*/}
                        {/*    <a href={"https://www.instagram.com/Seminar Edukasi Musik_id/?hl=en"} onClick={() => {*/}
                        {/*        setIsLanguageModalShow(true)*/}
                        {/*    }}>*/}
                        {/*        @Seminar Edukasi Musik_id*/}
                        {/*    </a>*/}
                        {/*</ul>*/}
                        {/*<ul style={{paddingLeft: "0.1em"}}>*/}
                        {/*    Email :*/}
                        {/*    <a href={"https://www.instagram.com/Seminar Edukasi Musik_id/?hl=en"} onClick={() => {*/}
                        {/*        setIsLanguageModalShow(true)*/}
                        {/*    }}>*/}
                        {/*        renjana@Seminar Edukasi Musik.com*/}
                        {/*    </a>*/}
                        {/*</ul>*/}
                    </Col>
                    <Col md={12} style={{textAlign: "center", paddingTop: "1em"}}>
                        Hak Cipta ©2020 <b>PT Wahana Mega Cita (Narabahasa)</b>. Dikembangkan oleh <b
                        style={{fontFamily: "Spartan", fontWeight: "900"}}>INTELLIVENT</b>
                    </Col>
                </Row>
            </Container>

        </div>
    </>


}

export default withRouter(Header)
