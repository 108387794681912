import PageWrapper from "./PageWrapper";
import React, {useEffect, useState} from "react";
import {AiFillCheckCircle, AiOutlineCheck, AiOutlineClose, AiOutlineMenu} from "react-icons/ai";
import Palette from "../../util/Palette";
import ProgressBar from "../reusable/ProgressBar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
    RiArrowDownSLine,
    RiArrowUpSLine,
    RiErrorWarningLine,
    RiFileEditLine,
    RiFileListLine,
    RiStickyNoteLine
} from "react-icons/ri";
import {Container, Dropdown, Modal} from "react-bootstrap";
import GlobalData from "../../util/GlobalData";
import {useCookies} from "react-cookie";
import UserMenuModal from "../reusable/UserMenuModal";
import Rating from "react-rating";
import {IoMdStar} from "react-icons/io";
import Strings from "../../util/Strings";
import Form from "react-bootstrap/Form";
import RatingModal from "../reusable/RatingModal";
import {MdEdit, MdHome, MdModeEdit, MdOndemandVideo, MdRateReview, MdVideoLibrary} from "react-icons/md";
import YouTube from "react-youtube";
import Button from "react-bootstrap/Button";
import {FiArrowLeft, FiArrowRight} from "react-icons/fi";
import {Link} from "react-router-dom";
import {Scrollbars} from 'react-custom-scrollbars';
import Course from "../../models/Course";
import {BsFillPeopleFill} from "react-icons/bs";
import {useHistory} from "react-router-dom";
import MemberCourseProgress from "../../models/MemberCourseProgress";
import CourseRating from "../../models/CourseRating";
import CourseNotes from "../../models/CourseNotes";
import ReactPlayer from "react-player";
import {FaCheck} from "react-icons/fa";
import {Parser} from 'html-to-react'

const CONTENT_TYPE = {
    VIDEO: 'Video',
    DOCUMENT: 'Dokumen',
    TEST: 'Kuis',
    PROLOGUE: 'Prolog',
    TEACHERS_PROFILE: 'TEACHERS_PROFILE',
    GLOSSARY: 'Glosarium',
    REVIEW: 'REVIEW',
    TEXT: 'Teks',
    ESSAY: 'Latihan',
    EXERCISE: 'Latihan'
}

export default function OnlineClass(props) {
    const history = useHistory()
    const memberCourseProgressModel = new MemberCourseProgress();
    const courseRatingModel = new CourseRating();

    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState(null);
    const [displayedPredicate, setDisplayedPredicate] = useState(null)

    let [firstRender, setFirstRender] = useState(true);
    const [isRatingModalShown, setRatingModalShown] = useState(false);
    const [user, setUser] = useState(null);
    const [isSidebarExpanded, setSidebarExpanded] = useState(true)
    const [isNoteExpanded, setNoteExpanded] = useState(false)
    const [userMenuModalShown, setUserMenuModalShown] = useState(false)
    const [selectedModule, setSelectedModule] = useState({});
    const [course, setCourse] = useState({});

    const [prologueComments, setPrologueComments] = useState([]);

    const [progress, setProgress] = useState(0)

    const [answers, setAnswers] = useState([]);
    const [userSummaries, setUserSummaries] = useState([]);

    const [isStartQuizModalVisible, setStartQuizModalVisible] = useState(false);
    const [isStartExerciseModalVisible, setStartExerciseModalVisible] = useState(false);
    const [onStartQuizConfirmed, setOnStartQuizConfirmed] = useState(() => {
    })

    const [isCourseFinishedModalVisible, setCourseFinishedModalVisible] = useState(false);

    const [remainingDuration, setRemainingDuration] = useState(-1);

    // const [quizCorrectAnswers, setQuizCorrectAnswers] = useState(0);
    const [note, setNote] = useState(null);

    const [updateNote, setUpdateNote] = useState(null);

    const [completedBefore, setCompletedBefore] = useState(false);

    // const [defaultMenuState, setDefaultMenu] = useState([
    //     {
    //         header: 'Pengantar',
    //         content: [
    //             {key: 0, title: "Video Pengantar", type: CONTENT_TYPE.VIDEO},
    //             {key: 1, title: "Prolog", type: CONTENT_TYPE.PROLOGUE},
    //             {key: 2, title: "Tes Awal", type: CONTENT_TYPE.TEST},
    //         ]
    //     },
    //     {
    //         header: 'Karakteristik Media Sosial',
    //         content: [
    //             {key: 3, title: "Platform", type: CONTENT_TYPE.DOCUMENT},
    //             {key: 4, title: "Pengguna"},
    //             {key: 5, title: "Konten"},
    //             {key: 6, title: "Admin"},
    //             {key: 7, title: "Kuis", type: CONTENT_TYPE.TEST, done: true},
    //             {key: 8, title: "Latihan", type: CONTENT_TYPE.TEST}
    //         ]
    //     },
    //     {
    //         header: 'Pengajar',
    //         content: [
    //             {key: 9, title: "Profil", type: CONTENT_TYPE.TEACHERS_PROFILE},
    //         ]
    //     },
    //     {
    //         header: 'Penunjang Pembelajaran',
    //         content: [
    //             {key: 10, title: "Glosarium", type: CONTENT_TYPE.GLOSSARY},
    //         ]
    //     },
    //     {
    //         header: 'Penutup',
    //         content: [
    //             {key: 11, title: "Ulasan", type: CONTENT_TYPE.REVIEW},
    //         ]
    //     }
    // ]);

    const [defaultMenuState, setDefaultMenu] = useState([]);

    const [highestKey, setHighestKey] = useState(0);

    const [menu, setMenu] = useState(JSON.parse(JSON.stringify(defaultMenuState)));

    const [notes, setNotes] = useState([])

    useEffect(() => {

        if (menu.length > 0 && firstRender) {
            const menuTemp = JSON.parse(JSON.stringify(menu));

            menuTemp[0].content[0].selected = true;

            setSelectedModule(menuTemp[0].content[0]);

            setFirstRender(false)
            setMenu(menuTemp)

            // console.log('menuTemp[0].content[0]', menuTemp[0].content[0])
        }
    }, [menu, firstRender])

    useEffect(() => {

    }, [progress])

    useEffect(() => {
        if (GlobalData.token) {
            getData(true)
        } else {
            history.push(`/`)
        }

    }, [])

    useEffect(() => {
        console.log('selectedModule', selectedModule)

        // if (selectedModule.type === CONTENT_TYPE.TEST && selectedModule.progress) {
        //     let correctAnswers = 0;
        //
        //     JSON.parse(selectedModule.progress.additional_information).answers.forEach((userAnswer, idx) => {
        //         const quizContent = JSON.parse(selectedModule.content);
        //
        //         if (userAnswer === quizContent[idx].correct_answer) {
        //             correctAnswers++;
        //         }
        //     })
        //
        //     setQuizCorrectAnswers(correctAnswers)
        // }

        if ((selectedModule.type === CONTENT_TYPE.TEST || selectedModule.type === CONTENT_TYPE.EXERCISE) && !selectedModule.progress) {
            setRemainingDuration(selectedModule.duration)
        }

        const {id, progress, type} = selectedModule;

        if (selectedModule.type === CONTENT_TYPE.PROLOGUE) {
            getPrologueComments()
        } else if ((type === CONTENT_TYPE.TEXT || type === CONTENT_TYPE.GLOSSARY) && !progress) {
            completeCourseSubChapterById(id)
        } else if (type === CONTENT_TYPE.TEST || type === CONTENT_TYPE.EXERCISE || type === CONTENT_TYPE.ESSAY) {
            const answers = []

            JSON.parse(selectedModule.content).forEach((item, idx) => {
                answers[idx] = null;
            })

            setAnswers(answers)

            setUserSummaries(answers)
        }

        if (type === CONTENT_TYPE.ESSAY && progress) {
            const additionalInformation = JSON.parse(progress.additional_information);

            if (additionalInformation.userSummaries) {
                setUserSummaries(additionalInformation.userSummaries)
            }
        }

        // setDone(false)
    }, [selectedModule])

    useEffect(() => {
        if (!selectedModule.progress) {
            console.log('remainingDuration', remainingDuration)

            let interval;

            if (remainingDuration > 0) {
                interval = setInterval(() => {
                    setRemainingDuration(remainingDuration - 1)
                }, 1000)
            }

            if (remainingDuration === 0) {
                clearInterval(interval);
                submitQuiz();
            }

            return () => clearInterval(interval)
        }
    }, [selectedModule, remainingDuration])

    const submitRating = async () => {
        if (!rating) {
            alert('Harap isi penilaian kelas!')
        } else {
            const body = {
                rating,
                comment,
                member_course_id: course.memberCourse.id
            }

            const result = await courseRatingModel.create(body);

            getData()
            console.log(result)
        }

    }

    const getPrologueComments = async () => {
        try {
            const result = await memberCourseProgressModel.getAllByCourseChapterId(selectedModule.id);

            setPrologueComments(result)
        } catch (e) {
            console.log(e)
        }
    }

    const getMyNotes = async (memberCourseId) => {
        try {
            const notes = await new CourseNotes().getMyNotes(memberCourseId)

            setNotes(notes);
        } catch (e) {
            console.log(e)
        }
    }

    const getData = async (firstTime) => {
        try {
            let result;
            if (props.match.params.code) {
                const course = await new Course().getByCode(props.match.params.code)

                result = await new Course().getById(parseInt(course.id))
            } else {
                result = await new Course().getById(parseInt(props.match.params.id))
            }

            getMyNotes(result.memberCourse.id)

            if (result.memberCourse?.status !== 'ACCEPTED') {
                alert('Anda belum terdaftar pada kelas ini!')

                if (props.match.params.code) {
                    return history.push(`/kdm/detail/${props.match.params.code}`)
                }

                return history.push(`/kdm/${props.match.params.id}`)
            }

            console.log('result', result)

            setCourse(result);

            if (result.custom_chapter_sequence) {
                const customChapterSequence = JSON.parse(result.custom_chapter_sequence);

                const courseChapters = [];
                customChapterSequence.forEach(item => {
                    result.courseChapters.forEach(chapter => {
                        if (chapter.id === item) {
                            courseChapters.push(chapter);
                        }
                    })
                })

                result.courseChapters = courseChapters
            }

            result.courseChapters.forEach(item => {
                if (item.custom_sub_chapter_sequence) {
                    const customSubChapterSequence = JSON.parse(item.custom_sub_chapter_sequence);

                    const courseSubChapters = []

                    customSubChapterSequence.forEach(item2 => {
                        result.courseSubChapters[item.id].forEach(subChapter => {
                            if (subChapter.id === item2) {
                                courseSubChapters.push(subChapter);
                            }
                        })
                    })

                    result.courseSubChapters[item.id] = courseSubChapters
                }
            })

            let key = 0;

            const defaultMenuTemp = result.courseChapters.map(courseChapter => {
                const content = result.courseSubChapters[courseChapter.id].map(courseSubChapter => {
                    // if(key === selectedModuleTemp.key) {
                    //     setSelectedModule({
                    //         key: key,
                    //         title: courseSubChapter.name,
                    //         ...courseSubChapter
                    //     })
                    // }

                    return {
                        key: key++,
                        title: courseSubChapter.name,
                        ...courseSubChapter
                    }
                })

                return {
                    header: courseChapter.name,
                    content
                }
            })

            key = 0;

            let completedModule = 0
            let totalModule = 0

            const menuTemp = result.courseChapters.map(courseChapter => {
                const content = result.courseSubChapters[courseChapter.id].map(courseSubChapter => {
                    totalModule++;

                    if (courseSubChapter.progress) {
                        completedModule++;
                    }

                    if (key === selectedModule.key) {
                        setSelectedModule({
                            key: key,
                            title: courseSubChapter.name,
                            ...courseSubChapter
                        })
                    }

                    return {
                        selected: key === selectedModule.key,
                        key: key++,
                        title: courseSubChapter.name,
                        ...courseSubChapter
                    }
                })

                return {
                    header: courseChapter.name,
                    content
                }
            })

            const progress = Math.floor(completedModule / totalModule * 100);

            if (!firstTime && progress === 100) {
                setCourseFinishedModalVisible(true)
            }

            setProgress(progress)

            menuTemp.push(
                {
                    header: 'Ulasan',
                    content: [
                        {
                            key: key,
                            title: "Ulasan",
                            type: CONTENT_TYPE.REVIEW,
                            progress: {id: result.myRating ? 1 : null}
                        },
                    ]
                }
            )

            defaultMenuTemp.push({
                header: 'Ulasan',
                content: [
                    {
                        key: key++,
                        title: "Ulasan",
                        type: CONTENT_TYPE.REVIEW,
                        progress: {id: result.myRating ? 1 : null}
                    },
                ]
            })

            if (result.instructors.length > 0) {
                menuTemp.push({
                    header: 'Widyaiswara',
                    content: [
                        {key: key, title: "Profil", type: CONTENT_TYPE.TEACHERS_PROFILE}
                    ]
                })

                defaultMenuTemp.push({
                    header: 'Pengajar',
                    content: [
                        {key: key++, title: "Profil", type: CONTENT_TYPE.TEACHERS_PROFILE}
                    ]
                })
            }

            setHighestKey(key - 1)

            setMenu(menuTemp)


            setDefaultMenu(defaultMenuTemp)
        } catch (e) {
            console.log(e)

            if (e.http_status) {
                if (e.http_status === 404) {
                    history.push('/')
                }
            }
        }
    }

    const selectMenu = (key) => {
        menu.forEach((aMenu, aMenuIdx) => {
            {
                aMenu.content.map((aContent, aContentIdx) => {
                    if (key === aContent.key) {
                        if ((aContent.type === CONTENT_TYPE.TEST || aContent.type === CONTENT_TYPE.EXERCISE) && !aContent.progress) {
                            if (aContent.type === CONTENT_TYPE.TEST) {
                                setStartQuizModalVisible(true)
                            }

                            if (aContent.type === CONTENT_TYPE.EXERCISE) {
                                setStartExerciseModalVisible(true)
                            }

                            setOnStartQuizConfirmed(() => {
                                return () => {
                                    const menuTemp = JSON.parse(JSON.stringify(defaultMenuState));

                                    menuTemp[aMenuIdx].content[aContentIdx].selected = true;

                                    const selectedModule = menuTemp[aMenuIdx].content[aContentIdx];

                                    setSelectedModule(selectedModule);

                                    setMenu(menuTemp)

                                    setStartQuizModalVisible(false)
                                    setStartExerciseModalVisible(false)
                                }
                            })
                        } else {
                            const menuTemp = JSON.parse(JSON.stringify(defaultMenuState));

                            if (!menuTemp[aMenuIdx].content[aContentIdx].selected) {
                                menuTemp[aMenuIdx].content[aContentIdx].selected = true;

                                const {type, progress} = menuTemp[aMenuIdx].content[aContentIdx];

                                if ((type === CONTENT_TYPE.GLOSSARY || type === CONTENT_TYPE.TEXT)) {

                                }

                                setSelectedModule(menuTemp[aMenuIdx].content[aContentIdx]);

                                setMenu(menuTemp)
                            }
                        }
                    }
                })
            }
        })
    }

    const submitPrologueComment = async () => {
        if (!comment) return alert('Komentar tidak boleh kosong!')

        try {
            const result = await memberCourseProgressModel.create({
                course_sub_chapter_id: selectedModule.id,
                additional_information: JSON.stringify({comment})
            })

            alert('Komentar berhasil tersimpan!')

            getData();
            getPrologueComments();
        } catch (e) {
            console.log(e)
        }
    }

    const submitQuiz = async () => {
        let correctAnswers = 0;

        answers.forEach((userAnswer, idx) => {
            const quizContent = JSON.parse(selectedModule.content);

            if (userAnswer === quizContent[idx].correct_answer) {
                correctAnswers++;
            }
        })

        console.log('correctAnswer', correctAnswers)

        try {
            console.log('submitting quiz . . .')

            const result = await memberCourseProgressModel.create({
                course_sub_chapter_id: selectedModule.id,
                additional_information: JSON.stringify({
                    answers,
                    score: Math.round((correctAnswers / JSON.parse(selectedModule.content).length) * 100)
                })
            })

            setAnswers([])
            setUserSummaries([])
            setRemainingDuration(-1)

            getData();
        } catch (e) {
            console.log(e)
        }
    }

    const updateUserSummaries = async () => {
        try {
            const additionalInformation = JSON.parse(selectedModule.progress.additional_information);

            additionalInformation.userSummaries = userSummaries;

            console.log(selectedModule)

            const result = await memberCourseProgressModel.update(selectedModule.progress.id, {
                additional_information: JSON.stringify(additionalInformation),
                course_sub_chapter_id: selectedModule.progress.course_sub_chapter_id
            })

            getData();
        } catch (e) {
            console.log(e)
        }
    }

    const completeCourseSubChapter = async () => {
        try {
            console.log('create', {
                course_sub_chapter_id: selectedModule.id,
            })

            const result = await memberCourseProgressModel.create({
                course_sub_chapter_id: selectedModule.id,
            })

            getData();
        } catch (e) {
            console.log('e', e)
        }
    }

    const completeCourseSubChapterById = async (id) => {
        try {
            const result = await memberCourseProgressModel.create({
                course_sub_chapter_id: id,
            })

            console.log('result', result)
            getData();
        } catch (e) {
            console.log('e', e)
        }
    }

    function pad(n) {
        const width = 2;
        n = n + '';

        return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
    }

    const submitNote = async () => {
        if (!note) {
            alert('Catatan tidak boleh kosong!')
        } else {
            try {
                const result = await new CourseNotes().create({
                    member_course_id: course.memberCourse.id,
                    notes: note
                })

                setNote('')
                getMyNotes(course.memberCourse.id)
            } catch (e) {
                console.log(e)
            }
        }
    }

    const deleteNote = async (id) => {
        try {
            const result = await new CourseNotes().delete(id)

            getMyNotes(course.memberCourse.id)
        } catch (e) {
            console.log(e)
        }
    }

    function isHTML(str) {
        var a = document.createElement('div');
        a.innerHTML = str;

        for (var c = a.childNodes, i = c.length; i--; ) {
            if (c[i].nodeType == 1) return true;
        }

        return false;
    }

    return (
        <PageWrapper
            loginListener={(user) => {
                setUser(user)
            }}
            hideMargin
            hideHeader
            // hideFooter
        >
            <Modal
                size="md"
                centered
                show={isCourseFinishedModalVisible}
                onHide={() => {
                    setCourseFinishedModalVisible(false)
                }}
            >
                <Modal.Body>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        paddingTop: 40,
                        paddingLeft: 20,
                        paddingRight: 20
                    }}>
                        <div style={{
                            backgroundColor: '#4abf3d',
                            width: 110,
                            height: 110,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 100
                        }}>
                            <FaCheck size={55} color={'white'}/>
                        </div>

                        <div style={{fontFamily: 'OpenSans-SemiBold', fontSize: '1.5em', marginTop: 18}}>
                            Selamat!
                        </div>

                        <div style={{
                            fontFamily: 'OpenSans-Regular',
                            marginTop: 10,
                            color: '#666666',
                            textAlign: 'center'
                        }}>
                            Anda telah berhasil menyelesaikan kelas
                            ini. 
                            {
                                course && 
                                course.title && 
                                !course.title.includes("KNO") && 
                                course.memberCourse?.certificate_url && 
                                'Sertifikat penyelesaian kelas Anda sudah tersedia untuk diunduh.'
                            }
                        </div>

                        {
                            course && 
                            course.title && 
                            !course.title.includes("KNO") && 
                            course.memberCourse?.certificate_url &&
                            <a href={course.memberCourse?.certificate_url} download target={"_blank"}
                               onClick={() => setCourseFinishedModalVisible(false)}
                            >
                                <Button style={{
                                    fontFamily: 'OpenSans-Bold',
                                    fontSize: '.9em',
                                    width: '100%',
                                    marginTop: 30,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    borderRadius: 8
                                }}>
                                    Unduh Sertifikat
                                </Button>
                            </a>
                        }

                        {
                            course && 
                            course.title && 
                            !course.title.includes("KNO") && 
                            course.memberCourse?.certificate_url &&
                            <Button
                                onClick={() => setCourseFinishedModalVisible(false)}
                                variant={'link'}
                                style={{
                                    fontFamily: 'OpenSans-Bold',
                                    fontSize: '.9em',
                                    marginTop: 15,
                                    marginBottom: 10
                                }}>
                                Unduh Nanti
                            </Button>
                        }
                    </div>
                </Modal.Body>
            </Modal>

            <RatingModal visible={isRatingModalShown} onClose={() => setRatingModalShown(false)}/>

            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={isStartQuizModalVisible || isStartExerciseModalVisible}
                onHide={() => {
                    setStartQuizModalVisible(false)
                    setStartExerciseModalVisible(false)
                }}
            >
                <div style={{padding: 30}}>
                    <div style={{
                        color: '#2d2c3e',
                        fontFamily: 'OpenSans-Bold',
                        fontSize: 18,
                        display: 'flex',
                        flexDirection: 'center',
                        alignItems: 'center'
                    }}>
                        <RiErrorWarningLine color={'#ddb9a8'} size={30} style={{marginRight: 10}}/>
                        Konfirmasi Mulai {isStartQuizModalVisible ? 'Kuis' : 'Latihan'}
                    </div>

                    <div style={{color: '#abaaad', fontSize: 15, fontFamily: 'OpenSans-SemiBold', marginTop: 20}}>
                        Apakah Anda yakin untuk memulai {isStartQuizModalVisible ? 'kuis' : 'latihan'}? Dengan
                        memulai {isStartQuizModalVisible ? 'kuis' : 'latihan'}, waktu
                        pengerjaan {isStartQuizModalVisible ? 'kuis' : 'latihan'} akan
                        berjalan dan Anda tidak bisa pindah ke modul lain sampai waktu pengerjaan berakhir.
                    </div>

                    <div style={{marginTop: 40, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                        <Button
                            onClick={() => {
                                setStartQuizModalVisible(false)
                                setStartExerciseModalVisible(false)
                            }}
                            variant={'link'} style={{color: '#2d2c3e', fontFamily: 'OpenSans-Bold'}}>
                            Batal
                        </Button>

                        <Button
                            onClick={onStartQuizConfirmed}
                            variant={'success'} style={{fontFamily: 'OpenSans-Bold', width: 100, marginLeft: 20}}>
                            Mulai
                        </Button>
                    </div>
                </div>
            </Modal>

            <div
                style={{marginTop: 30}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 15}}>
                    <Link to={"/"}>
                        <img
                            style={{
                                width: 80,
                                objectFit: "contain",
                            }}
                            // src={require("../../asset/mainlogo.png")}
                            src={require("../../asset/new-narabahasa-logo.jpeg")}
                        />
                    </Link>


                    <div style={{flex: 1, marginLeft: 30}}>
                        <div style={{maxWidth: 700}}>
                            <div>
                                <div style={{display: 'flex', alignItems: 'center', marginBottom: 15}}>
                                    <a href={props.match.params.code ? `/kdm/detail/${props.match.params.code}` : `/kdm/${course.id}`}>
                                        <div style={{
                                            fontFamily: 'OpenSans-SemiBold',
                                            fontSize: '1.1em',
                                            marginRight: 20,
                                            color: 'black'
                                        }}>
                                            {course.title}
                                        </div>
                                    </a>

                                    {
                                        course && 
                                        course.title && 
                                        !course.title.includes("KNO") && 
                                        course.memberCourse?.certificate_url &&
                                        <a href={course.memberCourse?.certificate_url} download target={"_blank"}>
                                            <Button
                                                variant={'outline-primary'}
                                                style={{fontFamily: 'OpenSans-Bold', fontSize: '.7em'}}
                                                onClick={() => {

                                                }}>
                                                Unduh Sertifikat
                                            </Button>
                                        </a>
                                    }
                                </div>

                                <div style={{display: 'flex', flexDirection: 'row', marginBottom: 6}}>
                                    <div style={{flex: 1, fontFamily: 'OpenSans-SemiBold', fontSize: '.9em'}}>
                                        KEMAJUAN KELAS
                                    </div>

                                    <div style={{fontFamily: 'OpenSans-SemiBold', fontSize: '.9em'}}>
                                        {progress}%
                                    </div>
                                </div>
                                <ProgressBar bgcolor={Palette.PRIMARY} completed={progress}/>
                            </div>
                        </div>


                    </div>

                    <div style={{
                        flexDirection: "row-reverse",
                        alignItems: "center",
                        marginRight: 20,
                        marginLeft: 20
                    }}
                    >

                        <Dropdown
                            alignRight>
                            <Dropdown.Toggle
                                onClick={() => setUserMenuModalShown(true)}
                                variant={"light"}
                                id="dropdown-custom-1">
                            <span style={{
                                marginLeft: '10px',
                                color: Palette.PRIMARY,
                                fontWeight: 'bold',
                                fontSize: '0.8em'
                            }}>
                            {user ? user.full_name : null}
                            </span>
                            </Dropdown.Toggle>
                        </Dropdown>
                    </div>
                </div>

                <div style={{height: 1, backgroundColor: '#dbdbdb', marginTop: 20, marginBottom: 25}}/>

                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <AiOutlineMenu
                        onClick={() => setSidebarExpanded(!isSidebarExpanded)}
                        color={Palette.PRIMARY} style={{cursor: 'pointer', marginLeft: 20}} size={20}/>
                    <div style={{fontFamily: 'OpenSans-SemiBold', marginLeft: 25, fontSize: '1.1em', flex: 1, marginBottom: isHTML(selectedModule.title) ? -15 : 0}}>
                        {selectedModule && isHTML(selectedModule.title) ? new Parser().parse(selectedModule.title) : selectedModule && !isHTML(selectedModule.title) ? selectedModule.title : null}
                    </div>


                    {
                        selectedModule && (selectedModule.type === CONTENT_TYPE.TEST || selectedModule.type === CONTENT_TYPE.ESSAY || selectedModule.type === CONTENT_TYPE.EXERCISE) ?
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                marginLeft: 30,
                                marginRight: 15
                            }}>
                                {!selectedModule.progress && remainingDuration !== -1 &&
                                <div style={{fontFamily: 'OpenSans-SemiBold', marginBottom: 4}}>
                                    {pad(Math.floor(remainingDuration / 60))}:{pad(remainingDuration % 60)}
                                </div>
                                }

                                {
                                    selectedModule &&
                                    <div
                                        onClick={() => {
                                            if (!selectedModule.progress) {
                                                submitQuiz()
                                            }
                                            // else if ((selectedModule.type === CONTENT_TYPE.ESSAY || selectedModule.type === CONTENT_TYPE.EXERCISE) && selectedModule.progress) {
                                            //     updateUserSummaries();
                                            // }
                                        }}
                                        style={{
                                            display: selectedModule.type === CONTENT_TYPE.EXERCISE && selectedModule.progress ? 'none' : 'block',
                                            backgroundColor: Palette.SECONDARY,
                                            color: 'white',
                                            padding: 18,
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            borderRadius: 3,
                                            cursor: 'pointer',
                                            userSelect: 'none',
                                            fontFamily: 'OpenSans-SemiBold'
                                        }}>
                                        {selectedModule.progress && selectedModule.type !== CONTENT_TYPE.ESSAY ? `Nilai Anda: ${JSON.parse(selectedModule.progress?.additional_information).score}` : (selectedModule.type === CONTENT_TYPE.EXERCISE && !selectedModule.progress) ? 'Selesaikan Latihan' : selectedModule.type === CONTENT_TYPE.ESSAY && !selectedModule.progress ? 'Selesaikan Uraian' : 'Selesaikan Kuis'}
                                    </div>
                                }
                            </div> :
                            null
                    }

                    <RiStickyNoteLine
                        onClick={() => setNoteExpanded(!isNoteExpanded)}
                        color={Palette.PRIMARY} style={{cursor: 'pointer', marginLeft: 20, marginRight: 20}}
                        size={20}/>
                </div>
                <div style={{height: 1, backgroundColor: '#dbdbdb', marginTop: 20}}/>
                <Row>

                    <Col
                        xs={isSidebarExpanded ? undefined : 0}
                        style={{
                            maxWidth: isSidebarExpanded ? 300 : 0,
                        }}
                    >
                        <Scrollbars style={{
                            maxWidth: isSidebarExpanded ? 300 : 0,
                            border: '1px solid #dbdbdb',
                            borderTopWidth: 0,
                            borderBottomWidth: 0,
                            flexDirection: 'column',
                            transition: 'max-width .2s', height: 'calc(100vh - 190px)'
                        }}>
                            {
                                menu.map((aMenu, aMenuIdx) => {
                                    return (
                                        <>
                                            <div style={{
                                                fontFamily: 'OpenSans-SemiBold',
                                                fontSize: '1.1em',
                                                marginLeft: 20,
                                                paddingBottom: 20,
                                                marginTop: 30
                                            }}>
                                                {aMenu.header}
                                            </div>

                                            {
                                                aMenu.content.map((aContent, aContentIdx) => {
                                                    return (
                                                        <>
                                                            <div
                                                                onClick={() => {
                                                                    const menuTemp = JSON.parse(JSON.stringify(defaultMenuState));

                                                                    menuTemp[aMenuIdx].content[aContentIdx].selected = true;

                                                                    const selectedModule = menuTemp[aMenuIdx].content[aContentIdx];

                                                                    if (selectedModule.progress || selectedModule.type === CONTENT_TYPE.REVIEW || selectedModule.type === CONTENT_TYPE.GLOSSARY || selectedModule.type === CONTENT_TYPE.TEACHERS_PROFILE) {
                                                                        setSelectedModule(selectedModule);

                                                                        setMenu(menuTemp)
                                                                    }
                                                                }}
                                                                style={{
                                                                    marginLeft: 20,
                                                                    marginBottom: 10,
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center',
                                                                    // cursor: 'pointer',
                                                                    userSelect: 'none'
                                                                }}>

                                                                <div style={{
                                                                    fontFamily: 'OpenSans-Regular',
                                                                    fontSize: '1.1em',
                                                                    flex: 1,
                                                                    backgroundColor: aContent.selected ? '#f2f2f2' : 'white',
                                                                    display: 'flex',
                                                                    alignItems: 'center'
                                                                }}>
                                                                    {aContent.type === CONTENT_TYPE.VIDEO ?
                                                                        <MdOndemandVideo/> : aContent.type === CONTENT_TYPE.DOCUMENT || aContent.type === CONTENT_TYPE.GLOSSARY || aContent.type === CONTENT_TYPE.TEXT ?
                                                                            <RiFileListLine/> : aContent.type === CONTENT_TYPE.TEST || aContent.type === CONTENT_TYPE.EXERCISE || aContent.type === CONTENT_TYPE.ESSAY ?
                                                                                <RiFileEditLine/> : aContent.type === CONTENT_TYPE.TEACHERS_PROFILE ?
                                                                                    <BsFillPeopleFill/> : aContent.type === CONTENT_TYPE.REVIEW ?
                                                                                        <MdRateReview/> : aContent.type === CONTENT_TYPE.PROLOGUE ?
                                                                                            <MdHome/> :
                                                                                            <MdVideoLibrary
                                                                                                style={{opacity: 0}}/>}
                                                                    <div style={{marginLeft: 10, flex: 1, marginBottom: isHTML(aContent.title) ? -15 : 0}}>
                                                                        {isHTML(aContent.title) ? new Parser().parse(aContent.title) : aContent.title}
                                                                    </div>

                                                                    {
                                                                        aContent.progress?.id &&
                                                                        <div style={{
                                                                            marginRight: 15,
                                                                            marginBottom: 5,
                                                                            color: Palette.SECONDARY
                                                                        }}>
                                                                            <AiFillCheckCircle/>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </>
                                    )
                                })
                            }

                            <div style={{backgroundColor: 'red', marginTop: 20, opacity: 0}}>
                                a
                            </div>
                        </Scrollbars>
                    </Col>

                    <Col style={{paddingRight: 20, marginLeft: 20}}>
                        <Scrollbars style={{
                            // marginLeft: 20, paddingTop: 30, paddingRight: 20,
                            marginTop: 30,
                            // border: '1px solid #dbdbdb',
                            borderTopWidth: 0,
                            borderBottomWidth: 0,
                            flexDirection: 'column',
                            transition: 'max-width .2s',
                            height: 'calc(100vh - 220px)'
                        }}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                marginBottom: 30
                            }}>
                                <div
                                    onClick={() => {
                                        if (selectedModule.key === 0 || ((selectedModule.type === CONTENT_TYPE.TEST || selectedModule.type === CONTENT_TYPE.EXERCISE) && !selectedModule.progress)) {

                                        } else {
                                            selectMenu(selectedModule.key - 1)
                                        }
                                    }}
                                    style={{
                                        fontFamily: 'OpenSans-Regular',
                                        color: selectedModule.key === 0 || ((selectedModule.type === CONTENT_TYPE.TEST || selectedModule.type === CONTENT_TYPE.EXERCISE) && !selectedModule.progress) ? '#cbcac6' : '#535353',
                                        backgroundColor: selectedModule.key === 0 || ((selectedModule.type === CONTENT_TYPE.TEST || selectedModule.type === CONTENT_TYPE.EXERCISE) && !selectedModule.progress) ? '#f0efed' : '#f7f7f7',
                                        height: 50,
                                        display: 'flex',
                                        paddingLeft: 15,
                                        paddingRight: 10,
                                        alignItems: "center",
                                        userSelect: 'none',
                                        borderTopLeftRadius: 5,
                                        borderBottomLeftRadius: 5,
                                        border: `1px solid ${selectedModule.key === 0 || ((selectedModule.type === CONTENT_TYPE.TEST || selectedModule.type === CONTENT_TYPE.EXERCISE) && !selectedModule.progress) ? '#e3e2e0' : '#cecdcb'}`
                                    }}>
                                    <FiArrowLeft style={{marginRight: 5}}/>
                                    Sebelumnya
                                </div>

                                <div
                                    onClick={() => {
                                        if (selectedModule.key === highestKey || ((selectedModule.type === CONTENT_TYPE.TEST || selectedModule.type === CONTENT_TYPE.EXERCISE) && !selectedModule.progress)) {

                                        } else if (selectedModule.progress) {
                                            selectMenu(selectedModule.key + 1)
                                        }
                                    }}
                                    style={{
                                        fontFamily: 'OpenSans-Regular',
                                        color: selectedModule.key === highestKey || ((selectedModule.type === CONTENT_TYPE.TEST || selectedModule.type === CONTENT_TYPE.EXERCISE) && !selectedModule.progress) || !selectedModule.progress ? '#cbcac6' : '#535353',
                                        backgroundColor: selectedModule.key === highestKey || ((selectedModule.type === CONTENT_TYPE.TEST || selectedModule.type === CONTENT_TYPE.EXERCISE) && !selectedModule.progress) || !selectedModule.progress ? '#f0efed' : '#f7f7f7',
                                        display: 'flex',
                                        paddingLeft: 10,
                                        paddingRight: 15,
                                        alignItems: 'center',
                                        userSelect: 'none',
                                        borderTopRightRadius: 5,
                                        borderBottomRightRadius: 5,
                                        border: `1px solid ${selectedModule.key === highestKey || ((selectedModule.type === CONTENT_TYPE.TEST || selectedModule.type === CONTENT_TYPE.EXERCISE) && !selectedModule.progress) || !selectedModule.progress ? '#e3e2e0' : '#cecdcb'}`
                                    }}>
                                    Selanjutnya
                                    <FiArrowRight style={{marginLeft: 5}}/>
                                </div>
                            </div>

                            {selectedModule &&
                            <div style={{paddingBottom: 50}}>
                                {
                                    selectedModule.type === CONTENT_TYPE.VIDEO ?
                                        <Container>

                                            <div
                                                onContextMenu={(e) => {
                                                    e.preventDefault()
                                                }}>
                                                <ReactPlayer
                                                    onPlay={() => {
                                                        console.log('selectedModule', selectedModule)

                                                        if (!selectedModule.progress?.id) {
                                                            completeCourseSubChapter()
                                                        }
                                                    }}
                                                    disablePictureInPicture
                                                    url={JSON.parse(selectedModule.content).url} controls={true}
                                                    height={400}
                                                    config={{
                                                        file: {
                                                            attributes: {
                                                                controlsList: "nodownload",
                                                                disablePictureInPicture: true
                                                            },
                                                            disablePictureInPicture: false,
                                                            pip: false
                                                        }
                                                    }}
                                                    width={'100%'}/>
                                            </div>

                                            {JSON.parse(selectedModule.content).document ?
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'flex-end',
                                                    flexDirection: 'column',
                                                    marginTop: 30
                                                }}>
                                                    <a href={JSON.parse(selectedModule.content).document}
                                                       style={{textDecorationLine: 'none'}}
                                                       target={'blank'}>
                                                        <div style={{
                                                            backgroundColor: Palette.SECONDARY,
                                                            color: 'white',
                                                            paddingLeft: 20,
                                                            paddingRight: 20,
                                                            paddingTop: 8,
                                                            paddingBottom: 8,
                                                            fontFamily: 'OpenSans-SemiBold',
                                                            cursor: 'pointer',
                                                            userSelect: 'none',
                                                            fontSize: '.95em',
                                                            borderRadius: 5
                                                        }}>
                                                            UNDUH DOKUMEN
                                                        </div>
                                                    </a>
                                                </div> : null
                                            }

                                            {/*<video controls width={"100%"} height="400">*/}
                                            {/*    <source*/}
                                            {/*        src={JSON.parse(selectedModule.content).url}*/}
                                            {/*        type='video/mp4'/>*/}
                                            {/*</video>*/}

                                            {/*<YouTube videoId="fRVr8e_5yFs" opts={{width: '100%', height: 400}}/>*/}

                                            {/*<div style={{*/}
                                            {/*    marginTop: 30,*/}
                                            {/*    fontFamily: 'OpenSans-Regular',*/}
                                            {/*    fontSize: '.9em'*/}
                                            {/*}}>*/}
                                            {/*    Anda juga dapat membaca modul pelatihan dalam bentuk <span*/}
                                            {/*    style={{fontStyle: 'italic'}}>e-book</span> melalui tautan di bawah ini.*/}
                                            {/*</div>*/}

                                            {/*<div style={{*/}
                                            {/*    marginTop: 10,*/}
                                            {/*    fontFamily: 'OpenSans-Regular',*/}
                                            {/*    fontSize: '.9em'*/}
                                            {/*}}>*/}
                                            {/*    Klik: <a href={'#'} style={{fontFamily: 'OpenSans-SemiBold'}}>Humas di*/}
                                            {/*    Era*/}
                                            {/*    Digital</a>*/}
                                            {/*</div>*/}
                                        </Container> :
                                        selectedModule.type === CONTENT_TYPE.PROLOGUE ?
                                            <Container style={{paddingBottom: 50}}>
                                                <Row>
                                                    <Col style={{fontFamily: 'OpenSans-SemiBold', fontSize: '1.1em'}}>
                                                        Kami ingin tahu pendapat Anda
                                                    </Col>
                                                </Row>

                                                <Row style={{paddingLeft: 15, paddingRight: 15, marginTop: 20}}>
                                                    <Col
                                                        style={{height: 1, width: '100%', backgroundColor: '#dbdbdb'}}/>
                                                </Row>

                                                <Row style={{
                                                    paddingLeft: 15,
                                                    paddingRight: 15,
                                                    marginTop: 10,
                                                    marginBottom: 10
                                                }}>
                                                    <Col style={{
                                                        backgroundColor: '#fcfcfc',
                                                        border: '1px solid #dbdbdb',
                                                        borderLeftColor: Palette.PRIMARY,
                                                        borderLeftWidth: 2,
                                                        padding: 30
                                                    }}>
                                                        <div style={{
                                                            color: '#484848',
                                                            fontFamily: 'OpenSans-Regular',
                                                            fontSize: '1.1em'
                                                        }}>
                                                            {new Parser().parse(JSON.parse(selectedModule.content).question)}
                                                        </div>
                                                    </Col>
                                                </Row>

                                                {!selectedModule.progress?.id &&
                                                <Row>
                                                    <Col style={{fontFamily: 'OpenSans-Regular', fontSize: '1.1em'}}>
                                                        Tuliskan pendapat Anda di kolom komentar!
                                                    </Col>
                                                </Row>
                                                }

                                                <Row>
                                                    <Col style={{fontFamily: 'OpenSans-SemiBold', marginTop: 50}}>
                                                        KOMENTAR ({prologueComments.length})
                                                    </Col>
                                                </Row>

                                                <Row style={{
                                                    paddingLeft: 15,
                                                    paddingRight: 15,
                                                    marginTop: 10,
                                                    marginBottom: 20
                                                }}>
                                                    <Col
                                                        style={{
                                                            height: 1,
                                                            width: '100%',
                                                            backgroundColor: '#dbdbdb'
                                                        }}/>
                                                </Row>

                                                {!selectedModule.progress?.id &&
                                                <>
                                                    <Row>
                                                        <Col style={{fontFamily: 'OpenSans-SemiBold'}}>
                                                            <Form.Control
                                                                as={'textarea'}
                                                                value={comment}
                                                                onChange={(e) => setComment(e.target.value)}
                                                                style={{
                                                                    fontSize: "1em",
                                                                    fontFamily: 'OpenSans-Regular',
                                                                    paddingTop: 10,
                                                                    paddingBottom: 20,
                                                                }} type="text"
                                                                placeholder="Tulis Komentar"/>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col style={{
                                                            display: 'flex',
                                                            justifyContent: 'flex-end',
                                                            marginTop: 10,
                                                            marginBottom: 20
                                                        }}>
                                                            <Button
                                                                onClick={submitPrologueComment}
                                                                style={{
                                                                    width: 100,
                                                                    height: 40,
                                                                    fontFamily: 'OpenSans-SemiBold'
                                                                }}>
                                                                Kirim
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </>
                                                }

                                                {
                                                    prologueComments.map(prologueComment => {
                                                        return (
                                                            <Row>
                                                                <Col>
                                                                    <div style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        marginBottom: 30
                                                                    }}>
                                                                        <div style={{
                                                                            flex: 1,
                                                                        }}>
                                                                            <div style={{display: 'flex'}}>
                                                                                <img
                                                                                    style={{
                                                                                        width: "2.5em",
                                                                                        objectFit: "contain",
                                                                                        borderRadius: "50%",
                                                                                        height: "2.5em",
                                                                                        padding: 1,
                                                                                        backgroundColor: Palette.SECONDARY
                                                                                    }}
                                                                                    // src={}/>
                                                                                    src={prologueComment.member?.image_url ? prologueComment.member?.image_url : require('../../asset/public_image_default_user.png')}/>

                                                                                <div style={{
                                                                                    flex: 1,
                                                                                    display: 'flex',
                                                                                    paddingLeft: 15,
                                                                                    flexDirection: 'column',
                                                                                }}>
                                                                                    <div style={{
                                                                                        display: 'flex',
                                                                                        flexDirection: 'row',
                                                                                        alignItems: 'center'
                                                                                    }}>
                                                                                        <div style={{
                                                                                            fontFamily: 'OpenSans-Bold',
                                                                                            fontSize: '1.15em'
                                                                                        }}>
                                                                                            {prologueComment.member?.full_name}
                                                                                        </div>

                                                                                        {prologueComment.member?.id === user?.id &&
                                                                                        <div style={{
                                                                                            fontFamily: 'OpenSans-Regular',
                                                                                            fontSize: 12,
                                                                                            marginLeft: 7,
                                                                                            backgroundColor: '#fcfcfc',
                                                                                            border: '1px solid #dbdbdb',
                                                                                            paddingLeft: 5,
                                                                                            paddingRight: 5
                                                                                        }}>
                                                                                            KOMENTAR ANDA
                                                                                        </div>
                                                                                        }
                                                                                    </div>

                                                                                    <div style={{
                                                                                        marginLeft: 2,
                                                                                        color: '#5F5555A6',
                                                                                        fontFamily: 'OpenSans-Regular',
                                                                                        marginTop: 10,
                                                                                        maxLines: 5,
                                                                                        lineHeight: "1.5em",
                                                                                        fontSize: '1.05em',
                                                                                        whiteSpace: 'pre-line'
                                                                                    }}>
                                                                                        {JSON.parse(prologueComment.additional_information).comment}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    })
                                                }
                                            </Container> :
                                            selectedModule.type === CONTENT_TYPE.DOCUMENT ?
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    flexDirection: 'column'
                                                }}>
                                                    <RiFileListLine color={Palette.SECONDARY} size={45}
                                                                    style={{marginBottom: 30}}/>

                                                    <a href={JSON.parse(selectedModule.content).url}
                                                       onClick={() => {
                                                           if (!selectedModule.progress?.id) {
                                                               completeCourseSubChapter()
                                                           }
                                                       }}
                                                       style={{textDecorationLine: 'none'}}
                                                       target={'blank'}>
                                                        <div style={{
                                                            backgroundColor: Palette.SECONDARY,
                                                            color: 'white',
                                                            paddingLeft: 20,
                                                            paddingRight: 20,
                                                            paddingTop: 8,
                                                            paddingBottom: 8,
                                                            fontFamily: 'OpenSans-SemiBold',
                                                            cursor: 'pointer',
                                                            userSelect: 'none',
                                                            fontSize: '.95em',
                                                            borderRadius: 5
                                                        }}>
                                                            UNDUH DOKUMEN
                                                        </div>
                                                    </a>
                                                </div> :
                                                selectedModule.type === CONTENT_TYPE.TEST || selectedModule.type === CONTENT_TYPE.EXERCISE ?
                                                    <Container>
                                                        {
                                                            JSON.parse(selectedModule.content).map((quiz, key) => {
                                                                let userAnswer = null;

                                                                if (selectedModule.progress) userAnswer = JSON.parse(selectedModule.progress?.additional_information).answers[key];

                                                                const correctAnswer = quiz.correct_answer;

                                                                return (
                                                                    <>
                                                                        <Row>
                                                                            <Col style={{
                                                                                display: 'flex',
                                                                                flexDirection: 'row'
                                                                            }}>
                                                                                <div style={{marginRight: 10}}>
                                                                                    {key + 1}.
                                                                                </div>
                                                                                <div style={{
                                                                                    display : "flex",
                                                                                    flexDirection: 'column'
                                                                                }}>
                                                                                    {new Parser().parse(quiz.question)}
                                                                                </div>
                                                                            </Col>
                                                                        </Row>

                                                                        <Row style={{marginTop: 30, marginBottom: 10}}>
                                                                            {
                                                                                quiz.choices && quiz.choices.length > 0 ?
                                                                                    quiz.choices.map((choice, idx) => {
                                                                                        return (
                                                                                            <Col xs={12}
                                                                                                 onClick={() => {
                                                                                                     if (!selectedModule.progress) {
                                                                                                         const answersTemp = [...answers];

                                                                                                         answersTemp[key] = choice;

                                                                                                         setAnswers(answersTemp)
                                                                                                     }
                                                                                                 }}
                                                                                                 style={{
                                                                                                     display: 'flex',
                                                                                                     marginBottom: 20,
                                                                                                     cursor: 'pointer',
                                                                                                     userSelect: 'none'
                                                                                                 }}>
                                                                                                <div style={{
                                                                                                    fontFamily: 'OpenSans-SemiBold',
                                                                                                    width: 30,
                                                                                                    height: 30,
                                                                                                    borderRadius: 30,
                                                                                                    display: 'flex',
                                                                                                    alignItems: 'center',
                                                                                                    color: answers[key] === choice || (selectedModule.progress && JSON.parse(selectedModule.progress.additional_information).answers[key] === choice) ? 'white' : Palette.PRIMARY,
                                                                                                    backgroundColor: answers[key] === choice || (selectedModule.progress && JSON.parse(selectedModule.progress?.additional_information).answers[key] === choice) ? Palette.PRIMARY : 'white',
                                                                                                    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 8px',
                                                                                                    justifyContent: 'center',
                                                                                                }}>
                                                                                                    {String.fromCharCode(97 + idx).toUpperCase()}
                                                                                                </div>

                                                                                                <div style={{
                                                                                                    fontFamily: 'OpenSans-Regular',
                                                                                                    marginLeft: 15,
                                                                                                    color: selectedModule.progress && correctAnswer === choice ? 'rgb(37, 211, 102)' : selectedModule.progress && choice === userAnswer && correctAnswer !== choice ? 'rgb(210, 5, 35)' : 'black',
                                                                                                    display: 'flex',
                                                                                                    flexDirection: 'column',
                                                                                                    marginBottom: isHTML(choice) ? -15 : 0
                                                                                                }}>
                                                                                                    {new Parser().parse(choice)} <div style={{marginLeft: 5}}>{selectedModule.progress && correctAnswer === choice && '(Jawaban Benar)'} {selectedModule.progress && choice === userAnswer && correctAnswer !== choice && '(Jawaban Anda)'}</div>
                                                                                                </div>
                                                                                            </Col>
                                                                                        )
                                                                                    }) :
                                                                                    <Form.Control as="textarea" rows="5"
                                                                                                  disabled={selectedModule.progress}
                                                                                                  placeholder={'Jawaban Anda'}
                                                                                                  value={selectedModule.progress ? JSON.parse(selectedModule.progress?.additional_information).answers[key] : answers[key]}
                                                                                                  onChange={(e) => {
                                                                                                      const answersTemp = [...answers];

                                                                                                      answersTemp[key] = e.target.value;

                                                                                                      setAnswers(answersTemp)
                                                                                                  }}
                                                                                                  style={{
                                                                                                      resize: 'none',
                                                                                                      paddingLeft: 20,
                                                                                                      paddingTop: 15,
                                                                                                      fontFamily: 'OpenSans-Regular'
                                                                                                  }}/>
                                                                            }
                                                                        </Row>

                                                                        {selectedModule.progress &&
                                                                        <Row style={{marginBottom: 50}}>
                                                                            <Col xs={12} style={{
                                                                                fontFamily: 'OpenSans-Bold',
                                                                                fontSize: '.9em'
                                                                            }}>
                                                                                {selectedModule.type === CONTENT_TYPE.EXERCISE ? 'Catatan Nara' : selectedModule.type === CONTENT_TYPE.TEST ? 'Jawaban ala Nara' : 'Penjelasan'}
                                                                            </Col>

                                                                            <Col xs={12}>
                                                                                {quiz.explanation ? new Parser().parse(quiz.explanation) : '-'}
                                                                            </Col>
                                                                        </Row>
                                                                        }
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </Container> :
                                                    // selectedModule.type === CONTENT_TYPE.ESSAY || selectedModule.type === CONTENT_TYPE.EXERCISE ?
                                                    //     <Container>
                                                    //         {
                                                    //             JSON.parse(selectedModule.content).map((quiz, key) => {
                                                    //                 return (
                                                    //                     <>
                                                    //                         <Row>
                                                    //                             <Col
                                                    //                                 style={{fontFamily: 'OpenSans-Regular'}}>
                                                    //                                 {key + 1}. {quiz.question}
                                                    //                             </Col>
                                                    //                         </Row>
                                                    //
                                                    //                         <Row style={{
                                                    //                             marginTop: 30,
                                                    //                             marginBottom: 10
                                                    //                         }}>
                                                    //                             <Form.Control as="textarea" rows="5"
                                                    //                                           disabled={selectedModule.progress}
                                                    //                                           placeholder={'Jawaban Anda'}
                                                    //                                           value={selectedModule.progress ? JSON.parse(selectedModule.progress?.additional_information).answers[key] : answers[key]}
                                                    //                                           onChange={(e) => {
                                                    //                                               const answersTemp = [...answers];
                                                    //
                                                    //                                               answersTemp[key] = e.target.value;
                                                    //
                                                    //                                               setAnswers(answersTemp)
                                                    //                                           }}
                                                    //                                           style={{
                                                    //                                               resize: 'none',
                                                    //                                               paddingLeft: 20,
                                                    //                                               paddingTop: 15,
                                                    //                                               fontFamily: 'OpenSans-Regular'
                                                    //                                           }}/>
                                                    //                         </Row>
                                                    //
                                                    //                         {selectedModule.progress &&
                                                    //                         <>
                                                    //                             <Row style={{marginBottom: 30}}>
                                                    //                                 <Col xs={12} style={{
                                                    //                                     fontFamily: 'OpenSans-Bold',
                                                    //                                     fontSize: '.9em'
                                                    //                                 }}>
                                                    //                                     {selectedModule.type === CONTENT_TYPE.EXERCISE ? 'Catatan Nara' : selectedModule.type === CONTENT_TYPE.TEST ? 'Jawaban ala Nara' : 'Penjelasan'}:
                                                    //                                 </Col>
                                                    //
                                                    //                                 <Col xs={12}>
                                                    //                                     {quiz.explanation ? quiz.explanation : '-'}
                                                    //                                 </Col>
                                                    //                             </Row>
                                                    //
                                                    //                             {/*<Row style={{marginBottom: 50}}>*/}
                                                    //                             {/*    <Col xs={12} style={{*/}
                                                    //                             {/*        fontFamily: 'OpenSans-Bold',*/}
                                                    //                             {/*        fontSize: '.9em'*/}
                                                    //                             {/*    }}>*/}
                                                    //                             {/*        Catatan Pengguna:*/}
                                                    //                             {/*    </Col>*/}
                                                    //
                                                    //                             {/*    <Col xs={12}>*/}
                                                    //                             {/*        <Form.Control as="textarea"*/}
                                                    //                             {/*                      rows="5"*/}
                                                    //                             {/*                      placeholder={'Catatan Pengguna'}*/}
                                                    //                             {/*                      value={userSummaries[key]}*/}
                                                    //                             {/*                      onChange={(e) => {*/}
                                                    //                             {/*                          const userSummariesTemp = [...userSummaries];*/}
                                                    //
                                                    //                             {/*                          userSummariesTemp[key] = e.target.value;*/}
                                                    //
                                                    //                             {/*                          setUserSummaries(userSummariesTemp)*/}
                                                    //                             {/*                      }}*/}
                                                    //                             {/*                      style={{*/}
                                                    //                             {/*                          resize: 'none',*/}
                                                    //                             {/*                          paddingLeft: 20,*/}
                                                    //                             {/*                          paddingTop: 15,*/}
                                                    //                             {/*                          fontFamily: 'OpenSans-Regular'*/}
                                                    //                             {/*                      }}/>*/}
                                                    //                             {/*    </Col>*/}
                                                    //                             {/*</Row>*/}
                                                    //                         </>
                                                    //                         }
                                                    //                     </>
                                                    //                 )
                                                    //             })
                                                    //         }
                                                    //     </Container> :
                                                    selectedModule.type === CONTENT_TYPE.TEACHERS_PROFILE ?
                                                        <div>
                                                            {
                                                                course.instructors.map(instructor => {
                                                                    return (
                                                                        <div>
                                                                            <img
                                                                                src={instructor.instructor_image_url}
                                                                                style={{
                                                                                    width: 270,
                                                                                    height: 270,
                                                                                    objectFit: 'cover',
                                                                                    borderRadius: 5
                                                                                }}/>

                                                                            <div style={{
                                                                                fontFamily: 'OpenSans-SemiBold',
                                                                                marginTop: 30,
                                                                                fontSize: '1.3em',
                                                                            }}>
                                                                                {instructor.name}
                                                                            </div>

                                                                            <div style={{
                                                                                fontFamily: 'OpenSans-Regular',
                                                                                fontSize: '.9em',
                                                                                marginBottom: 20,
                                                                            }}>
                                                                                {instructor.position}
                                                                            </div>

                                                                            <div style={{
                                                                                fontFamily: 'OpenSans-Regular',
                                                                                marginBottom: 50
                                                                            }}>
                                                                                {new Parser().parse(instructor.description)}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div> :
                                                        selectedModule.type === CONTENT_TYPE.GLOSSARY ?
                                                            <div style={{paddingRight : 20}}>
                                                                {
                                                                    JSON.parse(selectedModule.content).map(content => {
                                                                        return (
                                                                            <>
                                                                                <div
                                                                                    style={{
                                                                                        fontFamily: 'OpenSans-Bold',
                                                                                        marginBottom: 20
                                                                                    }}>
                                                                                    {new Parser().parse(content.word)}
                                                                                </div>

                                                                                <div style={{
                                                                                    fontFamily: 'OpenSans-Regular',
                                                                                    marginBottom: 25
                                                                                }}>
                                                                                    {new Parser().parse(content.definition)}
                                                                                </div>

                                                                                <hr/>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </div> :
                                                            selectedModule.type === CONTENT_TYPE.REVIEW ?
                                                                <div style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    width: '100%'
                                                                }}>
                                                                    <div style={{
                                                                        boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                                                                        padding: 20,
                                                                        borderRadius: 5,
                                                                        width: '100%',
                                                                        maxWidth: 400
                                                                    }}>
                                                                        <div style={{
                                                                            fontFamily: 'OpenSans-Bold',
                                                                            textAlign: 'center',
                                                                            marginTop: 15,
                                                                            fontSize: '1.25em',
                                                                            color: '#3f434f',
                                                                        }}>
                                                                            Beri Penilaian Kelas
                                                                        </div>

                                                                        <div style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            marginTop: 30
                                                                        }}>
                                                                            <Rating
                                                                                initialRating={course.myRating ? course.myRating.rating : rating}
                                                                                readonly={course.myRating}
                                                                                onChange={(rating) => {
                                                                                    setRating(rating)

                                                                                    switch (rating) {
                                                                                        case 1:
                                                                                            // setDisplayedPredicate('Sangat Buruk');
                                                                                            break;

                                                                                        case 2:
                                                                                            // setDisplayedPredicate('Buruk');
                                                                                            break;

                                                                                        case 3:
                                                                                            // setDisplayedPredicate('Biasa Saja');
                                                                                            break;

                                                                                        case 4:
                                                                                            // setDisplayedPredicate('Baik');
                                                                                            break;

                                                                                        case 5:
                                                                                            // setDisplayedPredicate('Sangat Baik');
                                                                                            break;
                                                                                    }
                                                                                }}
                                                                                emptySymbol={<IoMdStar size={50}
                                                                                                       color={'#C4C4C4'}/>}
                                                                                fullSymbol={<IoMdStar size={50}
                                                                                                      color={'#FEBF35'}/>}/>
                                                                        </div>

                                                                        <div style={{
                                                                            fontFamily: 'OpenSans-SemiBold',
                                                                            fontSize: 17,
                                                                            color: '#4e9e99',
                                                                            textAlign: 'center',
                                                                            marginTop: 15,
                                                                            opacity: displayedPredicate ? 1 : 0
                                                                        }}>
                                                                            {displayedPredicate ? displayedPredicate : 'placeholder'}
                                                                        </div>

                                                                        <div style={{
                                                                            marginLeft: 20,
                                                                            marginRight: 20,
                                                                            marginTop: 30
                                                                        }}>
                                                                            <div style={{
                                                                                fontFamily: 'OpenSans-Bold',
                                                                                color: '#343643',
                                                                                marginBottom: 3
                                                                            }}>
                                                                                Berikan Komentar (Opsional)
                                                                            </div>

                                                                            <Form.Control as="textarea" rows="3"
                                                                                          placeholder={'Berikan komentar kamu di sini'}
                                                                                          disabled={course.myRating}
                                                                                          value={course.myRating ? course.myRating.comment : comment}
                                                                                          onChange={(e) => {
                                                                                              setComment(e.target.value)
                                                                                          }}
                                                                                          style={{
                                                                                              paddingLeft: 20,
                                                                                              paddingTop: 15,
                                                                                              fontFamily: 'OpenSans-Regular'
                                                                                          }}/>

                                                                            {!course.myRating &&
                                                                            <div
                                                                                onClick={submitRating}
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                    userSelect: 'none',
                                                                                    backgroundColor: '#2cb3ad',
                                                                                    color: 'white',
                                                                                    fontFamily: 'OpenSans-Bold',
                                                                                    textAlign: 'center',
                                                                                    paddingTop: 10,
                                                                                    paddingBottom: 10,
                                                                                    borderRadius: 5,
                                                                                    marginTop: 10,
                                                                                    marginBottom: 20
                                                                                }}>KIRIM
                                                                            </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div> :
                                                                selectedModule.type === CONTENT_TYPE.TEXT ?
                                                                    <Container>
                                                                        <Row>
                                                                            <Col>
                                                                                <div style={{
                                                                                    fontFamily: 'OpenSans-Regular',
                                                                                    whiteSpace: 'pre-wrap',
                                                                                }}>
                                                                                    {new Parser().parse(JSON.parse(selectedModule.content).text)}
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Container> :
                                                                    null
                                }
                            </div>
                            }
                        </Scrollbars>
                    </Col>

                    {/*<div>*/}
                    <Col
                        xs={isNoteExpanded ? undefined : 0}
                        style={{
                            // height: 'calc(100vh - 220px)',
                            overflowY: 'scroll',
                            maxWidth: isNoteExpanded ? 300 : 0,
                            border: '1px solid #dbdbdb',
                            borderTopWidth: 0,
                            borderBottomWidth: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            transition: 'max-width .2s'
                        }}>
                        <div>
                            <div style={{paddingTop: 30, fontFamily: 'OpenSans-SemiBold', fontSize: '1.1em'}}>
                                Catatan ({notes.length})
                            </div>

                            <Form.Control
                                as={'textarea'}
                                style={{
                                    fontSize: "1em",
                                    fontFamily: 'OpenSans-Regular',
                                    paddingTop: 10,
                                    paddingBottom: 20,
                                    marginTop: 10
                                }} type="text"
                                value={note}
                                onChange={e => setNote(e.target.value)}
                                placeholder="Tambah Catatan"/>

                            <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 30}}>
                                <div
                                    onClick={submitNote}
                                    style={{
                                        backgroundColor: Palette.SECONDARY,
                                        color: 'white',
                                        padding: 18,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        borderRadius: 3,
                                        cursor: 'pointer',
                                        userSelect: 'none',
                                        fontFamily: 'OpenSans-SemiBold',
                                        marginTop: 15
                                    }}>
                                    Simpan
                                </div>
                            </div>

                            {
                                notes.map(note => {
                                    return (
                                        <div style={{
                                            backgroundColor: '#fff4ac',
                                            padding: 15,
                                            paddingBottom: 30,
                                            marginBottom: 20
                                        }}>
                                            <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 5}}>
                                                {!updateNote &&
                                                <>
                                                    <MdEdit
                                                        onClick={() => setUpdateNote(note)}
                                                        style={{cursor: 'pointer', marginRight: 15}}/>

                                                    <AiOutlineClose
                                                        onClick={() => deleteNote(note.id)}
                                                        style={{cursor: 'pointer'}}/>
                                                </>
                                                }
                                            </div>

                                            {note.id !== updateNote?.id ?
                                                <div style={{fontFamily: 'OpenSans-Regular'}}>
                                                    {note.notes}
                                                </div> :
                                                <>
                                                    <Form.Control
                                                        as={'textarea'}
                                                        style={{
                                                            fontSize: "1em",
                                                            fontFamily: 'OpenSans-Regular',
                                                            paddingTop: 10,
                                                            paddingBottom: 20,
                                                            marginTop: 10
                                                        }} type="text"
                                                        value={updateNote?.notes}
                                                        onChange={e => setUpdateNote({
                                                            ...updateNote,
                                                            notes: e.target.value
                                                        })}
                                                        placeholder="Perbarui Catatan"/>

                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                        marginTop: 20
                                                    }}>
                                                        <Button
                                                            // onClick={() => {
                                                            //     setStartQuizModalVisible(false)
                                                            //     setStartExerciseModalVisible(false)
                                                            // }}
                                                            onClick={() => setUpdateNote(null)}
                                                            variant={'link'}
                                                            style={{color: '#2d2c3e', fontFamily: 'OpenSans-Bold'}}>
                                                            Batal
                                                        </Button>

                                                        <Button
                                                            onClick={async () => {
                                                                if (!updateNote?.notes) {
                                                                    alert('Catatan tidak boleh kosong!');
                                                                } else {
                                                                    try {
                                                                        const result = await new CourseNotes().update(updateNote?.id, updateNote);

                                                                        getMyNotes(course.memberCourse.id);

                                                                        setUpdateNote(null)
                                                                    } catch (e) {
                                                                        console.log(e)
                                                                    }
                                                                    console.log('updateNote', updateNote)
                                                                }
                                                            }}
                                                            // onClick={onStartQuizConfirmed}
                                                            variant={'primary'}
                                                            style={{
                                                                fontFamily: 'OpenSans-Bold',
                                                                width: 100,
                                                                marginLeft: 20
                                                            }}>
                                                            Simpan
                                                        </Button>
                                                    </div>
                                                </>
                                            }

                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Col>
                    {/*</div>*/}
                </Row>
            </div>


            <UserMenuModal
                show={userMenuModalShown}
                onHide={() => {
                    setUserMenuModalShown(false)
                }}
            />
        </PageWrapper>
    )
}
