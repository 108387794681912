import {Button, Col, Container, Modal, Row, Spinner} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import GlobalData from "../../util/GlobalData";
import Form from "react-bootstrap/Form";
import Palette from "../../util/Palette";
import User from "../../models/User";
import ReactCodeInput from 'react-verification-code-input';
import {useCookies} from "react-cookie";

let requestCountDownTime = 0


export default function ForgotPasswordModal(props) {

    const [cookie, setCookie, removeCookie] = useCookies()
    const isEN = false;
    const [errorMsg, setErrorMsg] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isEmailSent, setEmailSent] = useState(false);
    const [validationCode, setValidationCode] = useState('');
    const [isLoading, setLoading] = useState(false);

    const [remainingTime, setRemainingTime] = useState(0)


    useEffect(() => {
        let countDownTimeSetInterval = setInterval(function () {

            if (requestCountDownTime > 0) {
                requestCountDownTime--
            }
            setRemainingTime(requestCountDownTime)
        }, 1000);
    }, [])

    const retrieveValidationCode = async () => {
        if (!email) setErrorMsg(isEN ? 'Email must be filled' : 'Harap mengisi email terlebih dahulu')
        else {
            setLoading(true)
            try {
                await new User().forgotPassword(email);

                setErrorMsg(null)
                setEmailSent(true);
                setLoading(false)
                setRemainingTime(90)
                requestCountDownTime = 90

            } catch (e) {
                if (e.code === 'EMAIL_NOT_FOUND' || e.code === 'MEMBER_NOT_FOUND') {
                    setErrorMsg(isEN ? 'Email has not registered' : "Surel belum terdaftar")
                }
                if (e.code === 'ACCOUNT_NOT_CLAIMED') {
                    setErrorMsg(`Akun belum diaktivasi. Harap aktivasi akun Anda melalui proses "Buat Akun".`)
                } else setErrorMsg(isEN ? 'An error occured' : "Terjadi kesalahan")
                setLoading(false)
            }
        }
    }

    const changePassword = async () => {
        if (!password && !confirmPassword && !validationCode) setErrorMsg(isEN ? 'Please fill all available fields' : 'Mohon mengisi formulir dengan lengkap')
        else if (password !== confirmPassword) setErrorMsg(isEN ? 'Your password and confirmation password do not match' : "Konfirmasi kata sandi tidak sesuai")
        else {

            setLoading(true)

            try {
                await new User().changePassword(password, validationCode);

                alert(isEN ? 'Your password has been updated!' : 'Kata sandi berhasil diperbarui!')

                props.hideForgotPassword();

                setLoading(false);
            } catch (e) {
                if (e.code === 'NO_TOKEN_REQUESTED') setErrorMsg(isEN ? 'Incorrect validation code. Please try again.' : 'Kode validasi salah')
                else if (e.code === 'RECOVERY_TOKEN_INVALID') setErrorMsg(isEN ? 'Incorrect validation code or it is already expired' : 'Kode validasi salah atau sudah tidak berlaku')
                else setErrorMsg(isEN ? 'An error occured' : 'Kesalahan terjadi')

                setLoading(false);
            }
        }
    }

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.show}
        >
            <Modal.Header>
                <Container>
                    <Row>
                        <Col xs={12} style={{display: 'flex', alignItems: 'center', padding: 0}}>
                            <b>Lupa Sandi</b>

                            <span
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    fontWeight: 'bold', cursor: 'pointer', color: 'grey'
                                }}
                                onClick={props.hideForgotPassword}>
                                x
                            </span>
                        </Col>
                    </Row>
                </Container>
            </Modal.Header>

            <Modal.Body>
                <Form style={{
                    fontSize: "0.7em"
                }}>
                    {isEmailSent ?
                        <>
                            <p>{isEN ? `Please enter the validation code sent to your email (${email})` : `Mohon masukan kode verifikasi yang dikirimkan ke surel Anda (${email})`}</p>

                            <p>
                                Tidak menerima surel? Harap periksa spam dan kolom promosi pada penyedia surel
                                Anda.<br/>

                                <br/>

                                Masih tidak menerima kode? <br/>
                                <a href={"#"} onClick={() => {
                                    if (remainingTime === 0) {
                                        // console.log("woiiiii")
                                        retrieveValidationCode()
                                    }
                                }} style={{
                                    color: remainingTime > 0 ? "grey" : Palette.PRIMARY
                                }}>
                                    Kirim ulang kode verifikasi {remainingTime > 0 ? `(${remainingTime})` : null}.
                                </a>
                                <br/>
                            </p>

                            <ReactCodeInput type={'text'} fields={6} fieldWidth={'16%'}
                                            onComplete={(validationCode) => setValidationCode(validationCode)}/>

                            <Form.Group>
                                <Form.Label style={{marginTop: '15px'}}>{isEN ? 'Password' : 'Kata Sandi'}</Form.Label>
                                <Form.Control
                                    style={{fontSize: "1em"}} type="password" placeholder="" value={password}
                                    onChange={evt => setPassword(evt.target.value)}/>

                                <Form.Label
                                    style={{marginTop: '10px'}}>{isEN ? 'Confirmation Password' : 'Konfirmasi Kata Sandi'}</Form.Label>
                                <Form.Control
                                    style={{fontSize: "1em"}} type="password" placeholder="" value={confirmPassword}
                                    onChange={evt => setConfirmPassword(evt.target.value)}
                                    onKeyPress={event => {
                                        if (event.key === "Enter") {
                                            changePassword();
                                        }
                                    }}/>
                            </Form.Group>
                        </> :
                        <Form.Group>
                            <Form.Label>Surel </Form.Label>
                            <Form.Control
                                style={{fontSize: "1em"}} type="email" placeholder="" value={email}
                                onChange={evt => setEmail(evt.target.value)}
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        retrieveValidationCode();
                                    }
                                }}/>
                            <p style={{marginTop: '7px'}}>{isEN ? 'Validation code to recover the password will be sent by email and may take a few minutes' : 'Kode validasi untuk memulihkan sandi akan dikirim melalui surel dan mungkin membutuhkan beberapa menit'}</p>
                        </Form.Group>
                    }
                </Form>

                <span style={{
                    color: "red",
                    fontSize: "0.8em"
                }}>
                        {errorMsg}
                        </span>
            </Modal.Body>

            <Modal.Footer>


                <Button
                    disabled={isLoading}
                    style={{backgroundColor: Palette.PRIMARY, borderWidth: 0}}
                    onClick={isEmailSent ? changePassword : retrieveValidationCode}>
                    {isLoading ? <Spinner animation="border" style={{marginLeft: '30px', marginRight: '30px'}}
                                          size={'sm'}/> : isEmailSent && isEN ? 'Update Password' : isEmailSent && !isEN ? 'Ganti Kata Sandi' : !isEmailSent && isEN ? 'Send Validation Code' : 'Kirim Kode Validasi'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
