import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import PageWrapper from "./PageWrapper";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {isMobile} from "react-device-detect";
import ReactPaginate from "react-paginate";
import LiveStreamingModel from "../../models/LiveStreaming";
import {useHistory, useParams} from "react-router-dom";


export default function TTSPage(props) {

    const {version} = useParams()
    let history = useHistory()
    const [url, setUrl] = useState(null);

    return (
        <PageWrapper headerActive={'article'}>
            <Container style={{marginTop: '60px'}}>
                <Row style={{
                    marginTop: isMobile ? 0 : "6em",
                    fontFamily: 'OpenSans-Bold'
                }}>

                    <Col md={12}>
                        <iframe
                            src='https://puzzel.org/en/crossword/embed?p=-MfBF2_DhbWSpKnLu5bw'
                            width='100%'
                            height='1000' frameBorder='0'></iframe>
                    </Col>
                </Row>
            </Container>
        </PageWrapper>
    )

    // return <PageWrapper headerActive={'article'}>
    //     <Container style={{marginTop: '60px'}}>
    //         <Row style={{
    //             marginTop: isMobile ? 0 : "6em",
    //             fontFamily: 'OpenSans-Bold'
    //         }}>
    //
    //             <Col md={12}>
    //                 <iframe
    //                     width="100%"
    //                     height={"1000"}
    //                     // style="background-color:white; padding:5px 0px 0 5px; border:3px solid black; margin:auto; display:block"
    //                     frameBorder="0" src="https://crosswordlabs.com/embed/tts-sinara"></iframe>
    //             </Col>
    //         </Row>
    //     </Container>
    // </PageWrapper>


}
