import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import React, {Component, useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import User from "../../models/User";
import GlobalData from "../../util/GlobalData";
import Palette from "../../util/Palette";
import {useCookies} from "react-cookie";

export default function EditProfileModal(props) {
    const [cookie, setCookie, removeCookie] = useCookies()
    const isEN = false;

    const defaultRegisterState = {
        passwordMismatch: false,
        emailIsRegistered: false,
    }

    const [user, setUser] = useState({...GlobalData.user});
    const [error, setError] = useState(null);
    const [registerState, setRegisterState] = useState({...defaultRegisterState});

    useEffect(() => {
        if (props.show) setUser({...GlobalData.user});

    }, [props.show])

    const handleSubmit = async () => {
        setError(null);

        let userModel = new User()

        let param = {
            ...GlobalData.user,
            ...user
        }

        try {
            let result = await userModel.updateUser(param)
            console.log(result)
            if (props.onHide) props.onHide();

            alert(isEN ? 'Profile Successfully Updated!' : "Profil Berhasil Diubah!")
            window.location.reload();
        } catch (e) {
            let msg = isEN ? 'An error occured' : "Terjadi Kesalahan"

            if (e.msg) {
                if (e.code === "DUPLICATE_ACCOUNT") {
                    msg = isEN ? 'Email has been registered' : "Alamat surel telah terdaftar"
                }

                setError(msg);

                return;
            }

            setError(msg);

            console.log(e)
        }
    }

    const renderEditProfileBody = () => {

        return <> <p style={{
            fontSize: "0.8em"
        }}>
            {isEN ? 'Edit Profile' : 'Ubah Profil'}<br/>
        </p>
            <Form
                style={{
                    fontSize: "0.7em"
                }}>
                <Form.Group>
                    <Form.Label>Alamat Surel <a
                        style={{color: "red", display: registerState.emailIsRegistered ? "inline" : "none"}}>{isEN ? '(Email has been registered)' : '(Alamat surel telah terdaftar)'}</a></Form.Label>
                    <Form.Control
                        value={user.email}
                        onChange={evt => setUser({...user, email: evt.target.value})}
                        style={{fontSize: "1em"}} type="email" placeholder=""/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{isEN ? 'Full Name' : 'Nama Lengkap'}</Form.Label>
                    <Form.Control
                        value={user.full_name}
                        onChange={evt => setUser({...user, full_name: evt.target.value})}
                        style={{fontSize: "1em"}} type="text" placeholder=""/>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>{isEN ? 'Phone Number' : 'Nomor Telepon'}</Form.Label>
                    <Form.Control
                        value={user.phone_num}
                        onChange={evt => setUser({...user, phone_num: evt.target.value})}
                        style={{fontSize: "1em"}} type="text" placeholder=""/>
                </Form.Group>
            </Form>
        </>
    }

    return <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.show}
        onHide={() => {
            setError(null);

            setRegisterState({...registerState, passwordMismatch: false, emailIsRegistered: false})

            props.onHide();
        }}
    >
        <Modal.Header>
            {/*<Modal.Title>*/}
            <Container>
                <Row>
                    <Col md={12} xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: "center"}}>
                        <img
                            style={{
                                marginTop : 10,
                                marginBottom : 10,
                                width: "85%",
                                objectFit: "contain",
                            }}
                            src={require("../../asset/new_logo.png")}/>
                    </Col>
                </Row>
            </Container>


            {/*</Modal.Title>*/}
        </Modal.Header>
        <Modal.Body>
            {renderEditProfileBody()}
            <span style={{
                color: "red",
                fontSize: "0.8em"
            }}>
                    {error ? error : props.message}
                </span>

        </Modal.Body>
        <Modal.Footer>
            <Container style={{padding: 0}}>
                <Row>
                    <Col md={6} style={{marginTop: '10px'}}>

                    </Col>
                    <Col md={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button
                            style={{backgroundColor: Palette.PRIMARY, borderWidth: 0}}
                            onClick={handleSubmit}>{isEN ? 'Save' : 'Simpan'}</Button>
                    </Col>
                </Row>
            </Container>
        </Modal.Footer>
    </Modal>
}
