import ApiRequest from "../util/ApiRequest";
import GlobalData from "../util/GlobalData";

export default class Session {

    joinClass = async (id) => {
        return await ApiRequest.set(`/v1/participant/${id}/join`, "GET");
    }

    getRegisteredClass = async () => {
        return await ApiRequest.set(`/v1/class/participations`, "GET");
    }

    getByCode = async (code) => {
        return await ApiRequest.set(`/v1/class/${code}`, "GET");
    }

    getAvailableClass = async (code) => {
        return await ApiRequest.set(`/v1/participant/available_class/${code}`, "GET")
    }

    getByTransactionCode = async (code) => {
        return await ApiRequest.set(`/v1/participant/token/${code}`, "GET");
    }

    getAll = async () => {
        return await ApiRequest.set("/v1/class", "GET");
    }

    participate = async (code, voucherCode, participantNote) => {
        return await ApiRequest.set(`/v1/class/${code}/participate`, "POST", {
            voucher_code:  voucherCode,
            participant_note: participantNote
        });
    }

    cancel = async (participantId) => {
        return await ApiRequest.set(`/v1/participant/${participantId}/cancel`, "POST");
    }

    getAllCategory = async () => {
        return await ApiRequest.set("/v1/topic", "GET");
    }

    buyTicket = async (sessionId, isEN) => {
        return await ApiRequest.set('/sessions/' + sessionId + '/register/1', 'POST', {language: isEN ? 'en' : 'id'});
    }

    confirmTransaction = async (sessionId, trxConfirmation) => {
        return await ApiRequest.set('/sessions/' + sessionId + '/register/1', 'POST', trxConfirmation);
    }

    uploadPaymentProof = async (sessionId, image) => {

        let formData = new FormData();

        console.log('blob', image)
        formData.append('upload', image, image.name);

        return await ApiRequest.setMultipart('/sessions/' + sessionId + '/proof/upload', 'POST', formData);
    }

    updateTransactionConfirmation = async(sessionId, trxConfirmation) => {
        return await ApiRequest.set('/sessions/' + sessionId + '/proof', 'POST', trxConfirmation);
    }

    getSessionStream = async(sessionId) => {
        return await ApiRequest.set('/sessions/' + sessionId + '/stream', 'GET');
    }

    changePaymentMethod = async(sessionId) => {
        return await ApiRequest.set(`/sessions/${sessionId}/registration/change`, 'POST')
    }

    getSessionRemainingQuota = async(sessionId) => {
        return await ApiRequest.set(`/sessions/${sessionId}/count`, 'GET')
    }

    getPapermoonSessions = async () => {
        return await ApiRequest.set("/events/75/sessions", "GET");
    }

    getPestaBonekaSessions = async () => {
        return await ApiRequest.set("/events/79/sessions", "GET");
    }

    getPestaBonekaWorkshops = async () => {
        return await ApiRequest.set("/events/80/sessions", "GET");
    }

    getPestaBonekaPerformances = async () => {
        return await ApiRequest.set("/events/81/sessions", "GET");
    }

    getRumasuarSessions = async () => {
        return await ApiRequest.set("/events/77/sessions", "GET");
    }

    getFBASessions = async () => {
        return await ApiRequest.set("/events/78/sessions", "GET");
    }

    getRegistrationInfo = async (registrationId) => {
        return await ApiRequest.set(`/sessions/registrations/${registrationId}`, "GET");
    }

    getVoucherInfo = async(voucherCode)=>{
        return await ApiRequest.set(`/v1/voucher/${voucherCode}`, "GET");
    }

    sendFeedbackAndQuestion = async(feedback) => {
        return await ApiRequest.set(`/v1/participant/feedback`, "POST", feedback);
    }

    getQnA = async(code) => {
        return await ApiRequest.set(`/v1/class/${code}/answers`, 'GET')
    }

    getFeedbacks = async(code) => {
        return await ApiRequest.set(`/v1/class/${code}/feedbacks`, 'GET')
    }

    getUpcomingForDropdown = async () => {
        return await ApiRequest.set("/v1/class/upcoming", "GET");
    }

}
