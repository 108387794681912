import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import React, {Component, useState} from "react";
import Form from "react-bootstrap/Form";
import User from "../../models/User";
import GlobalData from "../../util/GlobalData";
import Palette from "../../util/Palette";
import {
    isSafari, isMobileSafari
} from "react-device-detect";
import {useCookies} from "react-cookie";
import Strings from "../../util/Strings";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {register} from "../../serviceWorker";
import {IoMdStar} from "react-icons/io";

export default function LoginModal(props) {
    const [cookie, setCookie, removeCookie] = useCookies()
    const isEN = false;
    const defaultRegisterState = {
        passwordMismatch: false,
        emailIsRegistered: false,
    }

    const [loginMode, setLoginMode] = useState(true);
    const [loginCred, setLoginCred] = useState({});
    const [registerCred, setRegisterCred] = useState({subscribe_newsletter: true});
    const [registerState, setRegisterState] = useState({...defaultRegisterState});
    const [error, setError] = useState(null);
    const [isDeviceSafari, setSafari] = useState(isSafari || isMobileSafari)
    const [isSubscribeNewsletter, setSubscribeNewsletter] = useState(true);

    const [loginModeShowPassword, setLoginModeShowPassword] = useState(false)
    const [registerModeShowPassword, setRegisterModeShowPassword] = useState(false)
    const [registerModeShowPConfirmPassword, setRegisterModeShowConfirmPassword] = useState(false)

    const handleSubmit = async () => {
        setError(null);

        setRegisterState({
            ...defaultRegisterState
        })

        if (loginMode) {
            let userModel = new User();

            const loginCredTemp = {...loginCred};
            if (props.email) loginCredTemp.email = props.email

            if (!loginCredTemp.email || !loginCredTemp.password) {
                setError(isEN ? 'Please fill all available fields' : "Harap lengkapi email dan kata sandi");

                return
            }

            try {
                let result = await userModel.login(loginCredTemp)
                props.onHide(result.token)
                if (props.customOnHide) props.customOnHide();
                setTimeout(function () {
                    window.location.reload();
                }, 500);
                console.log(result)
            } catch (e) {

                let msg = isEN ? 'An error occured' : "Terjadi Kesalahan";

                if (e.msg) {
                    if (e.code === "MEMBER_PASSWORD_WRONG" || e.code === "MEMBER_NOT_FOUND") {
                        msg = "Periksa kembali email dan kata sandi Anda"
                    }
                    if (e.code === 'ACCOUNT_NOT_CLAIMED') {
                        msg = `Akun belum diaktivasi. Harap aktivasi akun Anda melalui proses "Buat Akun".`
                    }
                    if (e.code === "ACCOUNT_NOT_ACTIVATED") {
                        msg = "Harap aktifkan akun Anda melalui surel."
                    }
                    if (e.code === "ACCOUNT_SUSPENDED") {
                        msg = "Akun diblokir, harap hubungi admin"
                    }
                }

                setError(msg);

                console.log(e)
            }
        } else {
            let userModel = new User();

            if (registerCred.password !== registerCred.confirmPassword) {
                setRegisterState({
                    ...defaultRegisterState,
                    passwordMismatch: true
                });

                setError(isEN ? 'Your password and confirmation password do not match' : "Kata sandi tidak sesuai");

                return;
            }

            if (!registerCred.email) {
                setRegisterState({
                    ...defaultRegisterState,
                });

                setError(isEN ? 'Please fill in your Email' : "Harap isi alamat Email Anda");

                return;
            }

            if (!registerCred.password) {
                setRegisterState({
                    ...defaultRegisterState,
                });

                setError(isEN ? 'Please fill in your password' : "Harap lengkapi password");

                return;
            }

            if (!registerCred.confirmPassword) {
                setRegisterState({
                    ...defaultRegisterState,
                });

                setError(isEN ? 'Please fill in your password confirmation' : "Harap isi konfirmasi password");

                return;
            }

            /*
            if (!registerCred.date_of_birth) {
                setRegisterState({
                    ...defaultRegisterState,
                });

                setError(isEN ? 'Please fill in your Date of Birth' : "Harap isi tanggal lahir Anda.");

                return;
            }

            if (!registerCred.gender) {
                setRegisterState({
                    ...defaultRegisterState,
                });

                setError(isEN ? 'Please fill in your gender' : "Harap isi jenis kelamin Anda");

                return;
            }
            */

            if (!registerCred.phone_num) {
                setRegisterState({
                    ...defaultRegisterState,
                });
                setError(isEN ? 'Please fill in your full name' : "Harap isi Nomor Ponsel Anda");

                return;
            }

            if (!registerCred.full_name) {
                setRegisterState({
                    ...defaultRegisterState,
                });

                setError(isEN ? 'Please fill in your full name' : "Harap isi nama lengkap Anda");

                return;
            }

            /*
            if (!registerCred.email || !registerCred.password || !registerCred.confirmPassword ||
                !registerCred.date_of_birth || !registerCred.gender || !registerCred.phone_num || !registerCred.full_name) {

                setError(isEN ? 'Please fill all available fields' : "Harap lengkapi semua data");

                return;
            } else {
                setError(null)

                console.log("semua keisi")
            }
            */

            // if(isSafari){
            //
            //
            //     if(isNaN(new Date(registerCred.date_of_birth))){
            //
            //     }
            //
            // }

            try {

                console.log("phone number " + registerCred.phone_num)

                let result = await userModel.register(registerCred)
                alert("Registrasi Berhasil! Harap periksa surel yang terdaftar untuk mengonfirmasi akunmu.")

                setRegisterCred({})

                props.onHide(result.token)
                if (props.customOnHide) props.customOnHide();
                setLoginMode(true)

                console.log(result)
            } catch (e) {

                let msg = isEN ? 'An error occured' : "Terjadi kesalahan";

                console.log(msg);

                if (e.msg) {
                    if (e.code === "MEMBER_ALREADY_REGISTERED") {
                        msg = isEN ? 'Email has been registered' : "Email telah terdaftar"
                    }

                    if (e.code === "INVALID_EMAIL") {
                        msg = 'Format Surel tidak benar'
                    }

                    setRegisterState({
                        ...defaultRegisterState,
                        emailIsRegistered: e.code === "EMAIL_ALREADY_EXIST"
                    })

                    setError(msg)

                    return;
                }

                setError(msg);

                console.log(e)
                console.log(e.body)
            }

        }


    }

    const renderLoginBody = () => {
        return <> <p style={{
            fontSize: "0.8em"
        }}>
            {Strings.login_pop_up.welcome}<br/>
        </p>
            <Form style={{
                fontSize: "0.7em"
            }}>
                <Form.Group>
                    <Form.Label>{Strings.login_pop_up.email}</Form.Label>
                    <Form.Control
                        readOnly={props.email}
                        value={props.email ? props.email : loginCred.email}
                        onChange={evt => {
                            if (!props.email) {
                                setLoginCred({...loginCred, email: evt.target.value})
                                setRegisterCred({...registerCred, email: evt.target.value})
                            }
                        }}
                        style={{fontSize: "1em"}} type="email" placeholder=""/>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1" style={{marginBottom : 0}}>
                    <Form.Label>{Strings.login_pop_up.password}</Form.Label>
                    <Form.Control
                        value={loginCred.password}
                        onChange={evt => {
                            setLoginCred({...loginCred, password: evt.target.value})
                            setRegisterCred({...registerCred, password: evt.target.value})
                        }}
                        onKeyPress={event => {
                            if (event.key === "Enter") {
                                handleSubmit();
                            }
                        }}
                        style={{fontSize: "1em"}} type={loginModeShowPassword ? "text" : "password"} placeholder=""/>
                </Form.Group>
                <div onClick={()=>{setLoginModeShowPassword(!loginModeShowPassword)}} style={{cursor : "pointer", textDecoration : "underline", color : "blue", textAlign : "right", width : "100%", marginTop : 0, marginBottom : 10}}>
                    Tampilkan
                </div>
            </Form>

            {props.email ? null : <p style={{
                fontSize: "0.7em"
            }}>
                {Strings.login_pop_up.no_account}
                <a onClick={() => {
                    setError(null)
                    setLoginMode(false)
                }}
                   href={"#"}>&nbsp;{Strings.login_pop_up.create_account}
                </a>
            </p>
            }

        </>
    }

    const renderRegisterBody = () => {
        return <> <p style={{
            fontSize: "0.8em"
        }}>
            {isEN ? 'Welcome to Seminar Edukasi Musik' : 'Selamat Datang di Sinara'}<br/>
        </p>
            <Form
                style={{
                    fontSize: "0.7em"
                }}>
                <Form.Group>
                    <Form.Label>{isEN ? "E-mail" : "Surel"} <a
                        style={{color: "red", display: registerState.emailIsRegistered ? "inline" : "none"}}>(Email
                        Telah Terdaftar)</a></Form.Label>
                    <Form.Control
                        value={registerCred.email}
                        onChange={evt => {
                            setRegisterCred({...registerCred, email: evt.target.value})
                            setLoginCred({...loginCred, email: evt.target.value})
                        }}
                        style={{fontSize: "1em"}} type="email" placeholder=""/>
                </Form.Group>
                <Form.Group style={{marginBottom : 0}}>
                    <Form.Label>{isEN ? 'Password' : 'Kata Sandi'}</Form.Label>
                    <Form.Control
                        onChange={evt => {
                            setRegisterCred({...registerCred, password: evt.target.value})
                            setLoginCred({...loginCred, password: evt.target.value})
                        }}
                        style={{fontSize: "1em"}} type={registerModeShowPassword ? "text" : "password"} placeholder=""/>
                </Form.Group>
                <div onClick={()=>{setRegisterModeShowPassword(!registerModeShowPassword)}} style={{cursor : "pointer", textDecoration : "underline", color : "blue", textAlign : "right", width : "100%", marginTop : 0, marginBottom : 10}}>
                    Tampilkan
                </div>

                <Form.Group style={{marginBottom : 0}}>
                    <Form.Label>{isEN ? 'Password Confirmation' : 'Konfirmasi Kata Sandi'}<a
                        style={{color: "red", display: registerState.passwordMismatch ? "inline" : "none"}}>(Password
                        Tidak Sama)</a></Form.Label>
                    <Form.Control
                        onChange={evt => setRegisterCred({...registerCred, confirmPassword: evt.target.value})}
                        style={{fontSize: "1em"}} type={registerModeShowPConfirmPassword ? "text" : "password"}  placeholder=""/>
                </Form.Group>
                <div onClick={()=>{setRegisterModeShowConfirmPassword(!registerModeShowPConfirmPassword)}} style={{cursor : "pointer", textDecoration : "underline", color : "blue", textAlign : "right", width : "100%", marginTop : 0, marginBottom : 10}}>
                    Tampilkan
                </div>


                <Form.Group>
                    <Form.Label>{isEN ? 'Full Name' : 'Nama Lengkap'}</Form.Label>
                    <Form.Control
                        value={registerCred.full_name}
                        onChange={evt => setRegisterCred({...registerCred, full_name: evt.target.value})}
                        style={{fontSize: "1em"}} type="text" placeholder=""/>
                </Form.Group>


                {/*}
                <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>{isEN ? 'Date of Birth' : 'Tanggal Lahir'} {isDeviceSafari ? "(yyyy-mm-dd)" : ""}</Form.Label>
                    <Form.Control
                        onChange={evt => setRegisterCred({...registerCred, date_of_birth: evt.target.value})}
                        value={registerCred.date_of_birth}
                        style={{fontSize: "1em"}} type="date" placeholder=""/>
                </Form.Group>
                {*/}

                <Form.Group style={{marginBottom: 0}}>
                    <Form.Label>{isEN ? 'Phone Number' : 'Nomor Telepon'}</Form.Label>
                    <Form.Control
                        onChange={evt => setRegisterCred({...registerCred, phone_num: evt.target.value})}
                        style={{fontSize: "1em"}} type="text" placeholder=""/>
                </Form.Group>

                <FormControlLabel
                    control={
                        <Checkbox
                            // color={"success"}
                            onChange={() => {
                                setRegisterCred({...registerCred, subscribe_newsletter: !isSubscribeNewsletter})
                                setSubscribeNewsletter(!isSubscribeNewsletter);
                            }}
                            checked={isSubscribeNewsletter}
                        />
                    }
                    style={{
                        // color : isSubscribeNewsletter ? null : "grey",
                    }}
                    label={<p
                        style={{fontSize: '.75em', paddingTop: 20}}>{Strings.register_pop_up.register_newsletter}</p>}
                />


            </Form>

            <p style={{
                fontSize: "0.7em"
            }}>{isEN ? 'Already have an account? ' : 'Sudah punya akun? '}
                <a onClick={() => {
                    setError(null)
                    setLoginMode(true)

                }} href={"#"}>{isEN ? 'Login' : 'Masuk'}</a>
            </p>
        </>
    }

    return <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.show}
        onHide={() => {
            if (props.customOnHide) props.customOnHide()
            setError(null);
            setRegisterState({...registerState, passwordMismatch: false, emailIsRegistered: false})

            props.onHide();
        }}
    >
        <Modal.Header>
            {/*<Modal.Title>*/}
            <Container>
                <Row>
                    <Col md={12} xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: "center"}}>
                        <img
                            style={{
                                width: "50%",
                                objectFit: "contain",
                            }}
                            src={require("../../asset/new-narabahasa-logo.jpeg")}/>
                    </Col>
                </Row>
            </Container>


            {/*</Modal.Title>*/}
        </Modal.Header>
        <Modal.Body>
            {loginMode ? renderLoginBody() : renderRegisterBody()}
            <span style={{
                color: "red",
                fontSize: "0.8em"
            }}>
                {error ? error : props.message}
            </span>

        </Modal.Body>
        <Modal.Footer>
            <Container>
                <Row style={{display: 'flex', justifyContent: 'center'}}>
                    <Button
                        style={{backgroundColor: Palette.PRIMARY, borderWidth: 0, width: '100%'}}
                        onClick={handleSubmit}>
                        {loginMode ? Strings.login_pop_up.login : Strings.register_pop_up.register}
                    </Button>
                </Row>

                <Row style={{display: 'flex', justifyContent: 'center', marginTop: 10}}>
                    {loginMode ? <p style={{
                        fontSize: "0.7em"
                    }}>
                        <a href="#"
                           onClick={props.showForgotPassword}>{Strings.login_pop_up.forgot_password}</a>
                    </p> : null}
                </Row>
            </Container>
        </Modal.Footer>
    </Modal>
}
