import Container from "react-bootstrap/Container";
import React, {useEffect} from "react";
import PageWrapper from "./PageWrapper";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ApplicationActivities from "../../models/ApplicationActivity";
import {isMobile} from "react-device-detect";
import Palette from "../../util/Palette";
import {Button} from "react-bootstrap";

export default function NotFound(props) {

    return (
        <PageWrapper>
            <Container style={{marginTop: 80}}>
                <Row style={{display: 'flex', justifyContent: 'center'}}>
                    <Col style={{
                        textAlign : "center"
                    }}>
                        <div style={{fontSize: 36, fontWeight: 'bold', marginTop : "1.5em"}}>404</div>
                        <div style={{fontSize: 24, fontWeight: 'bold'}}>Halaman tidak ditemukan</div>

                        <a href={"/"}>
                            <Button style={{
                                marginTop : 15
                            }}>
                                Kembali ke Halaman Utama
                            </Button>
                        </a>


                    </Col>

                </Row>

            </Container>
        </PageWrapper>
    )
}
