export default {
    token : null,
    // subfolder : "inibukanvirtual/"
    // subfolder : "192.168.0.105:3000/jauhdimatadekatdihati/",
    // subfolder : "http://localhost:3000/jauhdimatadekatdihati/",
    // subfolder : "https://Seminar Edukasi Musik.com/",
    subfolder : "http://localhost:3000/",
    strings: {},
    // subfolder : "https://Seminar Edukasi Musik.com/jauhdimatadekatdihati/",
    cart: []
};
