import ApiRequest from "../util/ApiRequest";
import GlobalData from "../util/GlobalData";

export default class Course {
    getAll = async () => {
        return await ApiRequest.set(`/v1/course`, "GET");
    }

    getById = async (id) => {
        return await ApiRequest.set(`/v1/course/${id}`, "GET");
    }

    getByCode = async (code) => {
        return await ApiRequest.set(`/v1/course/${code}/code`, "GET");
    }
}
