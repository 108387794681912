import Dialog from "@material-ui/core/Dialog";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Rating from "react-rating";
import {IoMdStar} from "react-icons/io";
import FeedbackCard from "./FeedbackCard";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import PageWrapper from "../page/PageWrapper";
import React, {useState} from "react";
import Session from "../../models/Session";
import Strings from "../../util/Strings";
import moment from "moment";
import {FaExternalLinkAlt} from "react-icons/all";

export default function AnnouncementModal(props) {
    const {isOpen, onClose} = props

    const closeModal = () =>{
        localStorage.setItem('is11JunMaintenanceAnnouncementViewed', "true");
        onClose()
    }

    return <Dialog open={isOpen} fullWidth={true} onClose={() => onClose()}>
        <Container>
            <Row style={{marginTop: 25, marginBottom: 15}}>
                <Col md={12} style={{fontFamily: 'OpenSans-SemiBold', fontSize: 18, marginLeft: 5, color: '#302a28'}}>
                    Pengumuman
                </Col>
            </Row>

            <Row>
                <Col style={{
                    width: '100%',
                    height: 1,
                    backgroundColor: '#e9eaec',
                    marginTop: 5,
                    marginBottom: 40
                }}/>
            </Row>

            <Row style={{
                borderRadius: 8,
                // padding: 25,
                paddingBottom: 15,
                maxWidth: '700px',
                marginLeft: 0,
                marginRight: 0
            }}>
                <Container>
                    <Row style={{
                        marginBottom: 30
                    }}>
                        <Col md={12}>
                            Halo, Kerabat Nara.
                            <br/><br/>
                            Oleh karena Nara akan melakukan pemeliharaan sistem, Sinara tidak bisa diakses pada tanggal 12 Juni 2021, pukul 00.00 sampai 06.00. Nah, bagi Kerabat Nara yang belum menyelesaikan transaksi pendaftaran kelas, mohon selesaikan sebelum 11 Juni 2021, pukul 23.59 WIB. Hubungi Nara melalui WhatsApp 08119561220, ya, jika Kerabat Nara menemukan kendala. Terima kasih dan semoga Kerabat Nara selalu sehat.
                            <br/><br/>
                            Salam,<br/>
                            Narahubung Narabahasa<br/>
                            Kuasai bahasa, kuasai dunia<br/>
                        </Col>
                    </Row>

                    <Row>
                        <Col style={{
                            width: '100%',
                            height: 1,
                            backgroundColor: '#e9eaec',
                            marginTop: 5,
                            marginBottom: 10
                        }}/>
                    </Row>

                    <Row style={{marginBottom : 5}}>
                        <Col md={12} style={{display : "flex", justifyContent : "flex-end"}}>
                            <Button onClick={()=>closeModal()}>Tutup</Button>
                        </Col>
                    </Row>

                </Container>

            </Row>
        </Container>
    </Dialog>


}
