import {withStyles} from "@material-ui/core";
import Radio from "@material-ui/core/Radio/Radio";
import React, {useEffect, useState} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function QuizRadioButton(props) {
    const {question, description, options, onChange, disabled, value, idx, correctAnswer} = props;

    const [selectedOption, setSelectedOption] = useState(null);

    const CustomRadio = withStyles({
        root: {
            color: 'grey',
            '&$checked': {
                color: '#3882e0',
            },
        },
        checked: {},
    })((props) => <Radio color="default" {...props} />);

    const GreenRadio = withStyles({
        root: {
            color: 'green',
            '&$checked': {
                color: '#25d366',
            },
        },
        checked: {},
    })((props) => <Radio color="default" {...props} />);

    const RedRadio = withStyles({
        root: {
            color: 'red',
            '&$checked': {
                color: '#d20523',
            },
        },
        checked: {},
    })((props) => <Radio color="default" {...props} />);

    useEffect(() => {
        onChange(selectedOption)
    }, [selectedOption])

    // function shuffleArray(array) {
    //     for (let i = array.length - 1; i > 0; i--) {
    //         const j = Math.floor(Math.random() * (i + 1));
    //         [array[i], array[j]] = [array[j], array[i]];
    //     }
    //     return array
    // }
    //
    // let shuffledOption = disabled ? options : shuffleArray(options)

    return (
        <Row style={{
            boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
            borderRadius: 10,
            marginLeft: 5,
            marginRight: 5,
            backgroundColor: 'white',
            maxWidth: 900,
            width: '100%',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 35,
            paddingBottom: 25,
            marginTop: 20
        }}>
            <Col md={12} style={{fontFamily: 'OpenSans-SemiBold', fontSize: '18px', color: '#202124', whiteSpace : "pre-line"}}>
                {idx + 1}. {question}
            </Col>

            <Col md={12}
                 style={{fontFamily: 'OpenSans-Regular', fontSize: '15px', marginTop: '4px', color: '#878787'}}>
                {description}
            </Col>

            {
                options.map(option => {

                    if (disabled) {
                        if (selectedOption === option || value === option) {
                            if (option !== correctAnswer) {
                                return  <Col md={12} style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: 20,
                                    cursor: disabled ? 'default' : 'auto'
                                }}>
                                    <RedRadio disabled={disabled} checked={selectedOption === option || value === option}
                                              onClick={() => setSelectedOption(option)}/>
                                    <span style={{
                                        fontSize: '18px',
                                        fontFamily: 'OpenSans-Regular',
                                        marginLeft: 5,
                                        cursor: 'pointer',
                                        color : "#d20523"
                                    }} onClick={() => {
                                        if (!disabled) setSelectedOption(option)
                                    }}>{option} (Jawaban Anda)</span>

                                </Col>
                            }else{
                                return  <Col md={12} style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: 20,
                                    cursor: disabled ? 'default' : 'auto'
                                }}>
                                    <GreenRadio disabled={disabled} checked={selectedOption === option || value === option}
                                                onClick={() => setSelectedOption(option)}/>
                                    <span style={{
                                        fontSize: '18px',
                                        fontFamily: 'OpenSans-Regular',
                                        marginLeft: 5,
                                        cursor: 'pointer',
                                        color : "#25d366"
                                    }} onClick={() => {
                                        if (!disabled) setSelectedOption(option)
                                    }}>{option} (Jawaban Benar)</span>

                                </Col>
                            }
                        }else{
                            if (option === correctAnswer) {
                                return  <Col md={12} style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: 20,
                                    cursor: disabled ? 'default' : 'auto'
                                }}>
                                    <GreenRadio disabled={disabled} checked={selectedOption === option || value === option}
                                                onClick={() => setSelectedOption(option)}/>
                                    <span style={{
                                        fontSize: '18px',
                                        fontFamily: 'OpenSans-Regular',
                                        marginLeft: 5,
                                        cursor: 'pointer',
                                        color : "#25d366"
                                    }} onClick={() => {
                                        if (!disabled) setSelectedOption(option)
                                    }}>{option} (Jawaban Benar)</span>

                                </Col>
                            }

                        }
                    }

                    return (
                        <Col md={12} style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 20,
                            cursor: disabled ? 'default' : 'auto'
                        }}>
                            <CustomRadio disabled={disabled} checked={selectedOption === option || value === option}
                                         onClick={() => setSelectedOption(option)}/>
                            <span style={{
                                fontSize: '18px',
                                fontFamily: 'OpenSans-Regular',
                                marginLeft: 5,
                                cursor: 'pointer'
                            }} onClick={() => {
                                if (!disabled) setSelectedOption(option)
                            }}>{option}</span>
                        </Col>
                    )
                })
            }

        </Row>
    )
}
