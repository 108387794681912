import ApiRequest from "../util/ApiRequest";
import GlobalData from "../util/GlobalData";

export default class QuizForm {

    static answer = async (class_code,class_name, name, email, quiz_question_id, answer_json) => {
        return await ApiRequest.set(`/v1/quiz_forms`, "POST", {
            class_code,
            class_name,
            name,
            email,
            quiz_question_id,
            answer_json,
        });
    }

}
