import {Component, useEffect, useState} from "react";
import React from "react";
import Header from "../reusable/Header";
import LoginModal from "../reusable/LoginModal";
import {CookiesProvider, useCookies} from 'react-cookie';
import GlobalData from "../../util/GlobalData";
import User from "../../models/User"
import Footer from "../reusable/Footer";
import {
    isMobile
} from "react-device-detect";
import ForgotPasswordModal from "../reusable/ForgotPasswordModal";
import BookModal from "../reusable/BookModal";
import {Link, useHistory, useLocation, useParams} from 'react-router-dom'
import {AiOutlineForm, BsFillChatFill, FaWhatsapp, IoIosChatbubbles} from "react-icons/all";
import {} from "react-icons/bs";
import Palette from "../../util/Palette";
import Collapse from "@material-ui/core/Collapse/Collapse";
import Col from "react-bootstrap/Col";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Drawer from "@material-ui/core/Drawer/Drawer";
import MuiAlert from "@material-ui/lab/Alert/Alert";

export default function PageWrapper(props) {

    const [forgotPasswordShow, setForgotPasswordShow] = useState(false);
    const [loginShow, setLoginShow] = useState(false);
    const [cookie, setCookie, removeCookie] = useCookies()
    const [isLoggedIn, setIsLoggedIn] = useState(GlobalData.token || cookie.token)
    const [userData, setUserData] = useState(GlobalData.user);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [helpExpanded, setHelpExpanded] = useState(false);

    let location = useLocation();
    let history = useHistory()
    let params = useParams()


    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);

    let doLogin = async () => {
        if (!GlobalData.token && cookie.token) {
            GlobalData.token = cookie.token
            let userModel = new User()

            try {
                let user = await userModel.tokenLogin()

                console.log('token login result: ' + user)

                if (props.loginListener) {
                    props.loginListener(user)
                }
                GlobalData.user = user
                setUserData(user)

            } catch (e) {
                e.API = 'TOKEN_LOGIN';

                GlobalData.token = null
                GlobalData.user = null

                removeCookie("token", {path: '/'})
                console.log("cookie " + cookie.token)
                setTimeout(function () {
                    window.location.reload();
                }, 500);

                console.log(e)
            }
        }
    }

    useEffect(() => {
        doLogin()
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location.pathname])

    /**TEST**/

    // console.log("header active " + props.headerActive)

    // let redirectTo = translatePath(props.headerActive, cookie.lang ? cookie.lang : "en", params.id)

    // if(redirectTo !== location.pathname || (location.pathname === "" && redirectTo === "/")){
    //     console.log("will redirect to " + redirectTo , location.pathname)
    //     history.push(redirectTo)
    // }


    return (
        <div style={{marginTop: isMobile ? 0 : '22x', overflow: "hidden"}}>
            <BookModal show={props.isBookModalShown} book={props.selectedBook} onHide={props.hideBookModal}/>

            {!props.hideHeader &&
            <Header
                cart={props.cart}
                setCart={props.setCart}
                isCartOpen={props.isCartOpen}
                setCartOpen={props.setCartOpen}
                shy={props.headerShy}
                isLoggedIn={isLoggedIn}
                onLoginPress={() => {
                    console.log('onLoginPress')
                    setLoginShow(true)
                }}
                user={userData}
                active={props.headerActive}
                isBookStoresShown={props.isBookStoresShown}
            />
            }

            <LoginModal
                email={props.email}
                show={props.loginShow || (loginShow && !isLoggedIn)}
                message={props.message}
                onHide={(token) => {
                    setLoginShow(false)
                    if (GlobalData.token) {
                        setIsLoggedIn(true)
                        setCookie("token", token, {path: "/", maxAge: 60 * 60 * 24 * 30})
                    }

                    if (props.setLoginShow) {
                        props.setLoginShow(false)
                    }
                }}
                customOnHide={props.customOnHide}
                showForgotPassword={() => {
                    setLoginShow(false);
                    setForgotPasswordShow(true)
                }}
            />

            <ForgotPasswordModal
                show={forgotPasswordShow}
                hideForgotPassword={() => setForgotPasswordShow(false)}/>

            <div style={{
                height: props.headerShy || props.hideHeader ? 0 : "3em", overflowX: "hidden",
                display: "flex",
            }}/>
            <div style={{minHeight: " calc(100vh - 27em)", paddingTop: windowWidth > 800 ? 50 : 0}}>
                {
                    props.children
                }
                {/*<a href={"https://wa.me/+628119920220"} target="_blank">*/}
                {/*    <div style={{*/}
                {/*        position: "fixed",*/}
                {/*        bottom: isMobile ? "4em" : "25px",*/}
                {/*        right: "25px",*/}
                {/*        backgroundColor: "white",*/}
                {/*        height: isMobile ? '55px' : '70px',*/}
                {/*        width:  isMobile ? '55px' : "70px",*/}
                {/*        borderRadius: "50%",*/}
                {/*        display: "flex",*/}
                {/*        alignItems: "center",*/}
                {/*        justifyContent: "center",*/}
                {/*        boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",*/}
                {/*        cursor: "pointer",*/}
                {/*        zIndex: "9999999"*/}
                {/*    }}*/}
                {/*    >*/}
                {/*        <FaWhatsapp style={{*/}
                {/*            fontSize:  isMobile ? '25px' : "30px",*/}
                {/*            color: "#25D366"*/}
                {/*        }}/>*/}
                {/*    </div>*/}
                {/*</a>*/}

                <Collapse in={helpExpanded}>
                    <div style={{
                        position: "fixed",
                        bottom: isMobile ? "calc(4em + 70px)" : "120px",
                        right: "25px",
                        display: "flex",
                        flexDirection: "column",
                    }}
                    >
                        <a href={"https://wa.me/+628119561220"} target="_blank">
                            <div style={{
                                display : "flex",
                                flexDirection : "row",
                                alignItems : "center",
                                justifyContent : "flex-end",
                                marginTop: isMobile ? "15px" : "25px"
                            }}>
                                {/*<div style={{*/}
                                {/*    color: "#25D366"*/}
                                {/*}}>*/}
                                {/*    <b>Whatsapp&nbsp;</b>*/}
                                {/*</div>*/}
                                <div style={{
                                    backgroundColor: "#25D366",
                                    height: isMobile ? '55px' : '55px',
                                    width: isMobile ? '200px' : "200px",
                                    // width: isMobile ? '55px' : "70px",
                                    borderRadius: "55px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                                    cursor: "pointer",
                                    zIndex: "9999999",
                                    paddingLeft : "22px"
                                }}
                                >
                                    <FaWhatsapp style={{
                                        fontSize: isMobile ? '25px' : "30px",
                                        color: "white"
                                    }}/>
                                    <b style={{color:"white", marginLeft : "22px"}}>Whatsapp</b>
                                </div>
                            </div>

                        </a>

                        <Link to={"/aduan"}>
                            <div style={{
                                display : "flex",
                                flexDirection : "row",
                                alignItems : "center",
                                justifyContent : "flex-end",
                                marginTop: isMobile ? "15px" : "25px"
                            }}>
                                <div style={{
                                    backgroundColor: "white",
                                    height: isMobile ? '55px' : '55px',
                                    // width: isMobile ? '55px' : "70px",
                                    width: isMobile ? '200px' : "200px",
                                    borderRadius: "55px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                                    cursor: "pointer",
                                    zIndex: "9999999",
                                    paddingLeft : "22px"
                                }}
                                >
                                    <AiOutlineForm style={{
                                        fontSize: isMobile ? '25px' : "30px",
                                        color: Palette.PRIMARY,
                                        marginRight : "22px"
                                    }}/>
                                    <b>Bantuan&nbsp;</b>
                                </div>
                            </div>
                        </Link>

                    </div>
                </Collapse>

                <div
                    onClick={() => {
                        setHelpExpanded(!helpExpanded)
                    }}
                    style={{
                        position: "fixed",
                        bottom: isMobile ? "4em" : "25px",
                        right: "25px",
                        backgroundColor: Palette.SECONDARY,
                        height: isMobile ? '55px' : '70px',
                        width: isMobile ? '55px' : "70px",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                        cursor: "pointer",
                        zIndex: "9999999",
                    }}
                >
                    {/*<FaWhatsapp style={{*/}
                    {/*    fontSize:  isMobile ? '25px' : "30px",*/}
                    {/*    color: "#25D366"*/}
                    {/*}}/>*/}

                    <IoIosChatbubbles style={{
                        fontSize: isMobile ? '25px' : "30px",
                        color: "white"
                    }}/>

                </div>
                {/*</a>*/}

            </div>

            {!props.hideMargin &&
            <div style={{height: "4em", backgroundColor: props.backgroundColor ? props.backgroundColor : "white"}}/>}

            {props.hideFooter ? null : <Footer
                onLoginPress={() => {
                    setLoginShow(true)
                }}
                isLoggedIn={isLoggedIn}
                activeTab={props.headerActive} isBookStoresShown={props.isBookStoresShown}/>}


        </div>

    );

}
