import Dialog from "@material-ui/core/Dialog";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {useState} from "react";
import {Link} from "react-router-dom";

import {FaExternalLinkAlt, FiDownload} from "react-icons/all";
import {FaFileDownload} from "react-icons/fa";

export default function AttachmentUrlModal(props) {
    const {isOpen, onClose, recordings} = props

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const getFileName = (stringValue) => {
        if (!stringValue) {
            return ""
        }

        let segmentedString = stringValue.split("/")

        if (segmentedString.length > 0) {
            return segmentedString[segmentedString.length - 1]
        }

        return stringValue

    }

    return <Dialog open={isOpen} fullWidth={true} onClose={() => onClose()}>
        <Container>
            <Row style={{marginTop: 25, marginBottom: 15}}>
                <Col md={12} style={{fontFamily: 'OpenSans-SemiBold', fontSize: 18, marginLeft: 5, color: '#302a28'}}>
                    Unduh materi
                </Col>
            </Row>

            <Row>
                <Col style={{
                    width: '100%',
                    height: 1,
                    backgroundColor: '#e9eaec',
                    marginTop: 5,
                    marginBottom: 40
                }}/>
            </Row>

            <Row style={{
                borderRadius: 8,
                // padding: 25,
                paddingBottom: 15,
                maxWidth: '700px',
                marginLeft: 0,
                marginRight: 0
            }}>
                <Container>
                    <Row style={{
                        marginBottom: 30
                    }}>
                        {
                            recordings.map((obj, key) => {
                                return <Col md={12} className={"d-flex flex-row"}>
                                    <div style={{flex: 1}}>
                                        {getFileName(obj)}&nbsp;
                                    </div>
                                    <div>
                                        <a style={{marginRight : 5}} href={obj}>
                                            <FaFileDownload/>
                                        </a>
                                        <Link to={"/pdf-page/" + encodeURIComponent(obj)}>
                                            <FaExternalLinkAlt/>
                                        </Link>
                                    </div>

                                    {/*<a href={obj}*/}
                                    {/*   target={"_blank"}*/}
                                    {/*   download*/}
                                    {/*   style={{display : "flex", flexDirection : "row", alignItems:"center"}}>*/}
                                    {/*    <div style={{flex : 1}}>*/}
                                    {/*        {getFileName(obj)}&nbsp;*/}
                                    {/*    </div>*/}
                                    {/*    <div>*/}
                                    {/*        <FiDownload/>*/}
                                    {/*    </div>*/}
                                    {/*</a>*/}
                                    <hr/>
                                </Col>
                            })
                        }
                    </Row>

                </Container>

            </Row>
        </Container>
    </Dialog>


}
