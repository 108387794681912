import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import React, {useState} from "react";

export default function QuizTextInput (props) {
    const [isFocused, setFocused] = useState(false);
    const {question, description, onChange, disabled, value, idx} = props;

    return (
        <Row style={{
            boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
            borderRadius: 10,
            marginLeft: 5,
            marginRight: 5,
            backgroundColor: 'white',
            maxWidth: 900,
            width: '100%',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 35,
            paddingBottom: 25,
            marginTop: 20
        }}
        >
            <Col md={12} style={{fontFamily: 'OpenSans-SemiBold', fontSize: '18px', color: '#202124', whiteSpace : "pre-line"}}>
                {idx+1}. {question}
            </Col>

            <Col md={12}
                 style={{fontFamily: 'OpenSans-Regular', fontSize: '15px', marginTop: '4px', color: '#878787'}}>
                {description}
            </Col>

            <Col xs={12}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Control
                        as="textarea"
                        rows="3"
                        value={value}
                        disabled={disabled}
                        onChange={(event) => onChange(event.target.value)}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        style={{
                            color: 'black',
                            boxShadow: 'none',
                            fontSize: '18px',
                            fontFamily: 'OpenSans-Regular',
                            borderTopWidth: 0,
                            borderLeftWidth: 0,
                            borderRightWidth: 0,
                            borderRadius: 0,
                            borderBottomWidth: 2,
                            marginTop: 15,
                            borderColor: isFocused ? '#3882e0' : 'grey'
                        }} placeholder="Jawaban Anda"/>
                </Form.Group>
            </Col>
        </Row>
    )
}
