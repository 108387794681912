import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {useState} from "react";
import {IoMdStar} from "react-icons/io";
import Rating from "react-rating";

export default function FeedbackCard(props) {

    let {rating, setRating} = props

    return (
        <Col xs={12} style={{paddingLeft: 0, paddingRight: 0}}>
            <Container style={{
                border: '1px solid #d7d7d7CC',
                borderRadius: 5,
                fontFamily: 'OpenSans-Regular',
                fontSize: 15,
                paddingLeft: 25,
                marginBottom: 10,
                paddingTop: 25,
                paddingBottom: 25
            }}>
                <Row>
                    <Col xs={12} md={6} style={{marginBottom: props.windowWidth < 768 ? 20 : 0}}>
                        <span style={{fontFamily: 'OpenSans-SemiBold'}}>{props.name}</span>
                    </Col>

                    <Col style={{textAlign: 'right'}}>
                        <Rating
                            value={rating}
                            onChange={(rating) => setRating(rating)}
                            initialRating={rating}
                            emptySymbol={<IoMdStar size={40} color={'#C4C4C4'}/>}
                            fullSymbol={<IoMdStar color={'red'} size={40} color={'#FEBF35'}/>}/>
                        {/*                <span style={{*/}
                        {/*                    fontFamily: 'OpenSans-Regular',*/}
                        {/*                    borderRadius: 100,*/}
                        {/*                    border: '1px solid #d7d7d7CC',*/}
                        {/*                    paddingLeft: 15,*/}
                        {/*                    paddingRight: 15,*/}
                        {/*                    paddingTop: 5,*/}
                        {/*                    paddingBottom: 5,*/}
                        {/*                    cursor: 'pointer',*/}
                        {/*                    backgroundColor: props.status === false ? '#d7d7d7CC' : 'white',*/}
                        {/*                }} onClick={props.onNo}>*/}
                        {/*                    Tidak*/}
                        {/*                </span>*/}

                        {/*<span style={{*/}
                        {/*    marginLeft: 12,*/}
                        {/*    borderRadius: 100,*/}
                        {/*    border: '1px solid #d7d7d7CC',*/}
                        {/*    paddingLeft: 15,*/}
                        {/*    paddingRight: 15,*/}
                        {/*    paddingTop: 5,*/}
                        {/*    paddingBottom: 5,*/}
                        {/*    backgroundColor: props.status === true ? '#d7d7d7CC' : 'white',*/}
                        {/*    cursor: 'pointer'*/}
                        {/*}} onClick={props.onYes}>*/}
                        {/*                    Iya*/}
                        {/*                </span>*/}
                    </Col>
                </Row>
            </Container>
        </Col>
    )
}
