import React, {useEffect, useRef, useState} from "react";
import PageWrapper from "./PageWrapper";
import GlobalData from "../../util/GlobalData";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Palette from "../../util/Palette";
import {isMobile} from "react-device-detect";
import Card from "react-bootstrap/Card";
import Book from "../../models/News"
import textFormatter from "../../util/textFormatter";
import ApplicationActivities from "../../models/ApplicationActivity";
import {Modal} from "react-bootstrap";
import {useCookies} from "react-cookie";
import Merchandise from "../../models/Merchandise";
import Strings from "../../util/Strings";
import moment from "moment";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Dialog from "@material-ui/core/Dialog";

const stores = [
    {
        url: "https://www.tokopedia.com/indiebookcorner/etalase/50-buku-rekomendasi-Seminar Edukasi Musik",
        name: "Toko Budi",
        marketplace: "Tokopedia",
        image: require("../../asset/logo-tokped.png")
    }
]

const storesEmpty = []

const mockupMerchandise = [
    {
        id: 1,
        name: 'Death Note',
        image_url: "http://my-test-11.slatic.net/p/3/fashion-anime-theme-death-note-cosplay-notebook-new-school-large-writing-journal-205cm145cm-2020-428441011-eeca78e3b15ea2c9954072f210756dfa-.jpg_200x200q90.jpg",
        description: 'Nama orang yang ditulis di buku ini akan meninggal.',
        price: 73000,
        marketplace_link: "https://www.lazada.co.id/products/modis-anime-tema-death-note-cosplay-buku-catatan-sekolah-baru-besar-jurnal-menulis-205-cm-145-cm-internasional-i305458111-s305914727.html?spm=a2o4j.searchlist.list.2.664561a2neOIte&search=1",
        active: 1,
        created_at: '2021-03-26 05:16:50',
        modified_at: '2021-03-26 05:16:50'
    }
]

export default function BookStore(props) {
    const [highlight, setHighlight] = useState([]);
    const [specials, setSpecials] = useState([]);
    const [merchandise, setMerchandise] = useState([]);
    let [windowIsLarge, setWindowIsLarge] = useState(window.innerWidth > 1199)
    const [selectedBook, setSelectedBook] = useState({additional_information: {}});
    const [isBookModalShown, setBookModalShown] = useState(false);
    const [isLanguageInfoOpen, setIsLanguageInfoOpen] = useState(true);
    const [cookie, setCookie, removeCookie] = useCookies()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [productImages, setProductImages] = useState([]);
    const [productImgHoveredIdx, setProductImgHoveredIdx] = useState(-1);
    const [selectedProductImgIdx, setSelectedProductImgIdx] = useState(0);
    const productImgRef = useRef(null);
    const [productImageTransformOrigin, setImageTransformOrigin] = useState(null);
    const [isImageLoaded, setImageLoaded] = useState(false);

    let isEN = cookie.lang !== "id"
    console.log("is en ? " + isEN)

    console.log("is window large ? " + windowIsLarge)

    const updateWindowDimensions = () => {
        console.log(window.innerWidth)

        setWindowWidth(window.innerWidth);
    }

    useEffect(() => {
        getMerchandise()
        ApplicationActivities.sendActivity({
            type: "BOOKLIST",
            description: "",
            timestamp: new Date(),
            os: isMobile ? "Mobile Webiste" : "Website"
        })
    }, [])

    async function getMerchandise() {
        try {
            let result = await new Merchandise().getAll();
            console.log("Merchandise:", result)
            setMerchandise(result);
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <PageWrapper
            headerShy={false}
            headerActive={'book-store'}
            selectedBook={selectedBook}>
            <Dialog open={isBookModalShown} maxWidth={'md'} fullWidth={true} onClose={() => {
                setBookModalShown(false)
            }}
            >
                <Container style={{minHeight: '60vh'}}>
                    <Row style={{margin: 'auto'}}>
                        <Col style={{paddingBottom: 30, marginLeft: 30}}>
                            <div
                                style={{
                                    width: '100%',
                                    height: undefined,
                                    overflow: 'hidden',
                                    marginTop: 40,
                                }}
                                ref={productImgRef}
                                onMouseLeave={() => setImageTransformOrigin(null)}
                                onMouseMove={(e) => {
                                    if (productImages[selectedProductImgIdx]?.loaded) {
                                        setImageTransformOrigin({
                                            x: (e.clientX - productImgRef.current.getBoundingClientRect().left) / productImgRef.current.clientWidth * 100,
                                            y: (e.clientY - productImgRef.current.getBoundingClientRect().top) / productImgRef.current.clientHeight * 100
                                        })
                                    }
                                }}
                            >
                                <img
                                    onLoad={() => setImageLoaded(true)}
                                    // onClick={() => {
                                    //     if (isImageLoaded && session.class_image_url) setImagePreview(true)
                                    // }}
                                    src={productImages[selectedProductImgIdx]?.url ? productImages[selectedProductImgIdx]?.url : '/image/no-img-placeholder.png'}
                                    style={{
                                        cursor: productImages[selectedProductImgIdx]?.loaded ? 'crosshair' : 'auto',
                                        objectFit: 'contain',
                                        width: 300,
                                        height: 300,
                                        maxHeight: 300,
                                        backgroundColor: 'white',
                                        transform: productImageTransformOrigin ? 'scale(1.5)' : 'none',
                                        transformOrigin: productImageTransformOrigin ? `${productImageTransformOrigin.x}% ${productImageTransformOrigin.y}%` : '50% 50%'
                                    }}/>
                            </div>
                            <Row style={{
                                marginLeft: 0,
                                marginRight: 0,
                                borderTop: '1px solid #e5e7e9',
                                marginTop: 20,
                                paddingTop: 15
                            }}>
                                {
                                    productImages.map(
                                        (productImage, idx) => {
                                            console.log('images', productImage)
                                            return (
                                                <Col
                                                    onMouseOver={() => {
                                                        setProductImgHoveredIdx(idx)
                                                    }}
                                                    onMouseLeave={() => {
                                                        setProductImgHoveredIdx(-1)
                                                    }}
                                                    onLoad={() => {
                                                        const productImagesTemp = [...productImages];

                                                        productImagesTemp[idx].loaded = true;

                                                        setProductImages(productImagesTemp);
                                                    }}
                                                    onClick={() => {
                                                        setSelectedProductImgIdx(idx)
                                                    }}
                                                    style={{
                                                        marginLeft: 0,
                                                        marginRight: 15,
                                                        backgroundColor: productImage.loaded ? 'white' : '#f0f0f0',
                                                        maxWidth: 70,
                                                        maxHeight: 70,
                                                        borderRadius: 5,
                                                        boxShadow: productImgHoveredIdx === idx || selectedProductImgIdx === idx ? '0 3px 6px 0 rgba(49,53,59,0.5)' : 'none',
                                                        paddingLeft: 0,
                                                        cursor: 'pointer',
                                                    }}>
                                                    <img
                                                        src={productImage.url}
                                                        style={{
                                                            objectFit: 'contain',
                                                            width: 70,
                                                            height: 70,
                                                            // backgroundColor: 'white',
                                                            borderRadius: 5,
                                                        }}/>
                                                </Col>
                                            )
                                        }
                                    )
                                }
                            </Row>
                        </Col>
                        <Col
                            sm={12}
                            md={6}
                            style={{
                                backgroundColor: '',
                                padding: 0,
                                marginRight: 50,
                                paddingTop: windowWidth < 768 ? 30 : 50,
                                display: 'flex',
                                flexDirection: 'column',
                                // justifyContent: 'center',
                                order: windowWidth < 768 ? 1 : 0
                            }}>
                            <div style={{fontFamily: 'OpenSans-Bold', fontWeight: '400', fontSize: '1rem', color: 'rgba(49, 53, 59, 0.96)'}}>
                                {selectedBook.name}
                            </div>

                            <div style={{fontFamily: 'OpenSans-Bold', fontWeight: '300', fontSize: '1.7rem', color: 'rgba(49, 53, 59, 0.96)'}}>
                                Rp{selectedBook.price ? textFormatter.moneyFormatter(selectedBook.price) : selectedBook.price}
                            </div>

                            <div style={{fontFamily: 'OpenSans-Bold', marginTop: 50, fontSize: '.8rem', color: Palette.PRIMARY}}>
                                DESKRIPSI
                            </div>

                            <div style={{
                                fontFamily: 'OpenSans-Regular',
                                marginTop: 10, maxHeight: '34vh',
                                width: '110%', overflow: 'auto',
                                whiteSpace : "pre-line"
                            }}>
                                {selectedBook.description ? selectedBook.description : '-'}
                            </div>
                        </Col>

                    </Row>


                    <Row>
                        <Col style={{
                            width: '100%',
                            height: 1,
                            backgroundColor: '#e9eaec',
                            marginTop: 25,
                            marginBottom: 25,
                        }}/>
                    </Row>

                    <Row>
                        <Col style={{
                            display: 'flex',
                            flexDirection: windowWidth < 550 ? 'column' : 'row',
                            justifyContent: 'flex-end',
                            marginRight: 16,
                            marginBottom: 22,
                            fontFamily: 'OpenSans-Bold'
                        }}>
                            <Button variant={'link'} style={{
                                marginRight: windowWidth < 550 ? 0 : 20,
                                order: windowWidth < 550 ? 2 : 0,
                                marginTop: windowWidth < 550 ? 8 : 0
                            }}
                                    onClick={() => setBookModalShown(false)}>
                                Batal
                            </Button>

                            <a href={selectedBook.marketplace_link} target={'_blank'} style={{textDecoration: 'none'}}
                               onClick={() => {
                                   console.log(selectedBook.marketplace_link)
                               }}>
                                <Button
                                    style={{
                                        width: windowWidth < 550 ? '100%' : 100,
                                        height: 40,
                                        order: windowWidth < 550 ? 0 : 2
                                    }}>
                                    Beli
                                </Button>
                            </a>
                        </Col>
                    </Row>
                </Container>

            </Dialog>


            <Container style={{marginBottom: '60px'}} fluid>
                {isMobile ?
                    <Row style={{marginTop: '12%'}}>
                        <Col
                            md={12}
                            style={{
                                color: Palette.PRIMARY,
                                fontSize: "1.5em",
                                fontFamily: 'OpenSans-Bold'
                            }}>
                            Publikasi
                        </Col>
                    </Row> : null}


                <Row style={{
                    paddingInlineStart: isMobile ? "2%" : windowIsLarge ? "12%" : "5%",
                    paddingInlineEnd: isMobile ? "2%" : windowIsLarge ? "12%" : "5%",
                    alignItems: "flex-start",
                    justifyContent: "flex-start"
                }} noGutters={isMobile}>
                    {
                        storesEmpty.map((obj, key) => {
                            return <Col
                                style={{
                                    marginBottom: "3px",
                                    padding: "2px",

                                }} md={3}>
                                <a target={"_blank"} rel="noopener noreferrer"
                                   href={obj.url}>

                                    <Card>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            color: "black"
                                            // justifyContent : "center"
                                        }}>
                                            <img
                                                style={{
                                                    width: "4em",
                                                    height: "4em",
                                                    objectFit: "contain"
                                                }}
                                                src={obj.image}/>

                                            <div style={{width: "0.2em"}}/>

                                            <div style={{
                                                flex: 1,
                                                display: "flex",
                                                flexDirection: "column"
                                            }}>
                                                <div style={{
                                                    fontSize: "0.7em"
                                                }}>
                                                    {obj.marketplace}<br/>
                                                </div>

                                                <div style={{
                                                    fontSize: "0.9em"
                                                }}>
                                                    <b>{obj.name}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </a>
                            </Col>
                        })
                    }
                </Row>
                <div
                    style={{
                        paddingLeft: isMobile ? null: '',
                        backgroundColor: ''
                    }}
                >
                    <Row style={{
                        paddingInlineStart: isMobile ? "2%" : windowIsLarge ? "12%" : "5%",
                        paddingInlineEnd: isMobile ? "2%" : windowIsLarge ? "12%" : "5%",
                        marginTop: '30px'
                    }}
                    >

                        <Col
                            md={12}>
                            {
                                !isMobile ?
                                    <div
                                        style={{
                                            color: Palette.PRIMARY,
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            fontWeight: "bold",
                                            fontSize: "1.5em",
                                            cursor: "pointer",
                                            userSelect: "none",
                                            marginTop: 40,
                                            marginBottom: '3em'

                                        }}
                                    >
                                        Publikasi
                                    </div> : null
                            }

                        </Col>
                    </Row>

                    <Row style={{
                        paddingTop: 10,
                        paddingInlineStart: isMobile ? "0%" : windowIsLarge ? "12%" : "5%",
                        paddingInlineEnd: isMobile ? "0%" : windowIsLarge ? "12%" : "5%",
                        backgroundColor: ''
                    }} noGutters={isMobile}
                    >
                        {
                            merchandise.map((merch, key) => {
                                return <Col
                                    // style={{
                                    //     paddingInlineStart: isMobile && key % 2 === 1 ? "0.15em" : "0.3em",
                                    //     paddingInlineEnd: isMobile && key % 2 !== 1 ? "0.15em" : "0.3em"
                                    // }}
                                    xs={6}
                                    md={{span: 3}}
                                    // xl={{span: 3}}
                                    // md={{span: 3}}
                                >
                                    <a href={'#'}
                                       onClick={() => {
                                           console.log(merch)
                                           setSelectedBook(merch)
                                           let tempArr = [];

                                           tempArr.push({url: merch.image_url})

                                           for (let i in merch.other_image_url) {
                                               tempArr.push({url: merch.other_image_url[i]})
                                           }

                                           console.log('image array', tempArr)
                                           setProductImages(tempArr)
                                           setBookModalShown(true);
                                       }}>

                                        <Card style={{width: '100%'}}>

                                            <div style={{
                                                width: "100%",
                                                paddingTop: "100%",
                                                backgroundImage: `url(${merch.image_url})`,
                                                backgroundSize: "contain",
                                                backgroundPosition: "center",
                                                backgroundRepeat: "no-repeat",
                                                backgroundColor: "white"
                                            }}/>

                                            {/*<Card.Img*/}
                                            {/*    style={{*/}
                                            {/*        width: "100%",*/}
                                            {/*        height: isMobile ? "15em" : windowIsLarge ? "30em" : "20em",*/}
                                            {/*        objectFit: "cover"*/}
                                            {/*    }}*/}
                                            {/*    variant="top" src={info.image}/>*/}
                                            <Card.Body style={{
                                                // height: isMobile || !windowIsLarge ? "8em" : "8rem",
                                                color: "black",
                                                display: "flex",
                                                flexDirection: "column",
                                                padding: "0.5rem"
                                            }}>
                                                <div style={{
                                                    fontSize: '0.86rem',
                                                    fontWeight: 'OpenSans-Regular',
                                                    color: 'rgba(49, 53, 49, .96)'
                                                }}>
                                                    {merch.name}
                                                </div>
                                                {/*}
                                            <p style={{
                                                fontSize: isMobile || !windowIsLarge ? "0.65rem" : "0.875rem",
                                                lineClamp: 2,
                                                fontWeight: 'OpenSans-SemiBold'
                                            }}>{merch.description} {isMobile || !merch.description ? "" : null} {isMobile ? "" : null}</p>
                                            {*/}

                                                <div style={{
                                                    fontSize: ".95rem",
                                                    fontFamily: 'OpenSans-Bold',
                                                    color: 'rgba(49, 53, 49, .96)'
                                                }}>
                                                    Rp{textFormatter.moneyFormatter(merch.price)}
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </a>
                                    <div style={{
                                        height: 10
                                    }}/>
                                </Col>
                            })
                        }
                    </Row>
                </div>


            </Container>
        </PageWrapper>
    )
}
