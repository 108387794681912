import React, { Component, useState, useEffect } from "react";
import { Button, ButtonGroup, Modal, Nav, Navbar, NavDropdown } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { FaCalendar, FaList } from "react-icons/fa";
import Col from "react-bootstrap/Col";
import PageWrapper from "./PageWrapper";
import moment from "moment";
import ScheduleNode from "../reusable/ScheduleNode";
import Session from "../../models/Session"
import _ from "lodash"
import SessionModal from "../reusable/SessionModal";
import GlobalData from "../../util/GlobalData";
import 'moment/locale/id'
import cookie from 'react-cookies'
import { useHistory } from "react-router-dom"

import Select from 'react-select';

import {
    isMobile
} from "react-device-detect";
import ApplicationActivities from "../../models/ApplicationActivity";
import Palette from "../../util/Palette";
import SessionCard from "../reusable/SessionCard";

import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { useCookies } from "react-cookie";
import Strings from "../../util/Strings";
import MenuItem from "@material-ui/core/MenuItem";
import IndependentClassCard from "../reusable/IndependentClassCard";
import Course from "../../models/Course";

let firstLoad = true

export default function Sessions(props) {

    const [cookie, setCookie, removeCookie] = useCookies()
    const history = useHistory()

    let [sessions, setSessions] = useState([])
    let [otherSessions, setOtherSessions] = useState([])
    let [gsmsSessions, setGsmsSessions] = useState([])

    let [categories, setCategories] = useState({})
    let [selectedCategory, setSelectedCategory] = useState(Strings.main_menu.all)

    let [sessionViewMode, setSessionViewMode] = useState(true)

    let [selectedFilterIndex, setSelectedFilterIndex] = useState(0)
    let [registeredSession, setRegisteredSession] = useState([])
    const [isLoginVisible, setLoginVisible] = useState(false);
    const [loginMessage, setLoginMessage] = useState("")

    const [isLoggedIn, setIsLoggedIn] = useState(GlobalData.token || cookie.token)

    const hashUrl = props.location?.hash;

    const [courses, setCourses] = useState([]);

    useEffect(() => {
        switch (hashUrl) {
            case '#kelas-publik-mendatang':
                setSelectedFilterIndex(0);
                break;

            case '#kelas-saya':
                setSelectedFilterIndex(1);
                break;

            case '#kelas-daring-mandiri':
                setSelectedFilterIndex(2);
                break;

            case '#acara-lain':
                setSelectedFilterIndex(3);
                break;

            case '#gsms':
                setSelectedFilterIndex(4);
                break;

            default:
                history.push('/kelas#kelas-publik-mendatang')
                break;
        }
    }, [hashUrl])

    useEffect(() => {
        if (props.location.pathname === '/account-activated' && !isLoggedIn) {
            setLoginVisible(true)
            setLoginMessage('Akun Anda telah diaktifkan, silakan masuk.')
        }
        fetchSessions()
        fetchRegisteredSessions()
        fetchCourses()
    }, [cookie.token])

    const fetchCourses = async () => {
        try {
            const result = await new Course().getAll()

            setCourses(result)
        } catch (e) {
            console.log(e)
        }
    }

    const fetchRegisteredSessions = async () => {
        try {
            const sessionModel = await new Session().getRegisteredClass()


            let formattedSessions = [];

            for (let session of sessionModel) {


                if (session.class_code) {
                    session.class.classStatus = session.class.status
                    session.class.status = session.status;

                    if (session.status !== "REJECTED") {
                        formattedSessions.push(session.class)
                    }
                } else if (session.status !== "REJECTED") {
                    console.log('session', session)

                    formattedSessions.push(session)
                }

            }

            console.log("fms", formattedSessions)

            setRegisteredSession(formattedSessions)

        } catch (e) {
            e.API = 'fetchSessions'
            console.log(e)
        }
    }

    const fetchSessions = async () => {
        try {
            const unformattedCategory = await new Session().getAllCategory();
            const categoriesTemp = {}

            categoriesTemp[Strings.main_menu.all] = { isHovered: false, data: [] }

            // categories = {
            //     All: {isHovered: false},
            //     Drum: {isHovered: false},
            //     Guitar: {isHovered: false},
            //     Vocals: {isHovered: false}
            // };
            unformattedCategory.map(category => {
                categoriesTemp[category.code] = { isHovered: false, data: [], name: category.name }
            })

            try {
                const result = await new Session().getAll()
                const sessionTemp = [...result];

                let normalSessions = []
                let otherSessions = []
                let gsmsSessions = []

                for (let s of sessionTemp) {
                    if (s.topic_code === "4ZLBL2UED0") {
                        otherSessions.push(s)
                    } else if (s.topic_code === "4KCSLSAUV5") {
                        gsmsSessions.push(s)
                    } else {
                        normalSessions.push(s)
                    }
                }

                categoriesTemp[Strings.main_menu.all].data = normalSessions;

                console.log(categoriesTemp[Strings.main_menu.all].data)

                result.map((session, idx) => {
                    // console.log(session.topic_code)
                    if (categoriesTemp[session.topic_code]) {
                        sessionTemp[idx].topic = categoriesTemp[session.topic_code].name;
                        categoriesTemp[session.topic_code].data.push(session);
                    }
                })


                // console.log(categoriesTemp)


                console.log("set session is called", normalSessions, otherSessions)

                console.log(categoriesTemp)
                delete categoriesTemp["4ZLBL2UED0"]
                delete categoriesTemp["4KCSLSAUV5"]
                setCategories({...categoriesTemp})
                setSessions(normalSessions)
                setOtherSessions(otherSessions)
                setGsmsSessions(gsmsSessions)

            } catch (e) {
                e.API = 'fetchSessions'
                console.log(e)
            }
        } catch (e) {
            console.log(e)
        }

    }

    const renderCategoryNode = (category) => {

        return (
            <div onClick={() => {
                setSelectedCategory(category)
                console.log("woi " + category)
            }} style={{
                cursor: 'pointer',
                fontSize: 14,
                letterSpacing: 2,
                fontWeight: '400',
                color: categories[category].isHovered || selectedCategory === category ? Palette.PRIMARY : '#868887',
                marginLeft: 30,
                marginBottom: 15
            }}
                onMouseOver={() => {
                    const categoriesTemp = { ...categories };

                    categoriesTemp[category] = {
                        ...categoriesTemp[category],
                        isHovered: true
                    }
                    setCategories(categoriesTemp)

                }}
                onMouseLeave={() => {
                    const categoriesTemp = { ...categories };

                    categoriesTemp[category] = {
                        ...categoriesTemp[category],
                        isHovered: false
                    }

                    setCategories(categoriesTemp)
                }}>
                {category !== Strings.main_menu.all ? categories[category].name : Strings.main_menu.all}
                <div style={{
                    width: categories[category].isHovered || selectedCategory === category ? '100%' : 0,
                    height: 1,
                    backgroundColor: Palette.PRIMARY,
                    marginTop: 3,
                    transition: 'width .1s'
                }} />
            </div>
        )
    }

    const renderSessionCategory = () => {

        let formattedCategories = [{ key: "Semua", label: "Semua" }]

        for (let category of Object.keys(categories)) {


            if (categories[category]?.data?.length > 0 && category !== "Semua") {
                formattedCategories.push({
                    key: category,
                    label: categories[category].name,
                })
            }

        }

        return (
            <Row style={{ marginTop: isMobile ? 0 : 60, marginLeft: 0, marginRight: 0 }}>

                {
                    isMobile ?
                        <Col md={12} style={{
                            width: "100vw",
                            padding: 0,
                            fontFamily: 'OpenSans-Bold'
                        }}>
                            Kategori :
                            <Select
                                options={formattedCategories.map((obj, key) => {
                                    return {
                                        label: obj.label,
                                        value: key,
                                        code: obj.key
                                    }
                                })}
                                style={{
                                    display: "flex", width: "100%"
                                }}
                                onChange={(selected) => {
                                    console.log("changing to ", selected)
                                    // console.log('val ' + selected.label)
                                    setSelectedCategory(selected.code)
                                }}
                                placeholder={"Semua Kategori"}
                                fullWidth
                            />
                        </Col>
                        :
                        <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Row>

                                {
                                    Object.keys(categories).map(category => {

                                        if (categories[category]?.data?.length > 0) {
                                            // console.log(category)
                                            // return renderCategoryNode(category)
                                        }

                                    })
                                }
                            </Row>

                        </Col>
                }

            </Row>
        )

    }

    const renderIndependentClass = () => {
        return <>
            {
                courses
                    .map((course, key) => {
                        console.log("key " + key)
                        return <IndependentClassCard
                            image={course.course_image_url}
                            title={course.title}
                            index={key + 1}
                            onClick={() => {
                                if (course.course_code) {
                                    history.push(`/kdm/detail/${course.course_code}`)
                                } else {
                                    history.push(`/kdm/${course.id}`)
                                }
                            }}
                        />
                    })
            }
        </>

    }

    const renderSessionGridMode = (sessions) => {

        console.log("once", sessions)

        return <>{
            sessions.length > 0 ?

                sessions.map(session => {
                    if (session.status === 'EXPIRED') return null

                    return (
                        <SessionCard
                            classStatus={session.classStatus}
                            registrationStatus={session.status}
                            permanent={session.permanent || session.course}
                            classDate={session.class_date}
                            image={session.class_image_url && session.code ? session.class_image_url : session.course && session.course?.course_image_url ? session.course?.course_image_url : '/image/no-img-placeholder.png'}
                            name={session.code ? session.name : session.course?.title}
                            topic={session.code ? session.topic : session.course && session.course?.category ? JSON.parse(session.course?.category).join(', ') : ''}
                            onClick={() => {
                                if (session.code) {
                                    history.push(`/kelas/detail/${session.code}`)
                                } else if (session.course?.course_code) {
                                    history.push(`/kdm/detail/${session.course?.course_code}`)
                                } else {
                                    history.push(`/kdm/${session.course?.id}`)
                                }
                            }}/>
                    )
                })
                :
                <div style={{
                    fontSize: "1.2em",
                    color: Palette.PRIMARY,
                    height: "50vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%"
                }}>
                    <b>Belum ada kelas mendatang</b>
                </div>
        }</>
    }

    const renderSessionListView = () => {

        if (categories[selectedCategory]) {

            let filteredData = categories[selectedCategory].data

            let temp = filteredData.filter((obj, key) => {

                if (key > 70 && key < 74) {
                    console.log(key)
                    console.log(obj.start_time)
                }

                return new Date(obj.end_time) > new Date()
            })

            return (
                <>
                    {
                        // renderSessionCategory()
                    }
                    <Row>
                        {
                            renderSessionGridMode(temp)
                        }

                    </Row>
                </>
            )
        }
    }

    const renderUpcomingSession = () => {

        // <SessionCard
        //     image={session.class_image_url ? session.class_image_url : '/image/no-img-placeholder.png'}
        //     name={session.name}
        //     teacher={session.source}
        //     onClick={() => this.props.history.push(`/kelas/detail/${session.code}`)}/>


        return <Row style={{ marginTop: isMobile ? "0em" : "2em", transition: 'height 5s' }}>

            <Col md={12}>

                {
                    sessionViewMode || isMobile ?
                        renderSessionListView()
                        :
                        renderSessionBigCalendar()
                }

                <ButtonGroup
                    style={{
                        display: isMobile ? "none" : null,
                        position: "absolute",
                        top: 0,
                    }}
                    color="primary" aria-label="outlined primary button group">

                    <Button
                        variant={"outlined"}
                        onClick={() => {
                            setSessionViewMode(true)
                        }}
                    >
                        <FaList style={{ color: sessionViewMode ? Palette.PRIMARY : "grey" }} />
                    </Button>
                    <Button
                        variant={"outlined"}
                        onClick={() => {
                            setSessionViewMode(false)
                        }}
                    >
                        <FaCalendar style={{ color: !sessionViewMode ? Palette.PRIMARY : "grey" }} />
                    </Button>


                </ButtonGroup>

            </Col>

        </Row>
    }

    const renderSessionBigCalendar = () => {

        let formattedSession = [];

        for (let key in sessions) {

            let obj = sessions[key]

            if (new Date(obj.start_time) < new Date()) {

            } else {
                formattedSession.push({
                    id: key,
                    title: obj.name,
                    date: new moment(obj.start_time).format("YYYY-MM-DD")
                    // start:new moment(obj.registration_start_time).format("YYYY-MM-DD"),
                    // end:new moment(obj.registration_end_time).format("YYYY-MM-DD"),
                    // allDay:false,
                })
            }

        }

        //2020-08-05
        return <FullCalendar
            locale={'id'}
            headerToolbar={{
                left: '',
                center: 'title',
                right: 'prev,next'
                // right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            defaultView="dayGridMonth"
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            weekends={true}
            events={formattedSession}
            eventContent={(eventInfo) => {

                let eventData = sessions[eventInfo.event.id]
                return <>
                    <div
                        style={{
                            background: Palette.SECONDARY, padding: 5, display: "flex",
                            flexDirection: "column",
                            cursor: "pointer"
                        }}
                        onClick={() => {
                            history.push(`/kelas/detail/${eventData.code}`)
                        }}
                    >
                        <p style={{
                            flex: 1, textAlign: "left",
                            whiteSpace: "pre-line"
                        }}>
                            <b style={{
                                textTransform: "capitalize"
                            }}>{eventData.name}</b><br />
                            {eventData.source}

                        </p>
                        <p style={{
                            flex: 1, wordWrap: "break-word", textAlign: "right",
                            paddingBottom: 0, marginBottom: 0,
                            paddingTop: 0, marginTop: 0,
                        }}>
                            <div style={{
                                flex: 1, wordWrap: "break-word", textAlign: "right",
                                height: 1,
                                width: "100%",
                                backgroundColor: "white"
                            }} />

                            <div style={{ height: "1em" }}></div>

                        </p>
                    </div>
                </>
            }}
        // events={[
        //     { title: 'event 1', date: '2020-08-11' },
        //     { title: 'event 2', date: '2020-08-22' }
        // ]}
        />

    }


    const renderEventFilter = () => {

        const renderOldHeaders = () => {
            return <>
                {isMobile ?

                    GlobalData.token && registeredSession.length > 0 ?
                        <Col
                            md={12}>
                            Filter Kelas
                            <Select
                                options={initialFilters.map((obj, key) => {

                                    let tempFilters = initialFilters.map((obj2, key2) => {
                                        return {
                                            ...obj2,
                                            selected: obj2 === obj
                                        }
                                    })

                                    if (obj.visible()) {
                                        return {
                                            label: obj.name,
                                            value: key
                                        }
                                    }

                                })}
                                style={{
                                    display: "flex", width: "100%"
                                }}
                                onChange={(selected) => {
                                    console.log('val ' + selected.value)
                                    setSelectedFilterIndex(selected.value)
                                }}
                                placeholder={"Kelas Publik"}
                                fullWidth
                            />
                        </Col>
                        :
                        <Col
                            md={12}>
                            <div
                                style={{
                                    color: Palette.PRIMARY,
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    fontWeight: "bold",
                                    fontSize: "1.5em",
                                    cursor: "pointer",
                                    userSelect: "none",
                                    marginTop: "1em"
                                }}
                            >
                                {Strings.main_menu.upcoming_class}
                            </div>
                        </Col>


                    :
                    <Col md={12} style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                        paddingTop: 50
                    }}
                    >
                        {initialFilters.map((obj, key) => {

                            let selected = key === selectedFilterIndex

                            if (!obj.visible()) {
                                return;
                            }

                            return <div
                                key={key}
                                style={{
                                    color: selected ? Palette.PRIMARY : "grey",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginRight: isMobile ? 2 : 10,
                                    fontWeight: selected ? "bold" : "normal",
                                    fontSize: "1.5em",
                                    cursor: "pointer",
                                    userSelect: "none"
                                }}
                                onClick={() => {
                                    let tempFilters = initialFilters.map((obj2, key2) => {
                                        return {
                                            ...obj2,
                                            selected: obj2 === obj
                                        }
                                    })

                                    console.log(key, "keys")

                                    setSelectedFilterIndex(key)

                                }}
                            >
                                {/*{obj.name}*/}
                                <span style={{
                                    height: "75%",
                                    width: 5,
                                    marginLeft: isMobile ? 5 : 10,
                                    backgroundColor: selected ? Palette.PRIMARY : "grey"
                                }} />
                            </div>
                        })}
                    </Col>
                }
            </>
        }

        let initialFilters = [
            {
                name: Strings.main_menu.upcoming_class,
                selected: true,
                visible: () => {
                    return true
                },
                renderBody: renderUpcomingSession
            },
            {
                name: Strings.main_menu.my_class,
                selected: false,
                visible: () => {
                    return GlobalData.token && registeredSession.length > 0 || false
                },
                renderBody: () => {

                    console.log(registeredSession)

                    return <Row>
                        {renderSessionGridMode(registeredSession)}
                    </Row>
                }
            },
            {
                name: 'Kelas Mandiri',
                selected: false,
                visible: () => {
                    return GlobalData.token && registeredSession.length > 0 || false
                },
                renderBody: () => {
                    return <Row>
                        {renderIndependentClass()}
                    </Row>
                }
            },
            {
                name: 'Acara Lain',
                selected: false,
                renderBody: () => {
                    return <Row>
                        {renderSessionGridMode(otherSessions)}
                    </Row>
                }
            },
            {
                name: 'GSMS',
                selected: false,
                renderBody: () => {
                    return <Row>
                        {renderSessionGridMode(gsmsSessions)}
                    </Row>
                }
            },
        ]

        return <>
            <Row style={{
                marginTop: isMobile ? 0 : "2em",
                fontFamily: 'OpenSans-Bold'
            }}>

            </Row>

            <Row style={{ marginTop: isMobile ? "0em" : "2em", transition: 'height 5s' }}>
                <Col
                    md={12}
                    style={{
                        color: Palette.PRIMARY,
                        fontSize: "1.5em",
                        fontFamily: 'OpenSans-Bold'
                    }}>
                    {
                        selectedFilterIndex === 0 ?
                            "Kelas Publik Mendatang" :
                            selectedFilterIndex === 1 ?
                                "Kelas Saya" :
                                selectedFilterIndex === 2 ?
                                    "Kelas Mandiri"
                                    :
                                    selectedFilterIndex === 3 ?
                                        "Acara Lain"
                                        :
                                        "GSMS"
                    }
                </Col>
            </Row>

            {initialFilters[selectedFilterIndex].renderBody()}

        </>

    }

    return (
        <PageWrapper
            customOnHide={isLoginVisible ? () => {
                setLoginVisible(false)
                setLoginMessage(null)
            } : null}
            headerShy={false}
            history={history}
            loginShow={isLoginVisible}
            message={loginMessage}
            loginListener={(user) => {
                fetchSessions()
                fetchRegisteredSessions()
            }}
            headerActive={'my-session'}
        >


            <Container style={{ marginTop: isMobile ? "3em " : "1em" }}>
                <Row style={{
                    marginTop: isMobile ? 0 : "2em"
                }}>
                </Row>

                {
                    renderEventFilter()
                }
                {/*{this.state.sessions.map((session, key) => {*/}
                {/*    return <ScheduleNode*/}
                {/*        key={key}*/}
                {/*        image={session.image_url}*/}
                {/*        speaker={session.session_speaker}*/}
                {/*        title={session.session_name}*/}
                {/*        timeStart={moment(session.start_time)}*/}
                {/*        timeEnd={moment(session.end_time)}*/}
                {/*    />*/}
                {/*})}*/}

            </Container>
        </PageWrapper>
    );

}
