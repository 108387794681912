import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import PageWrapper from "./PageWrapper";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {isMobile} from "react-device-detect";
import ReactPaginate from "react-paginate";
import LiveStreamingModel from "../../models/LiveStreaming";
import {useHistory, useParams} from "react-router-dom";
import moment from 'moment'

export default function LiveStreamingChatPage(props) {
    const [streamData, setStreamData] = useState([])
    const {id} = useParams()
    let history = useHistory()
    const embeddedChat = (url) => {

        return "https://www.youtube.com/live_chat?v=" + id + "&embed_domain=sinara.narabahasa.id";

    }

    return (
        <div style={{
            width: "100%",
            height: "100vh",
        }}>
            <iframe
                width={"100%"}
                height={"100%"}
                src={embeddedChat()}
                frameBorder="0"></iframe>
        </div>
    );
}
