import React, {useEffect, useRef, useState} from "react";
import Session from "../../models/Session";
import PageWrapper from "./PageWrapper";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FiAlignJustify, FiClock, FiInfo, FiTv} from "react-icons/fi";
import {TiTicket} from "react-icons/ti";
import {BsMic} from "react-icons/bs";
import Button from "react-bootstrap/Button";
import {AiFillTag, AiOutlineTag} from "react-icons/ai";
import Palette from "../../util/Palette";
import SimpleLineIcon from "react-simple-line-icons";
import Dialog from '@material-ui/core/Dialog';
import Form from "react-bootstrap/Form";
import Rating from "react-rating";
import {FaStar, FaFileDownload} from "react-icons/fa";
import {IoMdStar} from "react-icons/io";
import moment from "moment";
import FeedbackCard from "../reusable/FeedbackCard";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import GlobalData from "../../util/GlobalData";
import {CookiesProvider, useCookies} from 'react-cookie';
import {isMobile} from "react-device-detect";
import textFormatter from "../../util/textFormatter";
import Strings from "../../util/Strings";
import FeedbackModal from "../reusable/FeedbackModal";
import QnASection from "../reusable/QnASection";
import {Helmet} from "react-helmet";
import {useHistory, useLocation, useParams} from "react-router-dom";
import EditProfileModal from "../reusable/EditProfileModal";
import CompleteProfileModal from "../reusable/CompleteProfileModal";
import ParticipateModel from '../../models/Paticipate'
import RecordingUrlModal from "../reusable/RecordingUrlModal";
import MuiAlert from "@material-ui/lab/Alert/Alert";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Order from "../../models/Order";
import AttachmentUrlModal from "../reusable/AttachmentUrlModal";
import {Accordion, Card} from "react-bootstrap";
import {Parser} from "html-to-react";
import {FaMinus, FaPlus} from "react-icons/all";
import SessionCard from "../reusable/SessionCard";

var momentTz = require('moment-timezone');

const BUTTON_STATUS = {
  REGISTER: 'REGISTER',
  REGISTERED: 'REGISTERED',
  BUY_TICKET: 'BUY_TICKET',
  REGISTRATION_CLOSED: 'REGISTRATION_CLOSED',
  PAY: 'PAY',
  IN_QUEUE: 'IN_QUEUE',
  OUT_OF_QUOTA: 'OUT_OF_QUOTA'
}

const WATCH_STATUS = {
  AVAILABLE_AFTER_LOGIN: 'AVAILABLE_AFTER_LOGIN',
  AVAILABLE_AFTER_REGISTER: 'AVAILABLE_AFTER_REGISTER',
  WATCH: 'WATCH',
  TICKET_REQUIRED: 'TICKET_REQUIRED',
  COUNTDOWN: 'COUNTDOWN',
}

export default function SessionDetail(props) {
  const DISCOUNT_TYPE = {
    PERCENT: 'PERCENT',
    AMOUNT: 'AMOUNT'
  }


  function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  const fetchUpcomingSessions = async () => {
    try {

      const result = await new Session().getAll()
      const sessionTemp = result.filter((obj, key) => new Date(obj.start_time) > new Date() && code !== obj.code)

      setUpcomingSession(shuffle(sessionTemp))

    } catch (e) {
      console.log(e)
    }
  }

  const [isCartOpen, setCartOpen] = useState(false);

  const [upcomingSession, setUpcomingSession] = useState([])
  const waitingStatusRef = useRef(null);
  const editable_strings = GlobalData.strings.class_detail;
  const editable_strings_private_class = GlobalData.strings.private_class_detail;

  const sessionModel = new Session();
  const [cancellableLogin, setCancellableLogin] = useState(true);
  const [loginShow, setLoginShow] = useState(false);
  const [loginMessage, setLoginMessage] = useState("");
  const [customOnHide, setCustomOnHide] = useState(null);
  const [session, setSession] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [buttonStatus, setButtonStatus] = useState('REGISTERED');
  const [watchStatus, setWatchStatus] = useState('WATCH');
  const [isButtonHovered, setButtonHovered] = useState(false);
  const [isCloseHovered, setCloseHovered] = useState(false);
  const [isImagePreview, setImagePreview] = useState(false)
  const [isImageLoaded, setImageLoaded] = useState(false);
  const [user, setUser] = useState({...GlobalData.user})
  const [isCheckoutModalShown, setCheckoutModalShown] = useState(false);
  const [isRegistrationOpen, setRegistrationOpen] = useState(false);

  const [isFeedbackModalShown, setFeedbackModalShown] = useState(false);
  const [needFeedback, setNeedFeedback] = useState(false)
  const [isFeedbackExtended, setFeedbackExtended] = useState(false)

  const [isWaitingStatusHovered, setWaitingStatusHovered] = useState(false);

  const [cookie, setCookie, removeCookie] = useCookies()
  const [isLoggedIn, setIsLoggedIn] = useState(GlobalData.token || cookie.token)

  const [voucherCode, setVoucherCode] = useState("")
  const [voucherMessage, setVoucherMessage] = useState("")
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [feedbacks, setFeedbacks] = useState([]);
  const [discountType, setDiscountType] = useState(null);
  const [noOfFeedback, setNoOfFeedback] = useState(0);

  const [completeProfileModalMessage, setCompleteProfileModalMessage] = useState("")
  const [isCompleteProfileModalShown, setCompleteProfileModalShown] = useState(false)

  const [isJoiningClass, setJoiningClass] = useState(false)
  const history = useHistory()

  const [loginEmail, setLoginEmail] = useState(null);

  const [isLoading, setIsLoading] = useState(false)

  const [recordingUrlModal, setRecordingUrlModal] = useState(false)
  const [attachmentUrlModal, setAttachmentUrlModal] = useState(false)

  const [participantNote, setParticipantNote] = useState(null);

  const [cart, setCart] = useState([]);

  const [inCart, setInCart] = useState(false);

  const [instructorExpandedIdx, setInstructorExpandedIdx] = useState(null)

  const orderModel = new Order();

  const updateWindowDimensions = () => {
    console.log(window.innerWidth)

    setWindowWidth(window.innerWidth);
  }

  const {code} = useParams();

  useEffect(() => {
    fetchUpcomingSessions()
  }, [])

  useEffect(() => {
    const cart = localStorage.getItem('cart');

    if (cart) {
      setCart(JSON.parse(cart))
    }


    window.addEventListener('resize', updateWindowDimensions);

    window.scrollTo(0, 0);

    if (props.match.params.code || !isLoggedIn) {
      getSessionData()
    }

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, [code])

  useEffect(() => {
    cart.forEach(item => {
      if (item.code === props.match.params.code) {
        setInCart(true)
      }
    })
  }, [cart])

  const getSessionData = (user) => {

    if (props.match.params.code) {
      getSessionByCode()
      getSessionFeedbacks()
    } else if (props.match.params.transactionCode) {
      getSessionByParticipationCode(user)
    }
  }


  const [averageRating, setAverageRating] = useState(0)

  const [count5, setCount5] = useState(0)
  const [count4, setCount4] = useState(0)
  const [count3, setCount3] = useState(0)
  const [count2, setCount2] = useState(0)
  const [count1, setCount1] = useState(0)


  const getSessionFeedbacks = async () => {
    try {
      const feedbacks = await sessionModel.getFeedbacks(props.match.params.code);

      setNoOfFeedback(feedbacks.length);

      console.log('feedbacks', feedbacks)

      let total = 0

      let tempCount5 = 0;
      let tempCount4 = 0;
      let tempCount3 = 0;
      let tempCount2 = 0;
      let tempCount1 = 0;

      for (let f of feedbacks) {

        total += f.average

        if (Math.round(f.average) === 5) {
          tempCount5++
        } else if (Math.round(f.average) === 4) {
          tempCount4++
        } else if (Math.round(f.average) === 3) {
          tempCount3++
        } else if (Math.round(f.average) === 2) {
          tempCount2++
        } else {
          tempCount1++
        }

      }

      setCount1(tempCount1)
      setCount2(tempCount2)
      setCount3(tempCount3)
      setCount4(tempCount4)
      setCount5(tempCount5)

      setAverageRating(total / feedbacks.length)

      let filteredFeedbacks = feedbacks.filter((obj, key) => {
        return obj.masukan
      })

      setFeedbacks(filteredFeedbacks);


    } catch (e) {
      console.log('feedbacks_err', e)
    }
  }

  const getSessionByParticipationCode = async (user) => {
    try {
      const sessionObj = await sessionModel.getByTransactionCode(props.match.params.transactionCode);

      const isRegistrationOpen = sessionObj.status === 'REGISTRATION';

      // console.log(session)
      sessionObj.classStatus = sessionObj.status

      console.log("fetching....")
      let session = await getSessionByCode(sessionObj.code)

      if (user) {
        console.log("session status " + session.status)
        if (user.email !== sessionObj?.member.email && session.status !== "ACCEPTED") {
          setLoginShow(true)
          setCancellableLogin(false);
          setLoginEmail(sessionObj?.member.email)
          alert("Surel yang sedang digunakan (" + user.email + ") tidak terdaftar pada sesi ini. Harap masuk menggunakan surel " + sessionObj?.member.email)
        }
        // history.push("/kelas/detail/"+sessionObj.code)
      } else {
        setLoginShow(true)

        if (sessionObj?.member?.is_claimed) {
          setCancellableLogin(false);
          setLoginEmail(sessionObj?.member.email)
          setLoginMessage("Harap masuk menggunakan surel " + sessionObj?.member.email)
        } else {
          setLoginMessage("Harap melakukan pendaftaran menggunakan surel " + sessionObj?.member.email)
        }
      }


    } catch (e) {
      console.log(e)
    }

  }

  const joinClass = async () => {
    let sessionModel = new Session();
    // console.log(session.id + "!", session)

    try {
      await sessionModel.joinClass(session?.participant_info?.id)
      let result = await sessionModel.joinClass(session?.participant_info?.id)
      console.log('Join class', result)
    } catch (e) {
      console.log(e)
    } finally {
      window.location = session.stream_url
    }
  }

  const whatsappLink = async () => {
    window.location = session.whatsapp_url;
  }

  const getSessionByCode = async (code) => {
    try {
      const sessionObj = await sessionModel.getByCode(props.match.params.code ? props.match.params.code : code);


      const isRegistrationOpen = sessionObj.status === 'REGISTRATION' || sessionObj.permanent;

      sessionObj.classStatus = sessionObj.status

      // console.log("status " + sessionObj.classStatus)
      let available_class = await sessionModel.getAvailableClass(props.match.params.code ? props.match.params.code : code);


      if (sessionObj.participant_info) {

        if (sessionObj.classStatus === "ENDED") {
          // console.log(sessionObj.participant_info)
          if (sessionObj.participant_info.participant_feedback_id === null) {
            setFeedbackModalShown(true)
            setNeedFeedback(true)
          } else {
            setNeedFeedback(false)
          }
        }
        sessionObj.status = sessionObj.participant_info.status;
        setRegistrationOpen(true)

        sessionObj.participant_id = sessionObj.participant_info.id


      } else {
        sessionObj.status = null;
        setRegistrationOpen(isRegistrationOpen)
      }
      console.log('ganteng', available_class);
      if (available_class.length !== undefined && available_class.length > 0) {
        if (available_class[0].usage_class >= available_class[0].quota) {
          sessionObj.classStatus = 'REGISTRATION_CLOSED'
          setRegistrationOpen(false)
        }
      }

      setSession(sessionObj);

      return sessionObj

    } catch (e) {
      console.log(e)
    }
  }

  const thousandSeparator = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

// console.log(session.price)
// console.log(session)

  const participate = async () => {

    if (!isLoggedIn) {
      setLoginMessage(Strings.class_detail.login_before_purchasing)
      return setLoginShow(true)
    }

    try {
      //check available class
      let available_class = await sessionModel.getAvailableClass(session.code);
      if (available_class.length !== undefined && available_class.length > 0) {
        if (available_class[0].usage_class >= available_class[0].quota) {
          session.classStatus = 'REGISTRATION_CLOSED'
          setRegistrationOpen(false)
          alert('Kelas sudah penuh!')
          window.location.reload()
        }
      }

      const result = await orderModel.checkout({
        orders: [session],
        participant_note: participantNote,
        voucher_code: voucherCode
      })

      console.log('ganteng hubla', result);
      localStorage.setItem('cart', '[]')

      if (result.snap_payment_url) {
        window.location.replace(result.snap_payment_url)
      } else if (result.success) {
        alert('Anda telah berhasil terdaftar!')

        window.location.reload()
      } else {
        alert('Terjadi Kesalahan')
      }
      console.log(result)


    } catch (e) {
      if (e.code === 'VOUCHER_NOT_FOUND') {
        alert(e.msg)
      }

      if (e.code === "VOUCHER_MINIMUM_USAGE_NOT_REACHED") {
        if (e.msg) {
          alert(e.msg)
        } else {
          alert("Voucer tidak bisa dipakai")
        }
        return
      }

      if (e.code === 'USER_HAS_REGISTERED') {
        alert('Anda sudah pernah melakukan pendaftaran pada kelas ini!');
        window.location.reload();
      } else if (e.code === 'QUOTA_IS_FULL') {
        alert(e.msg);
        window.location.reload();
      }

      console.log(e)
    }
    // try {
    //     setIsLoading(true)
    //     const result = await sessionModel.participate(session.code, voucherCode, participantNote)
    //
    //     console.log('bayar', result);
    //
    //     if (result.status === 'ACCEPTED') {
    //         alert('Anda berhasil terdaftar pada acara ini!')
    //         window.location.reload()
    //         setCheckoutModalShown(false)
    //         setIsLoading(false)
    //     } else if (result.status === 'PENDING') {
    //         alert('Kuota kelas sudah penuh. Kami akan mengabarkan Anda jika ada kuota baru.')
    //         window.location.reload()
    //         setCheckoutModalShown(false)
    //         setIsLoading(false)
    //     } else if (session.price > 0) {
    //         window.open(result?.transaction.snap_payment_url, '_self')
    //         setCheckoutModalShown(false)
    //         setIsLoading(false)
    //     }
    //
    // } catch (e) {
    //     console.log(e)
    // }
  }

  const addToCart = () => {
    showSnackBar('Kelas daring berhasil ditambahkan ke keranjang!')
    setCheckoutModalShown(false)
    setCartOpen(true)

    let cartTemp = [...cart];

    if (cartTemp) {
      let inCart = false;

      cartTemp.forEach(item => {
        if (item.code === session.code) inCart = true;
      })

      if (!inCart) {
        cartTemp.push(session)

        setCart(cartTemp)
        localStorage.setItem('cart', JSON.stringify(cartTemp))
      }
    } else {
      setCart([session])
      localStorage.setItem('cart', JSON.stringify([session]))
    }
  }

  const changePayment = async () => {
    try {
      const cancelResult = await sessionModel.cancel(session?.participant_info?.id)

      // console.log(cancelResult)

      const result = await sessionModel.participate(session.code, voucherCode)

      // console.log(result)

      if (result.status === 'ACCEPTED') {
        alert('Anda berhasil terdaftar pada acara ini!')
        window.location.reload()
      } else if (session.price > 0) {
        window.open(result?.transaction.snap_payment_url, '_self')
      }

    } catch (e) {
      console.log(e)
    }
  }

  const getVoucherInfo = async () => {
    let sessionModel = new Session();
    try {
      let result = await sessionModel.getVoucherInfo(voucherCode)

      console.log("voucher", result)

      if (result.used) {
        setVoucherMessage(Strings.class_detail.voucher_used)
        setDiscountPercentage(0)
        return
      }

      if (result.minimum_usage) {
        if (result.minimum_usage > session.price) {
          setDiscountPercentage(0)
          setDiscountAmount(0)
          return setVoucherMessage("Minimum pembelian untuk memakai voucer ini adalah Rp" + thousandSeparator(result.minimum_usage))
        }
      }

      if (result.percentage) {
        setDiscountType(DISCOUNT_TYPE.PERCENT)
        setVoucherMessage(Strings.class_detail.you_will_get_discount + result.percentage + "%")
        setDiscountPercentage(result.percentage)

      } else {
        setDiscountType(DISCOUNT_TYPE.AMOUNT)
        setVoucherMessage(Strings.class_detail.you_will_get_discount + "Rp" + thousandSeparator(result.amount))
        setDiscountAmount(result.amount)

      }


    } catch (e) {
      console.log(e.code)
      if (e.code === "VOUCHER_NOT_FOUND") {
        setVoucherMessage(Strings.class_detail.code_not_found)
        setDiscountPercentage(0)
      }
    }
  }

// console.log("isFeedbackModalSHOWN", isFeedbackModalShown)

  const waitTimeout = (ms) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, ms)
    })
  }

  const [snackBarProp, setSnackBarProp] = useState({});

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const showSnackBar = (message, severity) => {
    setSnackBarProp({
      open: true,
      message,
      severity
    })
  }

  return (
    <PageWrapper
      cart={cart}
      setCart={setCart}
      isCartOpen={isCartOpen}
      setCartOpen={setCartOpen}
      email={loginEmail}
      headerShy={false}
      history={props.history}
      loginShow={loginShow}
      message={loginMessage}
      customOnHide={cancellableLogin ? () => {
        setLoginShow(false)
      } : null}
      loginListener={(user) => {
        console.log("LOGIN LISTENER CALLED", user)
        setUser(user)
        getSessionData(user)
      }}
      headerActive={'my-session'}
    >
      <Snackbar
        anchorOrigin={{vertical: "top", horizontal: "center"}}
        open={snackBarProp.open} autoHideDuration={6000}
        onClose={() => {
          setSnackBarProp({
            open: false
          })
        }}>
        <Alert severity={snackBarProp.severity}>
          {snackBarProp.message}
        </Alert>
      </Snackbar>

      <CompleteProfileModal
        message={completeProfileModalMessage}
        show={isCompleteProfileModalShown}
        onProfileCompleted={(user) => {
          if (isJoiningClass) {
            joinClass()
          } else {
            setCheckoutModalShown(true)
          }

          setUser(user)
        }}
        onHide={() => {
          setJoiningClass(false)
          setCompleteProfileModalShown(false)
        }}
      />

      <Helmet>
        <title>{session.name ? session.name : "Kelas Daring Narabahasa"}</title>
        <meta name="description" content={`Kelas Daring Narabahasa ${session.name ? session.name : ''}`}/>

      </Helmet>

      <FeedbackModal
        session={session}
        isOpen={isFeedbackModalShown}
        onClose={(success) => {
          if (success) {
            getSessionByCode()
          }
          setFeedbackModalShown(false)
        }}
      />

      <RecordingUrlModal
        session={session}
        recordings={session.recording_url_array && Array.isArray(session.recording_url_array) ? session.recording_url_array : []}
        isOpen={recordingUrlModal}
        onClose={(success) => {
          setRecordingUrlModal(false)
        }}
      />

      <AttachmentUrlModal
        recordings={session.attachment_urls && Array.isArray(session.attachment_urls) ? session.attachment_urls : []}
        isOpen={attachmentUrlModal}
        onClose={(success) => {
          setAttachmentUrlModal(false)
        }}
      />

      <Dialog open={isCheckoutModalShown} fullWidth>
        <Container>
          <Row style={{marginTop: 25, marginBottom: 15}}>
            <Col style={{fontFamily: 'OpenSans-SemiBold', fontSize: 20, marginLeft: 15, color: '#302a28'}}>
              Lanjutkan Pembayaran
            </Col>
          </Row>

          <Row>
            <Col style={{
              width: '100%',
              height: 1,
              backgroundColor: '#e9eaec',
              marginTop: 5,
              marginBottom: 30
            }}/>
          </Row>

          <Row>
            <Col style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
              <img
                src={session.class_image_url ? session.class_image_url : '/image/no-img-placeholder.png'}
                onClick={(e) => e.stopPropagation()}
                style={{
                  objectFit: 'cover',
                  width: 70,
                  height: 70,
                  marginLeft: 20,
                }}/>

              <div style={{flex: 1}}>

                <Row>
                  <Col xs={12} md={6} style={{
                    paddingLeft: 40,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column'
                  }}>
                    <div
                      style={{fontFamily: 'OpenSans-SemiBold', fontSize: 15}}>{session.name}</div>
                    <div style={{
                      fontSize: 14,
                      color: 'grey'
                    }}> {moment(session.class_date).format('D MMMM YYYY')}, {moment(session.start_time).format('HH:mm')}</div>
                  </Col>

                  <Col style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    fontFamily: 'OpenSans-SemiBold',
                    fontSize: 15,
                    paddingRight: 40,
                  }}>
                    Rp{session.price ? thousandSeparator(session.price) : '0'}
                  </Col>
                </Row>
              </div>

            </Col>
          </Row>

          <Row>
            <Col style={{
              width: '100%',
              height: 1,
              backgroundColor: '#e9eaec',
              marginTop: 20,
              marginBottom: 20,
            }}/>
          </Row>

          <Row>
            <Col xs={12} md={9} style={{paddingRight: windowWidth < 768 ? 30 : 0, paddingLeft: 30}}>
              <Form.Control
                value={voucherCode}
                onChange={(e) => {
                  setVoucherCode(e.target.value)
                }}
                style={{
                  fontSize: "1em",
                  fontFamily: 'OpenSans-Regular',
                  paddingTop: 20,
                  paddingBottom: 20,
                }} type="text"
                placeholder="Kode Voucer"/>
              <div style={{
                fontFamily: 'OpenSans-SemiBold',
                color: discountPercentage != 0 ? Palette.SECONDARY : Palette.PRIMARY,
                fontSize: 13,
                marginTop: 5,
              }}>
                {voucherMessage}
              </div>
            </Col>

            <Col style={{
              marginTop: windowWidth < 768 ? 10 : 0,
              paddingRight: 30,
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
              <Button
                onClick={() => {
                  getVoucherInfo()
                }}
                style={{
                  fontFamily: 'OpenSans-Bold',
                  borderWidth: 0,
                  backgroundColor: '#5fcaf9',
                  height: 43,
                }}>
                Gunakan
              </Button>
            </Col>
          </Row>

          <Row>
            <Col style={{
              width: '100%',
              height: 1,
              backgroundColor: '#e9eaec',
              marginTop: 25,
              marginBottom: 25,
            }}/>
          </Row>

          <Row>
            <Col xs={12} style={{paddingLeft: 30, paddingRight: 30}}>
              <Form.Control
                as={'textarea'}
                onChange={(e) => {
                  setParticipantNote(e.target.value)
                }}
                style={{
                  fontSize: "1em",
                  fontFamily: 'OpenSans-Regular',
                  paddingTop: 20,
                  paddingBottom: 20,
                }} type="text"
                placeholder="Tulis Catatan (Opsional)"/>
            </Col>
          </Row>

          <Row>
            <Col style={{
              width: '100%',
              height: 1,
              backgroundColor: '#e9eaec',
              marginTop: 25,
              marginBottom: 25,
            }}/>
          </Row>

          <Row style={{marginLeft: 7}}>
            <Col style={{fontFamily: 'OpenSans-Regular', fontSize: 15}}>
              Subtotal
            </Col>

            <Col style={{
              fontFamily: 'OpenSans-SemiBold',
              fontSize: 15,
              paddingRight: 37,
              textAlign: 'right'
            }}>
              Rp{session.price ? thousandSeparator(session.price) : '0'}
            </Col>
          </Row>

          <Row style={{marginLeft: 7, marginTop: 10}}>
            <Col style={{fontFamily: 'OpenSans-Regular', fontSize: 15}}>
              Diskon
            </Col>

            <Col style={{
              fontFamily: 'OpenSans-SemiBold',
              fontSize: 15,
              paddingRight: 37,
              textAlign: 'right',
              color: Palette.PRIMARY
            }}>
              - {discountType === DISCOUNT_TYPE.PERCENT ? `Rp${thousandSeparator(session.price * discountPercentage / 100)}` : discountType === DISCOUNT_TYPE.AMOUNT ? `Rp${thousandSeparator(discountAmount)}` : 'Rp0'}
            </Col>
          </Row>

          <Row>
            <Col style={{
              width: '100%',
              height: 1,
              backgroundColor: '#e9eaec',
              marginTop: 15,
              marginBottom: 10,
            }}/>
          </Row>

          <Row style={{marginLeft: 7, marginTop: 10}}>
            <Col style={{fontFamily: 'OpenSans-SemiBold', fontSize: 16}}>
              Total
            </Col>

            <Col style={{
              fontFamily: 'OpenSans-SemiBold',
              fontSize: 19,
              paddingRight: 37,
              textAlign: 'right',

            }}>
              Rp{session.price && discountType === DISCOUNT_TYPE.PERCENT ?
              thousandSeparator(session.price - session.price * discountPercentage / 100) :
              session.price && discountType === DISCOUNT_TYPE.AMOUNT && session.price - discountAmount > 0 ?
                thousandSeparator(session.price - discountAmount) : discountType || !session.price ? '0' : thousandSeparator(session.price)}
            </Col>
          </Row>

          <Row>
            <Col style={{
              display: 'flex',
              flexDirection: windowWidth < 550 ? 'column' : 'row',
              justifyContent: 'flex-end',
              marginRight: 16,
              marginTop: 50,
              marginBottom: 22,
              fontFamily: 'OpenSans-Bold'
            }}>
              <Button variant={'link'} style={{
                marginRight: windowWidth < 550 ? 0 : 20,
                order: windowWidth < 550 ? 2 : 0,
                marginTop: windowWidth < 550 ? 8 : 0
              }}
                      onClick={() => setCheckoutModalShown(false)}>
                Batal
              </Button>

              {/*<Button*/}
              {/*    disabled={isLoading}*/}
              {/*    variant={'info'}*/}
              {/*    style={{*/}
              {/*        width: windowWidth < 550 ? '100%' : 240,*/}
              {/*        height: 40,*/}
              {/*        marginRight: windowWidth < 550 ? 0 : 20,*/}
              {/*        order: 1,*/}
              {/*        marginTop: windowWidth < 550 ? 10 : 0*/}
              {/*    }}*/}
              {/*    onClick={addToCart}>*/}
              {/*    Tambahkan ke Keranjang*/}
              {/*</Button>*/}

              <Button
                disabled={isLoading}
                style={{
                  width: windowWidth < 550 ? '100%' : 100,
                  height: 40,
                  order: windowWidth < 550 ? 0 : 2
                }} onClick={participate}>
                {/**todo: calculate price - discount**/}
                {session.price === 0 ? 'Daftar' : 'Bayar'}
              </Button>
            </Col>
          </Row>
        </Container>

      </Dialog>

      <Dialog open={isImagePreview} fullScreen>
        <Container fluid style={{
          width: '100vw',
          height: '100vh',
          backgroundColor: '#6b6b6b',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Row style={{display: 'flex', justifyContent: 'flex-end', paddingTop: 15, paddingRight: 15}}>
            <SimpleLineIcon onClick={() => setImagePreview(false)} onMouseOver={() => setCloseHovered(true)}
                            onMouseLeave={() => setCloseHovered(false)} name={'close'} style={{
              cursor: 'pointer',
              textShadow: isCloseHovered ? '0px 0px 2px #FFFFFF' : 'none',
              fontSize: 40
            }} color={isCloseHovered ? '#ffffff' : '#b5b5b5'}/>
          </Row>
          <Row style={{flex: 1}}>
            {/*<img*/}
            {/*    src={session.class_image_url ? session.class_image_url : '/image/no-img-placeholder.png'}*/}
            {/*    style={{*/}
            {/*        objectFit: 'contain',*/}
            {/*    }}/>*/}
            {/*    <div>abc</div>*/}
            <Container style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              cursor: 'pointer'
            }} onClick={(e) => setImagePreview(false)}>
              <img
                src={session.class_image_url ? session.class_image_url : '/image/no-img-placeholder.png'}
                onClick={(e) => e.stopPropagation()}
                style={{
                  cursor: 'auto',
                  objectFit: 'contain',
                  width: '100%',
                }}/>

              <Row onClick={(e) => e.stopPropagation()} style={{
                marginLeft: 0,
                marginRight: 0,
                marginTop: 15,
                cursor: 'text',
                color: '#b5b5b5',
                fontSize: 17,
                fontFamily: 'OpenSans-Regular'
              }}>{session.code}</Row>
            </Container>
          </Row>
        </Container>
      </Dialog>

      <Container style={{marginTop: isMobile ? 0 : 100}}>

        <Row style={{
          marginBottom: 30
        }}>
          <Col md={12}>
            <a href={"/"}>{"<"} Kembali ke daftar kelas</a>
          </Col>
        </Row>

        <Row>
          <Col md={12} lg={7}>
            <img
              onLoad={() => setImageLoaded(true)}
              // onClick={() => {
              //     if (isImageLoaded && session.class_image_url) setImagePreview(true)
              // }}
              src={session.class_image_url ? session.class_image_url : '/image/no-img-placeholder.png'}
              style={{
                cursor: isImageLoaded && session.class_image_url ? 'pointer' : 'auto',
                objectFit: 'contain',
                width: '100%',
                backgroundColor: 'white',
              }}/>
          </Col>

          <Col lg={5}>
            <Row style={{
              color: '#302a28',
              fontFamily: 'OpenSans-Bold',
              fontSize: 30,
              paddingLeft: 20,
              lineHeight: 1.375,
              paddingTop: windowWidth < 992 ? 30 : 0,
              display: 'flex',
              alignItems: 'center'
            }}>
              {session.name}
            </Row>

            {
              session.status === 'WAITING' ?
                <Row style={{
                  fontFamily: 'OpenSans-SemiBold',
                  fontSize: 14,
                  color: '#4f4f4',
                  alignSelf: 'flex-start',
                  marginTop: 10,
                  marginLeft: 5,
                }}>
                  <div ref={waitingStatusRef}
                       onMouseOver={() => setWaitingStatusHovered(true)}
                       onMouseLeave={() => setWaitingStatusHovered(false)}
                       style={{
                         backgroundColor: 'yellow',
                         borderRadius: 15,
                         paddingLeft: 15,
                         paddingRight: 15,
                         paddingTop: 3,
                         paddingBottom: 3,
                         cursor: 'pointer'
                       }}>
                    Menunggu Pembayaran
                  </div>
                </Row> : null
            }

            {/*{*/}
            {/*    session.status === 'WAITING' ?*/}
            {/*        <Row style={{*/}
            {/*            fontFamily: 'OpenSans-Regular',*/}
            {/*            fontSize: 15,*/}
            {/*            color: '#4f4f4',*/}
            {/*            alignSelf: 'flex-start',*/}
            {/*            marginTop: 10,*/}
            {/*            marginLeft: 0*/}
            {/*        }}>*/}
            {/*            <Col>*/}
            {/*                </span>*/}
            {/*            </Col>*/}
            {/*        </Row> : null*/}
            {/*}*/}

            {
              session.status === 'WAITING' ?
                <Row style={{
                  marginLeft: 20,
                  marginTop: 30,
                }}>
                  <div style={{fontFamily: 'OpenSans-Regular', fontSize: 13}}>
                    Harap bayar sebelum
                    <div
                      style={{fontFamily: 'OpenSans-SemiBold'}}>{moment(session.order_info?.should_expire_at).format('D MMMM YYYY, HH:mm')}</div>
                  </div>
                </Row>
                : null
            }

            <Row style={{marginLeft: 0, display: 'flex', marginTop: 30,}}>
              {session.name &&
                <a href={session.status === 'WAITING' ? session?.order_info?.snap_payment_url : '#'}
                   target={'_blank'}>
                  <Button
                    disabled={session.status === 'PENDING' || session.status === 'ACCEPTED' || !isRegistrationOpen}
                    style={{
                      height: 45,
                      width: session.status !== 'ACCEPTED' && !isRegistrationOpen ? 200 : 140,
                      borderRadius: 50,
                      backgroundColor: '#5fcaf9',
                      opacity: session.status === 'PENDING' || session.status === 'ACCEPTED' || !isRegistrationOpen ? .5 : isButtonHovered ? .7 : 1,
                      color: 'white',
                      borderWidth: 0,
                      fontSize: 14,
                      fontFamily: 'OpenSans-Bold',
                    }} onClick={(e) => {
                    e.preventDefault()
                    console.log(session.status)

                    console.log("Cermat ", session.status)

                    if (!session.status) {

                      e.preventDefault()

                      if (!isLoggedIn) {
                        setLoginMessage(Strings.class_detail.login_before_purchasing)
                        return setLoginShow(true)
                      } else if (!user.date_of_birth || !user.gender || !user.phone_num || !user.organization || !user.province || !user.city) {
                        console.log(user.date_of_birth, user.gender, user.phone_num, user.organization, user.province, user.city)
                        setCompleteProfileModalShown(true)
                        setCompleteProfileModalMessage('Harap lengkapi data Anda sebelum mendaftar pada sesi ini')
                      } else {
                        setCheckoutModalShown(true)
                      }
                    }

                    // console.log(session?.transaction_info?.snap_payment_url)
                    let participateModel = new ParticipateModel();
                    if (session?.order_info?.snap_payment_url) {
                      window.location = session?.order_info?.snap_payment_url
                      //window.location = session?.transaction_info?.snap_payment_url
                    }

                  }}
                    onMouseOver={() => setButtonHovered(true)}
                    onMouseLeave={() => setButtonHovered(false)}>
                    {session.status === 'ACCEPTED' ? editable_strings.label_list_class : !isRegistrationOpen ? Strings.class_detail.registration_closed : session.status === 'PENDING' ? "Dalam Antrean" : session.status === 'WAITING' ? Strings.class_detail.purchase : Strings.class_detail.register}
                  </Button>
                </a>
              }

              {
                session.name && !session.status && session.price !== 0 && isRegistrationOpen ?
                  <Button
                    variant={"info"}
                    onClick={addToCart}
                    // onClick={() => changePayment()}
                    style={{
                      height: 45,
                      width: 220,
                      borderRadius: 50,
                      color: 'white',
                      borderWidth: 0,
                      marginLeft: "0.5em",
                      fontSize: 14,
                      fontFamily: 'OpenSans-Bold',
                      display: "flex",
                      flexDirection: "row",
                      marginRight: 10,
                      alignItems: "center",
                      justifyContent: "center"
                    }}>
                    Tambahkan ke Keranjang
                  </Button> : null
              }

              <a>

                {
                  session.status === "ACCEPTED" ?
                    <Button
                      onClick={async () => {
                        if (session.classStatus === "ENDED") {
                          if (!session.recording_url && !session.recording_url_array) {
                            alert(Strings.class_detail.recording_not_available)
                          } else {

                            if (session.recording_url_array) {
                              setRecordingUrlModal(true)
                            } else {
                              window.location = session.recording_url
                            }

                          }
                        } else {
                          if (!session.stream_url && !session.permanent) {
                            alert(Strings.class_detail.button_will_be_active_later)
                          } else if (!user.date_of_birth || !user.gender || !user.phone_num || !user.organization || !user.city) {
                            setJoiningClass(true)
                            setCompleteProfileModalShown(true)
                            setCompleteProfileModalMessage('Harap lengkapi data Anda sebelum masuk ke sesi ini.')
                          } else {
                            joinClass()
                          }
                        }
                      }}
                      style={{
                        height: 45,
                        width: session.classStatus === "ENDED" ? 160 : 140,
                        borderRadius: 50,
                        backgroundColor: '#5fcaf9',
                        opacity: (!session.stream_url && session.classStatus !== "ENDED" && !session.permanent) || ((!session.recording_url && !session.recording_url_array) && session.classStatus === "ENDED" && !session.permanent) ? .5 : 1,
                        color: 'white',
                        borderWidth: 0,
                        fontSize: 14,
                        marginLeft: 10,
                        fontFamily: 'OpenSans-Bold',
                        display: (!!session.stream_url && session.stream_url !== "") || (session.recording_url || session.recording_url_array) ? null : "none"
                      }}>
                      {session.classStatus === "ENDED" ? editable_strings.label_watch_recording : Strings.class_detail.watch}
                    </Button> : null
                }

              </a>

              {
                session.whatsapp_url && user ?
                  <a>
                    {
                      session.status === "ACCEPTED" ?
                        <Button
                          onClick={async () => {
                            whatsappLink()
                          }}
                          style={{
                            height: 45,
                            width: 180,
                            borderRadius: 50,
                            backgroundColor: '#5fcaf9',
                            opacity: 1,
                            color: 'white',
                            borderWidth: 0,
                            fontSize: 14,
                            // marginLeft: 10,
                            marginTop: 15,
                            fontFamily: 'OpenSans-Bold',
                          }}>
                          Grup Whatsapp
                        </Button> : null
                    }

                  </a> : null
              }

            </Row>

            <Row style={{marginLeft: 0, display: 'flex', marginBottom: 30}}>
              {
                session.status === "ACCEPTED" && (session?.attachment_url || (session.attachment_urls && session.attachment_urls.length && session.attachment_urls.length > 0)) ?
                  // <a href={session?.attachment_ url} download style={{textDecorationLine: 'none'}}>
                  <Button
                    onClick={() => {
                      if (session.attachment_urls) {
                        setAttachmentUrlModal(true)
                      } else {
                        window.location = session.attachment_url
                      }
                    }}
                    style={{
                      marginTop: 15,
                      height: 45,
                      width: 170,
                      borderRadius: 50,
                      backgroundColor: '#5fcaf9',
                      color: 'white',
                      borderWidth: 0,
                      fontSize: 14,
                      fontFamily: 'OpenSans-Bold',
                      display: "flex",
                      flexDirection: "row",
                      marginRight: 10,
                      alignItems: "center",
                      justifyContent: "center"
                    }}>
                    {editable_strings.label_download_theory} <FaFileDownload
                    style={{marginLeft: 5}}/>
                  </Button>
                  // </a>
                  : null
              }

              {
                (session.classStatus === "ENDED" && session.status === 'ACCEPTED' && session.quiz_question) || (session.status === 'ACCEPTED' && session.permanent && session.quiz_question) ?
                  <Button
                    onClick={() => {
                      history.push(`/tes/${session.code}`)
                    }}
                    style={{
                      marginTop: 15,
                      height: 45,
                      width: 125,
                      borderRadius: 50,
                      backgroundColor: '#5fcaf9',
                      color: 'white',
                      borderWidth: 0,
                      fontSize: 14,
                      fontFamily: 'OpenSans-Bold',
                      display: "flex",
                      flexDirection: "row",
                      marginRight: 10,
                      alignItems: "center",
                      justifyContent: "center"
                    }}>
                    Tes Akhir
                  </Button> : null
              }

              {
                session?.participant_info?.certificate_url ?
                  <a href={session?.participant_info?.certificate_url} download>
                    <Button
                      style={{
                        marginTop: 15,
                        height: 45,
                        width: 140,
                        borderRadius: 50,
                        backgroundColor: '#5fcaf9',
                        color: 'white',
                        borderWidth: 0,
                        fontSize: 14,
                        marginRight: 10,
                        fontFamily: 'OpenSans-Bold',
                      }}>
                      {editable_strings.label_certificate} <FaFileDownload/>
                    </Button>
                  </a>
                  : null
              }

              {
                session.status === "ACCEPTED"
                && session.classStatus !== "ENDED"
                && (session.recording_url_array || session.recording_url) &&
                <Button
                  style={{
                    borderRadius: 50,
                    backgroundColor: '#5fcaf9',
                    color: 'white',
                    borderWidth: 0,
                    fontSize: 14,
                    fontFamily: 'OpenSans-Bold',
                    paddingLeft: 25,
                    paddingRight: 25,
                    marginRight: 10,
                    marginTop: 15
                  }}
                  onClick={() => {
                    if (session.recording_url_array) {
                      setRecordingUrlModal(true)
                    } else {
                      window.location = session.recording_url
                    }
                  }}
                >
                  {editable_strings.label_watch_recording}
                </Button>
              }

            </Row>

            {
              session.classStatus === "ENDED" && session.status === 'ACCEPTED' && session.documentation_url ?
                <Row
                  style={{
                    marginTop: -10,
                    marginLeft: 0, display: 'flex', marginBottom: 30
                  }}>
                  <a href={session.documentation_url} download>
                    <Button
                      style={{
                        height: 45,
                        width: 180,
                        borderRadius: 50,
                        backgroundColor: '#5fcaf9',
                        opacity: session.documentation_url ? 1 : .5,
                        color: 'white',
                        borderWidth: 0,
                        fontSize: 14,
                        fontFamily: 'OpenSans-Bold',
                      }}>
                      Dokumentasi <FaFileDownload/>
                    </Button>
                  </a>
                </Row>
                : null
            }

            {session.classStatus === "ENDED" && session.status === 'ACCEPTED' ?
              <Row style={{
                backgroundColor: '#e9eaec',
                height: 1,
                width: '100%',
                marginTop: 15,
                marginBottom: 15,
                marginLeft: 0
              }}/> : null
            }

            {
              session.speaker && <>
                <Row style={{paddingLeft: 25, marginTop: 5, marginLeft: 0}}>
                  <SimpleLineIcon name="user" style={{marginRight: 18, color: Palette.PRIMARY}}/>
                  {
                    session.narasumber_instead_of_pengajar ? "Narasumber" : "Pengajar"
                  }
                </Row>
                <Row style={{paddingLeft: 25, marginTop: 5, marginLeft: 0}}>
                  <SimpleLineIcon name="tag" style={{marginRight: 18, color: Palette.PRIMARY, opacity: 0}}/>
                  <div style={{
                    fontSize: 14,
                    color: '#575757',
                    fontFamily: 'OpenSans-Regular'
                  }}>
                    <div dangerouslySetInnerHTML={{__html: session.speaker}}/>
                  </div>
                </Row>
                <Row style={{
                  backgroundColor: '#e9eaec',
                  height: 1,
                  width: '100%',
                  marginTop: 15,
                  marginBottom: 15,
                  marginLeft: 0
                }}/>
              </>
            }

            {
              session.show_price ?
                <div>
                  <Row style={{paddingLeft: 25, marginTop: 5, marginLeft: 0}}>
                    <b style={{marginRight: 18, color: Palette.PRIMARY}}>Rp</b>
                    {/*<SimpleLineIcon name="tag" style={{marginRight: 18, color: Palette.PRIMARY}}/>*/}
                    <div style={{
                      fontSize: 14,
                      color: '#575757',
                      fontWeight: "bold",
                      fontFamily: 'OpenSans-Regular'
                    }}>{editable_strings.investation}</div>
                  </Row>

                  {
                    session.non_discounted_price &&
                    <Row style={{paddingLeft: 25, marginTop: 5, marginLeft: 0}}>
                      <SimpleLineIcon name="tag"
                                      style={{
                                        marginRight: 18,
                                        color: Palette.PRIMARY,
                                        opacity: 0
                                      }}/>
                      <div style={{
                        fontSize: 12,
                        color: '#575757',
                        fontFamily: 'OpenSans-Regular',
                        textDecoration: "line-through"
                      }}>{session.price > 0 ? textFormatter.moneyFormatter(session.non_discounted_price) : 'Gratis'}</div>
                      <div style={{
                        fontSize: 10,
                        borderRadius: 10,
                        paddingLeft: 10,
                        paddingRight: 10,
                        marginLeft: 10,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        backgroundColor: Palette.PRIMARY
                      }}>
                        -{Math.floor((session.non_discounted_price - session.price) / session.non_discounted_price * 100)}%
                      </div>
                    </Row>
                  }


                  <Row style={{paddingLeft: 25, marginTop: 5, marginLeft: 0}}>
                    <SimpleLineIcon name="tag"
                                    style={{marginRight: 18, color: Palette.PRIMARY, opacity: 0}}/>
                    <div style={{
                      // fontSize: 14,
                      fontSize: 14,
                      color: '#575757',
                      fontFamily: 'OpenSans-Regular',
                    }}>{session.price > 0 ? textFormatter.moneyFormatter(session.price) : 'Gratis'}</div>
                  </Row>

                  <Row style={{
                    backgroundColor: '#e9eaec',
                    height: 1,
                    width: '100%',
                    marginTop: 15,
                    marginBottom: 15,
                    marginLeft: 0
                  }}/>
                </div>
                : null
            }

            <Row
              style={{
                color: '#302a28',
                fontFamily: 'OpenSans-Bold',
                paddingLeft: 25,
                lineHeight: 1.375,
                marginLeft: 0
              }}>
              <SimpleLineIcon name="tag" style={{marginRight: 18, color: Palette.PRIMARY}}/>
              <div style={{fontSize: 14, color: '#575757'}}>{editable_strings.quota}</div>
            </Row>

            <Row style={{paddingLeft: 25, marginTop: 5, marginLeft: 0}}>
              <SimpleLineIcon name="tag" style={{marginRight: 18, color: Palette.PRIMARY, opacity: 0}}/>
              <div style={{
                fontSize: 14,
                color: '#575757',
                fontFamily: 'OpenSans-Regular'
              }}>
                {session.remaining_quota > 0 ? session.remaining_quota : 0} orang
              </div>
            </Row>


            {
              !session.permanent && <>
                <Row style={{
                  backgroundColor: '#e9eaec',
                  height: 1,
                  width: '100%',
                  marginTop: 15,
                  marginBottom: 15,
                  marginLeft: 0
                }}/>
                <Row
                  style={{
                    color: '#302a28',
                    fontFamily: 'OpenSans-Bold',
                    paddingLeft: 25,
                    lineHeight: 1.375,
                    marginLeft: 0
                  }}>
                  <SimpleLineIcon name="calendar" style={{marginRight: 18, color: Palette.PRIMARY}}/>
                  <div style={{fontSize: 14, color: '#575757'}}>{editable_strings.date}</div>
                </Row>

                <Row style={{paddingLeft: 25, marginTop: 5, marginLeft: 0}}>
                  <SimpleLineIcon name="tag"
                                  style={{marginRight: 18, color: Palette.PRIMARY, opacity: 0}}/>
                  <div style={{fontSize: 14, color: '#575757', fontFamily: 'OpenSans-Regular'}}>
                    {
                      moment(session.start_time).isSame(session.end_time, 'day') ?
                        moment(session.class_date).format('dddd, D MMMM YYYY')
                        :
                        <>{moment(session.start_time).format('dddd, D MMMM YYYY')}--{moment(session.end_time).format('dddd, D MMMM YYYY')}</>
                    }

                  </div>
                </Row>
                <Row style={{
                  backgroundColor: '#e9eaec',
                  height: 1,
                  width: '100%',
                  marginTop: 15,
                  marginBottom: 15,
                  marginLeft: 0
                }}/>
                <Row
                  style={{
                    color: '#302a28',
                    fontFamily: 'OpenSans-Bold',
                    paddingLeft: 25,
                    lineHeight: 1.375,
                    marginLeft: 0
                  }}>
                  <SimpleLineIcon name="clock" style={{marginRight: 18, color: Palette.PRIMARY}}/>
                  <div style={{fontSize: 14, color: '#575757'}}>{editable_strings.time}</div>
                </Row>

                <Row style={{paddingLeft: 25, marginTop: 5, marginLeft: 0}}>
                  <SimpleLineIcon name="tag" style={{
                    marginRight: 18,
                    color: Palette.PRIMARY,
                    opacity: 0,
                    marginLeft: 0
                  }}/>
                  <div style={{
                    fontSize: 14,
                    color: '#575757',
                    fontFamily: 'OpenSans-Regular',
                    display: "flex",
                    flexDirection: "column"
                  }}>

                    {
                      session.display_time ?
                        <div style={{
                          height: 110,
                          display: "flex",
                          flexDirection: "column",
                          overflow: "scroll",
                        }}>
                          {
                            session.display_time.map((obj, key) => {
                              return <div>
                                {obj}
                              </div>
                            })
                          }
                        </div>
                        :
                        <>
                          {momentTz(session.start_time).tz('Asia/Jakarta').format('HH.mm')} WIB--{momentTz(session.end_time).tz('Asia/Jakarta').format('HH.mm ')} WIB
                        </>
                    }


                  </div>
                </Row>

              </>

            }


            {/*<Row style={{*/}
            {/*    backgroundColor: '#e9eaec',*/}
            {/*    height: 1,*/}
            {/*    width: '100%',*/}
            {/*    marginTop: 15,*/}
            {/*    marginBottom: 15,*/}
            {/*    marginLeft: 0*/}
            {/*}}/>*/}

            {/*<Row*/}
            {/*    style={{*/}
            {/*        color: '#302a28',*/}
            {/*        fontFamily: 'OpenSans-Bold',*/}
            {/*        paddingLeft: 25,*/}
            {/*        lineHeight: 1.375,*/}
            {/*        marginLeft: 0*/}
            {/*    }}>*/}
            {/*    <SimpleLineIcon name="folder-alt" style={{marginRight: 18, color: Palette.PRIMARY}}/>*/}
            {/*    <div style={{fontSize: 14, color: '#575757'}}>{Strings.class_detail.category}</div>*/}
            {/*</Row>*/}

            {/*<Row style={{paddingLeft: 25, marginTop: 5, marginLeft: 0}}>*/}
            {/*    <SimpleLineIcon name="tag" style={{*/}
            {/*        marginRight: 18,*/}
            {/*        color: Palette.PRIMARY,*/}
            {/*        opacity: 0,*/}
            {/*        marginLeft: 0*/}
            {/*    }}/>*/}
            {/*    <div style={{fontSize: 14, color: '#575757', fontFamily: 'OpenSans-Regular'}}>*/}
            {/*        {session.topic ? session.topic.name : null}*/}
            {/*    </div>*/}
            {/*</Row>*/}
          </Col>
        </Row>

        <Row style={{paddingTop: 40}}>
          <Col lg={12}>
            {/* <p style={{
                            whiteSpace: 'pre-line',
                            wordWrap: "break-word",
                            width: "300",
                            textAlign: "justify",
                            textJustify: "distribute",
                            textAlignLast: "left"
                        }}>

                            {session.description}
                        </p> */}
            <div dangerouslySetInnerHTML={{__html: session.description}}/>
          </Col>
        </Row>

        {session?.instructors?.length > 0 &&
          <Row style={{marginTop: 40}}>
            <Col>
              <div style={{
                fontFamily: 'OpenSans-SemiBold',
                fontSize: '1.2em',
                color: '#393b37',
                marginBottom: 15
              }}>
                {session.narasumber_instead_of_pengajar ? "Tentang Narasumber" : "Tentang Pengajar"}
              </div>

              <Accordion defaultActiveKey={0} onSelect={(selected) => {
                console.log('selecteed', selected)
                setInstructorExpandedIdx(selected)
              }}
              >
                {
                  session?.instructors?.map((instructor, key) => {
                    return (
                      <Card>
                        <Accordion.Toggle as={Card.Header} eventKey={key}
                                          style={{backgroundColor: Palette.SECONDARY}}>
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <img style={{
                              width: 50,
                              height: 50,
                              borderRadius: 45,
                              objectFit: 'cover'
                            }}
                                 src={instructor.instructor_image_url ? instructor.instructor_image_url : require('../../asset/public_image_default_user.png')}/>

                            <div style={{
                              marginLeft: 15,
                              color: 'white',
                              fontFamily: 'OpenSans-SemiBold',
                              flex: 1
                            }}>
                              {instructor.name}

                              <div style={{fontFamily: 'OpenSans-Regular'}}>
                                {instructor.position}
                              </div>
                            </div>

                            {
                              instructor.description &&
                              <>
                                {instructorExpandedIdx === key ?
                                  <FaMinus color={'#e6e6e6'}/> :
                                  <FaPlus color={'#e6e6e6'}/>}
                              </>
                            }

                          </div>
                        </Accordion.Toggle>
                        {instructor.description &&
                          <Accordion.Collapse eventKey={key}>
                            <Card.Body style={{fontFamily: 'OpenSans-Regular'}}>
                              {new Parser().parse(`<p>${instructor.description}</p>`)}
                            </Card.Body>
                          </Accordion.Collapse>
                        }
                      </Card>
                    )
                  })
                }
              </Accordion>
            </Col>
          </Row>
        }

        <QnASection session={session}/>

        {session.classStatus !== 'REGISTRATION' ?
          <>
            <Row style={{
              fontFamily: 'OpenSans-Bold',
              color: '#302a28',
              fontSize: 16,
              marginTop: 50,
              marginBottom: 20,
              marginLeft: 0
            }}>
              {editable_strings.rating_reviews_title}
            </Row>


            {
              feedbacks.length === 0 ?
                <>
                  <Row style={{marginTop: 40}}>
                    <Col xs={12} style={{marginBottom: 30}}>
                      <div style={{
                        border: '1px solid #D7D7D7CC',
                        borderRadius: 8,
                                                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#5F5555A6',
                        fontFamily: 'OpenSans-Regular'
                      }}>
                        Nilai dan ulasan belum tersedia
                      </div>
                    </Col>
                  </Row>
                </>
                :
                <>
                  <Row style={{marginLeft: 30, display: 'flex'}}
                  >
                    <div style={{fontFamily: 'OpenSans-SemiBold', fontSize: 50}}>
                      <Row style={{display: 'flex', justifyContent: 'center'}}>
                        {Math.round(averageRating * 10) / 10}
                      </Row>
                      <Row style={{marginTop: -35, display: 'flex', justifyContent: 'center'}}>
                        <Rating
                          readonly
                          initialRating={averageRating}
                          emptySymbol={<IoMdStar size={20} color={'#C4C4C4'}/>}
                          fullSymbol={<IoMdStar color={'red'} size={20} color={'#FEBF35'}/>}/>
                      </Row>

                      <Row style={{
                        color: '#00000080',
                        fontSize: 12,
                        fontFamily: 'OpenSans-Regular',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: -18
                      }}>
                        ({feedbacks.length} {Strings.rating.review})
                      </Row>
                      {
                        needFeedback ?
                          <Row style={{
                            marginTop: 10,
                            display: 'flex',
                            justifyContent: 'center'
                          }}>
                            <Button
                              style={{color: Palette.PRIMARY, fontSize: '.3em'}}
                              variant={'link'}
                              onClick={() => {
                                setFeedbackModalShown(true)
                              }}>
                              {Strings.rating.add_review}
                            </Button>
                          </Row>
                          :
                          null
                      }

                    </div>
                    <div style={{flex: 1, paddingLeft: 80, marginRight: 50}}>
                      <Row style={{display: 'flex', alignItems: 'center'}}>
                        <IoMdStar size={20} color={'#FEBF35'}/>
                        <div style={{
                          fontFamily: 'OpenSans-SemiBold',
                          fontSize: 14,
                          marginLeft: 8,
                          marginRight: 20
                        }}>5
                        </div>
                        <div style={{
                          flex: 1,
                          maxWidth: 200,
                          height: 6,
                          backgroundColor: '#EBE7DF',
                          borderRadius: 70,
                        }}>
                          <div style={{
                            height: '100%',
                            width: `${count5 / noOfFeedback * 100}%`,
                            backgroundColor: '#FEBF35',
                            borderRadius: 'inherit',
                            textAlign: 'right'
                          }}/>
                        </div>
                      </Row>

                      <Row style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                        <IoMdStar size={20} color={'#FEBF35'}/>
                        <div style={{
                          fontFamily: 'OpenSans-SemiBold',
                          fontSize: 14,
                          marginLeft: 8,
                          marginRight: 20
                        }}>4
                        </div>
                        <div style={{
                          flex: 1,
                          maxWidth: 200,
                          height: 6,
                          backgroundColor: '#EBE7DF',
                          borderRadius: 70
                        }}>
                          <div style={{
                            height: '100%',
                            width: `${count4 / noOfFeedback * 100}%`,
                            backgroundColor: '#FEBF35',
                            borderRadius: 'inherit',
                            textAlign: 'right'
                          }}/>
                        </div>
                      </Row>

                      <Row style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                        <IoMdStar size={20} color={'#FEBF35'}/>
                        <div style={{
                          fontFamily: 'OpenSans-SemiBold',
                          fontSize: 14,
                          marginLeft: 8,
                          marginRight: 20
                        }}>3
                        </div>
                        <div style={{
                          flex: 1,
                          maxWidth: 200,
                          height: 6,
                          backgroundColor: '#EBE7DF',
                          borderRadius: 70
                        }}>
                          <div style={{
                            height: '100%',
                            width: `${count3 / noOfFeedback * 100}%`,
                            backgroundColor: '#FEBF35',
                            borderRadius: 'inherit',
                            textAlign: 'right'
                          }}/>
                        </div>
                      </Row>

                      <Row style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                        <IoMdStar size={20} color={'#FEBF35'}/>
                        <div style={{
                          fontFamily: 'OpenSans-SemiBold',
                          fontSize: 14,
                          marginLeft: 8,
                          marginRight: 20
                        }}>2
                        </div>
                        <div style={{
                          flex: 1,
                          maxWidth: 200,
                          height: 6,
                          backgroundColor: '#EBE7DF',
                          borderRadius: 70
                        }}>
                          <div style={{
                            height: '100%',
                            width: `${count2 / noOfFeedback * 100}%`,
                            backgroundColor: '#FEBF35',
                            borderRadius: 'inherit',
                            textAlign: 'right'
                          }}/>
                        </div>
                      </Row>

                      <Row style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                        <IoMdStar size={20} color={'#FEBF35'}/>
                        <div style={{
                          fontFamily: 'OpenSans-SemiBold',
                          fontSize: 14,
                          marginLeft: 8,
                          marginRight: 20
                        }}>1
                        </div>
                        <div style={{
                          flex: 1,
                          maxWidth: 200,
                          height: 6,
                          backgroundColor: '#EBE7DF',
                          borderRadius: 70
                        }}>
                          <div style={{
                            height: '100%',
                            width: `${count1 / noOfFeedback * 100}%`,
                            backgroundColor: '#FEBF35',
                            borderRadius: 'inherit',
                          }}/>
                        </div>
                      </Row>

                    </div>
                  </Row>

                  <Row style={{marginTop: 40}}>
                    {
                      feedbacks.map(
                        (feedback, key) => {

                          if (!isFeedbackExtended && key > 1) {
                            return
                          }

                          return (
                            <Col xs={12} md={feedback.masukan.length > 500 ? 12 : 6}
                                 style={{marginBottom: 30}}>
                              <div style={{
                                border: '1px solid #D7D7D7CC',
                                borderRadius: 8,
                                paddingTop: 25,
                                minHeight: 220,
                                display: 'flex',
                                flexDirection: 'column',
                              }}>
                                <div style={{
                                  paddingLeft: 25,
                                  paddingRight: 25,
                                  flex: 1,
                                  paddingBottom: 25
                                }}>
                                  <div style={{display: 'flex'}}>
                                    <img
                                      style={{
                                        width: "2.5em",
                                        objectFit: "contain",
                                        borderRadius: "50%",
                                        height: "2.5em",
                                        padding: 1,
                                        backgroundColor: Palette.SECONDARY
                                      }}
                                      src={feedback.image_url ? feedback.image_url : require("../../asset/public_image_default_user.png")}/>

                                    <div style={{
                                      flex: 1,
                                      display: 'flex',
                                      paddingLeft: 15,
                                      flexDirection: 'column',
                                      paddingTop: '.25em'
                                    }}>
                                      <div style={{
                                        fontFamily: 'OpenSans-Bold',
                                        fontSize: '1.15em'
                                      }}>
                                        {feedback.full_name}
                                      </div>

                                      <div style={{
                                        marginLeft: 2,
                                        color: '#5F5555A6',
                                        fontFamily: 'OpenSans-Regular',
                                        marginTop: 20,
                                        maxLines: 5,
                                        lineHeight: "1.5em",
                                        minHeight: "7.5em",
                                        fontSize: '1.05em',
                                        whiteSpace: 'pre-line'
                                      }}>
                                        {feedback.masukan}
                                      </div>
                                    </div>
                                  </div>

                                </div>

                                <div style={{
                                  backgroundColor: '#7C777733',
                                  height: 1,
                                  width: '100%',
                                  marginLeft: 0
                                }}/>

                                <div style={{
                                  marginTop: 10,
                                  marginBottom: 15,
                                  textAlign: 'right',
                                  marginRight: 15
                                }}>
                                  <Rating
                                    readonly
                                    initialRating={feedback.average}
                                    emptySymbol={<IoMdStar size={25}
                                                           color={'#C4C4C4'}/>}
                                    fullSymbol={<IoMdStar size={25}
                                                          color={'#FEBF35'}/>}/>
                                </div>
                              </div>
                            </Col>
                          )
                        }
                      )
                    }
                    <Col md={12}>
                      {
                        feedbacks.length <= 2 ? null : <Button
                          style={{color: Palette.PRIMARY}}
                          variant={'link'}
                          onClick={() => {
                            setFeedbackExtended(!isFeedbackExtended)
                          }}>
                          {isFeedbackExtended ? 'Tampilkan Beberapa' : 'Tampilkan Semua Ulasan'}
                        </Button>
                      }

                    </Col>
                  </Row>
                </>
            }
          </> : null
        }

        {upcomingSession.length > 0 ?
          <>
            <Row style={{
              fontFamily: 'OpenSans-Bold',
              color: '#393b37',
              fontSize: '1.2em',
              marginTop: 50,
              marginBottom: -20,
              marginLeft: 0
            }}>
              Rekomendasi Kelas Lainnya
            </Row>

            <Row>
              {
                upcomingSession.map((session, key) => {
                  if (key > 2) return null;

                  return (
                    <SessionCard
                      key={key}
                      classStatus={session.classStatus}
                      registrationStatus={session.status}
                      permanent={session.permanent}
                      classDate={session.class_date}
                      image={session.class_image_url ? session.class_image_url : '/image/no-img-placeholder.png'}
                      name={session.name}
                      topic={session.topic}
                      onClick={() => {
                        history.push(`/kelas/detail/${session.code}`)
                      }}/>
                  )
                })
              }
            </Row>
          </> : null
        }
      </Container>
    </PageWrapper>
  )

}
